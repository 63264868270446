import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import { BASE_URL } from "views/Utiles/Constants";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

class AdminLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            user_name: "",
            password: "",
            current_time: new Date(),
            errors: {},
            isAdminUser: false,
            loginError: false,
            isLoading: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmitLoginAttempt = this.onSubmitLoginAttempt.bind(this);
    }
    componentDidMount() {
        document.title = "Login - Ambrosia Care";
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    componentWillReceiveProps() {
        if (localStorage.token) {
            window.location.reload();
        }
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSubmitLoginAttempt(e) {
        e.preventDefault();
        const userData = {
            user_name: this.state.user_name,
            password: this.state.password,
            last_login_time: this.formatTimestamp(this.state.current_time),
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/admin-login",
            data: userData,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    this.setState({ isLoading: false });
                    this.setState({ errors: DataJSON.msg });
                    this.setState({ loginError: "Oops!. Please check the username and password." });
                } else {
                    this.setState({ loginError: false, isAdminUser: true });
                    localStorage.setItem("loginType", "Admin");
                    localStorage.setItem("first_name", "Admin");
                    localStorage.setItem("last_name", "User");
                    localStorage.setItem("profile_picture", "null");
                    localStorage.setItem("token", DataJSON.data.token);
                    window.location.reload();
                }
            }.bind(this),
            error: (error) => {
                this.setState({ isLoading: false });
                this.setState({ loginError: "Somthing went wrong while authentaction. Please try again." });
                console.error(error);
            },
        });
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            second = "" + date.getSeconds(),
            millisecond = "" + date.getMilliseconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;
        if (millisecond.length < 2) millisecond = "00" + millisecond;
        return `${year}-${month}-${day} ${hours}:${minute}:${second}`;
    }
    render() {
        const { classes, ...rest } = this.props;
        return this.state.isAdminUser ? (
            <Redirect
                to={{
                    pathname: "caregiver-list",
                }}
            />
        ) : this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                <AuthNavbar brandText="Ambrosia Care" {...rest} />
                <div className={classes.wrapper} ref="wrapper">
                    <div className={classes.fullPage}>
                        <GridContainer style={{ width: "100%" }} justify="center">
                            <GridItem xs={12} sm={9} md={3}>
                                <form id="login-form" onSubmit={this.onSubmitLoginAttempt}>
                                    <Card login className={classes[this.state.cardAnimaton]}>
                                        <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="success">
                                            <h4 className={classes.cardTitle}>Log in</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Username"
                                                id="user_name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                onChange={this.onChange}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    name: "user_name",
                                                    value: this.state.user_name,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    name: "password",
                                                    type: "password",
                                                    value: this.state.password,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />

                                            {/* <h5 style={{ fontSize: "0.99em", color: "red" }}>
                                                <b>Note:</b> Once you login here, You will be logged out from the
                                                {this.state.userType === "patient" ? " LinkBluCon" : " FollowBluCon"} mobile app and you need to
                                                re-login in app.
                                            </h5> */}
                                        </CardBody>
                                        <CardFooter className={classes.justifyContentCenter}>
                                            <Button type="submit" color="primary" simple size="lg" block>
                                                Let's Go
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </form>
                            </GridItem>
                        </GridContainer>
                        <Footer white />
                    </div>
                </div>
            </div>
        );
    }
}

AdminLogin.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle, loginPageStyle)(AdminLogin);
