import React, { Component } from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
// import Assignment from "@material-ui/icons/Assignment";
import Delete from "@material-ui/icons/Delete";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const style = {
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0",
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
    },
};
class PatientInvitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            invitationData: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        document.title = "Invitations - Ambrosia Care";
        this.loadingInvitationsRecords();
    }

    loadingInvitationsRecords() {
        if (!localStorage.getItem("token") || localStorage.getItem("loginType") !== "Caregiver") {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-caregiver-invitations",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ invitationData: DataJSON.data, alert: null });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    handleOnInvitationUpdate(invitation_id, type) {
        const data = {
            token: localStorage.getItem("token"),
            invitation_id: invitation_id,
            action: type,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/invitation-action",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.loadingInvitationsRecords();
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    deleteInvitation(user_id) {
        const data = {
            token: localStorage.getItem("token"),
            patient_id: user_id,
            caregiver_email: localStorage.getItem("email"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-invitation",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.loadingInvitationsRecords()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Patient invitation has been deleted.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    warningWithConfirmMessage(user_id) {
        this.setState({
            alert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deleteInvitation(user_id)} onCancel={() => this.hideAlert()} confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success} cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger} confirmBtnText="Yes, delete it!" cancelBtnText="Cancel" showCancel>
                    You will not be able to recover this patient invitation again..!
                </SweetAlert>
            ),
        });
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    statusButtons(invitation_id, status) {
        return status === "Pending" ? (
            <div>
                <Button color="info" onClick={() => this.handleOnInvitationUpdate(invitation_id, "Accepted")}>
                    Accept
                </Button>
                <Button color="danger" onClick={() => this.handleOnInvitationUpdate(invitation_id, "Rejected")}>
                    Decline
                </Button>
            </div>
        ) : status === "Accepted" || status === "Unfollow" ? (
            <h5 style={{ color: "lightgreen", fontSize: "1.0em" }}>Accepted</h5>
        ) : status === "Rejected" ? (
            <h5 style={{ color: "lightred", fontSize: "1.0em" }}>Rejected</h5>
        ) : null;
    }

    deleteAction(user_id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessage(user_id)}>
                <Delete />
            </Button>
        );
    }

    render() {
        const { classes } = this.props;

        var readingCount = 0;
        const tableData = this.state.invitationData.map((data, i) => {
            const { invitation_id, user_id, first_name, last_name, email, status } = data;
            readingCount++;
            return [readingCount, `${first_name} ${last_name}`, email, this.statusButtons(invitation_id, status), this.deleteAction(user_id)];
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.alert}
                <GridContainer>
                    <GridItem xs={12}>
                        <Card style={{ background: "black" }}>
                            <CardHeader color="success" icon>
                                {/* <CardIcon color="success">
                                    <Assignment />
                                </CardIcon> */}
                                <h4 className={classes.cardIconTitle} style={{ color: "white" }}>Patient Invitations</h4>
                            </CardHeader>
                            <CardBody>
                                <PerfectScrollbar>
                                    <Table tableHeaderColor="primary" tableHead={["ID", "Patient Name", "Email", "Status", "Action"]} tableData={tableData} coloredColls={[3]} colorsColls={["primary"]} />
                                </PerfectScrollbar>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(style)(PatientInvitations);
