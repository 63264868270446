import { whiteColor } from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import image from "assets/img/LoginPageBGI.jpeg";

const pagesStyle = theme => ({
    ...customCheckboxRadioSwitch,
    ...regularFormsStyle,
    wrapper: {
        height: "auto",
        minHeight: "100vh",
        position: "relative",
        top: "0"
    },
    textCenter: {
        textAlign: "center"
    },
    fullPage: {
        padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        color: whiteColor,
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content!important"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "100%",
            border: "none !important"
        },

        "&:before": {
            display: "block",
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            opacity: "0.5",
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover"
            // backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
            //   zIndex: "2"
        }
        // commented for the login page design
        // "&:after": {
        //     display: "block",
        //     content: '""',
        //     position: "absolute",
        //     // width: "100%",
        //     // height: "100%",
        //     top: "0",
        //     left: "0"
        //     //   zIndex: "2"
        // }
    }
});

export default pagesStyle;
