import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";



// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";


class reportedDiabuddyUsers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tc: false,
            notification: [],
            isLoading: false,
            isGetDataLoading: false,
            userNotificationMessage: "",
            app_version: "All",
            app_name: "LBC",
            app_platform_name: "Android",
            notificationMessage: "",
            classicModal: false,
            checked: true,
            deleteAlert: null,
            isUpdate: false,
        };
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.showNotification = this.showNotification.bind(this);
        // this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.getDiabuddyUsers = this.getDiabuddyUsers.bind(this);
        // this.onChange = this.onChange.bind(this);
        // this.setClassicModal = this.setClassicModal.bind(this);
        // this.getCheckboxValue = this.getCheckboxValue.bind(this);
        // this.addNotificationDialog = this.addNotificationDialog.bind(this);
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }

    componentDidMount() {
        document.title = "Users Dia Buddy - Ambrosia Care";
        this.getDiabuddyUsers();
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    getDiabuddyUsers() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isGetDataLoading: true });
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-reported-diabuddy-users",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ notification: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ notification: DataJSON.data, deleteAlert: null });
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }







    render() {


        var readingCount = 0;
        const tableData = this.state.notification.map((data, i) => {
            const { user_id, first_name, last_name, email, count } = data;

            readingCount++;
            return {
                id: readingCount,
                user_id: user_id,
                first_name: first_name,
                last_name: last_name,
                email: email,
                count: count,
            };
        });
        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}


                {this.state.isGetDataLoading ? (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody style={{ height: "100px" }}>
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : (
                    <div>
                        <GridContainer>
                            {tableData.length > 0 ? (
                                <Card>
                                    <CardBody>
                                        <PerfectScrollbar>
                                            <ReactTable
                                                data={tableData}
                                                filterable
                                                columns={[
                                                    {
                                                        width: 100,
                                                        Header: "ID",
                                                        accessor: "id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 100,
                                                        Header: "User ID",
                                                        accessor: "user_id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "First Name",
                                                        accessor: "first_name",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Last Name",
                                                        accessor: "last_name",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 180,
                                                        Header: "Email",
                                                        accessor: "email",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 100,
                                                        Header: "Count",
                                                        accessor: "count",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },

                                                ]}
                                                defaultPageSize={5}
                                                showPaginationTop
                                                showPaginationBottom={false}
                                                className="-highlight"
                                            />
                                        </PerfectScrollbar>
                                    </CardBody>
                                </Card>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    There are no dia buddy users found.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(reportedDiabuddyUsers);
