const isRelease = true;

export const BASE_URL = isRelease ? "https://developer.ambrosiasys.com/app-server/ios" : "http://localhost/blucon-rest-api/app-server/ios";
// export const BASE_URL = "http://192.168.10.118/blucon-rest-api/app-server/ios" ;

// Google Fit constants variable
export const GOOGLE_FIT_BASE_URL = isRelease ? "https://developer.ambrosiasys.com/google-fit" : "http://localhost/blucon/google-fit";

export const GOOGLE_FIT_AUTHENTICATE_URL = "https://accounts.google.com/o/oauth2/auth";

export const GOOGLE_FIT_CLIENT_ID = "468330241774-v1198u2hfaa56671im4l10gj3cncbnsf.apps.googleusercontent.com";

export const GOOGLE_FIT_REDIRECT_URI = isRelease ? "https%3A%2F%2Fwww.ambrosia.care%2FgoogleFit" : "http%3A%2F%2Flocalhost%3A3000%2FgoogleFit";

// Fitbit constants variable
export const FITBIT_BASE_URL = isRelease ? "https://developer.ambrosiasys.com/fitbit-authentication" : "http://localhost/blucon/fitbit-authentication";

export const FITBIT_AUTHENTICATE_URL = "https://www.fitbit.com/oauth2/authorize";

export const FITBIT_CLIENT_ID = isRelease ? "22DMVR" : "22DRH3";

export const FITBIT_REDIRECT_URI = isRelease ? "https%3A%2F%2Fwww.ambrosia.care%2Ffitbit-activities" : "http%3A%2F%2Flocalhost%3A3000%2Ffitbit-activities";

export function removeFromLocalStorage() {
    localStorage.removeItem("loginType");
    localStorage.removeItem("token");
    localStorage.removeItem("device_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("glucose_unit");
    localStorage.removeItem("fitbit_access_token");
    localStorage.removeItem("fitbit_refresh_token");
    localStorage.removeItem("google_fit_access_token");
    localStorage.removeItem("profile_picture");
    localStorage.removeItem("is_parent");
    localStorage.removeItem("my-followers");
}
