import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import SweetAlert from "react-bootstrap-sweetalert";
import { Editor } from "@tinymce/tinymce-react";
import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Close from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Delete from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class AddNotification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tc: false,
            notification: [],
            isLoading: false,
            isGetDataLoading: false,
            userNotificationMessage: "",
            app_version: "All",
            app_name: "LBC",
            app_platform_name: "Android",
            notificationMessage: "",
            classicModal: false,
            checked: true,
            deleteAlert: null,
            isUpdate: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.getUserNotification = this.getUserNotification.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setClassicModal = this.setClassicModal.bind(this);
        this.getCheckboxValue = this.getCheckboxValue.bind(this);
        this.addNotificationDialog = this.addNotificationDialog.bind(this);
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }

    componentDidMount() {
        document.title = "User Notification - Ambrosia Care";
        this.getUserNotification();
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    getUserNotification() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isGetDataLoading: true });
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-user-notification",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ notification: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ notification: DataJSON.data, deleteAlert: null });
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleSubmitForm() {
        this.setState({ isUpdate: false });
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
            app_name: this.state.app_name,
            app_version: this.state.app_version,
            notification_message: this.state.userNotificationMessage,
            app_platform_name: this.state.app_platform_name,
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-user-notification",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ notificationMessage: "Something went wrong while adding user notification." }, () => {
                        this.showNotification("tc_danger");
                    });
                    // this.showNotification("tc");
                } else {
                    this.setState({ notificationMessage: "User notification added." }, () => {
                        this.showNotification("tc_info");
                        this.setClassicModal(false);
                        this.getUserNotification();
                    });
                }
                this.setState({ isLoading: false, userNotificationMessage: "", app_version: "All", checked: true, app_platform_name: "Android", app_name: "LBC" });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    deleteUserNotification(id) {
        const data = {
            token: localStorage.getItem("token"),
            id: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-user-notification",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.getUserNotification()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                User Notification Deleted.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    updateUserNotification(id, userNotificationMessage, app_version, app_name, app_platform_name) {
        const data = {
            token: localStorage.getItem("token"),
            id: id,
            notification_message: userNotificationMessage,
            app_version: app_version,
            app_name: app_name,
            app_platform_name: app_platform_name,
            platform_name: "WEB",
        };

        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-user-notification",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else if (DataJSON.msg.includes("Something went wrong while updating the details.")) {
                        this.setState({ notificationMessage: "Something went wrong while updating notification message." }, () => {
                            this.showNotification("tc_danger");
                        });
                    }
                } else {
                    this.setState({
                        userNotificationMessage: "",
                    });
                    this.setState({ notificationMessage: "User notification updated." }, () => {
                        this.showNotification("tc_info");
                        this.setClassicModal(false);
                    });
                    this.getUserNotification();
                }
                this.setState({ isLoading: false, app_version: "All", checked: true, app_name: "LBC", app_platform_name: "Android", userNotificationMessage: "" });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    warningWithConfirmMessageUpdate(id, userNotificationMessage, app_name, app_version, app_platform_name) {
        this.setState({ isUpdate: true });
        this.setClassicModal(true);
        this.setState({ id: id, userNotificationMessage: userNotificationMessage, app_name: app_name, app_version: app_version, app_platform_name: app_platform_name });
    }
    updateUserNotificationData(id, userNotificationMessage, app_version, app_name, app_platform_name) {
        return (
            <Button justIcon round simple color="primary" className="remove" onClick={() => this.warningWithConfirmMessageUpdate(id, userNotificationMessage, app_name, app_version, app_platform_name)}>
                <CreateIcon />
            </Button>
        );
    }
    warningWithConfirmMessageDelete(id) {
        this.setState({
            deleteAlert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deleteUserNotification(id)} onCancel={() => this.hideAlertDelete()} confirmBtnText="Yes, Delete it!" cancelBtnText="Cancel" showCancel>
                    Your data will be lost .
                </SweetAlert>
            ),
        });
    }
    deleteUserNotificationData(id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessageDelete(id)}>
                <Delete />
            </Button>
        );
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    addNotificationDialog() {
        this.setState({
            isUpdate: false,
        });
        this.setClassicModal(true);
    }
    setClassicModal(classicModal) {
        this.setState({ app_version: "All", checked: true, app_name: "LBC", app_platform_name: "Android", userNotificationMessage: "" });
        this.setState({
            classicModal: classicModal,
        });
    }
    getCheckboxValue() {
        this.state.checked === false ? this.setState({ checked: true }) : this.setState({ checked: false });
        this.state.app_version === "All" ? this.setState({ app_version: "" }) : this.setState({ app_version: "All" });
        return this.state.checked;
    }
    handleEditorChange = (userNotificationMessage, editor) => {
        this.setState({ userNotificationMessage });
    };

    render() {
        const { classes } = this.props;

        var readingCount = 0;
        const tableData = this.state.notification.map((data, i) => {
            const { id, app_version, app_name, notification_message, app_platform_name, created_time, updated_time } = data;

            readingCount++;
            return {
                id: readingCount,
                app_name: app_name,
                app_version: app_version,
                notification_message: notification_message,
                app_platform_name: app_platform_name,
                created_time: created_time,
                updated_time: updated_time,
                update: this.updateUserNotificationData(id, notification_message, app_version, app_name, app_platform_name),
                delete: this.deleteUserNotificationData(id),
            };
        });
        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Dialog
                            classes={{
                                root: classes.center + " " + classes.modalRoot,
                                paper: classes.modal,
                            }}
                            open={this.state.classicModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.setClassicModal(false)}
                            aria-labelledby="classic-modal-slide-title"
                            aria-describedby="classic-modal-slide-description"
                            PaperProps={{
                                style: {
                                    minHeight: "30vh",
                                    minWidth: "30vw",
                                },
                            }}
                        >
                            <DialogTitle style={{ display: "flex", alignItems: "center" }} id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                                <h4 style={{ textAlign: "center", width: "100%" }} className={classes.modalTitle}>
                                    {this.state.isUpdate !== false ? "Update User Notification" : "Add User Notification"}
                                </h4>
                                <Button justIcon className={classes.modalClose} style={{ position: "absolute", right: "20px" }} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                    <Close className={classes.modalCloseButton} />
                                </Button>
                            </DialogTitle>
                            <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} />
                                    <CustomInput
                                        labelText="App Version"
                                        id="app_version"
                                        name="app_version"
                                        inputProps={{
                                            value: `${this.state.app_version}`,
                                            disabled: this.state.app_version === "All" ? true : false,
                                        }}
                                        formControlProps={{
                                            onChange: this.handleInputChange,
                                            fullWidth: false,
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.app_version === "All" ? true : false}
                                                onChange={this.getCheckboxValue}
                                                classes={{
                                                    root: classes.root,
                                                    checked: classes.checked,
                                                }}
                                            />
                                        }
                                        label={"All"}
                                    />
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} />
                                    <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000", marginRight: "5px" }}>
                                        Platform Name:
                                    </h5>

                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_platform_name === "Android"}
                                                onChange={this.onChange}
                                                value="Android"
                                                name="app_platform_name"
                                                aria-label="Android"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Android"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_platform_name === "iOS"}
                                                onChange={this.onChange}
                                                value="iOS"
                                                name="app_platform_name"
                                                aria-label="iOS"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="iOS"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_platform_name === "Both"}
                                                onChange={this.onChange}
                                                value="Both"
                                                name="app_platform_name"
                                                aria-label="Both"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Both"
                                    />
                                </GridContainer>
                                <GridContainer>
                                    <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000", marginRight: "5px" }}>
                                        Application Name:
                                    </h5>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_name === "LBC"}
                                                onChange={this.onChange}
                                                value="LBC"
                                                name="app_name"
                                                aria-label="Patient (LinkBluCon)"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Patient (LinkBluCon)"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_name === "FBC"}
                                                onChange={this.onChange}
                                                value="FBC"
                                                name="app_name"
                                                aria-label="Caregiver (FollowBluCon)"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Caregiver (FollowBluCon)"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.app_name === "Both"}
                                                onChange={this.onChange}
                                                value="Both"
                                                name="app_name"
                                                aria-label="Both"
                                                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label,
                                        }}
                                        label="Both"
                                    />
                                </GridContainer>
                                <GridContainer>
                                    <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                        Notification Message:
                                    </h5>
                                    <Editor
                                        apiKey="0q7izru2yq2vohfc5b1e2cn6264n49nfqzugmsg6ihvagut1"
                                        value={this.state.userNotificationMessage}
                                        init={{
                                            height: 250,
                                            menubar: false,
                                            plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                                            toolbar: "undo redo | formatselect | bold italic backcolor |   alignleft aligncenter alignright alignjustify |    bullist numlist outdent indent | removeformat | help",
                                        }}
                                        onEditorChange={this.handleEditorChange}
                                    />
                                </GridContainer>
                            </DialogContent>
                            <DialogActions className={classes.modalFooter}>
                                <Button justIcon round simple color="primary" disabled={this.state.app_version === "" || this.state.userNotificationMessage === ""} className="remove" onClick={() => (this.state.isUpdate === true ? this.updateUserNotification(this.state.id, this.state.userNotificationMessage, this.state.app_version, this.state.app_name, this.state.app_platform_name) : this.handleSubmitForm())}>
                                    <CheckIcon />
                                </Button>
                                <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Clearfix />
                    </GridItem>
                </GridContainer>
                <Button style={{ marginLeft: "10px" }} color="info" onClick={this.addNotificationDialog} className={classes.updateProfileButton}>
                    Add Notification
                </Button>
                {this.state.isGetDataLoading ? (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody style={{ height: "100px" }}>
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : (
                    <div>
                        <GridContainer>
                            {tableData.length > 0 ? (
                                <Card>
                                    <CardBody>
                                        <PerfectScrollbar>
                                            <ReactTable
                                                data={tableData}
                                                filterable
                                                columns={[
                                                    {
                                                        width: 100,
                                                        Header: "ID",
                                                        accessor: "id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "App Name",
                                                        accessor: "app_name",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "App Version",
                                                        accessor: "app_version",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        Header: "Notification Message",
                                                        accessor: "notification_message",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 180,
                                                        Header: "Platform Name",
                                                        accessor: "app_platform_name",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 180,
                                                        Header: "Created Time",
                                                        accessor: "created_time",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Updated Time",
                                                        accessor: "updated_time",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 100,
                                                        Header: "Action",
                                                        accessor: "update",
                                                        Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
                                                        filterable: false,
                                                        sortable: false,
                                                    },
                                                    {
                                                        width: 100,
                                                        Header: "",
                                                        accessor: "delete",
                                                        filterable: false,
                                                        sortable: false,
                                                    },
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationTop
                                                showPaginationBottom={false}
                                                className="-highlight"
                                            />
                                        </PerfectScrollbar>
                                    </CardBody>
                                </Card>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    There are no user notification available.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(AddNotification);
