import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import ShowReportedUsers from "../Modal/ShowReportedUsers";
import Switch from "@material-ui/core/Switch";

class diabuddyUsers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tc: false,
            diabuddyArry: [],
            diabuddyReportedArry: [],
            isLoading: false,
            isGetDataLoading: false,
            userNotificationMessage: "",
            app_version: "All",
            app_name: "LBC",
            app_platform_name: "Android",
            deleteAlert: null,
            user_id: 0,
            modalShow: false,
        };

        this.modalClose = this.modalClose.bind(this);
        this.showReportedUsersDetails = this.showReportedUsersDetails.bind(this);
        this.getDiabuddyUsers = this.getDiabuddyUsers.bind(this);
    }
    modalClose = () => this.setState({ modalShow: false });
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }

    componentDidMount() {
        document.title = "Users Dia Buddy - Ambrosia Care";
        this.getDiabuddyUsers();
    }

    getDiabuddyUsers() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }

        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-all-diabuddy-users",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ diabuddyArry: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ diabuddyArry: DataJSON.data, deleteAlert: null });
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    warningWithConfirmMessageUpdateEnabled(user_id, value) {
        this.setState({
            deleteAlert: <SweetAlert warning style={{ display: "block" }} title="Are you sure?" onConfirm={() => this.updateDiabuddyUserEnableStatus(user_id, value)} onCancel={() => this.hideAlertDelete()} confirmBtnText={value === "0" ? "Yes, Enable" : "Yes, Disable"} cancelBtnText="Cancel" showCancel />,
        });
    }

    warningWithConfirmMessageUpdateBlock(user_id, value) {
        this.setState({
            deleteAlert: <SweetAlert warning style={{ display: "block" }} title="Are you sure?" onConfirm={() => this.updateDiabuddyUserBlockStatus(user_id, value)} onCancel={() => this.hideAlertDelete()} confirmBtnText={value === "0" ? "Yes, Block" : "Yes, Unblock"} cancelBtnText="Cancel" showCancel />,
        });
    }

    showEnabledButton(value, id) {
        return <Switch checked={value === "1" ? true : false} onChange={() => this.warningWithConfirmMessageUpdateEnabled(id, value)} color="primary" />;
        // <button className="btn btn-primary" onClick={() => this.warningWithConfirmMessageUpdateEnabled(id, value)}>
        //     {value === "0" ? "Enable" : "Disable"}
        // </button>
    }

    showBlockedButton(value, id) {
        return <Switch checked={value === "1" ? true : false} onChange={() => this.warningWithConfirmMessageUpdateBlock(id, value)} color="primary" />;
    }

    showReportedUsersList(id, value) {
        return (
            <button className="btn btn-primary" onClick={() => this.showReportedUsersDetails(id)}>
                {value}
            </button>
        );
    }

    showReportedUsersDetails(id) {
        const data = {
            token: localStorage.getItem("token"),
            reported_id: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-reported-by-diabuddy-users",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ modalShow: false, diabuddyReportedArry: [] });
                } else {
                    this.setState({ diabuddyReportedArry: DataJSON.data, modalShow: true });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    updateDiabuddyUserEnableStatus(id, value) {
        const data = {
            token: localStorage.getItem("token"),
            id: id,
            value: value === "1" ? 0 : 1,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-enabled-for-diabuddy-user",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Updated..." onConfirm={() => this.getDiabuddyUsers()}>
                                User's Enabled status Updated.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    updateDiabuddyUserBlockStatus(id, value) {
        const data = {
            token: localStorage.getItem("token"),
            reported_id: id,
            value: value === "1" ? 0 : 1,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/block-diabuddy-user-update",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Updated..." onConfirm={() => this.getDiabuddyUsers()}>
                                User's Block status Updated.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    render() {
        var readingCount = 0;
        const tableData = this.state.diabuddyArry.map((data, i) => {
            const { user_id, nick_name, enabled, is_blocked, email, count } = data;

            readingCount++;
            return {
                id: readingCount,
                user_id: user_id,
                nick_name: nick_name,
                showEnable: this.showEnabledButton(enabled, user_id),
                is_blocked: is_blocked,
                email: email,
                showReported: this.showReportedUsersList(user_id, count),
                isBlocked: this.showBlockedButton(is_blocked, user_id),
            };
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                {this.state.isGetDataLoading ? (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody style={{ height: "100px" }}>
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : (
                    <div>
                        <ShowReportedUsers show={this.state.modalShow} onHide={this.modalClose} title="Reported Diabuddy List" data={this.state.diabuddyReportedArry} />
                        <GridContainer>
                            {tableData.length > 0 ? (
                                <Card>
                                    <CardBody>
                                        <PerfectScrollbar>
                                            <ReactTable
                                                data={tableData}
                                                filterable
                                                columns={[
                                                    {
                                                        width: 100,
                                                        Header: "ID",
                                                        accessor: "id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Nick Name",
                                                        accessor: "nick_name",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 120,
                                                        Header: "Enabled",
                                                        accessor: "showEnable",
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 120,
                                                        Header: "Blocked",
                                                        accessor: "isBlocked",
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 250,
                                                        Header: "Email",
                                                        accessor: "email",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Reported Count",
                                                        accessor: "showReported",
                                                        filterable: false,
                                                    },
                                                ]}
                                                defaultPageSize={5}
                                                showPaginationTop
                                                showPaginationBottom={false}
                                                className="-highlight"
                                            />
                                        </PerfectScrollbar>
                                    </CardBody>
                                </Card>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    There are no dia buddy users found.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(diabuddyUsers);
