import React, { Component } from "react";
import $ from "jquery";
import { BASE_URL, FITBIT_BASE_URL, FITBIT_AUTHENTICATE_URL, FITBIT_CLIENT_ID, FITBIT_REDIRECT_URI, removeFromLocalStorage } from "views/Utiles/Constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { OverlayTrigger, Popover } from "react-bootstrap";

class FitbitAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            executed: false,
            isLoading: false,
            syncButton: false,
            maxDate: moment(),
            begin_date: new Date().valueOf() - 14 * 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            tableData: [],
        };
        this.authenticate = this.authenticate.bind(this);
        this.syncData = this.syncData.bind(this);
        this.getFitbitData = this.getFitbitData.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.getFitbitNewData = this.getFitbitNewData.bind(this);
        this.getFitbitWeekData = this.getFitbitWeekData.bind(this);
    }

    componentDidMount() {
        document.title = "Fitbit Activities - Ambrosia Care";
        const urlParams = new URLSearchParams(this.props.location.search);
        const code = urlParams.get("code");
        if (code !== null) {
            $.ajax({
                method: "POST",
                url: FITBIT_BASE_URL + "/authorize",
                data: {
                    code: code,
                    patientId: localStorage.getItem("user_id"),
                    platform_name: "WEB",
                },
                success: function (data) {
                    var DataJSON = JSON.parse(data);
                    if (DataJSON.success === true) {
                        localStorage.setItem("fitbit_access_token", DataJSON.fitbit_access_token);
                        localStorage.setItem("fitbit_refresh_token", DataJSON.fitbit_refresh_token);
                    }
                    this.props.history.push("/fitbit-activities");
                    // this.getFitbitData();
                    this.getFitbitWeekData();
                    // this.getFitbitNewData();
                }.bind(this),
                error: (error) => {
                    console.error(error);
                },
            });
        }
        // For Geting Fitbit Data For Table
        if (localStorage.getItem("fitbit_access_token") !== "") {
            // this.getFitbitData();
            this.getFitbitWeekData();
            // this.getFitbitNewData();
        }
    }
    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
        this.getFitbitNewData();
    }
    authenticate() {
        window.location.href = FITBIT_AUTHENTICATE_URL + "?response_type=code&client_id=" + FITBIT_CLIENT_ID + "&redirect_uri=" + FITBIT_REDIRECT_URI + "&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800";
    }

    syncData() {
        this.setState({ isLoading: true });
        this.setState({ syncButton: true });
        $.ajax({
            method: "POST",
            url: FITBIT_BASE_URL + "/sync_data",
            data: {
                patientId: localStorage.getItem("user_id"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    localStorage.setItem("fitbit_access_token", DataJSON.fitbit_access_token);
                    localStorage.setItem("fitbit_refresh_token", DataJSON.fitbit_refresh_token);
                    // this.getFitbitData();
                    // this.getFitbitWeekData();
                    this.getFitbitNewData();
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return `${year}-${month}-${day}`;
    }
    getFitbitNewData() {
        this.setState({ syncButton: true });
        this.setState({ isLoading: true });

        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("user_id"),
            begin_date: this.formatTimestamp(new Date(this.state.begin_date)),
            end_date: this.formatTimestamp(new Date(this.state.end_date)),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-new-fitbit-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                // console.log(DataJSON);
                if (DataJSON.success === false) {
                    if ("msg" in DataJSON && DataJSON.msg.includes("Data not available.")) {
                        this.setState({ tableData: [] });
                    }
                    if ("msg" in DataJSON && DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    // if (!DataJSON.data.length) {
                    //     this.setState({ tableData: DataJSON.data });
                    // }
                } else {
                    this.setState({ tableData: DataJSON.data });
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getFitbitWeekData() {
        this.setState({ syncButton: true });
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("user_id"),
            begin_date: this.formatTimestamp(new Date(this.state.begin_date)),
            end_date: this.formatTimestamp(new Date(this.state.end_date)),
            platform_name: "WEB",
        };

        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-new-fitbit-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if ("msg" in DataJSON && DataJSON.msg.includes("Data not available.")) {
                        this.setState({ tableData: [] });
                    }
                    if ("msg" in DataJSON && DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ tableData: DataJSON.data });
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getFitbitData() {
        this.setState({ isLoading: true });
        this.setState({ syncButton: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-fitbit-data",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("user_id"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (!DataJSON.data.length) {
                        this.setState({ tableData: DataJSON.data });
                    }
                    if ("msg" in DataJSON && DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ tableData: DataJSON.data });
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    render() {
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };
        const { classes } = this.props;
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h3">Step 1: Authorize</Popover.Title>
                <Popover.Content>By clicking Authorize button you will be redirected to Fitbit login page to provide access to ambrosia care platform.</Popover.Content>
                <Popover.Title as="h3">Step 2: Sync</Popover.Title>
                <Popover.Content>Sync Data button will sync data from Fitbit server to platform from last sync time to current time and loads last 14 days data.</Popover.Content>
                <Popover.Title as="h3">Step 3: Generate</Popover.Title>
                <Popover.Content>After selecting date range when you click on Show Data button, you will be able to see data for those dates.</Popover.Content>
            </Popover>
        );
        return localStorage.getItem("fitbit_access_token") === "null" || localStorage.getItem("fitbit_access_token") === "" ? (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button color="info" className={classes.marginRight} onClick={this.authenticate}>
                        Authorize
                    </Button>
                </GridItem>
            </GridContainer>
        ) : (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div style={divStyle}>
                                    <div>
                                        <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                                            <Button id="infoIcon" style={{ marginRight: "1rem" }} color="info">
                                                <FontAwesomeIcon icon={"info"} size="2x" />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                    <Button style={{ marginRight: "10px" }} color="info" className={classes.marginRight} disabled={this.state.syncButton} onClick={this.syncData}>
                                        Sync Data
                                    </Button>
                                    <DateRangePicker dateLimit={{ days: 90 }} maxDate={this.state.maxDate} onApply={this.handleEvent}>
                                        <Button id="datePicker" style={{ marginRight: "1rem" }} color="info">
                                            <FontAwesomeIcon icon={"calendar"} size="2x" />
                                        </Button>
                                    </DateRangePicker>
                                    <h5 style={{ fontSize: "0.99em", color: "#000000", marginLeft: "10px" }}>
                                        <b>Date Frame:</b>
                                        {` ${new Date(this.state.begin_date).toLocaleDateString()} To ${new Date(this.state.end_date).toLocaleDateString()}`}
                                    </h5>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                    <GridContainer>
                        {
                            <GridItem xs={12} sm={12} md={12}>
                                <Card plain>
                                    <CardBody style={{ height: "100px" }}>
                                        <CircularProgress
                                            style={{
                                                position: "absolute",
                                                left: "50%",
                                                top: "50%",
                                            }}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                        }
                    </GridContainer>
                ) : this.state.tableData.length !== 0 ? (
                    this.state.tableData.map((data, count) => {
                        const tableData = [];
                        tableData.push(data);
                        return (
                            <GridContainer key={count}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                <b>Date: </b>
                                                {data.date}
                                            </h5>
                                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                <b>Steps: </b>
                                                {data.steps}
                                            </h5>
                                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                <b>Distance: </b>
                                                {data.distance}
                                            </h5>
                                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                <b>Calories: </b>
                                                {data.calories}
                                            </h5>
                                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                <b>Heart Rate: </b>
                                                {data.heart_rate}
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        );
                    })
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody style={{ height: "200px" }}>
                                    <h4
                                        style={{
                                            marginTop: "80px",
                                            textAlign: "center",
                                        }}
                                    >
                                        There are no fitbit data available for this period.
                                    </h4>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                )}
            </div>
        );
    }
}

export default withStyles(extendedTablesStyle)(FitbitAuthentication);
