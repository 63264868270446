import React from "react";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { getSampleTypeData } from "views/Utiles/Utils";
import PatientGoogleFitData from "views/Patient/patientGoogleFitData";
import PatientAppleHealthData from "views/Patient/patientAppleHealthData";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

class patientHealthDataSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            sampleType: [],
            patients: [],
            isLoading: false,
            isPatientSelect: false,
            maxDate: moment(),
            begin_date: new Date().valueOf() - 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            device_name: [],
            device: "",
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("user_id"),
            patient_email: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("email"),
            patient_name: localStorage.getItem("loginType") === "Caregiver" ? "" : `${localStorage.getItem("first_name").toUpperCase()} ${localStorage.getItem("last_name").toUpperCase()}`,
        };
        this.generateHealthData = this.generateHealthData.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handlePatientDropdownChange = this.handlePatientDropdownChange.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    componentDidMount() {
        document.title = "Patient, Device & Date Selection - Ambrosia Care";
        this.state.device_name.push("GOOGLE_FIT", "APPLE_HEALTH");
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
            const followers = localStorage.getItem("my-followers");
            if (followers) this.setState({ patients: JSON.parse(followers) });
        }
    }

    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
    }

    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()}`,
            patient_email: e.email,
        });
    }

    handleDevicNameDropdownChange(e) {
        this.setState({
            device: e,
        });
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    generateHealthData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            patientId: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB",
            device_name: this.state.device,
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-patient-health-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ sampleType: [], isPatientSelect: true });
                    }
                    this.showNotification("tc");
                } else if (DataJSON.success === true) {
                    const sampleTypeData = [];
                    DataJSON.data.forEach((data) => {
                        sampleTypeData.push(getSampleTypeData(data));
                    });
                    this.setState({ sampleType: sampleTypeData, isPatientSelect: true });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleBackButton() {
        this.setState({
            sampleType: [],
            isPatientSelect: false,
        });
    }
    render() {
        const { classes } = this.props;
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };
        return this.state.isPatientSelect ? (
            this.state.device === "GOOGLE_FIT" ? (
                <PatientGoogleFitData handleBackButton={this.handleBackButton} sampleType={this.state.sampleType} patientName={this.state.patient_name} device_name={this.state.device} begin_date={this.state.begin_date} end_date={this.state.end_date} />
            ) : (
                <PatientAppleHealthData handleBackButton={this.handleBackButton} sampleType={this.state.sampleType} patientName={this.state.patient_name} device_name={this.state.device} begin_date={this.state.begin_date} end_date={this.state.end_date} />
            )
        ) : (
            <GridContainer style={{
                display: "flex",
                alignItems: "center",
            }}>
                {/* <GridItem xs={12} sm={12} md={3} /> */}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card style={{ background: "black" }}>
                        <CardHeader color="info" icon>
                            <h4 className={classes.cardIconTitle} style={{ color: "white" }}>Select Patient, Device & Date</h4>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "white" }}>
                                <b>Patient Name:</b> {this.state.patient_name}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "white" }}>
                                <b>Date Frame:</b>
                                {`${new Date(this.state.begin_date).toLocaleDateString()} To ${new Date(this.state.end_date).toLocaleDateString()}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "white" }}>
                                <b>Device Name:</b>
                                {this.state.device}
                            </h5>
                        </CardHeader>
                        <CardBody >
                            <form>
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                    {localStorage.getItem("loginType") === "Caregiver" ? (
                                        <div style={divStyle}>
                                            <CustomDropdown
                                                hoverColor="info"
                                                buttonText={this.state.patient_name === "" ? "Select Patient" : this.state.patient_name}
                                                buttonProps={{
                                                    round: true,
                                                    fullWidth: true,
                                                    style: { marginBottom: "0", width: "300px" },
                                                    color: "info",
                                                }}
                                                id="secondYaxis"
                                                dropdownList={this.state.patients.map((e, i) => (
                                                    <p
                                                        style={{
                                                            display: "block",        // Makes the <p> behave like an <li>
                                                            color: "white",
                                                            width: "100%",
                                                            margin: 0,               // Removes any default margin
                                                            padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                            boxSizing: "border-box",
                                                        }}
                                                        onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                                        {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                                    </p>
                                                ))}
                                            />
                                            <CustomDropdown
                                                hoverColor="info"
                                                buttonText={this.state.device === "" ? "Select device" : this.state.device}
                                                buttonProps={{
                                                    round: true,
                                                    fullWidth: false,
                                                    style: { marginBottom: "0", marginLeft: "10px", marginRight: "10px" },
                                                    color: "info",
                                                }}
                                                id="secondYaxis"
                                                dropdownList={this.state.device_name.map((e, i) => (
                                                    <p
                                                        style={{
                                                            display: "block",        // Makes the <p> behave like an <li>
                                                            color: "white",
                                                            width: "100%",
                                                            margin: 0,               // Removes any default margin
                                                            padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                            boxSizing: "border-box",
                                                        }}
                                                        onClick={() => this.handleDevicNameDropdownChange(e)}>{`${e} `}</p>
                                                ))}
                                            />
                                        </div>
                                    ) : null}
                                    <div>
                                        <DateRangePicker dateLimit={{ days: 89 }} maxDate={this.state.maxDate} onApply={this.handleEvent}>
                                            <Button id="datePicker" style={{ marginRight: "1rem", marginLeft: "1rem" }} color="info">
                                                <FontAwesomeIcon icon={"calendar"} size="2x" />
                                            </Button>
                                        </DateRangePicker>
                                        <Button color="info" disabled={this.state.patient_name === "" || this.state.device === "" ? true : false} onClick={this.generateHealthData}>
                                            Show Health Data
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={3} /> */}
            </GridContainer>
        );
    }
}

export default withStyles(validationFormsStyle)(patientHealthDataSelection);
