import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Avatar from "react-avatar";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { getCurrentTimeInMillies, getGlucoseValue, getMemberId } from "../Utiles/Utils";
import CommanProfile from "../Utiles/CommanProfile";
import { FormControl, InputLabel } from "@mui/material";
import { MenuItem, Select } from "@material-ui/core";
import color from "@material-ui/core/colors/amber";

class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            isLoading: false,
            isPatientSelect: false,
            patientName: "",
            patientFirstName: "",
            patientEmail: "",
            glucose_unit: "",
            page_row: 10,
            page_number: 0,
            last_index: 9,
            start_index: 0,
            total_page: 0,
        };
    }


    componentDidMount() {
        document.title = "Patient List - Ambrosia Care";
        if (!localStorage.getItem("token") || localStorage.getItem("loginType") !== "Caregiver") {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-count-followers",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg !== undefined) {
                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                        }
                    }
                } else {
                    this.setState({ total_page: parseInt(DataJSON.data) });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-my-followers-care",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
                page_start: this.state.start_index,
                page_quantity: this.state.page_row
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg !== undefined) {
                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                            this.setState({ isLoading: false });
                        }
                    }
                } else {
                    this.setState({ patients: DataJSON.data.data });
                    localStorage.setItem("my-followers", JSON.stringify(DataJSON.data.data));
                    this.setState({ isLoading: false });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    apiCall(index, row) {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-my-followers-care",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
                page_start: index,
                page_quantity: row,
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg !== undefined) {
                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                            this.setState({ isLoading: false });
                        }
                    }
                } else {
                    this.setState({ patients: DataJSON.data.data });
                    localStorage.setItem("my-followers", JSON.stringify(DataJSON.data.data));
                    this.setState({ isLoading: false });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getReading(reading, glucose_unit) {

        // set Global_GlusoseType
        CommanProfile.Global_GlucoseType = "" + glucose_unit;

        // console.log("------------reading---->" + reading + "====glucose_unit : " + glucose_unit)
        // console.log("------------Global_GlucoseType---->" + CommanProfile.Global_GlucoseType)


        // console.log("------------AAA---->"+glucose_unit)
        //console.log("------------reading---->" + reading)

        switch (reading) {
            case null:
            case "NA":
                return reading;
            case parseInt(reading) <= 40:
                return "LO";
            case parseInt(reading) >= 400:
                return "HI";
            default:
                return getGlucoseValue(parseInt(reading), glucose_unit) + " " + glucose_unit;
            // return glucose_unit === "mmol/L" ? (parseInt(reading) / 18.0182).toFixed(1) + " " + glucose_unit : parseInt(reading) % 1 === 0 ? parseInt(reading) + " " + glucose_unit : (parseInt(reading) / 1).toFixed(0) + " " + glucose_unit;
            //return glucose_unit === "mmol/L" ? (parseInt(reading) / 18.0182).toFixed(1) + " " + "AA" : parseInt(reading) % 1 === 0 ? parseInt(reading) + " " + glucose_unit : (parseInt(reading) / 1).toFixed(0) + " " + glucose_unit;

        }
    }

    handlePageSizeChange(event) {
        this.setState({ page_row: event.target.value });
    }

    handlePageChange(newPageSize) {
        if (newPageSize === 1) {
            this.setState(prevState => ({
                page_number: prevState.page_number + 1
            }));
            var newData = this.state.page_row + this.state.last_index
            this.setState({ last_index: newData });
            this.setState({ start_index: (newData - this.state.page_row) + 1 });
            this.apiCall(((newData - this.state.page_row) + 1), this.state.page_row)
        } else {
            this.setState(prevState => ({
                page_number: prevState.page_number - 1
            }));

            var newData = this.state.last_index - this.state.page_row
            this.setState({ last_index: newData });
            this.setState({ start_index: (newData - this.state.page_row) + 1 });
            this.apiCall(((newData - this.state.page_row) + 1), this.state.page_row)
        }
    }


    handleOnPatientSelect(patient_id, first_name, last_name, email, glucose_unit, settings_json) {
        if (settings_json != undefined) {
            try {
                settings_json = JSON.parse(settings_json);
            } catch (e) {
            }
            if (settings_json.hasOwnProperty('companyDetails')) {
                if (settings_json.companyDetails.hasOwnProperty('memberID')) {
                    localStorage.setItem("memberID", settings_json.companyDetails.memberID);
                } else {
                    localStorage.setItem("memberID", '999');
                }
                if (settings_json.companyDetails.hasOwnProperty('companyLastUpdatedTime')) {
                    localStorage.setItem("companyLastUpdatedTime", settings_json.companyDetails.companyLastUpdatedTime);
                } else {
                    localStorage.setItem("companyLastUpdatedTime", 0);
                }
            }
        }
        this.setState({
            isPatientSelect: patient_id,
            patientName: `${first_name} ${last_name}`,
            patientFirstName: `${first_name}`,
            patientEmail: email,
            glucose_unit: glucose_unit,
        });
    }
    getDiff(reading, previous_reading, reading_time, previous_reading_time) {
        var diffInRange = reading - previous_reading;
        var diffInMillis = (new Date(parseInt(reading_time)).getTime() - new Date(parseInt(previous_reading_time)).getTime()) / 60 / 1000;
        diffInMillis = Math.round(diffInMillis);
        if (diffInMillis === 0) {
            diffInMillis = 1;
        }
        var diff = diffInRange / Math.abs(diffInMillis);
        if (parseInt(diff) === 0) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 1 || parseInt(diff) === -1) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(45deg)" }} />;
        } else if (parseInt(diff) === -2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(135deg)" }} />;
        } else if (parseInt(diff) < -2) {
            return <i className="fa fa-arrow-down" aria-hidden="true" />;
        } else if (parseInt(diff) > 2) {
            return <i className="fas fa-arrow-up" />;
        }
    }

    handleChange = (event) => {
        const lastIndex = Array.from({ length: event.target.value }, (_, i) => i);
        this.setState({ page_number: 0 });
        this.setState({ start_index: 0 });
        this.setState({ page_row: event.target.value });
        this.setState({ last_index: lastIndex.length - 1 });
        this.apiCall(0, event.target.value)
    };
    render() {
        const { classes } = this.props;
        const { page_row, total_page, page_number, last_index } = this.state;
        var count = (last_index - page_row) + 1;
        const tableData = this.state.patients.map((patient, i) => {
            const { patient_id, first_name, last_name, profile_picture, email, glucose_unit, reading, reading_time, previous_reading, previous_reading_time, settings_json } = patient;
            count++;
            return {
                id: count,
                name: (
                    <Button simple style={{ padding: "0px", color: "#00CED1" }} className={classes.marginRight} onClick={() => this.handleOnPatientSelect(patient_id, first_name, last_name, email, glucose_unit, settings_json)}>
                        <Avatar size="50" name={first_name + " " + last_name} src={"data:image/jpeg;base64," + profile_picture} round={true} style={{ borderRadius: "50%", marginRight: "5px" }} />
                        {`${first_name} ${last_name}`} {getMemberId(settings_json)}
                    </Button>
                ),
                lastReading: this.getReading(reading, glucose_unit),
                icon: this.getDiff(reading, previous_reading, reading_time, previous_reading_time),
                lastReadingTime: reading_time === null ? "NA" : new Date(parseInt(reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25),
            };
        });
        return this.state.isPatientSelect ? (
            <Redirect
                to={{
                    pathname: "/patientInfo",
                    state: {
                        patient_id: this.state.isPatientSelect,
                        patientName: this.state.patientName,
                        patientFirstName: this.state.patientFirstName,
                        patientEmail: this.state.patientEmail,
                        glucose_unit: localStorage.getItem("loginType") === "Caregiver" ? this.state.glucose_unit : localStorage.getItem("glucose_unit"),
                    },
                }}
            />
        ) : this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody style={{ background: "black" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button style={{ background: "#0063CC" }} disabled={page_number === 0 ? true : false} value="Previous" onClick={(e) => this.handlePageChange(0)}>Previous</Button>
                                <h4 style={{ color: "white" }}>Page: {page_number + 1} of {Math.ceil(total_page / page_row)}</h4>
                                <FormControl style={{ width: '8rem' }}>
                                    <InputLabel id="demo-simple-select-label" style={{ color: "white" }}>Rows</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={page_row}
                                        label="Age"
                                        onChange={this.handleChange}
                                        style={{ color: "white", borderBlockColor: "white" }}
                                    >
                                        <MenuItem value={5}>5 Rows</MenuItem>
                                        <MenuItem value={10}>10 Rows</MenuItem>
                                        <MenuItem value={20}>20 Rows</MenuItem>
                                        <MenuItem value={25}>25 Rows</MenuItem>
                                        <MenuItem value={50}>50 Rows</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button style={{ background: "#0063CC" }} disabled={page_number + 1 === Math.ceil(total_page / page_row) ? true : false} value="Next" onClick={(e) => this.handlePageChange(1)}>Next</Button>
                            </div>
                            <PerfectScrollbar>
                                <ReactTable
                                    data={tableData}
                                    // filterable
                                    columns={[
                                        {
                                            Header: "ID",
                                            accessor: "id",
                                            headerStyle: { color: "white" },
                                            style: { color: "white" }
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            headerStyle: { color: "white" },
                                        },
                                        {
                                            Header: "Last Reading",
                                            accessor: "lastReading",
                                            headerStyle: { color: "white" },
                                            Cell: (row) => <div style={{ textAlign: "left", color: "white" }}>{row.value === null ? 'NA' : row.value}</div>,
                                        },
                                        {
                                            Header: "",
                                            accessor: "icon",
                                            filterable: false,
                                            sortable: false,
                                            headerStyle: { color: "white" },
                                            style: { color: "white" }
                                        },
                                        {
                                            Header: "Last Reading Time",
                                            accessor: "lastReadingTime",
                                            headerStyle: { color: "white" },
                                            Cell: (row) => <div style={{ textAlign: "left", color: "white" }}>{row.value === null ? 'NA' : row.value}</div>,
                                        },
                                    ]}
                                    defaultPageSize={page_row}
                                    showPaginationBottom={false}
                                    onPageSizeChange={(e) => { this.handlePageSizeChange(e) }}
                                    className="-highlight"
                                />
                            </PerfectScrollbar>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(extendedTablesStyle)(PatientList);
