import React from "react";
// import { Redirect } from "react-router-dom";

import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ambrosiasysLogo from "assets/img/ambrosiasys_logo.svg";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import { trim } from "jquery";
// import Chart from "react-google-charts";
import { ResponsiveLine } from "@nivo/line";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import "./header-footer.css";
import { ResponsiveBar } from "@nivo/bar";
import { getGlucoseValue, getGlucoseValuestr, getTickValue } from "../Utiles/Utils";
import CommanProfile from "../Utiles/CommanProfile";

class DailyGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            isLoading: false,
            isPatientSelect: false,
            standard_deviation: [],
            average_glucose: [],
            a1c: [],
            veryLowDataPercentage: [],
            lowDataPercentage: [],
            targetDataPercentage: [],
            highDataPercentage: [],
            veryHighDataPercentage: [],
            isCaregiverHeaderData: localStorage.getItem("loginType") === "Caregiver" ? true : false,
            caregiver_id: localStorage.getItem("user_id"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("user_id"),
            patient_email: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("email"),
            patient_name: localStorage.getItem("loginType") === "Caregiver" ? "" : `${localStorage.getItem("first_name").toUpperCase()} ${localStorage.getItem("last_name").toUpperCase()}`,
        };
        this.timeFormate = "%Y-%m-%d %I:%M:%S.%L %p";
        this.showNotification = this.showNotification.bind(this);
    }

    componentDidMount() {
        document.title = "Patient Daily Report - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
        }
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    render() {
        const overlayGraph = this.getOverlayReportGraph(this.props.dailyReportData);
        const dailyGraphArray = this.getDailyReportGraphs(this.props.dailyReportData);
        const agpGraph = this.getAgpReportData(this.props.agpReportData);
        const getCaregiverDeatils = this.getCaregiverDeatils(this.props.caregiverHeaderData);
        const getPatientDetails = this.getPatientDetails(this.props);
        const getOnlyPatientDetails = this.getOnlyPatientDetails(this.props);
        const getDailyStatitics = this.getDailyStatitics();
        const getHourlyStatitics = this.getHourlyStatitics();
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button color="info" onClick={this.props.handleBackButton}>
                        Back
                    </Button>
                    <ReactToPrint content={() => this.componentRef}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button className={"float-right"} color="info" onClick={handlePrint}>
                                    Print Report
                                </Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card style={{}} ref={(el) => (this.componentRef = el)}>
                        <CardBody style={{}}>
                            <h5 className="page-footer-left" style={{ color: "#000000" }}>
                                Data source: Ambrosia.care{" "}
                            </h5>
                            <div className="page-number" style={{ color: "#000000" }} />
                            {this.state.isCaregiverHeaderData ? getCaregiverDeatils : null}
                            {this.state.isCaregiverHeaderData ? getPatientDetails : getOnlyPatientDetails}
                            {overlayGraph}
                            {dailyGraphArray}
                            {agpGraph}
                            {getDailyStatitics}
                            {getHourlyStatitics}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    getBar() {
        if (this.state.veryHighDataPercentage[0] === 0.0 && this.state.highDataPercentage[0] === 0.0 && this.state.targetDataPercentage[0] === 0.0 && this.state.lowDataPercentage[0] === 0.0 && this.state.veryLowDataPercentage[0] === 0.0) {
            return null;
        } else {
            var data = [
                {
                    "Time in Target": "Time in Target",
                    VeryHigh: parseInt(this.state.veryHighDataPercentage[0]),
                    High: parseInt(this.state.highDataPercentage[0]),
                    InTargetRange: parseInt(this.state.targetDataPercentage[0]),
                    Low: parseInt(this.state.lowDataPercentage[0]),
                    VeryLow: parseInt(this.state.veryLowDataPercentage[0]),
                },
                { "": "", "": "", "": "", "": "", "": "" },
            ];
            const commonProps = {
                width: 50,
                height: 100,
            };
            return (
                <GridContainer style={{ width: "200px" }}>
                    <GridItem style={{ width: "100px", height: "95px", marginLeft: "30px" }}>
                        <ResponsiveBar
                            {...commonProps}
                            data={data}
                            keys={["VeryLow", "Low", "InTargetRange", "High", "VeryHigh"]}
                            indexBy="Time in Target"
                            margin={{ top: 10, right: 0, bottom: 9, left: 0 }}
                            colors={
                                data[0].VeryLow === 0 && data[0].InTargetRange === 0 && data[0].High === 0 && data[0].VeryHigh === 0
                                    ? ["#fd8c80"]
                                    : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].High === 0
                                        ? ["#FC8001"]
                                        : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].VeryHigh === 0
                                            ? ["#FCD45A"]
                                            : data[0].VeryLow === 0 && data[0].High === 0 && data[0].VeryHigh === 0 && data[0].Low !== 0 && data[0].InTargetRange !== 0
                                                ? ["#fd8c80", "#98CF4A"]
                                                : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].VeryHigh !== 0 && data[0].High !== 0
                                                    ? ["#FCD45A", "#FC8001"]
                                                    : data[0].VeryLow === 0 && data[0].InTargetRange === 0 && data[0].VeryHigh === 0 && data[0].Low !== 0 && data[0].High !== 0
                                                        ? ["#fd8c80", "#FCD45A"]
                                                        : data[0].VeryLow === 0 && data[0].InTargetRange === 0 && data[0].High === 0 && data[0].Low !== 0 && data[0].VeryHigh !== 0
                                                            ? ["#ED1C24", "#FC8001"]
                                                            : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].VeryHigh === 0 && data[0].InTargetRange !== 0 && data[0].High !== 0
                                                                ? ["#98CF4A", "#FCD45A"]
                                                                : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].High === 0 && data[0].InTargetRange !== 0
                                                                    ? ["#98CF4A", "#FC8001"]
                                                                    : data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].VeryHigh === 0 && data[0].VeryLow !== 0 && data[0].High !== 0
                                                                        ? ["#ED1C24", "#FCD45A"]
                                                                        : data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].High === 0 && data[0].VeryLow !== 0
                                                                            ? ["#ED1C24", "#FC8001"]
                                                                            : data[0].InTargetRange === 0 && data[0].VeryHigh === 0 && data[0].VeryLow !== 0 && data[0].Low !== 0 && data[0].High !== 0
                                                                                ? ["#ED1C24", "#fd8c80", "#FCD45A"]
                                                                                : data[0].InTargetRange === 0 && data[0].High === 0 && data[0].VeryLow !== 0 && data[0].Low !== 0 && data[0].VeryHigh !== 0
                                                                                    ? ["#ED1C24", "#fd8c80", "#FC8001"]
                                                                                    : data[0].Low === 0 && data[0].VeryHigh === 0 && data[0].VeryLow !== 0 && data[0].InTargetRange !== 0 && data[0].High !== 0
                                                                                        ? ["#ED1C24", "#98CF4A", "#FCD45A"]
                                                                                        : data[0].Low === 0 && data[0].High === 0 && data[0].VeryLow !== 0 && data[0].VeryHigh !== 0 && data[0].InTargetRange !== 0
                                                                                            ? ["#ED1C24", "#98CF4A", "#FC8001"]
                                                                                            : data[0].Low === 0 && data[0].InTargetRange === 0 && data[0].VeryLow !== 0 && data[0].VeryHigh !== 0 && data[0].High !== 0
                                                                                                ? ["#ED1C24", "#FCD45A", "#FC8001"]
                                                                                                : data[0].VeryLow === 0 && data[0].VeryHigh === 0 && data[0].Low !== 0 && data[0].High !== 0 && data[0].InTargetRange !== 0
                                                                                                    ? ["#fd8c80", "#98CF4A", "#FCD45A"]
                                                                                                    : data[0].VeryLow === 0 && data[0].High === 0 && data[0].Low !== 0 && data[0].VeryHigh !== 0 && data[0].InTargetRange !== 0
                                                                                                        ? ["#fd8c80", "#98CF4A", "#FC8001"]
                                                                                                        : data[0].VeryLow === 0 && data[0].InTargetRange === 0 && data[0].Low !== 0 && data[0].VeryHigh !== 0 && data[0].High !== 0
                                                                                                            ? ["#fd8c80", "#FCD45A", "#FC8001"]
                                                                                                            : data[0].VeryLow === 0 && data[0].Low === 0 && data[0].InTargetRange !== 0 && data[0].High !== 0 && data[0].VeryHigh !== 0
                                                                                                                ? ["#98CF4A", "#FCD45A", "#FC8001"]
                                                                                                                : data[0].VeryHigh === 0
                                                                                                                    ? ["#ED1C24", "#fd8c80", "#98CF4A", "#FCD45A"]
                                                                                                                    : data[0].High === 0
                                                                                                                        ? ["#ED1C24", "#fd8c80", "#98CF4A", "#FC8001"]
                                                                                                                        : data[0].InTargetRange === 0
                                                                                                                            ? ["#ED1C24", "#98CF4A", "#FCD45A", "#FC8001"]
                                                                                                                            : data[0].Low === 0
                                                                                                                                ? ["#ED1C24", "#98CF4A", "#FCD45A", "#FC8001"]
                                                                                                                                : data[0].VeryLow === 0
                                                                                                                                    ? ["#fd8c80", "#98CF4A", "#FCD45A", "#FC8001"]
                                                                                                                                    : ["#ED1C24", "#fd8c80", "#98CF4A", "#FCD45A", "#FC8001"]
                            }
                            borderColor={{ from: "color", modifiers: [["darker", "2"]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            isInteractive={false}
                            // padding={0.1}
                            markers={[
                                {
                                    axis: "y",
                                    value: parseInt(this.state.veryLowDataPercentage[0] / 2),
                                    lineStyle: {
                                        stroke: "#666666",
                                        strokeWidth: 1,
                                        strokeDasharray: "0,15,45,5",
                                    },
                                    textStyle: { fontSize: "9px" },
                                    legend: this.state.veryLowDataPercentage[0] + "%",
                                    legendPosition: "bottom-right",
                                    legendOffsetY: 6,
                                    legendOffsetX: 1,
                                    legendOrientation: "horizontal",
                                },
                                {
                                    axis: "y",
                                    value: parseInt(this.state.veryLowDataPercentage[0]) + parseInt(this.state.lowDataPercentage[0]) / 2,
                                    lineStyle: {
                                        stroke: "#666666",
                                        strokeWidth: 1,
                                        strokeDasharray: "0,15,45,5",
                                    },
                                    textStyle: { fontSize: "9px" },
                                    legend: this.state.lowDataPercentage[0] + "%",
                                    legendPosition: "top-right",
                                    legendOffsetY: 6,
                                    legendOffsetX: 1,
                                    legendOrientation: "horizontal",
                                },
                                {
                                    axis: "y",
                                    value: parseInt(this.state.veryLowDataPercentage[0]) + parseInt(this.state.lowDataPercentage[0]) + parseInt(this.state.targetDataPercentage[0]) / 2,
                                    lineStyle: {
                                        stroke: "#666666",
                                        strokeWidth: 1,
                                        strokeDasharray: "0,15,45,5",
                                    },
                                    textStyle: { fontSize: "9px" },
                                    legend: this.state.targetDataPercentage[0] + "%",
                                    legendPosition: "top-right",
                                    legendOffsetY: 6,
                                    legendOffsetX: 1,
                                    legendOrientation: "horizontal",
                                },
                                {
                                    axis: "y",
                                    value: parseInt(this.state.veryLowDataPercentage[0]) + parseInt(this.state.lowDataPercentage[0]) + parseInt(this.state.targetDataPercentage[0]) + parseInt(this.state.highDataPercentage[0]) / 2,
                                    lineStyle: {
                                        stroke: "#666666",
                                        strokeWidth: 1,
                                        strokeDasharray: "0,15,45,5",
                                    },
                                    textStyle: { fontSize: "9px" },
                                    legend: this.state.highDataPercentage[0] + "%",
                                    legendPosition: "bottom-right",
                                    legendOffsetY: 6,
                                    legendOffsetX: 1,
                                    legendOrientation: "horizontal",
                                },
                                {
                                    axis: "y",
                                    value: parseInt(this.state.veryLowDataPercentage[0]) + parseInt(this.state.lowDataPercentage[0]) + parseInt(this.state.targetDataPercentage[0]) + parseInt(this.state.highDataPercentage[0]) + parseInt(this.state.veryHighDataPercentage[0]) / 2,
                                    lineStyle: {
                                        stroke: "#666666",
                                        strokeWidth: 1,
                                        strokeDasharray: "0,15,45,5",
                                    },
                                    textStyle: { fontSize: "9px" },
                                    legend: this.state.veryHighDataPercentage[0] + "%",
                                    legendPosition: "top-right",
                                    legendOffsetY: 6,
                                    legendOffsetX: 1,
                                    legendOrientation: "horizontal",
                                },
                            ]}
                            axisLeft={null}
                            enableGridY={false}
                            labelSkipWidth={36}
                            labelSkipHeight={36}
                            labelTextColor={{ from: "color", modifiers: [["darker", "1.9"]] }}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                        />
                        <h5 style={{ fontSize: "10px" }}>{`Time in Target`}</h5>
                    </GridItem>
                </GridContainer>
            );
        }
    }
    getCaregiverDeatils(caregiverHeaderData) {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={10} sm={10} md={10} lg={10}>
                    <div style={{ margin: "10px 10px 0px 10px" }}>
                        <h3 style={{ color: "#000000" }} className={classes.cardIconTitle}>
                            {caregiverHeaderData.caregiver_name}
                        </h3>
                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                            <b>Email: </b>
                            {caregiverHeaderData.caregiver_email}
                        </h5>
                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                            <b>Country: </b>
                            {caregiverHeaderData.caregiver_country}
                        </h5>
                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                            <b>Clinic: </b>
                            {caregiverHeaderData.caregiver_clinic}
                        </h5>
                    </div>
                </GridItem>
                <GridItem xs={2}>
                    <img className={"float-right"} src={caregiverHeaderData.caregiver_logo === "null" ? ambrosiasysLogo : caregiverHeaderData.caregiver_logo} alt="..." width="150" />
                </GridItem>
            </GridContainer>
        );
    }
    getOnlyPatientDetails(patientData) {
        const { classes } = this.props;
        const dateTitle = this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)));
        const beginDateTitle = this.formatTimestampWithHM(new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date)));
        const endDateTitle = this.formatTimestampWithHM(new Date(parseInt(this.props.dailyReportData[0].date)));
        var beginDate = new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date));
        var endDate = new Date(parseInt(this.props.dailyReportData[0].date));
        const difference_in_time = endDate.getTime() - beginDate.getTime();
        const difference_in_days = Math.ceil(difference_in_time / (1000 * 3600 * 24));
        return (
            <GridContainer style={{ pageBreakAfter: "always" }}>
                <GridItem xs={10} sm={10} md={10} lg={10}>
                    <div>
                        <div style={{ margin: "10px 10px 50px 10px" }}>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Name: </b>
                                {`${this.props.patientName}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Email: </b>
                                {`${this.props.dailyReportData[0].email}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Country: </b>
                                {`${this.props.dailyReportData[0].country}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Glucose Measurement unit: </b>
                                {`${this.props.dailyReportData[0].glucose_unit}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Glucose Target Range: </b>
                                {`${this.props.dailyReportData[0].target_range}`}
                            </h5>
                        </div>
                        <div className="row ">
                            <div className="block" style={{ marginLeft: "15px" }}>
                                <h3 style={{ marginTop: "5px" }}>
                                    <b>{`${this.state.a1c[this.state.a1c.length === 0 ? 0 : this.state.a1c.length - 1] === "NaN" ? "NA" : this.state.a1c[this.state.a1c.length === 0 ? 0 : this.state.a1c.length - 1]} %`}</b>
                                </h3>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Glucose Management Indicator</h5>
                            </div>
                            <div className="block">
                                <h3 style={{ marginTop: "5px" }}>
                                    <b>{this.state.average_glucose[0] === "NaN" ? "NA" : parseFloat(this.state.average_glucose[0]).toFixed(2)} </b>
                                    {/* <b>{this.state.average_glucose[0] === "NaN" ? "NA" : parseInt(this.state.average_glucose[0]), CommanProfile.Global_GlucoseType} </b> */}

                                </h3>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Average Glucose</h5>
                            </div>
                            <div className="block">
                                <h3 style={{ marginTop: "5px" }}>

                                    <b>{this.state.standard_deviation[this.state.standard_deviation.length - 1] === "NaN" ? "NA" : parseFloat(this.state.standard_deviation[this.state.standard_deviation.length - 1]).toFixed(2)} </b>



                                </h3>
                                <h5 style={{ fontSize: "10px" }}>{this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Standard Deviation</h5>
                            </div>
                            <div className="block">
                                <div style={{}}>{this.getBar()}</div>
                            </div>
                            <div className="block">
                                <h5 style={{ fontSize: "0.99em" }}>Total Days: {difference_in_days + 1}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "30px" }}>{`From ${beginDateTitle} To ${endDateTitle}`}</h5>
                            </div>
                        </div>
                        <div style={{ margin: "10px 10px 40px 10px" }}>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Reports details: </b>
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Generated on: </b>
                                Ambrosia.care
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Generated on: </b>
                                {`${dateTitle}`}
                            </h5>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={2}>
                    <img className={"float-right"} src={ambrosiasysLogo} alt="..." width="150" />
                </GridItem>
            </GridContainer>
        );
    }
    getPatientDetails(patientData) {
        const { classes } = this.props;
        const dateTitle = this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)));
        const beginDateTitle = this.formatTimestampWithHM(new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date)));
        const endDateTitle = this.formatTimestampWithHM(new Date(parseInt(this.props.dailyReportData[0].date)));
        var beginDate = new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date));
        var endDate = new Date(parseInt(this.props.dailyReportData[0].date));
        const difference_in_time = endDate.getTime() - beginDate.getTime();
        const difference_in_days = Math.ceil(difference_in_time / (1000 * 3600 * 24));
        const glucose_unit = this.props.dailyReportData[0].glucose_unit;

        if (glucose_unit === "mg/dL") {
            this.state.a1c.push(parseFloat((parseInt(this.state.average_glucose[0]) + 46.7) / 28.7).toFixed(2));
        } else {
            this.state.a1c.push(parseFloat((parseInt(this.state.average_glucose[0]) + 2.59) / 1.59).toFixed(2));
        }
        return (
            <GridContainer style={{ pageBreakAfter: "always" }}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div style={{ margin: "10px 10px 0px 10px" }}>
                        <h3 style={{ color: "#000000" }} className={classes.cardIconTitle}>
                            Patient Details
                        </h3>
                    </div>
                    <div>
                        <div style={{ margin: "10px 10px 40px 10px" }}>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Name: </b>
                                {`${this.props.patientName}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Email: </b>
                                {`${this.props.dailyReportData[0].email}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Country: </b>
                                {`${this.props.dailyReportData[0].country}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Glucose Measurement unit: </b>
                                {`${this.props.dailyReportData[0].glucose_unit}`}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Glucose Target Range: </b>
                                {`${this.props.dailyReportData[0].target_range}`}
                            </h5>
                        </div>
                        <div className="row ">
                            <div className="block" style={{ marginLeft: "15px" }}>
                                <h3 style={{ marginTop: "5px" }}>
                                    <b>{`${this.state.a1c[this.state.a1c.length - 1] === "NaN" ? "NA" : this.state.a1c[this.state.a1c.length - 1]} %`}</b>
                                </h3>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Glucose Management Indicator </h5>
                            </div>
                            <div className="block">
                                <h3 style={{ marginTop: "5px" }}>
                                    <b>{parseFloat(this.state.average_glucose[0]).toFixed(2)}</b>
                                </h3>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Average Glucose</h5>
                            </div>
                            <div className="block">
                                <h3 style={{ marginTop: "5px" }}>
                                    <b>{parseFloat(this.state.standard_deviation[0]).toFixed(2)}</b>
                                </h3>
                                <h5 style={{ fontSize: "10px" }}>{this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>Standard Deviation</h5>
                            </div>
                            <div className="block">
                                <div style={{}}> {this.getBar()}</div>
                            </div>
                            <div className="block">
                                <h5 style={{ fontSize: "0.99em" }}>Total Days: {difference_in_days + 1}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "30px" }}>{`From ${beginDateTitle} To ${endDateTitle}`}</h5>
                            </div>
                        </div>
                        <div style={{ margin: "10px 10px 40px 10px" }}>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Reports details: </b>
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Generated on: </b>
                                Ambrosia.care
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                <b>Generated on: </b>
                                {`${dateTitle}`}
                            </h5>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
    getOverlayReportGraph(overlayReportDataSet) {
        const theme = {
            axis: {
                domain: {
                    line: {
                        stroke: "#333333",
                        strokeWidth: 1,
                    },
                },
            },
        };
        const minRangeArray = [];
        const maxRangeArray = [];
        var graph = [];
        var dateConst = new Date(parseInt(overlayReportDataSet[0].date));
        const biginDateTitle = this.getFormattedDate(dateConst);
        dateConst = new Date(parseInt(overlayReportDataSet[overlayReportDataSet.length - 1].date));
        const endDateTitle = this.getFormattedDate(dateConst);
        overlayReportDataSet.forEach((data, graphIndex) => {
            const dateTitle = this.getFormattedDateMMDD(new Date(parseInt(data.date)));
            var range = data.target_range.split("-");
            var date = new Date();
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            if (data.readings.success && data.readings.data.length) {
                const dataset = data.readings.data.map((set) => {
                    if (parseInt(set.reading) < 40) set.reading = 40;
                    let read = getGlucoseValue(set.reading, overlayReportDataSet[0].glucose_unit);
                    // set.reading =
                    date.setHours(new Date(parseInt(set.reading_time)).getHours());
                    date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                    const timeData = this.formatTimestamp(date);
                    // if (graphIndex === 0) {
                    //     minRangeArray.push({ x: timeData, y: parseInt(trim(range[0])) });
                    //     maxRangeArray.push({ x: timeData, y: parseInt(trim(range[1])) });
                    // }
                    const lowerRange = parseInt(trim(range[0]));
                    const higherRange = parseInt(trim(range[1]));
                    minRangeArray.push({ x: timeData, y: lowerRange });
                    maxRangeArray.push({ x: timeData, y: higherRange });
                    return { x: timeData, y: read };
                });
                graph.push({
                    id: dateTitle,
                    color: this.getRandomColorHash(),
                    data: dataset,
                });
            }
        });
        if (graph.length) {
            graph.splice(0, 0, { id: "Min Limit", color: "#FF6347", data: minRangeArray }, { id: "Max Limit", color: "#FF6347", data: maxRangeArray });
            return (
                <GridContainer>
                    <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5>
                    <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>
                    <GridItem style={{ width: "800px" }}>
                        <div className="page-number" style={{ color: "#000000" }} />
                        <div style={{ height: "300px", margin: "20px 10px 40px 10px" }}>
                            <h4 style={{ paddingTop: "50px", color: "#000000" }}>{`Overlay Report: ${biginDateTitle} - ${endDateTitle}`}</h4>
                            <ResponsiveLine
                                data={graph}
                                margin={{ top: 50, right: 0, bottom: 70, left: 50 }}
                                xScale={{
                                    precision: "minute",
                                    format: this.timeFormate,
                                    useUTC: false,
                                    type: "time",
                                }}
                                xFormat={`time:${this.timeFormate}`}
                                yScale={{ type: "linear", stacked: false, min: 0, max: getGlucoseValue(400, overlayReportDataSet[0].glucose_unit) }}
                                curve="monotoneX"
                                axisBottom={{
                                    tickValues: "every 3 hours",
                                    format: (value) => {
                                        return this.formatAMPM(value);
                                    },
                                    legend: "Reading Time",
                                    legendOffset: 36,
                                    legendPosition: "middle",
                                }}
                                axisLeft={{
                                    // tickValues: [0, 100, 200, 300, 400],
                                    tickValues: getTickValue(overlayReportDataSet[0].glucose_unit),
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Glucose (" + overlayReportDataSet[0].glucose_unit + ")",
                                    legendOffset: -40,
                                    legendPosition: "middle",
                                }}
                                theme={theme}
                                isInteractive={false}
                                enableCrosshair={false}
                                enableGridX={false}
                                enableGridY={false}
                                colors={(d) => d.color}
                                lineWidth={2}
                                enablePoints={false}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: "bottom-left",
                                        direction: "row",
                                        justify: false,
                                        translateX: 0,
                                        translateY: 65,
                                        itemsSpacing: 0,
                                        itemDirection: "left-to-right",
                                        itemWidth: 55,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: "circle",
                                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                                    },
                                ]}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            );
        } else {
            return (
                <GridContainer>
                    <GridItem style={{ width: "800px" }}>
                        <h4>{`Overlay Report: ${biginDateTitle} - ${endDateTitle}`}</h4>
                        <h5>No data Found for the period</h5>
                    </GridItem>
                </GridContainer>
            );
        }
    }

    getDailyReportGraphs(dailyReportDataSet) {
        const theme = {
            axis: {
                domain: {
                    line: {
                        stroke: "#333333",
                        strokeWidth: 1,
                    },
                },
            },
        };
        const graph = dailyReportDataSet.map((data, graphIndex) => {
            const dateTitle = this.getFormattedDate(new Date(parseInt(data.date)));
            if (data.readings.success) {
                const dataSetArray = [];
                var range = data.target_range.split("-");
                const minRangeArray = [];
                const maxRangeArray = [];
                const lowRangeData = [];
                const readingTime = [];
                const dashedArray = [];
                var date = new Date();
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                var countInTarget = 0,
                    countAboveTarget = 0,
                    countBelowTarget = 0,
                    totalReading = 0;
                const dataset = data.readings.data.map((set) => {
                    if (parseInt(set.reading) < 40) set.reading = 40;
                    let reading = getGlucoseValue(set.reading, dailyReportDataSet[0].glucose_unit);
                    date.setHours(new Date(parseInt(set.reading_time)).getHours());
                    date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                    const timeData = this.formatTimestamp(date);
                    const lowerRange = parseInt(trim(range[0]));
                    const higherRange = parseInt(trim(range[1]));
                    minRangeArray.push({ x: timeData, y: lowerRange });
                    maxRangeArray.push({ x: timeData, y: higherRange });
                    totalReading += parseInt(reading);
                    if (reading >= lowerRange && reading <= higherRange) {
                        countInTarget++;
                    }
                    if (reading < lowerRange) {
                        countBelowTarget++;
                    }
                    if (reading > higherRange) {
                        countAboveTarget++;
                    }
                    lowRangeData.push({
                        x: timeData,
                        y: reading <= higherRange ? reading : higherRange,
                    });
                    readingTime.push(new Date(parseInt(set.reading_time)).getHours());
                    if (lowerRange - reading >= 10) {
                        lowRangeData.push({ x: timeData, y: reading });
                    }
                    return { x: timeData, y: reading };
                });
                readingTime.forEach((time, index) => {
                    if (readingTime[index + 1] - readingTime[index] >= 3) {
                        dashedArray.push(dataset[index]);
                        dashedArray.push(dataset[index + 1]);
                    }
                });
                const AVERAGE_READING = totalReading / data.readings.data.length;
                const ABOVE_TARGET = (countAboveTarget * 100) / data.readings.data.length;
                const BELOW_TARGET = (countBelowTarget * 100) / data.readings.data.length;
                const IN_TARGET = (countInTarget * 100) / data.readings.data.length;
                dataSetArray.push({
                    id: "Limit",
                    color: "#FFFFFF",
                    data: lowRangeData,
                });
                dataSetArray.push({
                    id: "Min Limit",
                    color: "#FF6347",
                    data: minRangeArray,
                });
                dataSetArray.push({
                    id: "Max Limit",
                    color: "#FF6347",
                    data: maxRangeArray,
                });
                dataSetArray.push({ id: dateTitle, color: "#000000", data: dataset });
                dataSetArray.push({
                    id: "DashedArray",
                    color: "#FFFFFF",
                    data: dashedArray,
                });
                const styleById = {
                    DashedArray: {
                        strokeDasharray: "6, 6",
                        strokeWidth: 3,
                    },
                    default: {
                        strokeWidth: 2,
                    },
                };
                const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
                    return series.map(({ id, data, color }) => (
                        <path
                            key={id}
                            d={lineGenerator(
                                data.map((d) => ({
                                    x: xScale(d.data.x),
                                    y: yScale(d.data.y),
                                }))
                            )}
                            fill="none"
                            stroke={color}
                            style={styleById[id] || styleById.default}
                        />
                    ));
                };
                return (
                    <GridContainer
                        key={graphIndex}
                        style={{
                            pageBreakBefore: graphIndex % 4 === 0 ? "always" : "auto",
                        }}
                    >
                        {graphIndex % 4 === 0 ? <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5> : <div />}
                        {graphIndex % 4 === 0 ? <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5> : <div />}
                        <GridItem style={{ width: "800px" }}>
                            <div className={graphIndex % 4 === 0 ? "page-number" : "colorClass"} style={{ color: "#000000" }} />
                            <div style={{ height: "300px", margin: "20px 10px 40px 10px" }}>
                                <h4
                                    style={{
                                        paddingTop: "50px",
                                        margin: "20px 0 0px",
                                        color: "#000000",
                                    }}
                                >{`Daily Report : ${dateTitle}`}</h4>
                                <ResponsiveLine
                                    data={dataSetArray}
                                    margin={{ top: 50, right: 0, bottom: 70, left: 50 }}
                                    xScale={{
                                        precision: "minute",
                                        format: this.timeFormate,
                                        useUTC: false,
                                        type: "time",
                                    }}
                                    xFormat={`time:${this.timeFormate}`}
                                    yScale={{ type: "linear", stacked: false, min: 0, max: getGlucoseValue(400, dailyReportDataSet[0].glucose_unit) }}
                                    curve="linear"
                                    axisBottom={{
                                        tickValues: "every 3 hours",
                                        format: (value) => {
                                            return this.formatAMPM(value);
                                        },
                                        legend: "Reading Time",
                                        legendOffset: 36,
                                        legendPosition: "middle",
                                    }}
                                    axisLeft={{
                                        tickValues: getTickValue(dailyReportDataSet[0].glucose_unit),
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "Glucose (" + this.props.dailyReportData[0].glucose_unit + ")",
                                        legendOffset: -40,
                                        legendPosition: "middle",
                                    }}
                                    layers={["grid", "markers", "areas", DashedLine, "slices", "points", "axes", "legends"]}
                                    defs={[
                                        {
                                            id: "higherData",
                                            type: "patternLines",
                                            background: "#FCBA03",
                                            color: "#FCBA03",
                                            size: 4,
                                            padding: 2,
                                            stagger: true,
                                        },
                                        {
                                            id: "data",
                                            type: "patternLines",
                                            background: "#FFFFFF",
                                            color: "#FFFFFF",
                                            size: 4,
                                            padding: 2,
                                            stagger: true,
                                        },
                                    ]}
                                    fill={[{ match: { id: "Max Limit" }, id: "data" }, { match: { id: dateTitle }, id: "higherData" }]}
                                    enableArea={true}
                                    areaOpacity={1}
                                    theme={theme}
                                    isInteractive={false}
                                    enableCrosshair={false}
                                    enableGridX={false}
                                    enableGridY={false}
                                    colors={(d) => d.color}
                                    lineWidth={2}
                                    enablePoints={false}
                                    useMesh={true}
                                />
                            </div>
                        </GridItem>
                        <GridItem style={{ marginTop: graphIndex % 4 === 0 ? "200px" : "210px" }}>
                            <div style={{ color: "#111111" }}>
                                <p>Average Glucose: {`${~~AVERAGE_READING} ` + this.props.dailyReportData[0].glucose_unit}</p>
                                <p>Time In Target: {`${parseFloat(IN_TARGET).toFixed(1)}%`}</p>
                                <p>Time Below Target: {`${parseFloat(BELOW_TARGET).toFixed(1)}%`}</p>
                                <p>Time Above Target: {`${parseFloat(ABOVE_TARGET).toFixed(1)}%`}</p>
                            </div>
                        </GridItem>
                    </GridContainer>
                );
            } else {
                return null;
            }
        });
        return graph;
    }
    getAgpReportData(agpReportDataSet) {
        const theme = {
            axis: {
                domain: {
                    line: {
                        stroke: "#333333",
                        strokeWidth: 1,
                    },
                },
            },
        };
        const dataSetArray = [];
        const minRangeArray = [];
        const maxRangeArray = [];
        const minVal = [];
        const maxVal = [];
        const average = [];
        const maxDashedArray = [];
        const minDashedArray = [];
        var averageGlucose = 0,
            veryLowDataRange = 0,
            lowDataRange = 0,
            targetDataRange = 0,
            highDataRange = 0,
            veryHighDataRange = 0,
            totalReading = 0,
            totalReadingValue = 0,
            variance = 0;
        var sd = "NA";
        const readingValue = [];
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        if (agpReportDataSet.success) {
            var range = agpReportDataSet.data.patientData.target_range.split("-");
            const beginDateTitle = this.getFormattedDate(new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date)));
            const endDateTitle = this.getFormattedDate(new Date(parseInt(this.props.dailyReportData[0].date)));
            var beginDate = new Date(parseInt(this.props.dailyReportData[this.props.dailyReportData.length - 1].date));
            var endDate = new Date(parseInt(this.props.dailyReportData[0].date));
            const difference_in_time = endDate.getTime() - beginDate.getTime();
            var difference_in_days = Math.ceil(difference_in_time / (1000 * 3600 * 24));
            agpReportDataSet.data.readings.forEach((data) => {
                if (parseInt(data.reading) < 40) data.reading = 40;
                // var minValue = getGlucoseValue(data.minVal, this.props.dailyReportData[0].glucose_unit);
                // var maxValue = getGlucoseValue(data.maxVal, this.props.dailyReportData[0].glucose_unit);
                var minValue = data.minVal;
                var maxValue = data.maxVal;

                var timeVal = data.maxTimestamp.split(":");
                date.setHours(timeVal[0]);
                date.setMinutes(timeVal[1]);
                const lowerRange = parseInt(trim(range[0]));
                const higherRange = parseInt(trim(range[1]));
                const timeData = this.formatTimestamp(date);
                minRangeArray.push({ x: timeData, y: lowerRange });
                maxRangeArray.push({ x: timeData, y: higherRange });
                minVal.push({ x: timeData, y: minValue });
                maxVal.push({ x: timeData, y: maxValue });
                average.push({ x: timeData, y: data.average });
                maxDashedArray.push({
                    x: timeData,
                    y: parseFloat(maxValue) + parseFloat(maxValue * 0.1),
                });
                minDashedArray.push({
                    x: timeData,
                    y: minValue - minValue * 0.1,
                });
            });
            this.props.dailyReportData.forEach((data) => {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = data.reading;
                        readingValue.push(read);
                        totalReading += 1;
                        // read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit)
                        totalReadingValue += parseInt(read);
                    });
                }
            });
            averageGlucose = parseFloat(totalReadingValue / totalReading);
            readingValue.forEach((data) => {
                var read = getGlucoseValue(data, this.props.dailyReportData[0].glucose_unit);
                if (read < getGlucoseValue(54, this.props.dailyReportData[0].glucose_unit)) {
                    veryLowDataRange += 1;
                } else if (read >= getGlucoseValue(54, this.props.dailyReportData[0].glucose_unit) && read < parseInt(range[0])) {
                    lowDataRange += 1;
                } else if (read >= parseInt(range[0]) && read < parseInt(range[1])) {
                    targetDataRange += 1;
                } else if (read >= parseInt(range[1]) && read < getGlucoseValue(250, this.props.dailyReportData[0].glucose_unit)) {
                    highDataRange += 1;
                } else if (read >= getGlucoseValue(250, this.props.dailyReportData[0].glucose_unit)) {
                    veryHighDataRange += 1;
                }
                // read = getGlucoseValue(data, this.props.dailyReportData[0].glucose_unit);
                // let avg = getGlucoseValue(averageGlucose, this.props.dailyReportData[0].glucose_unit);
                variance = variance + this.getPower(read, averageGlucose);
            });
            // read = getGlucoseValue(data, this.props.dailyReportData[0].glucose_unit);
            averageGlucose = getGlucoseValue(averageGlucose, this.props.dailyReportData[0].glucose_unit);
            variance = variance / totalReading;
            sd = getGlucoseValue(parseFloat(Math.sqrt(variance)), this.props.dailyReportData[0].glucose_unit);
            this.state.standard_deviation.push(sd);
            this.state.average_glucose.push(averageGlucose);
            const ACTIVE_TIME = (totalReading * 100) / (288 * (difference_in_days += 1));
            const COEFFICIENT = this.getCoefficient(parseFloat(sd), parseFloat(averageGlucose));
            this.state.veryLowDataPercentage.push(parseFloat((veryLowDataRange * 100) / totalReading).toFixed(1));
            this.state.lowDataPercentage.push(parseFloat((lowDataRange * 100) / totalReading).toFixed(1));
            this.state.targetDataPercentage.push(parseFloat((targetDataRange * 100) / totalReading).toFixed(1));
            this.state.highDataPercentage.push(parseFloat((highDataRange * 100) / totalReading).toFixed(1));
            this.state.veryHighDataPercentage.push(parseFloat((veryHighDataRange * 100) / totalReading).toFixed(1));
            dataSetArray.push({ id: "25%", color: "#0099ff", data: minVal });
            dataSetArray.push({ id: "75%", color: "#0099ff", data: maxVal });
            dataSetArray.push({ id: "Median ", color: "#ff8000", data: average });
            dataSetArray.push({
                id: "Min Limit",
                color: "#FF6347",
                data: minRangeArray,
            });
            dataSetArray.push({
                id: "Max Limit",
                color: "#FF6347",
                data: maxRangeArray,
            });
            dataSetArray.push({ id: "90%", color: "#ff8000", data: maxDashedArray });
            dataSetArray.push({ id: "10%", color: "#ff8000", data: minDashedArray });
            const styleById = {
                "10%": {
                    strokeDasharray: "6, 6",
                    strokeWidth: 3,
                },
                "90%": {
                    strokeDasharray: "6, 6",
                    strokeWidth: 3,
                },
                default: {
                    strokeWidth: 2,
                },
            };
            const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
                return series.map(({ id, data, color }) => (
                    <path
                        key={id}
                        d={lineGenerator(
                            data.map((d) => ({
                                x: xScale(d.data.x),
                                y: yScale(d.data.y),
                            }))
                        )}
                        fill="none"
                        stroke={color}
                        style={styleById[id] || styleById.default}
                    />
                ));
            };

            return (
                <GridContainer style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
                    <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5>
                    <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>
                    <GridItem style={{ width: "800px" }}>
                        <div className="page-number" style={{ color: "#000000" }} />
                        <h4
                            style={{
                                paddingTop: "50px",
                                margin: "20px 0 0px",
                                color: "#000000",
                            }}
                        >{`AGP Report : ${beginDateTitle} - ${endDateTitle}`}</h4>
                        <div className="row ">
                            <div className="square" style={{ marginLeft: "15px" }}>
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    Avg Glucose
                                </h5>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                    <b>{`${parseFloat(averageGlucose).toFixed(2)} `}</b>
                                </h5>
                            </div>
                            <div className="square" style={{ marginLeft: "15px" }}>
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    Very Low
                                </h5>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{`< ` + getGlucoseValue(54, this.props.dailyReportData[0].glucose_unit) + this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                    <b>{`${this.state.veryLowDataPercentage[0]} %`}</b>
                                </h5>
                            </div>
                            <div className="square">
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    Low
                                </h5>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{`< ${range[0]}  ` + this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                    <b>{`${this.state.lowDataPercentage[0]} %`}</b>
                                </h5>
                            </div>
                            <div className="square">
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    In Target Range
                                </h5>
                                <h5 style={{ fontSize: "10px" }}>{`${this.props.dailyReportData[0].target_range}`}</h5>
                                <h5 style={{ fontSize: "0.99em" }}>
                                    <b>{`${this.state.targetDataPercentage[0]} %`}</b>
                                </h5>
                            </div>
                            <div className="square">
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    High
                                </h5>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{`> ${range[1]} ` + this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                    <b>{`${this.state.highDataPercentage[0]} %`}</b>
                                </h5>
                            </div>
                            <div className="square">
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    Very High
                                </h5>
                                <h5 style={{ fontSize: "10px", marginTop: "5px" }}>{`> ` + getGlucoseValue(250, this.props.dailyReportData[0].glucose_unit) + this.props.dailyReportData[0].glucose_unit}</h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                    <b>{`${this.state.veryHighDataPercentage[0]} %`}</b>
                                </h5>
                            </div>
                            <div className="square" style={{ marginLeft: "15px" }}>
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    coefficient of variation
                                </h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                    <b>{`${COEFFICIENT} %`}</b>
                                </h5>
                            </div>
                            <div className="square">
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    SD {this.props.dailyReportData[0].glucose_unit}
                                </h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                    <b>{`${parseFloat(sd).toFixed(2)}`}</b>
                                </h5>
                            </div>
                            <div className="square" style={{ marginLeft: "15px" }}>
                                <h5
                                    style={{
                                        height: "37px",
                                        fontSize: "0.99em",
                                        marginTop: "5px",
                                    }}
                                >
                                    % time CGM Active
                                </h5>
                                <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                    <b>{`${ACTIVE_TIME.toFixed(1)} %`}</b>
                                </h5>
                            </div>
                        </div>
                        <div style={{ height: "300px", margin: "20px 10px 40px 10px" }}>
                            <ResponsiveLine
                                data={dataSetArray}
                                margin={{ top: 50, right: 0, bottom: 70, left: 50 }}
                                xScale={{
                                    precision: "minute",
                                    format: this.timeFormate,
                                    useUTC: false,
                                    type: "time",
                                }}
                                xFormat={`time:${this.timeFormate}`}
                                yScale={{ type: "linear", stacked: false, min: 0, max: getGlucoseValue(400, this.props.dailyReportData[0].glucose_unit) }}
                                curve="linear"
                                axisBottom={{
                                    tickValues: "every 3 hours",
                                    format: (value) => {
                                        return this.formatAMPM(value);
                                    },

                                    legendOffset: 36,
                                    legendPosition: "middle",
                                }}
                                axisLeft={{
                                    tickValues: getTickValue(this.props.dailyReportData[0].glucose_unit),
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Glucose (" + this.props.dailyReportData[0].glucose_unit + ")",
                                    legendOffset: -40,
                                    legendPosition: "middle",
                                }}
                                layers={["grid", "markers", "areas", DashedLine, "slices", "points", "axes", "legends"]}
                                defs={[
                                    {
                                        id: "fillArea",
                                        type: "patternLines",
                                        background: "#99ccff",
                                        color: "#99ccff",
                                        size: 4,
                                        padding: 2,
                                        stagger: true,
                                    },
                                    {
                                        id: "data",
                                        type: "patternLines",
                                        background: "#FFFFFF",
                                        color: "#FFFFFF",
                                        size: 4,
                                        padding: 2,
                                        stagger: true,
                                    },
                                ]}
                                fill={[{ match: { id: "75%" }, id: "fillArea" }, { match: { id: "90%" }, id: "data" }, { match: { id: "25%" }, id: "data" }, { match: { id: "Median" }, id: "fillArea" }, { match: { id: "Min Limit" }, id: "data" }, { match: { id: "Max Limit" }, id: "data" }]}
                                enableArea={true}
                                areaOpacity={1}
                                theme={theme}
                                isInteractive={false}
                                enableCrosshair={false}
                                enableGridX={false}
                                enableGridY={false}
                                colors={(d) => d.color}
                                lineWidth={2}
                                enablePoints={false}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: "bottom-left",
                                        direction: "row",
                                        justify: false,
                                        translateX: 0,
                                        translateY: 65,
                                        itemsSpacing: 0,
                                        itemDirection: "left-to-right",
                                        itemWidth: 70,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: "square",
                                        symbolBorderColor: "rgba(0, 0, 0, .5)",
                                    },
                                ]}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            );
        } else {
            return null;
        }
    }
    findQuartile(glucoseArray) {
        var qArray = [];
        if (glucoseArray.length === 0) {
            qArray[0] = "NA";
            qArray[1] = "NA";
            qArray[2] = "NA";
            qArray[3] = "NA";
        } else {
            glucoseArray.sort((a, b) => a - b);
            var mid_index = 0,
                Q1 = 0,
                Q3 = 0,
                qMedian = 0,
                IQR = 0;
            mid_index = this.getMedian(0, glucoseArray.length);
            Q1 = glucoseArray[Math.round(this.getMedian(0, mid_index))];
            Q3 = glucoseArray.length === 1 ? Q1 : glucoseArray[Math.round(this.getMedian(mid_index + 1, glucoseArray.length))];
            qMedian = glucoseArray[mid_index === 0.5 ? 0 : Math.round(mid_index)];
            IQR = Q3 - Q1;
            qArray[0] = Q1;
            qArray[1] = qMedian;
            qArray[2] = Q3;
            qArray[3] = IQR.toFixed(1);
        }
        return qArray;
    }
    getMedian(l, r) {
        var n = r - l + 1;
        n = (n + 1) / 2 - 1;
        return n + l;
    }
    getData(reading, averageGlucose, totalReading) {
        var low_data = 0,
            very_low_data = 0,
            target_data = 0,
            high_data = 0,
            very_high_data = 0,
            variance = 0;
        var data_array = [];
        if (reading.length === 0) {
            data_array[0] = "NA";
            data_array[1] = "NA";
            data_array[2] = "NA";
            data_array[3] = "NA";
            data_array[4] = "NA";
            data_array[5] = "NA";
        } else {
            reading.forEach((data) => {
                if (parseInt(data) < getGlucoseValue(40, this.props.dailyReportData[0].glucose_unit)) data = getGlucoseValue(40, this.props.dailyReportData[0].glucose_unit);
                let read = parseInt(data);
                var range = this.props.dailyReportData[0].target_range.split("-");
                if (read < getGlucoseValue(54, this.props.dailyReportData[0].glucose_unit)) {
                    very_low_data += 1;
                } else if (read >= getGlucoseValue(54, this.props.dailyReportData[0].glucose_unit) && read < range[0]) {
                    low_data += 1;
                } else if (read >= range[0] && read < range[1]) {
                    target_data += 1;
                } else if (read >= range[1] && read < getGlucoseValue(250, this.props.dailyReportData[0].glucose_unit)) {
                    high_data += 1;
                } else if (read >= getGlucoseValue(250, this.props.dailyReportData[0].glucose_unit)) {
                    very_high_data += 1;
                }
                // read = getGlucoseValue(data, this.props.dailyReportData[0].glucose_unit);
                variance = variance + this.getPower(read, averageGlucose);
            });
            data_array[0] = variance;
            data_array[1] = parseFloat((very_low_data * 100) / totalReading).toFixed(1);
            data_array[2] = parseFloat((low_data * 100) / totalReading).toFixed(1);
            data_array[3] = parseFloat((target_data * 100) / totalReading).toFixed(1);
            data_array[4] = parseFloat((high_data * 100) / totalReading).toFixed(1);
            data_array[5] = parseFloat((very_high_data * 100) / totalReading).toFixed(1);
        }

        return data_array;
    }
    getCoefficientVarience(sd, averageGlucose) {
        return sd === "NA" ? "NA" : this.getCoefficient(parseFloat(sd), parseFloat(averageGlucose));
    }
    getStandardDeviation(variance, totalReading) {
        variance = variance / totalReading;
        return totalReading === 0 ? "NA" : Math.sqrt(variance);
    }
    getDailyStatitics() {
        var total_reading_monday = 0,
            total_reading_tuesDay = 0,
            total_reading_wednesday = 0,
            total_reading_thursday = 0,
            total_reading_friday = 0,
            total_reading_saturday = 0,
            total_reading_sunday = 0,
            reading_value_sunday = 0,
            reading_value_monday = 0,
            reading_value_tuesDay = 0,
            reading_value_wednesday = 0,
            reading_value_thursday = 0,
            reading_value_friday = 0,
            reading_value_saturday = 0,
            average_value_sunday = 0,
            average_value_monday = 0,
            average_value_tuesDay = 0,
            average_value_wednesday = 0,
            average_value_thursday = 0,
            average_value_friday = 0,
            average_value_saturday = 0;
        const reading_sunday = [],
            reading_monday = [],
            reading_tuesDay = [],
            reading_wednesday = [],
            reading_thursday = [],
            reading_friday = [],
            reading_saturday = [];
        this.props.dailyReportData.forEach((data) => {
            var day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            var date = day[new Date(parseInt(data.date)).getDay()];
            if (date === "Sun") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading < 40)) data.reading = 40;
                        // var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        var read = data.reading;
                        reading_sunday.push(read);
                        total_reading_sunday += 1;
                        reading_value_sunday += parseInt(read);
                    });
                    average_value_sunday = getGlucoseValue(parseFloat(reading_value_sunday / total_reading_sunday), this.props.dailyReportData[0].glucose_unit);
                }
            }
            if (date === "Mon") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_monday.push(read);
                        total_reading_monday += 1;
                        reading_value_monday += parseInt(read);
                    });
                    average_value_monday = parseFloat(reading_value_monday / total_reading_monday);
                }
            }
            if (date === "Tue") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_tuesDay.push(read);
                        total_reading_tuesDay += 1;
                        reading_value_tuesDay += parseInt(read);
                    });
                    average_value_tuesDay = parseFloat(reading_value_tuesDay / total_reading_tuesDay);
                }
            }
            if (date === "Wed") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_wednesday.push(read);
                        total_reading_wednesday += 1;
                        reading_value_wednesday += parseInt(read);
                    });
                    average_value_wednesday = parseFloat(reading_value_wednesday / total_reading_wednesday);
                }
            }
            if (date === "Thu") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_thursday.push(read);
                        total_reading_thursday += 1;
                        reading_value_thursday += parseInt(read);
                    });
                    average_value_thursday = parseFloat(reading_value_thursday / total_reading_thursday);
                }
            }
            if (date === "Fri") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_friday.push(read);
                        total_reading_friday += 1;
                        reading_value_friday += parseInt(read);
                    });
                    average_value_friday = parseFloat(reading_value_friday / total_reading_friday);
                }
            }
            if (date === "Sat") {
                if (data.readings.success) {
                    data.readings.data.forEach((data) => {
                        if (parseInt(data.reading) < 40) data.reading = 40;
                        var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                        reading_saturday.push(read);
                        total_reading_saturday += 1;
                        reading_value_saturday += parseInt(read);
                    });
                    average_value_saturday = parseFloat(reading_value_saturday / total_reading_saturday);
                }
            }
        });
        return (
            <GridContainer style={{ pageBreakAfter: "always" }}>
                <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5>
                <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>

                <GridItem>
                    <h4> Daily Statistic</h4>
                    <div className="page-number" style={{ color: "#000000", top: "1470px" }} />
                    <div className="row ">
                        <div className="dailystatiticsblock" style={{ marginLeft: "15px", width: "200px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Daily statistics</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>Time in Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>% Very High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>% High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % In Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Very Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> # Readings</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Min</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Max</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Quartile 25</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> Median</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>MaQuartile 75</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> IQR</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Std.Dev</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Mean</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> %CV</b>
                            </h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Mon</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_monday, average_value_monday, total_reading_monday)[5], this.getData(reading_monday, average_value_monday, total_reading_monday)[4], this.getData(reading_monday, average_value_monday, total_reading_monday)[3], this.getData(reading_monday, average_value_monday, total_reading_monday)[2], this.getData(reading_monday, average_value_monday, total_reading_monday)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_monday, average_value_monday, total_reading_monday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_monday, average_value_monday, total_reading_monday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_monday, average_value_monday, total_reading_monday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_monday, average_value_monday, total_reading_monday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_monday, average_value_monday, total_reading_monday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_monday.length === 0 ? "NA" : total_reading_monday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_monday.length === 0 ? "NA" : Math.min.apply(Math, reading_monday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_monday.length === 0 ? "NA" : Math.max.apply(Math, reading_monday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_monday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_monday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_monday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_monday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_monday, average_value_monday, total_reading_monday)[0], total_reading_monday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_monday, average_value_monday, total_reading_monday)[0], total_reading_monday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_monday.length === 0 ? "NA" : Math.round(average_value_monday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_monday, average_value_monday, total_reading_monday)[0], total_reading_monday), average_value_monday)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Tue</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[5], this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[4], this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[3], this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[2], this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_tuesDay.length === 0 ? "NA" : total_reading_tuesDay}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_tuesDay.length === 0 ? "NA" : Math.min.apply(Math, reading_tuesDay).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_tuesDay.length === 0 ? "NA" : Math.max.apply(Math, reading_tuesDay).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_tuesDay)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_tuesDay)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_tuesDay)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_tuesDay)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[0], total_reading_tuesDay) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[0], total_reading_tuesDay))}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_tuesDay.length === 0 ? "NA" : Math.round(average_value_tuesDay)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_tuesDay, average_value_tuesDay, total_reading_tuesDay)[0], total_reading_tuesDay), average_value_tuesDay)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Wed</b>
                            </h5>
                            <div style={{}}>
                                {this.getStatisticsBar(this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[5], this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[4], this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[3], this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[2], this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[1])}
                            </div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_wednesday.length === 0 ? "NA" : total_reading_wednesday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_wednesday.length === 0 ? "NA" : Math.min.apply(Math, reading_wednesday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_wednesday.length === 0 ? "NA" : Math.max.apply(Math, reading_wednesday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_wednesday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_wednesday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_wednesday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_wednesday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[0], total_reading_wednesday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[0], total_reading_wednesday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_wednesday.length === 0 ? "NA" : Math.round(average_value_wednesday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_wednesday, average_value_wednesday, total_reading_wednesday)[0], total_reading_wednesday), average_value_wednesday)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Thu</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[5], this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[4], this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[3], this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[2], this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_thursday.length === 0 ? "NA" : total_reading_thursday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_thursday.length === 0 ? "NA" : Math.min.apply(Math, reading_thursday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_thursday.length === 0 ? "NA" : Math.max.apply(Math, reading_thursday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_thursday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_thursday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_thursday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_thursday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[0], total_reading_thursday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[0], total_reading_thursday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_thursday.length === 0 ? "NA" : Math.round(average_value_thursday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_thursday, average_value_thursday, total_reading_thursday)[0], total_reading_thursday), average_value_thursday)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Fri</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_friday, average_value_friday, total_reading_friday)[5], this.getData(reading_friday, average_value_friday, total_reading_friday)[4], this.getData(reading_friday, average_value_friday, total_reading_friday)[3], this.getData(reading_friday, average_value_friday, total_reading_friday)[2], this.getData(reading_friday, average_value_friday, total_reading_friday)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_friday, average_value_friday, total_reading_friday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_friday, average_value_friday, total_reading_friday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_friday, average_value_friday, total_reading_friday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_friday, average_value_friday, total_reading_friday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_friday, average_value_friday, total_reading_friday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_friday.length === 0 ? "NA" : total_reading_friday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_friday.length === 0 ? "NA" : Math.min.apply(Math, reading_friday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_friday.length === 0 ? "NA" : Math.max.apply(Math, reading_friday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_friday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_friday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_friday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_friday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_friday, average_value_friday, total_reading_friday)[0], total_reading_friday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_friday, average_value_friday, total_reading_friday)[0], total_reading_friday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_friday.length === 0 ? "NA" : Math.round(average_value_friday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_friday, average_value_friday, total_reading_friday)[0], total_reading_friday), average_value_friday)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Sat</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[5], this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[4], this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[3], this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[2], this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_saturday.length === 0 ? "NA" : total_reading_saturday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_saturday.length === 0 ? "NA" : Math.min.apply(Math, reading_saturday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_saturday.length === 0 ? "NA" : Math.max.apply(Math, reading_saturday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_saturday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_saturday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_saturday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_saturday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[0], total_reading_saturday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[0], total_reading_saturday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_saturday.length === 0 ? "NA" : Math.round(average_value_saturday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_saturday, average_value_saturday, total_reading_saturday)[0], total_reading_saturday), average_value_sunday)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Sun</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[5], this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[4], this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[3], this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[2], this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{reading_sunday.length === 0 ? "NA" : total_reading_sunday}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_sunday.length === 0 ? "NA" : Math.min.apply(Math, reading_sunday).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_sunday.length === 0 ? "NA" : Math.max.apply(Math, reading_sunday).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.findQuartile(reading_sunday)[0]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_sunday)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_sunday)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.findQuartile(reading_sunday)[3]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[0], total_reading_sunday) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[0], total_reading_sunday))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{reading_sunday.length === 0 ? "NA" : Math.round(average_value_sunday)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(reading_sunday, average_value_sunday, total_reading_sunday)[0], total_reading_sunday), average_value_sunday)}</h5>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
    getHourlyStatitics() {
        const hour_1 = [],
            hour_2 = [],
            hour_3 = [],
            hour_4 = [],
            hour_5 = [],
            hour_6 = [],
            hour_7 = [],
            hour_8 = [],
            hour_9 = [],
            hour_10 = [],
            hour_11 = [],
            hour_12 = [],
            hour_13 = [],
            hour_14 = [],
            hour_15 = [],
            hour_16 = [],
            hour_17 = [],
            hour_18 = [],
            hour_19 = [],
            hour_20 = [],
            hour_21 = [],
            hour_22 = [],
            hour_23 = [],
            hour_24 = [];
        var total_reading_hour1 = 0,
            total_reading_hour2 = 0,
            total_reading_hour3 = 0,
            total_reading_hour4 = 0,
            total_reading_hour5 = 0,
            total_reading_hour6 = 0,
            total_reading_hour7 = 0,
            total_reading_hour8 = 0,
            total_reading_hour9 = 0,
            total_reading_hour10 = 0,
            total_reading_hour11 = 0,
            total_reading_hour12 = 0,
            total_reading_hour13 = 0,
            total_reading_hour14 = 0,
            total_reading_hour15 = 0,
            total_reading_hour16 = 0,
            total_reading_hour17 = 0,
            total_reading_hour18 = 0,
            total_reading_hour19 = 0,
            total_reading_hour20 = 0,
            total_reading_hour21 = 0,
            total_reading_hour22 = 0,
            total_reading_hour23 = 0,
            total_reading_hour24 = 0;
        var reading_value_hour1 = 0,
            reading_value_hour2 = 0,
            reading_value_hour3 = 0,
            reading_value_hour4 = 0,
            reading_value_hour5 = 0,
            reading_value_hour6 = 0,
            reading_value_hour7 = 0,
            reading_value_hour8 = 0,
            reading_value_hour9 = 0,
            reading_value_hour10 = 0,
            reading_value_hour11 = 0,
            reading_value_hour12 = 0,
            reading_value_hour13 = 0,
            reading_value_hour14 = 0,
            reading_value_hour15 = 0,
            reading_value_hour16 = 0,
            reading_value_hour17 = 0,
            reading_value_hour18 = 0,
            reading_value_hour19 = 0,
            reading_value_hour20 = 0,
            reading_value_hour21 = 0,
            reading_value_hour22 = 0,
            reading_value_hour23 = 0,
            reading_value_hour24 = 0;
        var average_1 = 0,
            average_2 = 0,
            average_3 = 0,
            average_4 = 0,
            average_5 = 0,
            average_6 = 0,
            average_7 = 0,
            average_8 = 0,
            average_9 = 0,
            average_10 = 0,
            average_11 = 0,
            average_12 = 0,
            average_13 = 0,
            average_14 = 0,
            average_15 = 0,
            average_16 = 0,
            average_17 = 0,
            average_18 = 0,
            average_19 = 0,
            average_20 = 0,
            average_21 = 0,
            average_22 = 0,
            average_23 = 0,
            average_24 = 0;
        this.props.dailyReportData.forEach((data) => {
            if (data.readings.success) {
                data.readings.data.forEach((data) => {
                    // if (parseInt(data.reading) < getGlucoseValue(40, overlayReportDataSet[0].glucose_unit)) data.reading = getGlucoseValue(40, overlayReportDataSet[0].glucose_unit);
                    if (parseInt(data.reading) < 40) data.reading = 40;
                    var read = getGlucoseValue(data.reading, this.props.dailyReportData[0].glucose_unit);
                    var date = new Date(parseInt(data.reading_time));
                    var formattedTime = this.getHourMinute(date);
                    if (formattedTime <= 100) {
                        hour_1.push(read);
                        total_reading_hour1 += 1;
                        reading_value_hour1 += parseInt(read);
                    }

                    if (formattedTime > 100 && formattedTime <= 200) {
                        hour_2.push(read);
                        total_reading_hour2 += 1;
                        reading_value_hour2 += parseInt(read);
                    }
                    if (formattedTime > 200 && formattedTime <= 300) {
                        hour_3.push(read);
                        total_reading_hour3 += 1;
                        reading_value_hour3 += parseInt(read);
                    }
                    if (formattedTime > 300 && formattedTime <= 400) {
                        hour_4.push(read);
                        total_reading_hour4 += 1;
                        reading_value_hour4 += parseInt(read);
                    }
                    if (formattedTime > 400 && formattedTime <= 500) {
                        hour_5.push(read);
                        total_reading_hour5 += 1;
                        reading_value_hour5 += parseInt(read);
                    }
                    if (formattedTime > 500 && formattedTime <= 600) {
                        hour_6.push(read);
                        total_reading_hour6 += 1;
                        reading_value_hour6 += parseInt(read);
                    }
                    if (formattedTime > 600 && formattedTime <= 700) {
                        hour_7.push(read);
                        total_reading_hour7 += 1;
                        reading_value_hour7 += parseInt(read);
                    }
                    if (formattedTime > 700 && formattedTime <= 800) {
                        hour_8.push(read);
                        total_reading_hour8 += 1;
                        reading_value_hour8 += parseInt(read);
                    }
                    if (formattedTime > 800 && formattedTime <= 900) {
                        hour_9.push(read);
                        total_reading_hour9 += 1;
                        reading_value_hour9 += parseInt(read);
                    }
                    if (formattedTime > 900 && formattedTime <= 1000) {
                        hour_10.push(read);
                        total_reading_hour10 += 1;
                        reading_value_hour10 += parseInt(read);
                    }
                    if (formattedTime > 1000 && formattedTime <= 1100) {
                        hour_11.push(read);
                        total_reading_hour11 += 1;
                        reading_value_hour11 += parseInt(read);
                    }
                    if (formattedTime > 1100 && formattedTime <= 1200) {
                        hour_12.push(read);
                        total_reading_hour12 += 1;
                        reading_value_hour12 += parseInt(read);
                    }
                    if (formattedTime > 1200 && formattedTime <= 1300) {
                        hour_13.push(read);
                        total_reading_hour13 += 1;
                        reading_value_hour13 += parseInt(read);
                    }
                    if (formattedTime > 1300 && formattedTime <= 1400) {
                        hour_14.push(read);
                        total_reading_hour14 += 1;
                        reading_value_hour14 += parseInt(read);
                    }
                    if (formattedTime > 1400 && formattedTime <= 1500) {
                        hour_15.push(read);
                        total_reading_hour15 += 1;
                        reading_value_hour15 += parseInt(read);
                    }
                    if (formattedTime > 1500 && formattedTime <= 1600) {
                        hour_16.push(read);
                        total_reading_hour16 += 1;
                        reading_value_hour16 += parseInt(read);
                    }
                    if (formattedTime > 1600 && formattedTime <= 1700) {
                        hour_17.push(read);
                        total_reading_hour17 += 1;
                        reading_value_hour17 += parseInt(read);
                    }
                    if (formattedTime > 1700 && formattedTime <= 1800) {
                        hour_18.push(read);
                        total_reading_hour18 += 1;
                        reading_value_hour18 += parseInt(read);
                    }
                    if (formattedTime > 1800 && formattedTime <= 1900) {
                        hour_19.push(read);
                        total_reading_hour19 += 1;
                        reading_value_hour19 += parseInt(read);
                    }
                    if (formattedTime > 1900 && formattedTime <= 2000) {
                        hour_20.push(read);
                        total_reading_hour20 += 1;
                        reading_value_hour20 += parseInt(read);
                    }
                    if (formattedTime > 2000 && formattedTime <= 2100) {
                        hour_21.push(read);
                        total_reading_hour21 += 1;
                        reading_value_hour21 += parseInt(read);
                    }
                    if (formattedTime > 2100 && formattedTime <= 2200) {
                        hour_22.push(read);
                        total_reading_hour22 += 1;
                        reading_value_hour22 += parseInt(read);
                    }
                    if (formattedTime > 2200 && formattedTime <= 2300) {
                        hour_23.push(read);
                        total_reading_hour23 += 1;
                        reading_value_hour23 += parseInt(read);
                    }
                    if (formattedTime > 2300 && formattedTime <= 2400) {
                        hour_24.push(read);
                        total_reading_hour24 += 1;
                        reading_value_hour24 += parseInt(read);
                    }
                });
                average_1 = parseFloat(reading_value_hour1 / total_reading_hour1);
                average_2 = parseFloat(reading_value_hour2 / total_reading_hour2);
                average_3 = parseFloat(reading_value_hour3 / total_reading_hour3);
                average_4 = parseFloat(reading_value_hour4 / total_reading_hour4);
                average_5 = parseFloat(reading_value_hour5 / total_reading_hour5);
                average_6 = parseFloat(reading_value_hour6 / total_reading_hour6);
                average_7 = parseFloat(reading_value_hour7 / total_reading_hour7);
                average_8 = parseFloat(reading_value_hour8 / total_reading_hour8);
                average_9 = parseFloat(reading_value_hour9 / total_reading_hour9);
                average_10 = parseFloat(reading_value_hour10 / total_reading_hour10);
                average_11 = parseFloat(reading_value_hour11 / total_reading_hour11);
                average_12 = parseFloat(reading_value_hour12 / total_reading_hour12);
                average_13 = parseFloat(reading_value_hour13 / total_reading_hour13);
                average_14 = parseFloat(reading_value_hour14 / total_reading_hour14);
                average_15 = parseFloat(reading_value_hour15 / total_reading_hour15);
                average_16 = parseFloat(reading_value_hour16 / total_reading_hour16);
                average_17 = parseFloat(reading_value_hour17 / total_reading_hour17);
                average_18 = parseFloat(reading_value_hour18 / total_reading_hour18);
                average_19 = parseFloat(reading_value_hour19 / total_reading_hour19);
                average_20 = parseFloat(reading_value_hour20 / total_reading_hour20);
                average_21 = parseFloat(reading_value_hour21 / total_reading_hour21);
                average_22 = parseFloat(reading_value_hour22 / total_reading_hour22);
                average_23 = parseFloat(reading_value_hour23 / total_reading_hour23);
                average_24 = parseFloat(reading_value_hour24 / total_reading_hour24);
            }
        });
        return (
            <GridContainer>
                <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5>
                <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>
                <GridItem style={{ pageBreakAfter: "always", height: "1540px" }}>
                    <h4> Hourly Statistic</h4>
                    <div className="page-number" style={{ color: "#000000", top: "1470px" }} />
                    <div className="row ">
                        <div className="dailystatiticsblock" style={{ marginLeft: "15px", width: "200px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Hourly statistics</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>Time in Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>% Very High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>% High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % In Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Very Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> # Readings</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Min</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Max</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Quartile 25</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> Median</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>MaQuartile 75</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> IQR</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Std.Dev</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Mean</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> %CV</b>
                            </h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>12am-1am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_1, average_1, total_reading_hour1)[5], this.getData(hour_1, average_1, total_reading_hour1)[4], this.getData(hour_1, average_1, total_reading_hour1)[3], this.getData(hour_1, average_1, total_reading_hour1)[2], this.getData(hour_1, average_1, total_reading_hour1)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_1, average_1, total_reading_hour1)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_1, average_1, total_reading_hour1)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_1, average_1, total_reading_hour1)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_1, average_1, total_reading_hour1)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_1, average_1, total_reading_hour1)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_1.length === 0 ? "NA" : total_reading_hour1}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_1.length === 0 ? "NA" : Math.min.apply(Math, hour_1).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_1.length === 0 ? "NA" : Math.max.apply(Math, hour_1).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_1)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_1)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_1)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_1)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_1, average_1, total_reading_hour1)[0], total_reading_hour1) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_1, average_1, total_reading_hour1)[0], total_reading_hour1))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_1.length === 0 ? "NA" : Math.round(average_1)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_1, average_1, total_reading_hour1)[0], total_reading_hour1), average_1)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>1am-2am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_2, average_2, total_reading_hour2)[5], this.getData(hour_2, average_2, total_reading_hour2)[4], this.getData(hour_2, average_2, total_reading_hour2)[3], this.getData(hour_2, average_2, total_reading_hour2)[2], this.getData(hour_2, average_2, total_reading_hour2)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_2, average_2, total_reading_hour2)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_2, average_2, total_reading_hour2)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_2, average_2, total_reading_hour2)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_2, average_2, total_reading_hour2)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_2, average_2, total_reading_hour2)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_2.length === 0 ? "NA" : total_reading_hour2}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_2.length === 0 ? "NA" : Math.min.apply(Math, hour_2).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_2.length === 0 ? "NA" : Math.max.apply(Math, hour_2).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_2)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_2)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_2)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_2)[3]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_2, average_2, total_reading_hour2)[0], total_reading_hour2) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_2, average_2, total_reading_hour2)[0], total_reading_hour2))}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_2.length === 0 ? "NA" : Math.round(average_2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_2, average_2, total_reading_hour2)[0], total_reading_hour2), average_2)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>2am-3am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_3, average_3, total_reading_hour3)[5], this.getData(hour_3, average_3, total_reading_hour3)[4], this.getData(hour_3, average_3, total_reading_hour3)[3], this.getData(hour_3, average_3, total_reading_hour3)[2], this.getData(hour_3, average_3, total_reading_hour3)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_3, average_3, total_reading_hour3)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_3, average_3, total_reading_hour3)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_3, average_3, total_reading_hour3)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_3, average_3, total_reading_hour3)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_3, average_3, total_reading_hour3)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_3.length === 0 ? "NA" : total_reading_hour3}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_3.length === 0 ? "NA" : Math.min.apply(Math, hour_3).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_3.length === 0 ? "NA" : Math.max.apply(Math, hour_3).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_3)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_3)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_3)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_3)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_3, average_3, total_reading_hour3)[0], total_reading_hour3) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_3, average_3, total_reading_hour3)[0], total_reading_hour3))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_3.length === 0 ? "NA" : Math.round(average_3)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_3, average_3, total_reading_hour3)[0], total_reading_hour3), average_3)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>3am-4am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_4, average_4, total_reading_hour4)[5], this.getData(hour_4, average_4, total_reading_hour4)[4], this.getData(hour_4, average_4, total_reading_hour4)[3], this.getData(hour_4, average_4, total_reading_hour4)[2], this.getData(hour_4, average_4, total_reading_hour4)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_4, average_4, total_reading_hour4)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_4, average_4, total_reading_hour4)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_4, average_4, total_reading_hour4)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_4, average_4, total_reading_hour4)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_4, average_4, total_reading_hour4)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_4.length === 0 ? "NA" : total_reading_hour4}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_4.length === 0 ? "NA" : Math.min.apply(Math, hour_4).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_4.length === 0 ? "NA" : Math.max.apply(Math, hour_4).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_4)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_4)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_4)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_4)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_4, average_4, total_reading_hour4)[0], total_reading_hour4) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_4, average_4, total_reading_hour4)[0], total_reading_hour4))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_4.length === 0 ? "NA" : Math.round(average_4)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_4, average_4, total_reading_hour4)[0], total_reading_hour4), average_4)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>4am-5am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_5, average_5, total_reading_hour5)[5], this.getData(hour_5, average_5, total_reading_hour5)[4], this.getData(hour_5, average_5, total_reading_hour5)[3], this.getData(hour_5, average_5, total_reading_hour5)[2], this.getData(hour_5, average_5, total_reading_hour5)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_5, average_5, total_reading_hour5)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_5, average_5, total_reading_hour5)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_5, average_5, total_reading_hour5)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_5, average_5, total_reading_hour5)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_5, average_5, total_reading_hour5)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_5.length === 0 ? "NA" : total_reading_hour5}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_5.length === 0 ? "NA" : Math.min.apply(Math, hour_5).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_5.length === 0 ? "NA" : Math.max.apply(Math, hour_5).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_5)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_5)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_5)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_5)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_5, average_5, total_reading_hour5)[0], total_reading_hour5) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_5, average_5, total_reading_hour5)[0], total_reading_hour5))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_5.length === 0 ? "NA" : Math.round(average_5)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_5, average_5, total_reading_hour5)[0], total_reading_hour5), average_5)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>5am-6am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_6, average_6, total_reading_hour6)[5], this.getData(hour_6, average_6, total_reading_hour6)[4], this.getData(hour_6, average_6, total_reading_hour6)[3], this.getData(hour_6, average_6, total_reading_hour6)[2], this.getData(hour_6, average_6, total_reading_hour6)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_6, average_6, total_reading_hour6)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_6, average_6, total_reading_hour6)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_6, average_6, total_reading_hour6)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_6, average_6, total_reading_hour6)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_6, average_6, total_reading_hour6)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_6.length === 0 ? "NA" : total_reading_hour6}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_6.length === 0 ? "NA" : Math.min.apply(Math, hour_6).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_6.length === 0 ? "NA" : Math.max.apply(Math, hour_6).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_6)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_6)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_6)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_6)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_6, average_6, total_reading_hour6)[0], total_reading_hour6) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_6, average_6, total_reading_hour6)[0], total_reading_hour6))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_6.length === 0 ? "NA" : Math.round(average_6)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_6, average_6, total_reading_hour6)[0], total_reading_hour6), average_6)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>6am-7am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_7, average_7, total_reading_hour7)[5], this.getData(hour_7, average_7, total_reading_hour7)[4], this.getData(hour_7, average_7, total_reading_hour7)[3], this.getData(hour_7, average_7, total_reading_hour7)[2], this.getData(hour_7, average_7, total_reading_hour7)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_7, average_7, total_reading_hour7)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_7, average_7, total_reading_hour7)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_7, average_7, total_reading_hour7)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_7, average_7, total_reading_hour7)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_7, average_7, total_reading_hour7)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_7.length === 0 ? "NA" : total_reading_hour7}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_7.length === 0 ? "NA" : Math.min.apply(Math, hour_7).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_7.length === 0 ? "NA" : Math.max.apply(Math, hour_7).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_7)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_7)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_7)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_7)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_7, average_7, total_reading_hour7)[0], total_reading_hour7) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_7, average_7, total_reading_hour7)[0], total_reading_hour7))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_7.length === 0 ? "NA" : Math.round(average_7)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_7, average_7, total_reading_hour7)[0], total_reading_hour7), average_7)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>7am-8am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_8, average_8, total_reading_hour8)[5], this.getData(hour_8, average_8, total_reading_hour8)[4], this.getData(hour_8, average_8, total_reading_hour8)[3], this.getData(hour_8, average_8, total_reading_hour8)[2], this.getData(hour_8, average_8, total_reading_hour8)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_8, average_8, total_reading_hour8)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_8, average_8, total_reading_hour8)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_8, average_8, total_reading_hour8)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_8, average_8, total_reading_hour8)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_8, average_8, total_reading_hour8)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_8.length === 0 ? "NA" : total_reading_hour8}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_8.length === 0 ? "NA" : Math.min.apply(Math, hour_8).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_8.length === 0 ? "NA" : Math.max.apply(Math, hour_8).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_8)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_8)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_8)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_8)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_8, average_8, total_reading_hour8)[0], total_reading_hour8) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_8, average_8, total_reading_hour8)[0], total_reading_hour8))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_8.length === 0 ? "NA" : Math.round(average_8)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_8, average_8, total_reading_hour8)[0], total_reading_hour8), average_8)}</h5>
                        </div>
                    </div>
                </GridItem>
                <h5 className="page-header-left" style={{ color: "#000000", pageBreakBefore: "always" }}>{`${this.props.patientName}`}</h5>
                <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>

                <GridItem
                    style={{
                        pageBreakAfter: "always",
                        pageBreakBefore: "always",
                        height: "1540px",
                    }}
                >
                    <div className="page-number" style={{ color: "#000000", top: "1510px" }} />
                    <div className="row ">
                        <div className="dailystatiticsblock" style={{ marginLeft: "15px", width: "200px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Hourly statistics</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>Time in Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>% Very High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>% High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % In Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Very Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> # Readings</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Min</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Max</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Quartile 25</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> Median</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>MaQuartile 75</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> IQR</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Std.Dev</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Mean</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> %CV</b>
                            </h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>8am-9am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_9, average_9, total_reading_hour9)[5], this.getData(hour_9, average_9, total_reading_hour9)[4], this.getData(hour_9, average_9, total_reading_hour9)[3], this.getData(hour_9, average_9, total_reading_hour9)[2], this.getData(hour_9, average_9, total_reading_hour9)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_9, average_9, total_reading_hour9)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_9, average_9, total_reading_hour9)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_9, average_9, total_reading_hour9)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_9, average_9, total_reading_hour9)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_9, average_9, total_reading_hour9)[1]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_9.length === 0 ? "NA" : total_reading_hour9}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_9.length === 0 ? "NA" : Math.min.apply(Math, hour_9).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_9.length === 0 ? "NA" : Math.max.apply(Math, hour_9).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_9)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_9)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_9)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_9)[3]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_9, average_9, total_reading_hour9)[0], total_reading_hour9) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_9, average_9, total_reading_hour9)[0], total_reading_hour9))}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_9.length === 0 ? "NA" : Math.round(average_9)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_9, average_9, total_reading_hour9)[0], total_reading_hour9), average_9)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>9am-10am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_10, average_10, total_reading_hour10)[5], this.getData(hour_10, average_10, total_reading_hour10)[4], this.getData(hour_10, average_10, total_reading_hour10)[3], this.getData(hour_10, average_10, total_reading_hour10)[2], this.getData(hour_10, average_10, total_reading_hour10)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_10, average_10, total_reading_hour10)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_10, average_10, total_reading_hour10)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_10, average_10, total_reading_hour10)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_10, average_10, total_reading_hour10)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_10, average_10, total_reading_hour10)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_10.length === 0 ? "NA" : total_reading_hour10}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_10.length === 0 ? "NA" : Math.min.apply(Math, hour_10).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_10.length === 0 ? "NA" : Math.max.apply(Math, hour_10).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_10)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_10)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_10)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_10)[3]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_10, average_10, total_reading_hour10)[0], total_reading_hour10) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_10, average_10, total_reading_hour10)[0], total_reading_hour10))}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_10.length === 0 ? "NA" : Math.round(average_10)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_10, average_10, total_reading_hour10)[0], total_reading_hour10), average_10)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>10am-11am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_11, average_11, total_reading_hour11)[5], this.getData(hour_11, average_11, total_reading_hour11)[4], this.getData(hour_11, average_11, total_reading_hour11)[3], this.getData(hour_11, average_11, total_reading_hour11)[2], this.getData(hour_11, average_11, total_reading_hour11)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_11, average_11, total_reading_hour11)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_11, average_11, total_reading_hour11)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_11, average_11, total_reading_hour11)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_11, average_11, total_reading_hour11)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_11, average_11, total_reading_hour11)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_11.length === 0 ? "NA" : total_reading_hour11}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_11.length === 0 ? "NA" : Math.min.apply(Math, hour_11).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_11.length === 0 ? "NA" : Math.max.apply(Math, hour_11).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_11)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_11)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_11)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_11)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_11, average_11, total_reading_hour11)[0], total_reading_hour11) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_11, average_11, total_reading_hour11)[0], total_reading_hour11))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_11.length === 0 ? "NA" : Math.round(average_11)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_11, average_11, total_reading_hour11)[0], total_reading_hour11), average_11)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>11am-12pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_12, average_12, total_reading_hour12)[5], this.getData(hour_12, average_12, total_reading_hour12)[4], this.getData(hour_12, average_12, total_reading_hour12)[3], this.getData(hour_12, average_12, total_reading_hour12)[2], this.getData(hour_12, average_12, total_reading_hour12)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_12, average_12, total_reading_hour12)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_12, average_12, total_reading_hour12)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_12, average_12, total_reading_hour12)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_12, average_12, total_reading_hour12)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_12, average_12, total_reading_hour12)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_12.length === 0 ? "NA" : total_reading_hour12}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_12.length === 0 ? "NA" : Math.min.apply(Math, hour_12).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_12.length === 0 ? "NA" : Math.max.apply(Math, hour_12).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_12)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_12)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_12)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_12)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_12, average_12, total_reading_hour12)[0], total_reading_hour12) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_12, average_12, total_reading_hour12)[0], total_reading_hour12))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_12.length === 0 ? "NA" : Math.round(average_12)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_12, average_12, total_reading_hour12)[0], total_reading_hour12), average_12)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>12pm-1pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_13, average_13, total_reading_hour13)[5], this.getData(hour_13, average_13, total_reading_hour13)[4], this.getData(hour_13, average_13, total_reading_hour13)[3], this.getData(hour_13, average_13, total_reading_hour13)[2], this.getData(hour_13, average_13, total_reading_hour13)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_13, average_13, total_reading_hour13)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_13, average_13, total_reading_hour13)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_13, average_13, total_reading_hour13)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_13, average_13, total_reading_hour13)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_13, average_13, total_reading_hour13)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_13.length === 0 ? "NA" : total_reading_hour13}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_13.length === 0 ? "NA" : Math.min.apply(Math, hour_13).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_13.length === 0 ? "NA" : Math.max.apply(Math, hour_13).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_13)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_13)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_13)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_13)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_13, average_13, total_reading_hour13)[0], total_reading_hour13) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_13, average_13, total_reading_hour13)[0], total_reading_hour13))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_13.length === 0 ? "NA" : Math.round(average_13)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_13, average_13, total_reading_hour13)[0], total_reading_hour13), average_13)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>1pm-2pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_14, average_14, total_reading_hour14)[5], this.getData(hour_14, average_14, total_reading_hour14)[4], this.getData(hour_14, average_14, total_reading_hour14)[3], this.getData(hour_14, average_14, total_reading_hour14)[2], this.getData(hour_14, average_14, total_reading_hour14)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_14, average_14, total_reading_hour14)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_14, average_14, total_reading_hour14)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_14, average_14, total_reading_hour14)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_14, average_14, total_reading_hour14)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_14, average_14, total_reading_hour14)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_14.length === 0 ? "NA" : total_reading_hour14}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_14.length === 0 ? "NA" : Math.min.apply(Math, hour_14).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_14.length === 0 ? "NA" : Math.max.apply(Math, hour_14).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_14)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_14)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_14)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_14)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_14, average_14, total_reading_hour14)[0], total_reading_hour14) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_14, average_14, total_reading_hour14)[0], total_reading_hour14))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_14.length === 0 ? "NA" : Math.round(average_14)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_14, average_14, total_reading_hour14)[0], total_reading_hour14), average_14)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>2pm-3pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_15, average_15, total_reading_hour15)[5], this.getData(hour_15, average_15, total_reading_hour15)[4], this.getData(hour_15, average_15, total_reading_hour15)[3], this.getData(hour_15, average_15, total_reading_hour15)[2], this.getData(hour_15, average_15, total_reading_hour15)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_15, average_15, total_reading_hour15)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_15, average_15, total_reading_hour15)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_15, average_15, total_reading_hour15)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_15, average_15, total_reading_hour15)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_15, average_15, total_reading_hour15)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_15.length === 0 ? "NA" : total_reading_hour15}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_15.length === 0 ? "NA" : Math.min.apply(Math, hour_15).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_15.length === 0 ? "NA" : Math.max.apply(Math, hour_15).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_15)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_15)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_15)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_15)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_15, average_15, total_reading_hour15)[0], total_reading_hour15) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_15, average_15, total_reading_hour15)[0], total_reading_hour15))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_15.length === 0 ? "NA" : Math.round(average_15)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_15, average_15, total_reading_hour15)[0], total_reading_hour15), average_15)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>3pm-4pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_16, average_16, total_reading_hour16)[5], this.getData(hour_16, average_16, total_reading_hour16)[4], this.getData(hour_16, average_16, total_reading_hour16)[3], this.getData(hour_16, average_16, total_reading_hour16)[2], this.getData(hour_16, average_16, total_reading_hour16)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_16, average_16, total_reading_hour16)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_16, average_16, total_reading_hour16)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_16, average_16, total_reading_hour16)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_16, average_16, total_reading_hour16)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_16, average_16, total_reading_hour16)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_16.length === 0 ? "NA" : total_reading_hour16}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_16.length === 0 ? "NA" : Math.min.apply(Math, hour_16).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_16.length === 0 ? "NA" : Math.max.apply(Math, hour_16).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_16)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_16)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_16)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_16)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_16, average_16, total_reading_hour16)[0], total_reading_hour16) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_16, average_16, total_reading_hour16)[0], total_reading_hour16))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_16.length === 0 ? "NA" : Math.round(average_16)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_16, average_16, total_reading_hour16)[0], total_reading_hour16), average_16)}</h5>
                        </div>
                    </div>
                </GridItem>
                <h5 className="page-header-left" style={{ color: "#000000" }}>{`${this.props.patientName}`}</h5>
                <h5 className="page-header" style={{ color: "#000000" }}>{`${this.formatTimestampWithDay(new Date(parseInt(this.props.dailyReportData[0].date)))}`}</h5>

                <GridItem style={{ height: "1500px" }}>
                    <div className="page-number" style={{ color: "#000000", top: "1510px" }} />
                    <div className="row ">
                        <div className="dailystatiticsblock" style={{ marginLeft: "15px", width: "200px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Hourly statistics</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>Time in Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "50px" }}>
                                <b>% Very High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>% High</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % In Range</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> % Very Low</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> # Readings</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Min</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Max</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Quartile 25</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> Median</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>MaQuartile 75</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> IQR</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>
                                <b> Std.Dev</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>Mean</b>
                            </h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b> %CV</b>
                            </h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>4pm-5pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_17, average_17, total_reading_hour17)[5], this.getData(hour_17, average_17, total_reading_hour17)[4], this.getData(hour_17, average_17, total_reading_hour17)[3], this.getData(hour_17, average_17, total_reading_hour17)[2], this.getData(hour_17, average_17, total_reading_hour17)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_17, average_17, total_reading_hour17)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_17, average_17, total_reading_hour17)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_17, average_17, total_reading_hour17)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_17, average_17, total_reading_hour17)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_17, average_17, total_reading_hour17)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_17.length === 0 ? "NA" : total_reading_hour17}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_17.length === 0 ? "NA" : Math.min.apply(Math, hour_17).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_17.length === 0 ? "NA" : Math.max.apply(Math, hour_17).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_17)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_17)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_17)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_17)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_17, average_17, total_reading_hour17)[0], total_reading_hour17) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_17, average_17, total_reading_hour17)[0], total_reading_hour17))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_17.length === 0 ? "NA" : Math.round(average_17)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_17, average_17, total_reading_hour17)[0], total_reading_hour17), average_17)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>5pm-6pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_18, average_18, total_reading_hour18)[5], this.getData(hour_18, average_18, total_reading_hour18)[4], this.getData(hour_18, average_18, total_reading_hour18)[3], this.getData(hour_18, average_18, total_reading_hour18)[2], this.getData(hour_18, average_18, total_reading_hour18)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_18, average_18, total_reading_hour18)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_18, average_18, total_reading_hour18)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_18, average_18, total_reading_hour18)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_18, average_18, total_reading_hour18)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_18, average_18, total_reading_hour18)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_18.length === 0 ? "NA" : total_reading_hour18}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_18.length === 0 ? "NA" : Math.min.apply(Math, hour_18).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_18.length === 0 ? "NA" : Math.max.apply(Math, hour_18).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_18)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_18)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_18)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_18)[3]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_18, average_18, total_reading_hour18)[0], total_reading_hour18) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_18, average_18, total_reading_hour18)[0], total_reading_hour18))}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_18.length === 0 ? "NA" : Math.round(average_18)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_18, average_18, total_reading_hour18)[0], total_reading_hour18), average_18)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>6pm-7pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_19, average_19, total_reading_hour19)[5], this.getData(hour_19, average_19, total_reading_hour19)[4], this.getData(hour_19, average_19, total_reading_hour19)[3], this.getData(hour_19, average_19, total_reading_hour19)[2], this.getData(hour_19, average_19, total_reading_hour19)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_19, average_19, total_reading_hour19)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_19, average_19, total_reading_hour19)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_19, average_19, total_reading_hour19)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_19, average_19, total_reading_hour19)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_19, average_19, total_reading_hour19)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_19.length === 0 ? "NA" : total_reading_hour19}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_19.length === 0 ? "NA" : Math.min.apply(Math, hour_19).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_19.length === 0 ? "NA" : Math.max.apply(Math, hour_19).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_19)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_19)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_19)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_19)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_19, average_19, total_reading_hour19)[0], total_reading_hour19) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_19, average_19, total_reading_hour19)[0], total_reading_hour19))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_19.length === 0 ? "NA" : Math.round(average_19)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_19, average_19, total_reading_hour19)[0], total_reading_hour19), average_19)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>7pm-8pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_20, average_20, total_reading_hour20)[5], this.getData(hour_20, average_20, total_reading_hour20)[4], this.getData(hour_20, average_20, total_reading_hour20)[3], this.getData(hour_20, average_20, total_reading_hour20)[2], this.getData(hour_20, average_20, total_reading_hour20)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_20, average_20, total_reading_hour20)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_20, average_20, total_reading_hour20)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_20, average_20, total_reading_hour20)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_20, average_20, total_reading_hour20)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_20, average_20, total_reading_hour20)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_20.length === 0 ? "NA" : total_reading_hour20}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_20.length === 0 ? "NA" : Math.min.apply(Math, hour_20).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_20.length === 0 ? "NA" : Math.max.apply(Math, hour_20).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_20)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_20)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_20)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_20)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_20, average_20, total_reading_hour20)[0], total_reading_hour20) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_20, average_20, total_reading_hour20)[0], total_reading_hour20))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_20.length === 0 ? "NA" : Math.round(average_20)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_20, average_20, total_reading_hour20)[0], total_reading_hour20), average_20)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>8pm-9pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_21, average_21, total_reading_hour21)[5], this.getData(hour_21, average_21, total_reading_hour21)[4], this.getData(hour_21, average_21, total_reading_hour21)[3], this.getData(hour_21, average_21, total_reading_hour21)[2], this.getData(hour_21, average_21, total_reading_hour21)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_21, average_21, total_reading_hour21)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_21, average_21, total_reading_hour21)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_21, average_21, total_reading_hour21)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_21, average_21, total_reading_hour21)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_21, average_21, total_reading_hour21)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_21.length === 0 ? "NA" : total_reading_hour21}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_21.length === 0 ? "NA" : Math.min.apply(Math, hour_21).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_21.length === 0 ? "NA" : Math.max.apply(Math, hour_21).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_21)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_21)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_21)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_21)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_21, average_21, total_reading_hour21)[0], total_reading_hour21) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_21, average_21, total_reading_hour21)[0], total_reading_hour21))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_21.length === 0 ? "NA" : Math.round(average_21)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_21, average_21, total_reading_hour21)[0], total_reading_hour21), average_21)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>9pm-10pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_22, average_22, total_reading_hour22)[5], this.getData(hour_22, average_22, total_reading_hour22)[4], this.getData(hour_22, average_7, total_reading_hour22)[3], this.getData(hour_22, average_22, total_reading_hour22)[2], this.getData(hour_22, average_22, total_reading_hour22)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_22, average_22, total_reading_hour22)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_22, average_22, total_reading_hour22)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_22, average_22, total_reading_hour22)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_22, average_22, total_reading_hour22)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_22, average_22, total_reading_hour22)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_22.length === 0 ? "NA" : total_reading_hour22}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_22.length === 0 ? "NA" : Math.min.apply(Math, hour_22).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_22.length === 0 ? "NA" : Math.max.apply(Math, hour_22).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_22)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_22)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_22)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_22)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_22, average_22, total_reading_hour22)[0], total_reading_hour22) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_22, average_22, total_reading_hour22)[0], total_reading_hour22))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_22.length === 0 ? "NA" : Math.round(average_22)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_22, average_22, total_reading_hour22)[0], total_reading_hour22), average_22)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>10pm-11pm</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_23, average_23, total_reading_hour23)[5], this.getData(hour_23, average_23, total_reading_hour23)[4], this.getData(hour_23, average_23, total_reading_hour23)[3], this.getData(hour_23, average_23, total_reading_hour23)[2], this.getData(hour_23, average_23, total_reading_hour23)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_23, average_23, total_reading_hour23)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_23, average_23, total_reading_hour23)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_23, average_23, total_reading_hour23)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_23, average_23, total_reading_hour23)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_23, average_23, total_reading_hour23)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_23.length === 0 ? "NA" : total_reading_hour23}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_23.length === 0 ? "NA" : Math.min.apply(Math, hour_23).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_23.length === 0 ? "NA" : Math.max.apply(Math, hour_23).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_23)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_23)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_23)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_23)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_23, average_23, total_reading_hour23)[0], total_reading_hour23) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_23, average_23, total_reading_hour23)[0], total_reading_hour23))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_23.length === 0 ? "NA" : Math.round(average_23)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_23, average_23, total_reading_hour23)[0], total_reading_hour23), average_23)}</h5>
                        </div>
                        <div className="dailystatiticsblock" style={{ width: "100px" }}>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>
                                <b>11pm-12am</b>
                            </h5>
                            <div style={{}}>{this.getStatisticsBar(this.getData(hour_24, average_24, total_reading_hour24)[5], this.getData(hour_24, average_24, total_reading_hour24)[4], this.getData(hour_24, average_24, total_reading_hour24)[3], this.getData(hour_24, average_24, total_reading_hour24)[2], this.getData(hour_24, average_24, total_reading_hour24)[1])}</div>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_24, average_24, total_reading_hour24)[5]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_24, average_24, total_reading_hour24)[4]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_24, average_24, total_reading_hour24)[3]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_24, average_24, total_reading_hour24)[2]}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getData(hour_24, average_24, total_reading_hour24)[1]}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{hour_24.length === 0 ? "NA" : total_reading_hour24}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_24.length === 0 ? "NA" : Math.min.apply(Math, hour_24).toFixed(2)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_24.length === 0 ? "NA" : Math.max.apply(Math, hour_24).toFixed(2)}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{`${this.findQuartile(hour_24)[0]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_24)[1]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_24)[2]}`}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{`${this.findQuartile(hour_24)[3]}`}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "35px" }}>{this.getStandardDeviation(this.getData(hour_24, average_24, total_reading_hour24)[0], total_reading_hour24) === "NA" ? "NA" : Math.round(this.getStandardDeviation(this.getData(hour_24, average_24, total_reading_hour24)[0], total_reading_hour24))}</h5>

                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{hour_24.length === 0 ? "NA" : Math.round(average_24)}</h5>
                            <h5 style={{ fontSize: "0.99em", marginTop: "5px" }}>{this.getCoefficientVarience(this.getStandardDeviation(this.getData(hour_24, average_24, total_reading_hour24)[0], total_reading_hour24), average_24)}</h5>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        );
    }
    getStatisticsBar(veryHighDataPercentage, highDataPercentage, targetDataPercentage, lowDataPercentage, veryLowDataPercentage) {
        var data = [
            {
                "Time in Target": "Time in Target",
                // VeryHigh: parseInt(veryHighDataPercentage),
                // High: parseInt(highDataPercentage),
                // InTargetRange: parseInt(targetDataPercentage),
                // Low: parseInt(lowDataPercentage),
                // VeryLow: parseInt(veryLowDataPercentage),

                VeryLow: Math.round(veryLowDataPercentage),
                Low: Math.round(lowDataPercentage),
                InTargetRange: Math.round(targetDataPercentage),
                High: Math.round(highDataPercentage),
                VeryHigh: Math.round(veryHighDataPercentage),

            },
        ];
        return (
            <GridContainer>
                <GridItem style={{ width: "70px", height: "100px", marginLeft: "30px" }}>
                    <ResponsiveBar
                        data={data}
                        keys={["VeryLow", "Low", "InTargetRange", "High", "VeryHigh"]}
                        colors={this.getColorArray(data)}
                        indexBy="Time in Target"
                        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                        borderColor={{ from: "color", modifiers: [["darker", "2"]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridY={false}
                        labelSkipWidth={36}
                        labelSkipHeight={36}
                        labelTextColor={{ from: "color", modifiers: [["darker", "1.9"]] }}
                        animate={false}
                        motionStiffness={90}
                        motionDamping={15}
                        isInteractive={false}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    getColorArray(data) {
        //console.log("data", data);
        let finalArray = ["#ED1C24", "#fd8c80", "#98CF4A", "#FCD45A", "#FC8001"]
        if (data[0].VeryLow === 0) {
            finalArray.splice(finalArray.indexOf('#ED1C24'), 1);
        }
        if (data[0].Low === 0) {
            finalArray.splice(finalArray.indexOf('#fd8c80'), 1);
        }
        if (data[0].InTargetRange === 0) {
            finalArray.splice(finalArray.indexOf('#98CF4A'), 1);
        }
        if (data[0].High === 0) {
            finalArray.splice(finalArray.indexOf('#FCD45A'), 1);
        }
        if (data[0].VeryHigh === 0) {
            finalArray.splice(finalArray.indexOf('#FC8001'), 1);
        }
        if (data[0].VeryLow === 0 &&
            data[0].Low === 0 &&
            data[0].InTargetRange === 0 &&
            data[0].High === 0 &&
            data[0].VeryHigh === 0) {
            finalArray = ["#ED1C24", "#fd8c80", "#98CF4A", "#FCD45A", "#FC8001"]
        }
        return finalArray;
    }
    getPower(value, avg) {
        return Math.pow(value - avg, 2);
    }
    getCoefficient(sd, avg) {
        if (sd !== "NA") {
            var cv = (parseFloat(sd) / parseFloat(avg)) * 100;
            return cv.toFixed(1);
        } else {
            return "NA";
        }
    }
    getRandomColorHash = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return "#" + randomColor;
    };

    formatAMPM(date) {
        var hours = date.getHours();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours === 12 ? `${hours} ${ampm}` : `${hours}`;
        // return `${hours} ${ampm}`;
    }
    getHourMinute(date) {
        var hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (minute.length < 2) minute = "0" + minute;
        return `${hours}${minute}`;
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            seconds = "" + date.getSeconds(),
            milliseconds = "" + date.getMilliseconds();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        // if (hours.length < 2) hours = "0" + hours;
        if (minute.length < 2) minute = "0" + minute;
        if (seconds.length < 2) seconds = "0" + seconds;
        if (milliseconds.length < 3) milliseconds = "00" + milliseconds;
        return `${year}-${month}-${day} ${hours}:${minute}:${seconds}.${milliseconds} ${ampm}`;
    }
    formatTimestampWithDay(date) {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var month = months[date.getMonth()],
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            seconds = "" + date.getSeconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (hours.length < 2) hours = "0" + hours;
        if (minute.length < 2) minute = "0" + minute;
        if (seconds.length < 2) seconds = "0" + seconds;
        return `${month} ${day} ${year} ${hours}:${minute}:${seconds}`;
    }
    formatTimestampWithHM(date) {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var month = months[date.getMonth()],
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (hours.length < 2) hours = "0" + hours;
        if (minute.length < 2) minute = "0" + minute;
        return `${month} ${day} ${year} ${hours}:${minute}`;
    }
    getFormattedDate(date) {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let year = date.getFullYear();
        let month = months[date.getMonth()];
        let day = date
            .getDate()
            .toString()
            .padStart(2, "0");
        return `${month} ${day}, ${year}`;
    }

    getFormattedDateMMDD(date) {
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date
            .getDate()
            .toString()
            .padStart(2, "0");
        return `${month}/${day}`;
    }
}

export default withStyles(validationFormsStyle)(DailyGraph);
