import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap-daterangepicker/daterangepicker.css";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import defaultAvatar from "assets/img/placeholder.jpg";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import { trim } from "jquery";
// import Chart from "react-google-charts";
import { ResponsiveLine } from "@nivo/line";
import { getGlucoseValue, getGlucoseValuestr, getTickValue } from "../Utiles/Utils";
class FoodLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            isLoading: false,
            maxDate: moment(),
            begin_date: new Date().valueOf() - 1 * 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            date: new Date().valueOf(),
            starting_date: new Date().valueOf(),
            week_begin_date: new Date().valueOf() - 7 * 24 * 60 * 60 * 1000,
            week_end_date: new Date().valueOf(),
            graphDateDaily: new Date().valueOf(),
            graphDateInsight: new Date().valueOf(),
            graphDateWeekly: new Date().valueOf(),
            patient_id: "",
            meal_type: "Breakfast",
            week_meal_type: "Breakfast",
            mealType: [],
            foodLog: [],
            foodLogMealType: [],
            foodLogWeek: [],
            activeTab: 0,
            dailyReportData: [],
            insightReportData: [],
            weeklyReportData: [],
            higherReading: 0,
            startingReading: 0,
            higherReadingWeekly: 0,
            startingReadingWeekly: 0,
            higherReadingDaily: 0,
            startingReadingDaily: 0,
            glucose_unit: [],
            dailyGraphArray: [],
            insightGraphArray: [],
            weeklyGraphArray: [],
            dailyGrpahReprt: [],
            higherReadingWeeklyArray: [],
            startingReadingWeeklyArray: [],
            higherReadingDailyArray: [],
            startingReadingDailyArray: [],
            higherReadingArray: [],
            startingReadingArray: [],
        };
        this.timeFormate = "%Y-%m-%d %I:%M:%S.%L %p";
        this.handleFoodLogDropdownChange = this.handleFoodLogDropdownChange.bind(this);
        this.handleWeekFoodLogDropdownChange = this.handleWeekFoodLogDropdownChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleDateNext = this.handleDateNext.bind(this);
        this.handleDatePrevious = this.handleDatePrevious.bind(this);
        this.handleWeekNext = this.handleWeekNext.bind(this);
        this.handleWeekPrevious = this.handleWeekPrevious.bind(this);
    }
    componentDidMount() {
        document.title = "Food Log Data - Ambrosia Care";
        this.state.mealType.push("Breakfast", "Lunch", "Dinner", "Snack", "Drink");
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.getFoodLogDataBasedOnMealType(this.state.meal_type, this.state.begin_date, this.state.end_date);
        this.getDayFoodLogData(this.state.starting_date);
        this.getWeekFoodLogData(this.state.week_meal_type, this.state.week_begin_date, this.state.week_end_date);
    }
    formatAMPM(date) {
        var hours = date.getHours();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours === 12 ? `${hours} ${ampm}` : `${hours}`;
        // return `${hours} ${ampm}`;
    }
    getDayFoodLogData(date) {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-day-food-log",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
                date: this.formatTimestamp(new Date(parseInt(date))),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ foodLog: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ foodLog: DataJSON.foodlogs });
                    this.state.foodLog.forEach((data, count) => {
                        this.getDailyReportData(data.mealTimeMilies);
                        this.setState({ graphDateDaily: data.mealTimeMilies });
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getFoodLogDataBasedOnMealType(meal, begin_date, end_date) {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-insight-food-log",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
                begin_date: this.formatTimestamp(new Date(parseInt(begin_date))),
                end_date: this.formatTimestamp(new Date(parseInt(end_date))),
                meal_type: this.getMealTypeNumber(meal),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ foodLogMealType: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ foodLogMealType: DataJSON.foodlogs });
                    this.state.foodLogMealType.forEach((data, count) => {
                        this.getInsightReportData(data.mealTimeMilies);
                        this.setState({ graphDateInsight: data.mealTimeMilies });
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getWeekFoodLogData(meal, begin_date, end_date) {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-insight-food-log",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
                begin_date: this.formatTimestamp(new Date(parseInt(begin_date))),
                end_date: this.formatTimestamp(new Date(parseInt(end_date))),
                meal_type: this.getMealTypeNumber(meal),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ foodLogWeek: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ foodLogWeek: DataJSON.foodlogs });
                    this.state.foodLogWeek.forEach((data, count) => {
                        this.getWeeklyReportData(data.mealTimeMilies);
                        this.setState({ graphDateWeekly: data.mealTimeMilies });
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getInsightReportData(readingDate) {
        const begin_date = new Date(readingDate - 1 * 0.5 * 60 * 60 * 1000);
        const end_date = new Date(readingDate - 1);
        end_date.setHours(end_date.getHours() + 3);
        // end_date.setMinutes(end_date.getMinutes() + 30);
        const dates = [];
        const date_set = {};
        date_set.begin_date = begin_date.getTime();
        date_set.end_date = end_date.getTime();
        dates.push(date_set);
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
            dates: dates,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-daily-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    if (DataJSON.msg.includes("No data found for the day")) {
                        this.setState({ insightReportData: [] });
                    }
                } else {
                    this.setState({ insightReportData: DataJSON.data });
                    const readingData = [];
                    const readingTimeArray = [];
                    const startingGlucose = [];
                    const readingTime = [];
                    const aboveRange = [];
                    var date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    this.state.insightGraphArray.push(this.getDailyReportGraphs(DataJSON.data, readingDate));
                    if (DataJSON.data[0].readings.success) {
                        DataJSON.data.forEach((data, count) => {
                            data.readings.data.forEach((set) => {
                                date.setHours(new Date(parseInt(set.reading_time)).getHours());
                                date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                                const timeData = this.formatTimestampData(date);
                                readingTime.push(timeData);
                                readingData.push(parseInt(set.reading));
                                readingTimeArray.push(set.reading_time);
                            });
                            const max = Math.max(...readingData);
                            const higherGlucoseIndex = [];
                            readingData.forEach((item, index) => (item === max ? higherGlucoseIndex.push(index) : null));
                            const ABOVE_TARGET = Math.max.apply(Math, readingData);
                            aboveRange.push({ x: readingTime[higherGlucoseIndex[0]], y: readingData[higherGlucoseIndex[0]] });
                            const end_date = new Date(readingTimeArray[0] - 1);
                            end_date.setMinutes(end_date.getMinutes() + 30);
                            const startingGlucoseIndex = [];
                            var goal = readingDate;
                            var closest = readingTimeArray.reduce(function (prev, curr) {
                                return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
                            });
                            readingTimeArray.forEach((item, index) => (item === closest ? startingGlucoseIndex.push(index) : null));
                            startingGlucose.push({ x: readingTime[startingGlucoseIndex[0]], y: readingData[startingGlucoseIndex[0]] });
                            this.setState({ higherReading: ABOVE_TARGET });
                            this.state.startingReadingArray.push(readingData[startingGlucoseIndex[0]]);
                            this.state.higherReadingArray.push(ABOVE_TARGET);
                            this.setState({ startingReading: readingData[startingGlucoseIndex[0]] });
                        });
                    }
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getDailyReportData(readingDate) {
        const begin_date = new Date(readingDate - 1 * 0.5 * 60 * 60 * 1000);
        const end_date = new Date(readingDate - 1);
        end_date.setHours(end_date.getHours() + 3);
        // end_date.setMinutes(end_date.getMinutes() + 30);
        const dates = [];
        const date_set = {};
        date_set.begin_date = begin_date.getTime();
        date_set.end_date = end_date.getTime();
        dates.push(date_set);
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
            dates: dates,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-daily-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    if (DataJSON.msg.includes("No data found for the day")) {
                        this.setState({ dailyReportData: [] });
                    }
                } else {
                    this.setState({ dailyReportData: DataJSON.data });
                    const readingData = [];
                    const readingTimeArray = [];
                    const startingGlucose = [];
                    const aboveRange = [];
                    const readingTime = [];
                    var date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    this.state.dailyGraphArray.push(this.getDailyReportGraphs(DataJSON.data, readingDate));
                    if (DataJSON.data[0].readings.success) {
                        DataJSON.data.forEach((data, count) => {
                            data.readings.data.forEach((set) => {
                                date.setHours(new Date(parseInt(set.reading_time)).getHours());
                                date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                                const timeData = this.formatTimestampData(date);
                                readingTime.push(timeData);
                                readingData.push(parseInt(set.reading));
                                readingTimeArray.push(set.reading_time);
                            });
                            const max = Math.max(...readingData);
                            const higherGlucoseIndex = [];
                            readingData.forEach((item, index) => (item === max ? higherGlucoseIndex.push(index) : null));
                            const ABOVE_TARGET = Math.max.apply(Math, readingData);
                            aboveRange.push({ x: readingTime[higherGlucoseIndex[0]], y: readingData[higherGlucoseIndex[0]] });
                            const end_date = new Date(readingTimeArray[0] - 1);
                            end_date.setMinutes(end_date.getMinutes() + 30);
                            const startingGlucoseIndex = [];
                            var goal = readingDate;
                            var closest = readingTimeArray.reduce(function (prev, curr) {
                                return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
                            });
                            readingTimeArray.forEach((item, index) => (item === closest ? startingGlucoseIndex.push(index) : null));
                            startingGlucose.push({ x: readingTime[startingGlucoseIndex[0]], y: readingData[startingGlucoseIndex[0]] });
                            this.setState({ higherReadingDaily: ABOVE_TARGET });
                            this.state.startingReadingDailyArray.push(readingData[startingGlucoseIndex[0]]);
                            this.state.higherReadingDailyArray.push(ABOVE_TARGET);
                            this.setState({ startingReadingDaily: readingData[startingGlucoseIndex[0]] });
                        });
                    }
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getWeeklyReportData(readingDate) {
        const begin_date = new Date(readingDate - 1 * 0.5 * 60 * 60 * 1000);
        const end_date = new Date(readingDate - 1);
        end_date.setHours(end_date.getHours() + 3);
        // end_date.setMinutes(end_date.getMinutes() + 30);
        const dates = [];
        const date_set = {};
        date_set.begin_date = begin_date.getTime();
        date_set.end_date = end_date.getTime();
        dates.push(date_set);
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.props.patient_id : localStorage.getItem("user_id"),
            dates: dates,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-daily-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    if (DataJSON.msg.includes("No data found for the day")) {
                        this.setState({ weeklyReportData: [] });
                    }
                } else {
                    const readingData = [];
                    const readingTimeArray = [];
                    const startingGlucose = [];
                    const readingTime = [];
                    const aboveRange = [];
                    var date = new Date();
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    this.setState({ weeklyReportData: DataJSON.data });
                    this.state.weeklyGraphArray.push(this.getDailyReportGraphs(DataJSON.data, readingDate));
                    if (DataJSON.data[0].readings.success) {
                        DataJSON.data.forEach((data, count) => {
                            data.readings.data.forEach((set) => {
                                date.setHours(new Date(parseInt(set.reading_time)).getHours());
                                date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                                const timeData = this.formatTimestampData(date);
                                readingTime.push(timeData);
                                readingData.push(parseInt(set.reading));
                                readingTimeArray.push(set.reading_time);
                            });
                            const max = Math.max(...readingData);
                            const higherGlucoseIndex = [];
                            readingData.forEach((item, index) => (item === max ? higherGlucoseIndex.push(index) : null));
                            const ABOVE_TARGET = Math.max.apply(Math, readingData);
                            aboveRange.push({ x: readingTime[higherGlucoseIndex[0]], y: readingData[higherGlucoseIndex[0]] });
                            const end_date = new Date(readingTimeArray[0] - 1);
                            end_date.setMinutes(end_date.getMinutes() + 30);
                            const startingGlucoseIndex = [];
                            var goal = readingDate;
                            var closest = readingTimeArray.reduce(function (prev, curr) {
                                return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
                            });
                            readingTimeArray.forEach((item, index) => (item === closest ? startingGlucoseIndex.push(index) : null));
                            startingGlucose.push({ x: readingTime[startingGlucoseIndex[0]], y: readingData[startingGlucoseIndex[0]] });
                            this.setState({ higherReadingWeekly: ABOVE_TARGET });
                            this.state.startingReadingWeeklyArray.push(readingData[startingGlucoseIndex[0]]);
                            this.state.higherReadingWeeklyArray.push(ABOVE_TARGET);
                            this.setState({ startingReadingWeekly: readingData[startingGlucoseIndex[0]] });
                        });
                    }
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getDailyReportGraphs(dailyReportDataSet, graphDate) {
        const theme = {
            axis: {
                domain: {
                    line: {
                        stroke: "#333333",
                        strokeWidth: 1,
                    },
                },
                ticks: {
                    text: {
                        fill: '#ffffff',  // Set the text color for axis ticks to white
                    },
                },
            },
        };
        const graph = dailyReportDataSet.map((data, count) => {
            const dateTitle = this.getFormattedDate(new Date(parseInt(data.date)));
            if (data.readings.success) {
                this.state.glucose_unit.push(dailyReportDataSet[0].glucose_unit);
                var range = data.target_range.split("-");
                const minRangeArray = [];
                const maxRangeArray = [];
                const lowRangeData = [];
                const readingTime = [];
                const readingTimeArray = [];
                const dataReading = [];
                const readingData = [];
                const aboveRange = [];
                const startingGlucose = [];

                var date = new Date();
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);

                const dataset = data.readings.data.map((set) => {
                    if (parseInt(set.reading) < 40) set.reading = 40;
                    date.setDate(new Date(parseInt(set.reading_time)).getDate());
                    date.setMonth(new Date(parseInt(set.reading_time)).getMonth());
                    date.setHours(new Date(parseInt(set.reading_time)).getHours());
                    date.setMinutes(new Date(parseInt(set.reading_time)).getMinutes());
                    const timeData = this.formatTimestampData(date);
                    const reading = parseInt(set.reading);
                    const lowerRange = parseInt(trim(range[0]));
                    const higherRange = parseInt(trim(range[1]));
                    minRangeArray.push({ x: timeData, y: lowerRange });
                    maxRangeArray.push({ x: timeData, y: higherRange });

                    readingData.push(parseInt(reading));
                    readingTime.push(timeData);
                    readingTimeArray.push(parseInt(set.reading_time));
                    if (reading > lowerRange && reading < higherRange) {
                        lowRangeData.push({ x: timeData, y: reading });
                        dataReading.push(reading);
                    } else {
                        lowRangeData.push({ x: timeData, y: null });
                        dataReading.push(null);
                    }

                    return { x: timeData, y: set.reading };
                });
                const end_date = new Date(readingTime[0]);
                end_date.setMinutes(end_date.getMinutes() + 30);
                const max = Math.max(...readingData);
                const higherGlucoseIndex = [];
                readingData.forEach((item, index) => (item === max ? higherGlucoseIndex.push(index) : null));
                aboveRange.push({ x: readingTime[higherGlucoseIndex[0]], y: readingData[higherGlucoseIndex[0]] });
                const startingGlucoseIndex = [];
                var goal = this.formatTimestampData(end_date);
                var closest = readingTime.reduce(function (prev, curr) {
                    return Math.abs(new Date(curr).getTime() - new Date(goal).getTime()) < Math.abs(new Date(prev).getTime() - new Date(goal).getTime()) ? curr : prev;
                });

                readingTime.forEach((item, index) => (item === closest ? startingGlucoseIndex.push(index) : null));
                startingGlucose.push({ x: readingTime[startingGlucoseIndex[0]], y: readingData[startingGlucoseIndex[0]] });
                const triangle = ({ points }) => {
                    var arrayIndex = [];
                    points.forEach(({ id, index }) => (id === "HigherPoints.0" ? arrayIndex.push(index) : null));
                    const x = points.map(({ id, x, y }) => (id === "HigherPoints.0" ? x + 7 + " " + y + ", " + (parseFloat(x) - 7) + " " + y + ", " + parseFloat(x) + " " + (parseFloat(y) - 10) : 0));
                    return points.map(({ index }) => <polygon key={index} id="Triangle" points={x[arrayIndex[0]]} fill="#00ccff" stroke="#00ccff" className="triangle" />);
                };
                const points = ({ points }) => {
                    var arrayIndex = [];
                    // console.log("points> ", points);
                    points.forEach(({ id, index }) => (id === "StartingPoints.0" ? arrayIndex.push(index) : null));
                    const xPoint = points.map(({ id, x, y }) => (id === "StartingPoints.0" ? x : 0));
                    const yPoint = points.map(({ id, x, y }) => (id === "StartingPoints.0" ? y : 0));
                    const radius = points.map(({ id, x, y }) => (id === "StartingPoints.0" ? 7 : 0));
                    return points.map(({ id, index, x, y }) => <circle key={index} cx={xPoint[arrayIndex[0]]} cy={yPoint[arrayIndex[0]]} r={radius[arrayIndex[0]]} fill="#009999" />);
                };
                return (
                    <div key={count} style={{ width: "700px", height: "300px", background: "black" }}>
                        <ResponsiveLine
                            data={[
                                {
                                    id: "Min Limit",
                                    data: minRangeArray,
                                },
                                {
                                    id: "Max Limit",
                                    data: maxRangeArray,
                                },
                                {
                                    id: "Low Range Data",
                                    data: dataset,
                                },
                                {
                                    id: "Range Data",
                                    color: "#00ccff",
                                    data: dataReading.map((y, i) => ({
                                        x: `${readingTime[i]}`,
                                        y,
                                    })),
                                },
                                {
                                    id: "StartingPoints",
                                    color: "#cccccc",
                                    data: startingGlucose,
                                },
                                {
                                    id: "HigherPoints",
                                    color: "#cccccc",
                                    data: aboveRange,
                                },
                            ]}
                            margin={{ top: 50, right: 0, bottom: 70, left: 50 }}
                            xScale={{ precision: "minute", format: this.timeFormate, useUTC: false, type: "time" }}
                            xFormat={`time:${this.timeFormate}`}
                            yScale={{ type: "linear", stacked: false }}
                            curve="linear"
                            axisBottom={{
                                tickValues: "every 1 hours",
                                format: (value) => {
                                    return this.formatAMPM(value);
                                },
                                legendOffset: 3,
                                legendPosition: "middle",
                            }}
                            axisLeft={{
                                tickValues: getTickValue(data.glucose_unit),
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendOffset: -40,
                                legendPosition: "middle",
                            }}
                            layers={["grid", "markers", "axes", "areas", "crosshair", "lines", "slices", "mesh", "legends", triangle, points]}
                            defs={[
                                {
                                    id: "higherData",
                                    type: "patternLines",
                                    background: "#000",
                                    color: "#000",
                                    size: 4,
                                    padding: 2,
                                    stagger: true,
                                },
                                {
                                    id: "data",
                                    type: "patternLines",
                                    background: "#000",
                                    color: "#0000",
                                    size: 4,
                                    padding: 2,
                                    stagger: true,
                                },
                            ]}
                            fill={[{ match: { id: "Max Limit" }, id: "data" }, { match: { id: "Min Limit" }, id: "minData" }, { match: { id: "Low Range Data" }, id: "minData" }]}
                            enableArea={true}
                            areaOpacity={1}
                            theme={theme}
                            isInteractive={false}
                            enableCrosshair={false}
                            enableGridX={false}
                            enableGridY={false}
                            colors={(d) => d.color}
                            lineWidth={2}
                            enablePoints={false}
                            useMesh={true}
                        />

                    </div>
                );
            } else {
                return null;
            }
        });
        return graph;
    }
    formatTimestampData(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            seconds = "" + date.getSeconds(),
            milliseconds = "" + date.getMilliseconds();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        // if (hours.length < 2) hours = "0" + hours;
        if (minute.length < 2) minute = "0" + minute;
        if (seconds.length < 2) seconds = "0" + seconds;
        if (milliseconds.length < 3) milliseconds = "00" + milliseconds;
        return `${year}-${month}-${day} ${hours}:${minute}:${seconds}.${milliseconds} ${ampm}`;
    }

    getFormattedDate(date) {
        var dayArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var weekDay = dayArray[date.getDay()];
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var month = months[date.getMonth()],
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (hours.length < 2) hours = "0" + hours;
        if (minute.length < 2) minute = "0" + minute;
        return `${weekDay}, ${month} ${day} ${year} ${hours}:${minute}`;
    }
    handleFoodLogDropdownChange(e) {
        this.setState({ meal_type: e, activeTab: 0, higherReadingArray: [], startingReadingArray: [] });
        this.getFoodLogDataBasedOnMealType(e, this.state.begin_date, this.state.end_date);
    }
    handleWeekFoodLogDropdownChange(e) {
        this.setState({ week_meal_type: e, activeTab: 2, higherReadingWeeklyArray: [], startingReadingWeeklyArray: [], weeklyGraphArray: [] });
        this.getWeekFoodLogData(e, this.state.week_begin_date, this.state.week_end_date);
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return `${year}${month}${day}`;
    }
    handleNext() {
        let startDate = this.state.starting_date;
        this.setState({ activeTab: 1, starting_date: startDate + 1 * 24 * 60 * 60 * 1000, higherReadingDailyArray: [], startingReadingDailyArray: [], dailyGraphArray: [] });
        this.getDayFoodLogData(this.state.starting_date + 1 * 24 * 60 * 60 * 1000);
    }
    handlePrevious() {
        let startDate = this.state.starting_date;
        this.setState({ activeTab: 1, starting_date: startDate - 1 * 24 * 60 * 60 * 1000, higherReadingDailyArray: [], startingReadingDailyArray: [], dailyGraphArray: [] });
        this.getDayFoodLogData(this.state.starting_date - 1 * 24 * 60 * 60 * 1000);
    }
    handleDateNext() {
        let end_date = this.state.end_date;
        this.setState({ begin_date: end_date, end_date: end_date + 1 * 24 * 60 * 60 * 1000, activeTab: 0, meal_type: "Breakfast", higherReadingArray: [], startingReadingArray: [], insightGraphArray: [] });
        this.getFoodLogDataBasedOnMealType("Breakfast", end_date, end_date + 1 * 24 * 60 * 60 * 1000);
    }
    handleDatePrevious() {
        let begin_date = this.state.begin_date;
        this.setState({ begin_date: begin_date - 1 * 24 * 60 * 60 * 1000, end_date: begin_date, activeTab: 0, meal_type: "Breakfast", higherReadingArray: [], startingReadingArray: [], insightGraphArray: [] });
        this.getFoodLogDataBasedOnMealType("Breakfast", begin_date - 1 * 24 * 60 * 60 * 1000, begin_date);
    }
    handleWeekNext() {
        let end_date = this.state.week_end_date;
        this.setState({ week_begin_date: end_date, week_end_date: end_date + 7 * 24 * 60 * 60 * 1000, activeTab: 2, week_meal_type: "Breakfast", higherReadingWeeklyArray: [], startingReadingWeeklyArray: [], weeklyGraphArray: [] });
        this.getWeekFoodLogData("Breakfast", end_date, end_date + 7 * 24 * 60 * 60 * 1000);
    }
    handleWeekPrevious() {
        let begin_date = this.state.week_begin_date;
        this.setState({ week_begin_date: begin_date - 7 * 24 * 60 * 60 * 1000, week_end_date: begin_date, activeTab: 2, week_meal_type: "Breakfast", higherReadingWeeklyArray: [], startingReadingWeeklyArray: [], weeklyGraphArray: [] });
        this.getWeekFoodLogData("Breakfast", begin_date - 7 * 24 * 60 * 60 * 1000, begin_date);
    }
    getMealType(mealType) {
        var meal = "";
        switch (mealType) {
            case "1":
                meal = "Breakfast";
                break;
            case "2":
                meal = "Lunch";
                break;
            case "3":
                meal = "Dinner";
                break;
            case "4":
                meal = "Snack";
                break;
            case "5":
                meal = "Drink";
                break;
            default:
                meal = "meal is not defined.";
        }
        return meal;
    }
    getMealTypeNumber(mealType) {
        var meal = "";
        switch (mealType) {
            case "Breakfast":
                meal = "1";
                break;
            case "Lunch":
                meal = "2";
                break;
            case "Dinner":
                meal = "3";
                break;
            case "Snack":
                meal = "4";
                break;
            case "Drink":
                meal = "5";
                break;
            default:
                meal = "meal is not defined.";
        }
        return meal;
    }
    render() {
        const divStyle = {
            display: "flex",
            alignItems: "center",
            marginleft: "50px"
        };
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    {localStorage.getItem("loginType") === "Caregiver" ? (
                        <div style={{ flexDirection: "column-reverse" }}>
                            <Button color="info" style={{ height: "40px", width: "200px" }} onClick={this.props.handleBackButton}>
                                Back
                            </Button>
                            <b style={{ fontSize: "20px", marginLeft: "50px", color: "white" }}> {this.props.patientName}</b>
                        </div>
                    ) : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card plain>
                        <CardBody>
                            <NavPills
                                color="info"
                                active={this.state.activeTab}
                                tabs={[
                                    {
                                        tabButton: "INSIGHTS",
                                        tabContent: (
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <Card style={{ background: "black" }}>
                                                    <CardBody style={{ background: "black" }}>
                                                        <div style={divStyle}>
                                                            <Button id="infoIcon" color="info" onClick={this.handleDatePrevious} disabled={this.state.begin_date === this.state.maxDate}>
                                                                <FontAwesomeIcon icon={"chevron-left"} size="2x" />
                                                            </Button>
                                                            <h5 style={{ fontSize: "0.99em", marginLeft: "10px", marginRight: "10px", color: "white" }}>
                                                                <b>Date Frame:</b>
                                                                {` ${new Date(this.state.begin_date).toLocaleDateString()} To ${new Date(this.state.end_date).toLocaleDateString()}`}
                                                            </h5>
                                                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                                                <CustomDropdown
                                                                    hoverColor="info"
                                                                    buttonText={this.state.meal_type === "" ? "Select Meal Type" : this.state.meal_type}
                                                                    buttonProps={{
                                                                        round: true,
                                                                        fullWidth: true,
                                                                        style: { marginBottom: "0" },
                                                                        color: "info",
                                                                    }}
                                                                    id="secondYaxis"
                                                                    dropdownList={this.state.mealType.map((e, i) => (
                                                                        <p
                                                                            style={{
                                                                                display: "block",        // Makes the <p> behave like an <li>
                                                                                color: "white",
                                                                                width: "100%",
                                                                                margin: 0,               // Removes any default margin
                                                                                padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                boxSizing: "border-box",
                                                                            }}
                                                                            onClick={() => this.handleFoodLogDropdownChange(e)}>{e}
                                                                        </p>
                                                                    ))}
                                                                />
                                                            </GridItem>
                                                            <Button id="infoIcon" style={{ marginLeft: "10px", position: "absolute", right: "20px" }} color="info" onClick={this.handleDateNext} disabled={this.state.begin_date >= this.state.maxDate || this.state.end_date >= this.state.maxDate}>
                                                                <FontAwesomeIcon icon={"chevron-right"} size="2x" />
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                {this.state.isLoading ? (
                                                    <Card>
                                                        <CardBody style={{ height: "100px", background: "black" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                ) : this.state.foodLogMealType.length !== 0 ? (
                                                    this.state.foodLogMealType.map((data, count) => {
                                                        return (
                                                            <Card key={count}>
                                                                <CardBody style={{ background: "black" }}>
                                                                    <div style={divStyle}>
                                                                        <div style={{ margin: "0px 0px 0px 0px" }} className={"thumbnail" + (defaultAvatar ? " img-circle" : "")}>
                                                                            <img src={"data:image/jpeg;base64," + data.image} alt="..." />
                                                                        </div>
                                                                        <div style={{ marginLeft: "50px" }}>
                                                                            <h5 style={{ fontSize: "0.99em", color: "#fff" }}>{this.getFormattedDate(new Date(parseInt(data.mealTimeMilies)))}</h5>
                                                                            {data.description !== "" ? (
                                                                                <h5 style={{ fontSize: "0.99em", color: "#fff" }}>
                                                                                    <b style={{ fontSize: "15px", color: "#fff" }}>Description: </b>
                                                                                    {data.description}
                                                                                </h5>
                                                                            ) : null}

                                                                            <GridContainer>
                                                                                <div style={{ alignContent: "space-evenly", display: "flex", width: "70%" }}>
                                                                                    <div style={{ marginLeft: "20px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>BEFORE MEAL</b>
                                                                                        <div style={divStyle}>
                                                                                            <FontAwesomeIcon icon={"circle"} color="#009999" />
                                                                                            {/* <h5 style={{ fontSize: "20px", color: "#000000", marginLeft: "10px" }}>{this.state.startingReadingArray[count]}</h5> */}
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.startingReadingArray[count]), this.state.glucose_unit[0])}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>PEAK </b>
                                                                                        <div style={divStyle}>
                                                                                            <div style={{ width: "0", height: "0", borderLeft: "8px solid transparent", borderRight: "8px solid transparent", borderBottom: "12px solid #00ccff" }} />
                                                                                            {/* <h5 style={{ fontSize: "20px", color: "#000000", marginLeft: "10px" }}>{this.state.higherReadingArray[count]}</h5> */}
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.higherReadingArray[count]), this.state.glucose_unit[0])}</h5>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff", marginTop: "70px" }}>CHANGE </b>

                                                                                        <h5 style={{ fontSize: "20px", color: "#fff" }}>
                                                                                            {/* {this.state.higherReadingArray[count] > this.state.startingReadingArray[count] ? "+ " : "- "}
                                                                                            {this.state.higherReadingArray[count] - this.state.startingReadingArray[count]}  */}
                                                                                            {this.state.higherReadingArray[count] > this.state.startingReadingArray[count] ? "+ " : "- "}
                                                                                            {getGlucoseValue((this.state.higherReadingArray[count] - this.state.startingReadingArray[count]), this.state.glucose_unit[0])}
                                                                                            {this.state.glucose_unit[0]}
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                                {this.state.insightGraphArray[count]}
                                                                            </GridContainer>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        );
                                                    })
                                                ) : (
                                                    <Card>
                                                        <CardBody style={{ height: "200px", background: "black" }}>
                                                            <h4
                                                                style={{
                                                                    marginTop: "80px",
                                                                    textAlign: "center",
                                                                    color: "white"
                                                                }}
                                                            >
                                                                There are no food log data available for this period.
                                                            </h4>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </GridItem>
                                        ),
                                    },
                                    {
                                        tabButton: "DAY",
                                        tabContent: (
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <Card>
                                                    <CardBody style={{ background: "black" }}>
                                                        <div style={divStyle}>
                                                            <Button id="infoIcon" color="info" onClick={this.handlePrevious}>
                                                                <FontAwesomeIcon icon={"chevron-left"} size="2x" />
                                                            </Button>
                                                            <GridItem>
                                                                <h5 style={{ fontSize: "0.99em", color: "white", marginLeft: "10px" }}>
                                                                    <b>Date:</b>
                                                                    {` ${new Date(this.state.starting_date).toLocaleDateString()} `}
                                                                </h5>
                                                            </GridItem>
                                                            <Button id="infoIcon" style={{ marginLeft: "10px", position: "absolute", right: "20px" }} color="info" onClick={this.handleNext} disabled={this.state.starting_date >= this.state.maxDate}>
                                                                <FontAwesomeIcon icon={"chevron-right"} size="2x" />
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                {this.state.isLoading ? (
                                                    <Card>
                                                        <CardBody style={{ height: "100px", background: "black" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                ) : this.state.foodLog.length !== 0 ? (
                                                    this.state.foodLog.map((data, count) => {
                                                        return (
                                                            <Card key={count}>
                                                                <CardBody style={{ background: "black" }}>
                                                                    <div style={divStyle}>
                                                                        <div style={{ margin: "0px 0px 0px 0px" }} className={"thumbnail" + (defaultAvatar ? " img-circle" : "")}>
                                                                            <img src={"data:image/jpeg;base64," + data.image} alt="..." />
                                                                        </div>
                                                                        <div style={{ marginLeft: "50px" }}>
                                                                            <h5 style={{ fontSize: "0.99em", color: "#fff" }}>{this.getFormattedDate(new Date(parseInt(data.mealTimeMilies)))}</h5>
                                                                            {data.description !== "" ? (
                                                                                <h5 style={{ fontSize: "0.99em", color: "#fff" }}>
                                                                                    <b style={{ fontSize: "15px", color: "#fff" }}>Description: </b>
                                                                                    {data.description}
                                                                                </h5>
                                                                            ) : null}

                                                                            <GridContainer>
                                                                                <div style={{ alignContent: "space-evenly", display: "flex", width: "60%" }}>
                                                                                    <div style={{ marginLeft: "20px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>BEFORE MEAL</b>
                                                                                        <div style={divStyle}>
                                                                                            <FontAwesomeIcon icon={"circle"} color="#009999" />
                                                                                            {/* <h5 style={{ fontSize: "20px", color: "#000000", marginLeft: "10px" }}>{this.state.startingReadingDailyArray[count]}</h5> */}
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.startingReadingDailyArray[count]), this.state.glucose_unit[0])}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>PEAK </b>
                                                                                        <div style={divStyle}>
                                                                                            <div style={{ width: "0", height: "0", borderLeft: "8px solid transparent", borderRight: "8px solid transparent", borderBottom: "12px solid #00ccff" }} />
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.higherReadingDailyArray[count]), this.state.glucose_unit[0])}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>CHANGE </b>
                                                                                        <h5 style={{ fontSize: "20px", color: "#fff" }}>
                                                                                            {/* <b style={{ fontSize: "25px", color: "#000000" }}> */}
                                                                                            {/* {this.state.higherReadingDailyArray[count] > this.state.startingReadingDailyArray[count] ? "+ " : "- "}
                                                                                            {this.state.higherReadingDailyArray[count] - this.state.startingReadingDailyArray[count]}                                                                                   */}
                                                                                            {this.state.higherReadingDailyArray[count] > this.state.startingReadingDailyArray[count] ? "+ " : "- "}
                                                                                            {getGlucoseValue((this.state.higherReadingDailyArray[count] - this.state.startingReadingDailyArray[count]), this.state.glucose_unit[0])}
                                                                                            {this.state.glucose_unit[0]}
                                                                                            {/* </b> */}
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                                {this.state.dailyGraphArray[count]}
                                                                            </GridContainer>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        );
                                                    })
                                                ) : (
                                                    <Card>
                                                        <CardBody style={{ height: "200px", background: "black" }}>
                                                            <h4
                                                                style={{
                                                                    marginTop: "80px",
                                                                    textAlign: "center",
                                                                    color: "white"
                                                                }}
                                                            >
                                                                There are no food log data available for this period.
                                                            </h4>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </GridItem>
                                        ),
                                    },
                                    {
                                        tabButton: "WEEK",
                                        tabContent: (
                                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                                <Card>
                                                    <CardBody style={{ background: "black" }}>
                                                        <div style={divStyle}>
                                                            <Button id="infoIcon" color="info" onClick={this.handleWeekPrevious}>
                                                                <FontAwesomeIcon icon={"chevron-left"} size="2x" />
                                                            </Button>
                                                            <GridItem>
                                                                <h5 style={{ fontSize: "0.99em", color: "#fff", marginLeft: "10px", marginRight: "10px" }}>
                                                                    <b>Date Frame:</b>
                                                                    {` ${new Date(this.state.week_begin_date).toLocaleDateString()} To ${new Date(this.state.week_end_date).toLocaleDateString()}`}
                                                                </h5>
                                                            </GridItem>
                                                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                                                <CustomDropdown
                                                                    hoverColor="info"
                                                                    buttonText={this.state.week_meal_type === "" ? "Select Meal Type" : this.state.week_meal_type}
                                                                    buttonProps={{
                                                                        round: true,
                                                                        fullWidth: true,
                                                                        style: { marginBottom: "0" },
                                                                        color: "info",
                                                                    }}
                                                                    id="secondYaxis"
                                                                    dropdownList={this.state.mealType.map((e, i) => (
                                                                        <p
                                                                            style={{
                                                                                display: "block",        // Makes the <p> behave like an <li>
                                                                                color: "white",
                                                                                width: "100%",
                                                                                margin: 0,               // Removes any default margin
                                                                                padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                boxSizing: "border-box",
                                                                            }}
                                                                            onClick={() => this.handleWeekFoodLogDropdownChange(e)}>{e}</p>
                                                                    ))}
                                                                />
                                                            </GridItem>
                                                            <Button id="infoIcon" style={{ marginLeft: "10px", position: "absolute", right: "20px" }} color="info" onClick={this.handleWeekNext} disabled={this.state.week_begin_date >= this.state.maxDate || this.state.week_end_date >= this.state.maxDate}>
                                                                <FontAwesomeIcon icon={"chevron-right"} size="2x" />
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                                {this.state.isLoading ? (
                                                    <Card>
                                                        <CardBody style={{ height: "100px", background: "black" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                ) : this.state.foodLogWeek.length !== 0 ? (
                                                    this.state.foodLogWeek.map((data, count) => {
                                                        return (
                                                            <Card key={count}>
                                                                <CardBody style={{ background: "black" }}>
                                                                    <div style={divStyle}>
                                                                        <div style={{ margin: "0px 0px 0px 0px" }} className={"thumbnail" + (defaultAvatar ? " img-circle" : "")}>
                                                                            <img src={"data:image/jpeg;base64," + data.image} alt="..." />
                                                                        </div>
                                                                        <div style={{ marginLeft: "50px" }}>
                                                                            <h5 style={{ fontSize: "0.99em", color: "#fff" }}>{this.getFormattedDate(new Date(parseInt(data.mealTimeMilies)))}</h5>
                                                                            {data.description !== "" ? (
                                                                                <h5 style={{ fontSize: "0.99em", color: "#fff" }}>
                                                                                    <b style={{ fontSize: "15px", color: "#fff" }}>Description: </b>
                                                                                    {data.description}
                                                                                </h5>
                                                                            ) : null}
                                                                            <GridContainer>
                                                                                <div style={{ alignContent: "space-evenly", display: "flex", width: "60%" }}>
                                                                                    <div style={{ marginLeft: "20px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>BEFORE MEAL</b>
                                                                                        <div style={divStyle}>
                                                                                            <FontAwesomeIcon icon={"circle"} color="#009999" />
                                                                                            {/* <h5 style={{ fontSize: "20px", color: "#000000", marginLeft: "10px" }}>{this.state.startingReadingWeeklyArray[count]}</h5> */}
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.startingReadingWeeklyArray[count]), this.state.glucose_unit[0])}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>PEAK </b>
                                                                                        <div style={divStyle}>
                                                                                            <div style={{ width: "0", height: "0", borderLeft: "8px solid transparent", borderRight: "8px solid transparent", borderBottom: "12px solid #00ccff" }} />
                                                                                            {/* <h5 style={{ fontSize: "20px", color: "#000000", marginLeft: "10px" }}>{this.state.higherReadingWeeklyArray[count]}</h5> */}
                                                                                            <h5 style={{ fontSize: "20px", color: "#fff", marginLeft: "10px" }}>{getGlucoseValuestr(parseInt(this.state.higherReadingWeeklyArray[count]), this.state.glucose_unit[0])}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ marginLeft: "100px" }}>
                                                                                        <b style={{ fontSize: "15px", color: "#fff" }}>CHANGE </b>

                                                                                        <h5 style={{ fontSize: "20px", color: "#fff" }}>
                                                                                            {/* {this.state.higherReadingWeeklyArray[count] > this.state.startingReadingWeeklyArray[count] ? "+ " : "- "}
                                                                                            {this.state.higherReadingWeeklyArray[count] - this.state.startingReadingWeeklyArray[count]} */}
                                                                                            {this.state.higherReadingWeeklyArray[count] > this.state.startingReadingWeeklyArray[count] ? "+ " : "- "}
                                                                                            {getGlucoseValue((this.state.higherReadingWeeklyArray[count] - this.state.startingReadingWeeklyArray[count]), this.state.glucose_unit[0])}
                                                                                            {this.state.glucose_unit[0]}
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                                {this.state.weeklyGraphArray[count]}
                                                                            </GridContainer>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        );
                                                    })
                                                ) : (
                                                    <Card>
                                                        <CardBody style={{ height: "200px", background: "black" }}>
                                                            <h4
                                                                style={{
                                                                    marginTop: "80px",
                                                                    textAlign: "center",
                                                                    color: "white"
                                                                }}
                                                            >
                                                                There are no food log data available for this period.
                                                            </h4>
                                                        </CardBody>
                                                    </Card>
                                                )}
                                            </GridItem>
                                        ),
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card >
                </GridItem >
                <GridItem xs={12} sm={12} md={12} lg={3} />
            </GridContainer >
        );
    }
}

export default FoodLog;
