import React, { Component } from "react";
import moment from "moment";
import Chart from "react-google-charts";
import "bootstrap-daterangepicker/daterangepicker.css";
// @material-ui/core components

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

class PatientAppleHealtECG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDate: moment(),
            begin_date: new Date().valueOf() - 1 * 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
        };
    }

    componentDidMount() {
        document.title = "ECG Data - Ambrosia Care";

        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="info" onClick={this.props.handleBackButton}>
                            Back
                        </Button>
                        <Card style={{ background: "#000000" }}>
                            <CardBody>
                                <h4 style={{ fontSize: "20px", color: "#fff" }}>Patient Name: {this.props.patientName}</h4>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    {this.props.ecgChartData.length > 0 ? (
                        this.props.ecgChartData.map((data, count) => {
                            return (
                                <GridItem key={count} xs={12} sm={12} md={12} lg={12}>
                                    <Card>
                                        <CardBody>
                                            <h4 style={{ fontSize: "0.99em", color: "#fff" }}>
                                                <b>Date : </b>
                                                {this.props.starting_date[count]}
                                            </h4>
                                            <Chart
                                                width={"100%"}
                                                height={"400px"}
                                                chartType={"LineChart"}
                                                series={{ 0: { axis: "Time" }, 1: { axis: "Data" } }}
                                                options={{
                                                    hAxis: { textPosition: "none" },
                                                    vAxis: { textPosition: "none" },
                                                    legend: "none",
                                                    chartArea: { top: 5, width: "80%", height: "80%" },
                                                    colors: ["#ff4d4d"],
                                                }}
                                                data={data}
                                            />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            );
                        })
                    ) : (
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody style={{ height: "200px" }}>
                                    <h4
                                        style={{
                                            marginTop: "80px",
                                            textAlign: "center",
                                        }}
                                    >
                                        There are no data available for this period.
                                    </h4>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )}
                </GridContainer>
            </div>
        );
    }
}

export default PatientAppleHealtECG;
