import React, { Component } from "react";
import ReactTable from "react-table";
import PerfectScrollbar from "react-perfect-scrollbar";
import Chart from "react-google-charts";
import "bootstrap-daterangepicker/daterangepicker.css";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

class PatientGoogleFitData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            isLoading: false,
            syncButton: false,
            isGraphical: false,
            sampleType: [],
        };
        this.handleTableData = this.handleTableData.bind(this);
    }

    componentDidMount() {
        document.title = "Google Fit Data - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
        }
    }

    handleTableData() {
        this.setState((prevState) => ({ isGraphical: !prevState.isGraphical }));
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        return `${day}-${month}-${year} ${hours}:${minute}`;
    }
    render() {
        const activeTab = this.state.isGraphical ? 1 : 0;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="info" onClick={this.props.handleBackButton}>
                            Back
                        </Button>
                        <Card style={{ background: "black" }}>
                            <CardBody>
                                <h4 style={{ fontSize: "0.99em", color: "#fff" }}>Patient Name: {this.props.patientName}</h4>
                                <h4 style={{ fontSize: "0.99em", color: "#fff" }}>Device Name: {this.props.device_name}</h4>
                                <h4 style={{ fontSize: "0.99em", color: "#fff" }}>
                                    From {this.formatTimestamp(new Date(parseInt(this.props.begin_date)))} To {this.formatTimestamp(new Date(parseInt(this.props.end_date)))}
                                </h4>
                                <Button color="info" onClick={this.handleTableData}>
                                    {this.state.isGraphical === true ? "Tabular" : "Graphical"}
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {this.props.sampleType.length > 0 ? (
                        this.props.sampleType.map((sampleTypeData) => {
                            return sampleTypeData.map((data, count) => {
                                return data.tabularData != null && data.tabularData.length > 0 ? (
                                    <GridItem key={count} xs={6} sm={6} md={6} lg={6}>
                                        <Card style={{ background: "black" }}>
                                            <CardHeader>
                                                <h4 style={{ color: "white" }}>{data.name}</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <NavPills
                                                    active={activeTab}
                                                    color="info"
                                                    tabs={[
                                                        {
                                                            tabButton: "Tabular",
                                                            tabContent: (
                                                                <GridItem xs={12}>
                                                                    <PerfectScrollbar>
                                                                        <ReactTable
                                                                            data={data.tabularData}
                                                                            filterable
                                                                            columns={[
                                                                                {
                                                                                    Header: "ID",
                                                                                    accessor: "id",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    Header: "Time",
                                                                                    accessor: "timestamp",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    Header: "Data",
                                                                                    accessor: "info",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                            ]}
                                                                            defaultPageSize={10}
                                                                            showPaginationTop
                                                                            showPaginationBottom={false}
                                                                            className="-highlight"
                                                                        />
                                                                    </PerfectScrollbar>
                                                                </GridItem>
                                                            ),
                                                        },
                                                        {
                                                            tabButton: "Graphical",
                                                            tabContent: (
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Card plain>
                                                                        <CardBody>
                                                                            <Chart
                                                                                width={"100%"}
                                                                                height={"400px"}
                                                                                chartType={"LineChart"}
                                                                                options={{
                                                                                    hAxis: {
                                                                                        title: "Time",
                                                                                        textStyle: { color: "#fff" },
                                                                                        titleTextStyle: { color: "#fff" }
                                                                                    },
                                                                                    vAxis: {
                                                                                        title: data.name + " Data",
                                                                                        textStyle: { color: "#fff" },
                                                                                        titleTextStyle: { color: "#fff" }
                                                                                    },
                                                                                    legend: "none",
                                                                                    chartArea: { top: 5, width: "80%", height: "80%" },
                                                                                    colors: ["#fff"],
                                                                                    backgroundColor: "#000",
                                                                                    background: "#000"
                                                                                }}
                                                                                data={data.graphicalData}
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </GridItem>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                ) : (
                                    ""
                                );
                            });
                        })
                    ) : (
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardBody style={{ height: "200px" }}>
                                    <h4
                                        style={{
                                            marginTop: "80px",
                                            textAlign: "center",
                                        }}
                                    >
                                        There are no data available for this period.
                                    </h4>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )}
                </GridContainer>
            </div>
        );
    }
}

export default PatientGoogleFitData;
