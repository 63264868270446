import React from "react";
import PropTypes from "prop-types";
import { removeFromLocalStorage } from "views/Utiles/Constants";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToApp";

// core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
    state = {
        open: false,
    };

    onSignoutClick() {
        removeFromLocalStorage();
        const path = this.props.location.pathname;
        if (path.includes("/admin")) {
            this.props.history.push("/admin/login");
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <h4>
                    <Button color="transparent" aria-label="Person" className={classes.buttonLink} style={{ textTransform: "capitalize" }} onClick={this.onSignoutClick.bind(this)}>
                        <h4 style={{ color: "white" }}>
                            Sign Out
                        </h4>
                        <ExitToApp className={classes.headerLinksSvg + " " + classes.links} style={{ marginLeft: "5px", color: "white" }} />
                    </Button>
                </h4>
            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
