import React, { Component } from "react";
import ReactTable from "react-table";
import $ from "jquery";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { getSampleTypeData } from "views/Utiles/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Chart from "react-google-charts";
import "bootstrap-daterangepicker/daterangepicker.css";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

class GoogleFitData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            isLoading: false,
            syncButton: false,
            maxDate: moment(),
            begin_date: new Date().valueOf() - 14 * 24 * 60 * 60 * 1000,
            isGraphical: false,
            end_date: new Date().valueOf(),
            sampleType: [],
        };
        this.handleEvent = this.handleEvent.bind(this);
        this.handleTableData = this.handleTableData.bind(this);
    }
    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
        this.getGoogleFitHealthData();
    }
    componentDidMount() {
        document.title = "Google Fit Data - Ambrosia Care";

        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.getGoogleFitHealthWeekData();
    }
    getGoogleFitHealthData() {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-health-data",
            data: {
                token: localStorage.getItem("token"),
                patientId: localStorage.getItem("user_id"),
                // sampleType: "HKQuantityTypeIdentifierHeartRate",
                begin_date: this.state.begin_date,
                end_date: this.state.end_date,
                device_name: "GOOGLE_FIT",
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ sampleType: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ sampleType: getSampleTypeData(DataJSON) });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getGoogleFitHealthWeekData() {
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-health-data",
            data: {
                token: localStorage.getItem("token"),
                patientId: localStorage.getItem("user_id"),
                begin_date: this.state.begin_date,
                end_date: this.state.end_date,
                device_name: "GOOGLE_FIT",
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ sampleType: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ sampleType: getSampleTypeData(DataJSON) });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        return `${day}-${month}-${year} ${hours}:${minute}`;
    }
    handleTableData() {
        this.setState((prevState) => ({ isGraphical: !prevState.isGraphical }));
    }
    render() {
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };
        const activeTab = this.state.isGraphical ? 1 : 0;
        return (
            <div>
                {this.state.isLoading ? (
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Card plain>
                                <CardBody style={{ height: "100px" }}>
                                    <CircularProgress
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card style={{ background: "black" }}>
                                <CardBody>
                                    <div style={divStyle}>
                                        <DateRangePicker dateLimit={{ days: 15 }} maxDate={this.state.maxDate} onApply={this.handleEvent}>
                                            <Button id="datePicker" style={{ marginRight: "1rem" }} color="info">
                                                <FontAwesomeIcon icon={"calendar"} size="2x" />
                                            </Button>
                                        </DateRangePicker>
                                        <h5 style={{ fontSize: "0.99em", color: "white", marginLeft: "10px" }}>
                                            <b>Date Frame:</b>
                                            {` ${new Date(this.state.begin_date).toLocaleDateString()} To ${new Date(this.state.end_date).toLocaleDateString()}`}
                                        </h5>
                                    </div>
                                    <Button color="info" onClick={this.handleTableData}>
                                        {this.state.isGraphical === true ? "Tabular" : "Graphical"}
                                    </Button>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {this.state.sampleType && this.state.sampleType.length > 0 ? (
                            this.state.sampleType.map((data, count) => {
                                return data.tabularData != null && data.tabularData.length > 0 ? (
                                    <GridItem key={count} xs={6} sm={6} md={6}>
                                        <Card style={{ background: "black" }}>
                                            <CardHeader>
                                                <h4>{data.name}</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <NavPills
                                                    color="primary"
                                                    active={activeTab}
                                                    tabs={[
                                                        {
                                                            tabButton: "Tabular",
                                                            tabContent: (
                                                                <GridItem xs={12}>
                                                                    <PerfectScrollbar>
                                                                        <ReactTable
                                                                            data={data.tabularData}
                                                                            filterable
                                                                            columns={[
                                                                                {
                                                                                    Header: "ID",
                                                                                    accessor: "id",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    Header: "Time",
                                                                                    accessor: "timestamp",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    Header: "Data",
                                                                                    accessor: "info",
                                                                                    headerStyle: { color: "white" },
                                                                                    style: { color: "white" }
                                                                                },
                                                                            ]}
                                                                            defaultPageSize={10}
                                                                            showPaginationTop
                                                                            showPaginationBottom={false}
                                                                            className="-highlight"
                                                                        />
                                                                    </PerfectScrollbar>
                                                                </GridItem>
                                                            ),
                                                        },
                                                        {
                                                            tabButton: "Graphical",
                                                            tabContent: (
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Card plain>
                                                                        <CardBody>
                                                                            <Chart
                                                                                width={"100%"}
                                                                                height={"400px"}
                                                                                chartType={"LineChart"}
                                                                                options={{
                                                                                    hAxis: {
                                                                                        title: "Time",
                                                                                        textStyle: { color: "white" },
                                                                                        titleTextStyle: { color: "white" }
                                                                                    },
                                                                                    vAxis: {
                                                                                        title: data.name + " Data",
                                                                                        textStyle: { color: "white" },
                                                                                        titleTextStyle: { color: "white" }
                                                                                    },
                                                                                    legend: "none",
                                                                                    chartArea: { top: 5, width: "80%", height: "80%" },
                                                                                    colors: ["white"],
                                                                                    backgroundColor: "black",
                                                                                    background: "black"
                                                                                }}
                                                                                data={data.graphicalData}
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </GridItem>
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                ) : (
                                    ""
                                );
                            })
                        ) : (
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody style={{ height: "200px", background: "black" }}>
                                        <h4
                                            style={{
                                                marginTop: "80px",
                                                textAlign: "center",
                                                color: "white"
                                            }}
                                        >
                                            No data is currently available for this period.
                                        </h4>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        )}
                    </GridContainer>
                )}
            </div>
        );
    }
}

export default GoogleFitData;
