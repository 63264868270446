import React from "react";
// import { Redirect } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import DailyGraph from "./daily-graph";

class DailyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            dailyReportData: [],
            overlayReportData: [],
            agpReportData: [],
            patients: [],
            isLoading: false,
            isPatientSelect: false,
            isAGPReportData: false,
            isCaregiverHeaderData:
                localStorage.getItem("loginType") === "Caregiver" ? true : false,
            caregiverHeaderData: {},
            maxDate: moment(),
            begin_date: new Date().valueOf() - 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            patient_id:
                localStorage.getItem("loginType") === "Caregiver"
                    ? ""
                    : localStorage.getItem("user_id"),
            patient_email:
                localStorage.getItem("loginType") === "Caregiver"
                    ? ""
                    : localStorage.getItem("email"),
            patient_name:
                localStorage.getItem("loginType") === "Caregiver"
                    ? ""
                    : `${localStorage
                        .getItem("first_name")
                        .toUpperCase()} ${localStorage
                            .getItem("last_name")
                            .toUpperCase()}`
        };
        this.getDailyReportData = this.getDailyReportData.bind(this);
        this.getCaregiverHeaderData = this.getCaregiverHeaderData.bind(this);
        this.getOverlayReportData = this.getOverlayReportData.bind(this);
        this.getAGPReportData = this.getAGPReportData.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handlePatientDropdownChange = this.handlePatientDropdownChange.bind(
            this
        );
        this.showNotification = this.showNotification.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    componentDidMount() {
        document.title = "Patient Daily Report - Ambrosia Care";
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        if (localStorage.getItem("loginType") === "Caregiver") {
            const followers = localStorage.getItem("my-followers");
            if (followers) this.setState({ patients: JSON.parse(followers) });
        }
    }

    handleEvent(event, picker) {
        this.setState({
            begin_date: picker.startDate.valueOf(),
            end_date: picker.endDate.valueOf()
        });
    }

    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name} ${e.last_name}`,
            patient_email: e.email
        });
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    getOverlayReportData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            patient_id:
                localStorage.getItem("loginType") === "Caregiver"
                    ? this.state.patient_id
                    : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB"
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-overlay-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                // console.log(DataJSON);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.showNotification("tc");
                } else {
                    this.setState({
                        overlayReportData: DataJSON.data,
                        isPatientSelect: true
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: error => {
                console.error(error);
            }
        });
    }
    getAGPReportData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            patient_id:
                localStorage.getItem("loginType") === "Caregiver"
                    ? this.state.patient_id
                    : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB"
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-agp-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.showNotification("tc");
                } else {
                    this.setState({
                        agpReportData: DataJSON.data,
                        isAGPReportData: true
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: error => {
                console.error(error);
            }
        });
    }

    getDailyReportData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const begin_date = new Date(this.state.begin_date);
        const end_date = new Date(this.state.end_date);
        const dates = [];
        // difference between dates in time
        const difference_in_time = end_date.getTime() - begin_date.getTime();
        // difference between dates in days (Conversion of difference_in_time in days)
        const difference_in_days = Math.ceil(
            difference_in_time / (1000 * 3600 * 24)
        );
        var date = end_date;
        for (var i = 0; i < difference_in_days; i++) {
            const date_set = {};
            date.setHours(0);
            date.setMinutes(0);
            date_set.begin_date = date.getTime();
            date.setHours(23);
            date.setMinutes(59);
            date_set.end_date = date.getTime();
            dates.push(date_set);
            date.setDate(date.getDate() - 1);
        }
        const data = {
            token: localStorage.getItem("token"),
            patient_id:
                localStorage.getItem("loginType") === "Caregiver"
                    ? this.state.patient_id
                    : localStorage.getItem("user_id"),
            dates,
            platform_name: "WEB"
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-daily-report-data",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.showNotification("tc");
                } else {
                    this.setState({
                        dailyReportData: DataJSON.data,
                        isPatientSelect: true
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: error => {
                console.error(error);
            }
        });
    }
    getCaregiverHeaderData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: localStorage.getItem("user_id"),
            platform_name: "WEB"
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-caregiver-header",
            data: data,
            success: function (data) {
                // console.log(data);
                var DataJSON = JSON.parse(data);
                // console.log(DataJSON);
                if (DataJSON.success === false) {
                    if (data.includes("msg") && DataJSON.msg.includes("Invalid token")) {
                        this.showNotification("tc");
                    }
                } else {
                    this.setState({
                        caregiverHeaderData: DataJSON.data,
                        isCaregiverHeaderData: true
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: error => {
                console.error(error);
            }
        });
    }
    handleBackButton() {
        this.setState({
            dailyReportData: [],
            isPatientSelect: false
        });
    }

    render() {
        const { classes } = this.props;
        return this.state.isPatientSelect && this.state.isAGPReportData ? (
            <DailyGraph
                handleBackButton={this.handleBackButton}
                dailyReportData={this.state.dailyReportData}
                caregiverHeaderData={this.state.caregiverHeaderData}
                agpReportData={this.state.agpReportData}
                patientName={this.state.patient_name}
            />
        ) : (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Snackbar
                        place="tc"
                        color="warning"
                        message="Data are not available for selected date frame."
                        open={this.state.tc}
                        closeNotification={() => this.setState({ tc: false })}
                        close
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card style={{ background: "black" }}>
                        <CardHeader color="info" icon>
                            <h4 className={classes.cardIconTitle} style={{ color: "white" }}>Select Patient & Date</h4>
                            <h5
                                className={classes.cardIconTitle}
                                style={{ fontSize: "0.99em", color: "white" }}
                            >
                                <b>Patient Name:</b> {this.state.patient_name}
                            </h5>
                            <h5
                                className={classes.cardIconTitle}
                                style={{ fontSize: "0.99em", color: "white" }}
                            >
                                <b>Date Frame:</b>
                                {`${new Date(
                                    this.state.begin_date
                                ).toLocaleDateString()} To ${new Date(
                                    this.state.end_date
                                ).toLocaleDateString()}`}
                            </h5>
                            <h5
                                className={classes.cardIconTitle}
                                style={{ fontSize: "0.99em", color: "white" }}
                            >
                                <b>You can select only 15 days data</b>
                            </h5>
                        </CardHeader>
                        <CardBody style={{}}>
                            <form>
                                <GridContainer>
                                    {localStorage.getItem("loginType") === "Caregiver" ? (
                                        <GridItem xs={4} sm={4} md={4} lg={4}>
                                            <CustomDropdown
                                                hoverColor="info"
                                                buttonText={
                                                    this.state.patient_name === ""
                                                        ? "Select Patient"
                                                        : this.state.patient_name
                                                }
                                                buttonProps={{
                                                    round: true,
                                                    fullWidth: true,
                                                    style: { marginBottom: "0" },
                                                    color: "info"
                                                }}
                                                id="secondYaxis"
                                                dropdownList={this.state.patients.map((e, i) => (
                                                    <p
                                                        style={{
                                                            display: "block",        // Makes the <p> behave like an <li>
                                                            color: "white",
                                                            width: "100%",
                                                            margin: 0,               // Removes any default margin
                                                            padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                            boxSizing: "border-box",
                                                        }}
                                                        onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                                        {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                                    </p>
                                                ))}
                                            />
                                        </GridItem>
                                    ) : null}
                                    <GridItem xs={4} sm={4} md={4} lg={4}>
                                        <DateRangePicker
                                            dateLimit={{ days: 15 }}
                                            maxDate={this.state.maxDate}
                                            onApply={this.handleEvent}
                                        >
                                            <Button
                                                id="datePicker"
                                                style={{ marginRight: "1rem" }}
                                                color="info"
                                            >
                                                <FontAwesomeIcon icon={"calendar"} size="2x" />
                                            </Button>
                                        </DateRangePicker>
                                        <Button
                                            color="info"
                                            disabled={this.state.patient_name === ""}
                                            onClick={() => {
                                                this.getDailyReportData();
                                                this.getAGPReportData();
                                                if (this.state.isCaregiverHeaderData)
                                                    this.getCaregiverHeaderData();
                                            }}
                                        >
                                            Generate Report
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} />
            </GridContainer>
        );
    }
}

export default withStyles(validationFormsStyle)(DailyReport);
