import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Table from "components/Table/Table.jsx";

import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class groups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            isLoading: false,
            isGroupDataLoading: false,
            notificationMessage: "",
            group_name: "",
            updated_group_name: "",
            groupList: [],
            id: "",
            update_group_name: "",
            deleteAlert: null,
            updateAlert: null,
            classicModal: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    componentDidMount() {
        document.title = "Manage Groups - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
        }
        this.getGroupData();
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }
    hideAlertUpdate() {
        this.setState({ updateAlert: null });
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    handleSubmitForm() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            super_caregiver_id: localStorage.getItem("user_id"),
            group_name: this.state.group_name,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-group",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                        this.setState({ notificationMessage: "Something went wrong while creating group." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("Group name is already exist.")) {
                        this.setState({ notificationMessage: "Group name is already exist." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("You can add upto 30 groups only.")) {
                        this.setState({ notificationMessage: "You can create upto 30 groups only." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("FAILED")) {
                        this.setState({ notificationMessage: "Something went wrong while creating group." }, () => {
                            this.showNotification("tc_danger");
                        });
                    }
                } else {
                    this.setState({ notificationMessage: "Group created." }, () => {
                        this.showNotification("tc_info");
                        this.getGroupData();
                    });
                }
                this.setState({ group_name: "" });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getGroupData() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            super_caregiver_id: localStorage.getItem("user_id"),
            group_name: this.state.group_name,
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-group-count-list",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ groupList: [], deleteAlert: null, updateAlert: null });
                } else {
                    this.setState({ groupList: DataJSON.groups, deleteAlert: null, updateAlert: null });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    deleteGroup(id) {
        const data = {
            token: localStorage.getItem("token"),
            group_id: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-group",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.getGroupData()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Group Deleted.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    updateGroup(id) {
        const data = {
            token: localStorage.getItem("token"),
            id: id,
            group_name: this.state.update_group_name,
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-group",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else if (DataJSON.msg.includes("Group name is already exist.")) {
                        this.setState({ notificationMessage: "Group name is already exist." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("Failed to update group name.")) {
                        this.setState({ notificationMessage: "Something went wrong while updating group name." }, () => {
                            this.showNotification("tc_danger");
                        });
                    }
                } else {
                    this.setState({
                        updateAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Updated!"
                                onConfirm={() => this.getGroupData()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Group Updated.
                            </SweetAlert>
                        ),
                        group_name: "",
                    });
                    this.setClassicModal(false);
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    warningWithConfirmMessageUpdate(id, group_name) {
        this.setClassicModal(true);
        this.setState({ id: id, update_group_name: group_name });
    }
    updateGroupData(id, group_name) {
        return (
            <Button justIcon round simple color="primary" className="remove" onClick={() => this.warningWithConfirmMessageUpdate(id, group_name)}>
                <CreateIcon />
            </Button>
        );
    }
    setClassicModal(classicModal) {
        this.setState({
            classicModal: classicModal,
        });
    }
    warningWithConfirmMessageDelete(id) {
        this.setState({
            deleteAlert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deleteGroup(id)} onCancel={() => this.hideAlertDelete()} confirmBtnText="Yes, Delete it!" cancelBtnText="Cancel" showCancel>
                    Your data related to this group will be lost .
                </SweetAlert>
            ),
        });
    }
    deleteGroupData(id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessageDelete(id)}>
                <Delete />
            </Button>
        );
    }
    render() {
        var readingCount = 0;
        const { classes } = this.props;

        const tableData = this.state.groupList.map((data, i) => {
            const { id, group_name, patient_count, caregiver_count } = data;
            // this.setState({ group_name: group_name });
            readingCount++;
            return [readingCount, group_name, patient_count, caregiver_count, this.updateGroupData(id, group_name), this.deleteGroupData(id)];
        });
        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                {this.state.updateAlert}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info" icon />
                            <CardBody style={{ background: "black" }}>
                                <GridContainer>
                                    <GridItem style={{ display: "flex", alignItems: "center" }} xs={12} sm={12} md={12}>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#fff", marginRight: "10px" }}>
                                            Group Name :
                                        </h5>
                                        <CustomInput
                                            labelText="Group Name"
                                            id="group_name"
                                            inputProps={{
                                                type: "search",
                                                value: `${this.state.group_name}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: false,
                                            }}
                                        />
                                        <Button style={{ marginLeft: "10px" }} color="info" disabled={this.state.group_name === ""} onClick={this.handleSubmitForm} className={classes.updateProfileButton}>
                                            Create Group
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                                <Clearfix />
                            </CardBody>
                        </Card>
                        <Dialog
                            classes={{
                                root: classes.center + " " + classes.modalRoot,
                                paper: classes.modal,
                            }}
                            open={this.state.classicModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.setClassicModal(false)}
                            aria-labelledby="classic-modal-slide-title"
                            aria-describedby="classic-modal-slide-description"
                        >
                            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader} style={{ background: "black" }}>
                                <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                    <Close className={classes.modalClose} style={{ color: "white" }} />
                                </Button>
                                <h4 className={classes.modalTitle} style={{ color: "white" }}>Update Group</h4>
                            </DialogTitle>
                            <DialogContent id="classic-modal-slide-description" className={classes.modalBody} style={{ background: "black" }}>
                                <p style={{ color: "white" }}>Are You sure you want to update the group name ?</p>

                                <CustomInput
                                    labelText="Group Name"
                                    id="update_group_name"
                                    inputProps={{
                                        type: "search",
                                        value: `${this.state.update_group_name}`,
                                    }}
                                    formControlProps={{
                                        onChange: this.handleInputChange,
                                        fullWidth: false,
                                    }}
                                />
                            </DialogContent>
                            <DialogActions style={{ background: "black" }}>
                                <Button justIcon round simple color="primary" className="remove" onClick={() => this.updateGroup(this.state.id, this.state.update_group_name)}>
                                    <CheckIcon />
                                </Button>
                                <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {tableData.length > 0 ? (
                            <Card>
                                <CardBody style={{ background: "black" }}>
                                    <PerfectScrollbar>
                                        <Table tableHeaderColor="primary" tableHead={["ID", "Group Name", "Patient Count", "Caregiver Count", "Action"]} tableData={tableData} />
                                    </PerfectScrollbar>
                                </CardBody>
                            </Card>
                        ) : (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Card>
                                        <CardBody style={{ height: "200px" }}>
                                            <h4
                                                style={{
                                                    marginTop: "80px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                There are no groups available.
                                            </h4>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(groups);
