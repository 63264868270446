import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { CSVLink } from "react-csv";
import Chart from "react-google-charts";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Redirect } from "react-router-dom";
import ReactTable from "react-table";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Timeline from "components/Timeline/Timeline.jsx";

// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";

import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import CommanProfile from "../Utiles/CommanProfile";
import { decodeData, formatTimestampForExcepExport, getCurrentSystemDate, getCurrentSystemDateTime, getCurrentTimeInMillies, getDataAfterLastExported, getGlucoseValue, getGlucoseValuestr } from "../Utiles/Utils";
import color from "@material-ui/core/colors/amber";

var beginDateTime = new Date().valueOf() - 24 * 60 * 60 * 1000;
var endDateTime = new Date().valueOf();

const csvHeaders = [
    [
        'Device',
        'Serial Number',
        'Device Timestamp',
        'Record Type',
        'Historic Glucose mg/dL',
        'Scan Glucose mg/dL',
        'Non-numeric Rapid-Acting Insulin',
        'Rapid-Acting Insulin (units)',
        'Non-numeric Food',
        'Carbohydrates (grams)',
        'Carbohydrates (servings)',
        'Non-numeric Long-Acting Insulin',
        'Long-Acting Insulin (units)',
        'Notes',
        'Strip Glucose mg/dL',
        'Ketone mmol/L',
        'Meal Insulin (units)',
        'Correction Insulin (units)',
        'User Change Insulin (units)',
    ]
];

class Patient extends Component {
    constructor(props) {
        super(props);
        CommanProfile.Global_GlucoseType = localStorage.getItem("glucose_unit");

        this.state = {
            readings: [],
            readingsAfterExported: [],
            previousReading: [],
            notes: [],
            chartData: [],
            dualChartData: [],
            patient_id: "",
            patientName: "",
            patientFirstName: "",
            patientEmail: "",
            glucose_unit: "",
            is24HourGraph: true,
            begin_date: new Date().valueOf() - 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            isButtonDisabled: false,
            isPrintDualGraph: false,
            isLoadingGraph: false,
            isLoadingTabular: false,
            isLoadingDualGraph: false,
            isLoadingTimeline: false,
            dateIntervalActive: 1,
            maxDate: moment(),
            id: "oneDay",
            notesDropdownValue: "Food Calories",
            graphOptions: {
                title: "Last 24 Hours Glucose Readings",
                titlePosition: "out",
                titleTextStyle: {
                    color: "#0099cc",
                    fontSize: 20,
                },
                interpolateNulls: true,
                hAxis: {
                    title: "Reading Time (24 hours)",
                    titleTextStyle: {
                        bold: true,
                        italic: false,
                        color: "white"
                    },
                    textStyle: { color: "white" },
                },
                vAxis: {
                    //title: "Glucose (mg/dL)",
                    title: "Glucose " + "(" + CommanProfile.Global_GlucoseType + ")",

                    titleTextStyle: {
                        bold: true,
                        italic: false,
                        color: "white"
                    },
                    viewWindowMode: "explicit",
                    viewWindow: {
                        min: 0,
                    },
                    textStyle: { color: "white" },
                },
                colors: ["#1AD8F2", "#fbc6c2", "#ffd599"],
                legend: { position: "top", textStyle: { color: "white" } },
                series: {
                    0: {
                        pointSize: 0,
                    },
                    1: {
                        color: "#8B008B",
                        lineWidth: 2,
                        pointSize: 3,
                    },
                },
                pointShape: "circle",
                backgroundColor: "black",
                background: "black"
            },
            dualGraphOption: {
                title: "Last 24 Hours Glucose Readings & Notes",
                titlePosition: "out",
                titleTextStyle: {
                    color: "#0099cc",
                    fontSize: 20,
                    color: "white"
                },
                legend: { position: "top", textStyle: { color: "white" } },
                hAxis: {
                    title: "Reading Time",
                    titleTextStyle: {
                        bold: true,
                        italic: false,
                        color: "white"
                    },
                    textStyle: { color: "white" },
                },
                series: {
                    0: { targetAxisIndex: 0, pointSize: 3 },
                    1: { targetAxisIndex: 1, pointSize: 5 },
                },
                vAxes: {
                    0: {
                        title: "Glucose " + "(" + CommanProfile.Global_GlucoseType + ")",
                        titleTextStyle: {
                            bold: true,
                            italic: false,
                            color: "white"
                        },
                        textStyle: { color: "white" },
                        viewWindowMode: "explicit",
                        viewWindow: {
                            min: 0,
                        },
                    },
                    1: {
                        title: "Food Calories (kcal)",
                        titleTextStyle: {
                            bold: true,
                            italic: false,
                            color: "white"
                        },
                        textStyle: { color: "white" },
                        viewWindowMode: "explicit",
                        viewWindow: {
                            min: 0,
                            max: 1000,
                        },
                    },
                },
                colors: ["#99dde6", "#fbc6c2"],
                pointShape: "circle",
                backgroundColor: "black",
                background: "black"
            },
        };
        this.changeInterval = this.changeInterval.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleNotesDropdownChange = this.handleNotesDropdownChange.bind(this);
        this.isButtonDisableValueUpdate = this.isButtonDisableValueUpdate.bind(this);


    }

    componentDidMount() {
        document.title = "Patient Info - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (this.props.location.state) {
                const { patient_id, patientName, patientEmail, glucose_unit, patientFirstName } = this.props.location.state;
                this.setState({
                    patient_id, patientName, patientEmail, glucose_unit, patientFirstName
                }, function () {
                    this.getReadingsGraphApiCall("oneDay");
                    this.getReadingsApiCall("oneDay");
                    this.getDualAxisGraphApiCall();
                    this.getNotesApiCall();
                    this.interval = setInterval(() => this.getReadingsGraphApiCall(this.state.id), 300000);
                    this.interval = setInterval(() => this.getReadingsApiCall(this.state.id), 300000);
                    this.interval = setInterval(() => this.getDualAxisGraphApiCall(), 300000);
                    this.interval = setInterval(() => this.getNotesApiCall(), 300000);
                });
            } else {
                this.props.history.push("/");
            }
        } else {
            this.getReadingsGraphApiCall("oneDay");
            this.getReadingsApiCall("oneDay");
            this.getDualAxisGraphApiCall();
            this.getNotesApiCall();
            this.interval = setInterval(() => this.getReadingsGraphApiCall(this.state.id), 300000);
            this.interval = setInterval(() => this.getReadingsApiCall(this.state.id), 300000);
            this.interval = setInterval(() => this.getDualAxisGraphApiCall(), 300000);
            this.interval = setInterval(() => this.getNotesApiCall(), 300000);

        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleNotesDropdownChange(e) {
        const dualGraph = this.state.dualGraphOption;
        dualGraph.vAxes[1].title = e + (e === "Food Calories" ? ` (kcal)` : e === "Blood Glucose" ? (this.state.glucose_unit === "mmol/L" ? ` (mmol/L)` : ` (mg/dL)`) : e === "Rapid-Acting Insulin" || e === "Long-Acting Insulin" ? ` (Units)` : e === "Exercise" ? ` (Minutes)` : false);
        dualGraph.vAxes[1].viewWindow.max = e === "Food Calories" ? 1000 : e === "Blood Glucose" ? (this.state.glucose_unit === "mmol/L" ? 30 : 500) : e === "Rapid-Acting Insulin" || e === "Long-Acting Insulin" ? 25 : e === "Exercise" ? 120 : false;
        this.setState(dualGraph);
        this.setState({ notesDropdownValue: e }, function () {
            this.getDualAxisGraphApiCall();
        });
    }

    handleEvent(event, picker) {
        this.setState({ isButtonDisabled: true });
        beginDateTime = picker.startDate.valueOf();
        endDateTime = picker.endDate.valueOf();
        const graph = this.state.graphOptions;
        graph.title = "Glucose Readings Between " + picker.startDate.format("DD-MM-YYYY") + " To " + picker.endDate.format("DD-MM-YYYY");
        this.setState(graph);
        const dualGraph = this.state.dualGraphOption;
        dualGraph.title = "Glucose Readings & Notes Between " + picker.startDate.format("DD-MM-YYYY") + " To " + picker.endDate.format("DD-MM-YYYY");
        this.setState(dualGraph);
        this.changeInterval("datePicker");
    }

    changeInterval(id) {
        this.setState({ isButtonDisabled: true, id: id });
        const graph = this.state.graphOptions;
        const dualGraph = this.state.dualGraphOption;
        switch (id) {
            case "oneDay":
                beginDateTime = new Date().getTime() - 24 * 60 * 60 * 1000;
                endDateTime = new Date().valueOf();
                graph.title = "Last 24 Hours Glucose Readings";
                dualGraph.title = "Last 24 Hours Glucose Readings & Notes";
                graph.series = {
                    0: {
                        pointSize: 0,
                    },
                    1: {
                        color: "#8B008B",
                        lineWidth: 2,
                        pointSize: 3,
                    },
                };
                this.setState(graph);
                this.setState(dualGraph);
                this.setState({ dateIntervalActive: 1 });
                break;
            case "sevenDays":
                beginDateTime = new Date().getTime() - 7 * 24 * 60 * 60 * 1000;
                endDateTime = new Date().valueOf();
                graph.title = "Last 7 Days Glucose Readings";
                dualGraph.title = "Last 7 Days Glucose Readings & Notes";
                graph.colors = ["#99dde6", "#fbc6c2", "#ffd599"];
                graph.series = {
                    0: {
                        pointSize: 3,
                    },
                    1: {
                        color: "#fbc6c2",
                        lineWidth: 3,
                        pointSize: 3,
                    },
                    2: {
                        pointSize: 3,
                    },
                };
                this.setState(graph);
                this.setState(dualGraph);
                this.setState({ dateIntervalActive: 2, is24HourGraph: false });
                break;
            case "thirtyDays":
                beginDateTime = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
                endDateTime = new Date().valueOf();
                graph.title = "Last 30 Days Glucose Readings";
                dualGraph.title = "Last 30 Days Glucose Readings & Notes";
                graph.colors = ["#99dde6", "#fbc6c2", "#ffd599"];
                graph.series = {
                    0: {
                        pointSize: 3,
                    },
                    1: {
                        color: "#fbc6c2",
                        lineWidth: 3,
                        pointSize: 3,
                    },
                    2: {
                        pointSize: 3,
                    },
                };
                this.setState(graph);
                this.setState(dualGraph);
                this.setState({ dateIntervalActive: 3, is24HourGraph: false });
                break;
            case "datePicker":
                this.setState({ dateIntervalActive: 4, is24HourGraph: false });
                graph.colors = ["#99dde6", "#fbc6c2", "#ffd599"];
                graph.series = {
                    0: {
                        pointSize: 3,
                    },
                    1: {
                        color: "#fbc6c2",
                        lineWidth: 3,
                        pointSize: 3,
                    },
                    2: {
                        pointSize: 3,
                    },
                };
                break;
            default:
                break;
        }

        //title: "Glucose " + "(" + CommanProfile.Global_GlucoseType + ")",
        // graph.vAxis.title = this.state.glucose_unit === "mmol/L" ? `Glucose (mmol/L)` : `Glucose (mg/dL)`;
        // dualGraph.vAxes[0].title = this.state.glucose_unit === "mmol/L" ? `Glucose (mmol/L)` : `Glucose (mg/dL)`;

        graph.vAxis.title = CommanProfile.Global_GlucoseType === "mmol/L" ? `Glucose (mmol/L)` : `Glucose (mg/dL)`;
        dualGraph.vAxes[0].title = CommanProfile.Global_GlucoseType === "mmol/L" ? `Glucose (mmol/L)` : `Glucose (mg/dL)`;


        this.setState(graph);
        this.setState(dualGraph);
        this.setState({ begin_date: beginDateTime, end_date: endDateTime, chartData: [], readings: [], readingsAfterExported: [], dualChartData: [], notes: [] }, function () {
            this.getReadingsGraphApiCall(id);
            this.getReadingsApiCall(id);
            this.getDualAxisGraphApiCall();
            this.getNotesApiCall();
        });
    }

    getReadingsGraphApiCall(id) {
        // console.log(" Id : " + id + " begin_date : " + this.state.begin_date + " end_date : " + this.state.end_date);
        this.setState({ isLoadingGraph: true });
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            type: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-device-reading",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ chartData: [] });
                } else if (DataJSON.data.success === false) {
                    this.setState({ chartData: [] });
                } else {
                    const readingsChartData = [];
                    var countFlag = 1;
                    if (id === "sevenDays" || id === "thirtyDays" || id === "datePicker") {
                        readingsChartData.push(["Reading Time", "Max", "Min", "Average"]);
                        DataJSON.data.forEach((charts) => {
                            readingsChartData.push([charts.maxTimestamp, this.state.glucose_unit === "mmol/L" ? parseFloat(charts.maxVal) : parseInt(charts.maxVal), this.state.glucose_unit === "mmol/L" ? parseFloat(charts.minVal) : parseInt(charts.minVal), this.state.glucose_unit === "mmol/L" ? parseFloat(charts.average) : parseInt(charts.average)]);
                        });
                    } else {
                        readingsChartData.push(["Reading Time", "Readings", "Calibrated Values"]);
                        DataJSON.data.forEach((charts, count) => {
                            // console.log("charts.reading >> ", charts.reading);
                            // console.log("charts.calibrated_value >> ", charts.calibrated_value);
                            if (charts.calibrated_value === -1 || charts.calibrated_value === "-1") {
                                countFlag++;
                            }
                            readingsChartData.push([new Date(parseInt(charts.reading_time, 10)), this.state.glucose_unit === "mmol/L" ? parseFloat(charts.reading) : parseFloat(charts.reading), this.state.glucose_unit === "mmol/L" ? (charts.calibrated_value === -1 || charts.calibrated_value === "-1" ? (charts.calibrated_value = null) : parseFloat(charts.calibrated_value)) : charts.calibrated_value === -1 || charts.calibrated_value === "-1" ? (charts.calibrated_value = null) : parseInt(charts.calibrated_value)]);
                        });
                    }
                    if (countFlag === readingsChartData.length) {
                        readingsChartData[1][2] = 0;
                    }
                    // console.log(readingsChartData);
                    this.setState({ chartData: readingsChartData });
                }
                this.setState({ isLoadingGraph: false });
                this.isButtonDisableValueUpdate();
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    setExportDate() {
        this.setState({ readingsAfterExported: [] });
        localStorage.setItem("companyLastUpdatedTime", getCurrentTimeInMillies());
        const data = {
            user_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            companyLastUpdatedTime: getCurrentTimeInMillies()
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-export-history-time",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                console.log("DataJSON >>>>>>>>>>>> ", DataJSON);
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getReadingsApiCall(id) {
        // console.log(" Id : " + id + " begin_date : " + this.state.begin_date + " end_date : " + this.state.end_date);
        this.setState({ isLoadingTabular: true });
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-patient-reading",
            data: data,
            success: function (data) {

                // console.log("data>>>>>>>> ", data);
                var DataJSON = JSON.parse(data);

                // let G_U = DataJSON.data[0].glucose_unit;
                // console.log("DataJSON------->" + G_U);
                // localStorage.setItem("glucose_unit", G_U);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ readings: [] });
                    this.setState({ readingsAfterExported: [] });
                } else if (DataJSON.data.success === false) {
                    this.setState({ readings: [] });
                    this.setState({ readingsAfterExported: [] });
                } else {
                    let readingDataJson = decodeData(DataJSON.data);
                    this.setState({ readings: readingDataJson });
                    this.setState({ readingsAfterExported: getDataAfterLastExported(readingDataJson, (localStorage.getItem("companyLastUpdatedTime"))) });
                }
                this.setState({ isLoadingTabular: false });
                this.isButtonDisableValueUpdate();
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    getDualAxisGraphApiCall() {
        // console.log(" begin_date : " + this.state.begin_date + " end_date : " + this.state.end_date);
        this.setState({ isLoadingDualGraph: true });
        const notesDropDownValue = this.state.notesDropdownValue;
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            notes_type: notesDropDownValue,
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-dual-graph-reading",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ dualChartData: [] });
                } else if (DataJSON.data.success === false) {
                    this.setState({ dualChartData: [] });
                } else {
                    const dualChartNotesData = [];
                    if (DataJSON.data.length > 0) {
                        dualChartNotesData.push(["Reading Time", "Glucose", notesDropDownValue === "Food Calories" ? "Food Calories" : notesDropDownValue === "Blood Glucose" ? "Blood Glucose" : notesDropDownValue === "Rapid-Acting Insulin" ? "Rapid-Acting Insulin" : notesDropDownValue === "Long-Acting Insulin" ? "Long-Acting Insulin" : "Exercise"]);
                        DataJSON.data.forEach((dualAxis) => {
                            dualChartNotesData.push([new Date(parseInt(dualAxis.reading_time === null ? 0 : dualAxis.reading_time, 10)), this.state.glucose_unit === "mmol/L" ? parseFloat(dualAxis.reading) : parseFloat(dualAxis.reading), this.state.glucose_unit === "mmol/L" ? parseFloat(dualAxis.notesTypeData) : parseInt(dualAxis.notesTypeData)]);
                        });
                    }
                    this.setState({ dualChartData: dualChartNotesData });
                }
                this.setState({ isLoadingDualGraph: false });
                this.isButtonDisableValueUpdate();
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getNotesApiCall() {
        // console.log(" begin_date : " + this.state.begin_date + " end_date : " + this.state.end_date);
        this.setState({ isLoadingTimeline: true });
        const data = {
            token: localStorage.getItem("token"),
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
            begin_date: this.state.begin_date,
            end_date: this.state.end_date,
            platform_name: "WEB",
        };
        if (localStorage.getItem("loginType") === "Patient") {
            data.user_type = "patient";
        }
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-notes",
            data: data,
            success: function (data) {

                // console.log("----Data JSON1-->", data);

                var DataJSON = JSON.parse(data);
                // console.log("----Data JSON-->", data);

                if (DataJSON != null) {

                    if (DataJSON.success === false) {

                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                        }
                        this.setState({ notes: [] });
                    } else if (DataJSON.data.success === false) {
                        this.setState({ notes: [] });
                    } else {
                        this.setState({ notes: DataJSON.data });
                    }

                } else {
                    this.setState({ notes: [] });
                }


                this.setState({ isLoadingTimeline: false });
                this.isButtonDisableValueUpdate();
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getReading(reading) {

        // console.log("------------reading---->" + reading)

        switch (reading) {
            case null:
            case "NA":
                return reading;
            case parseInt(reading) <= 40:
            case parseInt(reading) >= 400:
                return parseInt(reading) >= 400 ? "HI" : "LO";
            default:
                // return this.state.glucose_unit === "mmol/L" ? (parseInt(reading) / 18.0182).toFixed(1) + " " + this.state.glucose_unit : parseInt(reading) % 1 === 0 ? parseInt(reading) + " " + this.state.glucose_unit : (parseInt(reading) / 1).toFixed(0) + " " + this.state.glucose_unit;
                return getGlucoseValue(parseInt(reading), this.state.glucose_unit);
        }
    }

    printDualGraph() {
        this.setState({ isPrintDualGraph: true });
    }

    isButtonDisableValueUpdate() {
        if (this.state.isLoadingGraph === false && this.state.isLoadingTabular === false && this.state.isLoadingDualGraph === false && this.state.isLoadingTimeline === false) {
            this.setState({ isButtonDisabled: false });
        }
    }

    getDiff(reading, previous_reading, reading_time, previous_reading_time) {
        if (previous_reading === "NA") {
            previous_reading = reading;
        }
        var diffInRange = reading - previous_reading;
        var diffInMillis = new Date(reading_time - 1).getMinutes() - new Date(previous_reading_time - 1).getMinutes();
        if (diffInMillis === 0) {
            diffInMillis = 1;
        }
        var diff = diffInRange / Math.abs(diffInMillis);
        if (parseInt(diff) === 0) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 1 || parseInt(diff) === -1) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(45deg)" }} />;
        } else if (parseInt(diff) === -2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(135deg)" }} />;
        } else if (parseInt(diff) < -2) {
            return <i className="fa fa-arrow-down" aria-hidden="true" />;
        } else if (parseInt(diff) > 2) {
            return <i className="fas fa-arrow-up" />;
        } else if (reading !== "NA") {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        }
    }

    getDifferenceText(reading, previous_reading, reading_time, previous_reading_time) {
        if (previous_reading === "NA") {
            previous_reading = reading;
        }
        var diffInRange = reading - previous_reading;
        var diffInMillis = new Date(reading_time - 1).getMinutes() - new Date(previous_reading_time - 1).getMinutes();
        if (diffInMillis === 0) {
            diffInMillis = 1;
        }
        var diff = diffInRange / Math.abs(diffInMillis);
        if (parseInt(diff) === 0) {
            return "Glucose is changing slowly";
        } else if (parseInt(diff) === 1) {
            return "Glucose is changing slowly";
        } else if (parseInt(diff) === 2) {
            return "Glucose is rising";
        } else if (parseInt(diff) === -2) {
            return "Glucose is falling";
        } else if (parseInt(diff) < -2) {
            return "Glucose is falling quickly";
        } else if (parseInt(diff) > 2) {
            return "Glucose is rising quickly";
        } else if (reading !== "NA") {
            return "Glucose is changing slowly";
        }
    }
    getNewDifference(diff) {
        if (diff === 0 && diff !== null) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 1 || parseInt(diff) === -1) {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        } else if (parseInt(diff) === 2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(45deg)" }} />;
        } else if (parseInt(diff) === -2) {
            return <i className="fas fa-arrow-up fa-rotate-90" style={{ transform: "rotate(135deg)" }} />;
        } else if (parseInt(diff) < -2) {
            return <i className="fa fa-arrow-down" aria-hidden="true" />;
        } else if (parseInt(diff) > 2) {
            return <i className="fas fa-arrow-up" />;
        } else if (diff !== "NA") {
            return <i className="fa fa-arrow-right" aria-hidden="true" />;
        }
    }
    getCSVDifferenceText(diff) {
        if (parseInt(diff) === 0) {
            return "Glucose is changing slowly";
        } else if (parseInt(diff) === 1) {
            return "Glucose is changing slowly";
        } else if (parseInt(diff) === 2) {
            return "Glucose is rising";
        } else if (parseInt(diff) === -2) {
            return "Glucose is falling";
        } else if (parseInt(diff) < -2) {
            return "Glucose is falling quickly";
        } else if (parseInt(diff) > 2) {
            return "Glucose is rising quickly";
        } else if (diff !== "NA") {
            return "Glucose is changing slowly";
        }
    }
    render() {
        const { classes } = this.props;
        const patientName = localStorage.getItem("loginType") === "Caregiver" ? this.state.patientName : localStorage.getItem("first_name") + " " + localStorage.getItem("last_name");
        const patientFirstName = localStorage.getItem("loginType") === "Caregiver" ? this.state.patientFirstName : localStorage.getItem("first_name");
        const memberID = localStorage.getItem("memberID");
        const patientEmail = localStorage.getItem("loginType") === "Caregiver" ? this.state.patientEmail : localStorage.getItem("email");
        // Glucose reading for table data
        var count = 0;
        const glucoseReading = this.state.readings.map((data, i) => {
            const { reading, calibrated_value, reading_time, reading_diff, calibrated_diff, glucose_unit } = data;
            count++;

            //console.log("------------reading---->" + reading + "====glucose_unit : " + this.state.glucose_unit)
            CommanProfile.Global_GlucoseType = glucose_unit;
            localStorage.setItem("glucose_unit", glucose_unit);

            // console.log("------------reading---->" + reading)
            // console.log("------------calibrated_value---->" + calibrated_value)
            // console.log("------------reading_time---->" + reading_time)
            // console.log("------------reading_diff---->" + reading_diff)
            // console.log("------------glucose_unit---->" + glucose_unit)
            // console.log("------------data---->" + JSON.stringify(data))


            //return { id: count, reading: reading + (reading !== "NA" ? " mg/dL" : ""), reading_icon: this.getNewDifference(reading_diff), calibrated_value: calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" && calibrated_value !== null ? " mmol/L" : ""), calibrated_icon: calibrated_diff !== null ? this.getNewDifference(calibrated_diff) : "", reading_time: new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25) };

            //console.log("------------reading_diff---->" + { id: count, reading: getGlucoseValue(parseInt(reading), this.state.glucose_unit) + " " + this.state.glucose_unit, reading_icon: this.getNewDifference(reading_diff), calibrated_value: calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" && calibrated_value !== null ? " mmol/L" : ""), calibrated_icon: calibrated_diff !== null ? this.getNewDifference(calibrated_diff) : "", reading_time: new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25) });


            //return { id: count, reading: getGlucoseValue(parseInt(reading), this.state.glucose_unit) + " " + this.state.glucose_unit, reading_icon: this.getNewDifference(reading_diff), calibrated_value: calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" && calibrated_value !== null ? " mmol/L" : ""), calibrated_icon: calibrated_diff !== null ? this.getNewDifference(calibrated_diff) : "", reading_time: new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25) };

            //   return { id: count, reading: getGlucoseValue(parseInt(reading), glucose_unit) + " " + glucose_unit, reading_icon: this.getNewDifference(reading_diff), calibrated_value: calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" && calibrated_value !== null ? " mmol/L" : ""), calibrated_icon: calibrated_diff !== null ? this.getNewDifference(calibrated_diff) : "", reading_time: new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25) };

            return {
                id: count,
                reading: getGlucoseValuestr(parseInt(reading), glucose_unit),
                reading_icon: this.getNewDifference(reading_diff),
                calibrated_value: getGlucoseValuestr(parseInt(calibrated_value), glucose_unit),
                calibrated_icon: calibrated_diff !== null ? this.getNewDifference(calibrated_diff) : "",
                reading_time: new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25)
            };


            // return getGlucoseValue(parseInt(reading), glucose_unit)+" " + glucose_unit;
        });

        const glucoseCSVReadingExportedTime = this.state.readingsAfterExported.map((data, i) => {
            const { reading, reading_time, nightrider_serial_id, calibrated_value } = data;
            return [
                //'Device',
                'BluCon',
                // 'Serial Number',
                nightrider_serial_id,
                // 'Device Timestamp',
                formatTimestampForExcepExport(parseInt(reading_time === null ? 0 : reading_time, 10)),
                // 'Record Type',
                '0',
                // 'Historic Glucose mg/dL',
                calibrated_value > 0 ? getGlucoseValue(parseInt(calibrated_value)) : getGlucoseValue(parseInt(reading)),
                // 'Scan Glucose mg/dL',
                '',
                // 'Non-numeric Rapid-Acting Insulin',
                '',
                // 'Rapid-Acting Insulin (units)',
                '',
                // 'Non-numeric Food',
                '',
                // 'Carbohydrates (grams)',
                '',
                // 'Carbohydrates (servings)',
                '',
                // 'Non-numeric Long-Acting Insulin',
                '',
                // 'Long-Acting Insulin (units)',
                '',
                // 'Notes',
                '',
                // 'Strip Glucose mg/dL',
                '',
                // 'Ketone mmol/L',
                '',
                // 'Meal Insulin (units)',
                '',
                // 'Correction Insulin (units)',
                '',
                // 'User Change Insulin (units)',
                ''
            ];

        });

        var countCSV = 0;
        const glucoseCSVReading = this.state.readings.map((data, i) => {
            const { reading, reading_time, nightrider_serial_id, calibrated_value } = data;
            countCSV++;

            // console.log("------------reading-ff--->" + [countCSV, reading + (reading !== "NA" ? " mg/dL" : ""), this.getCSVDifferenceText(reading_diff), calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" ? " mmol/L" : ""), this.getCSVDifferenceText(calibrated_diff), new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25)]);

            //01-Jun-2022 Updated
            //getGlucoseValue(parseInt(reading), this.state.glucose_unit)
            //return [countCSV, reading + (reading !== "NA" ? " mg/dL" : ""), this.getCSVDifferenceText(reading_diff), calibrated_value + (calibrated_value !== "NA" && calibrated_value !== "" && calibrated_value !== "null" ? " mmol/L" : ""), this.getCSVDifferenceText(calibrated_diff), new Date(parseInt(reading_time === null ? 0 : reading_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25)];

            return [
                //'Device',
                'BluCon',
                // 'Serial Number',
                nightrider_serial_id,
                // 'Device Timestamp',
                formatTimestampForExcepExport(parseInt(reading_time === null ? 0 : reading_time, 10)),
                // 'Record Type',
                '0',
                // 'Historic Glucose mg/dL',
                calibrated_value > 0 ? getGlucoseValue(parseInt(calibrated_value)) : getGlucoseValue(parseInt(reading)),
                // 'Scan Glucose mg/dL',
                '',
                // 'Non-numeric Rapid-Acting Insulin',
                '',
                // 'Rapid-Acting Insulin (units)',
                '',
                // 'Non-numeric Food',
                '',
                // 'Carbohydrates (grams)',
                '',
                // 'Carbohydrates (servings)',
                '',
                // 'Non-numeric Long-Acting Insulin',
                '',
                // 'Long-Acting Insulin (units)',
                '',
                // 'Notes',
                '',
                // 'Strip Glucose mg/dL',
                '',
                // 'Ketone mmol/L',
                '',
                // 'Meal Insulin (units)',
                '',
                // 'Correction Insulin (units)',
                '',
                // 'User Change Insulin (units)',
                ''
            ];

        });

        // Notes data for timeline chart
        var notesCount = 1;
        const stories = this.state.notes.map((notes, i) => {
            notesCount++;
            const color = notes.note === "0" || notes.note === "7" ? "warning" : notes.note === "1" || notes.note === "2" ? "info" : notes.note === "3" ? "success" : notes.note === "4" ? "rose" : notes.note === "5" ? "danger" : notes.note === "6" ? "primary" : "gray";
            const title = notes.note === "0" || notes.note === "7" ? "Food" : notes.note === "1" ? "Rapid-Acting Insulin" : notes.note === "2" ? "Long-Acting Insulin" : notes.note === "3" ? "Exercise" : notes.note === "4" ? "Comment" : notes.note === "5" ? "Blood Glucose" : notes.note === "6" ? "Medicine" : false;
            const icon = notes.note === "0" || notes.note === "7" ? "utensils" : notes.note === "1" || notes.note === "2" ? "syringe" : notes.note === "3" ? "running" : notes.note === "4" ? "comment-alt" : notes.note === "5" ? "tint" : notes.note === "6" ? "capsules" : false;
            const noteBody = notes.note === "0" ? `${notes.carbs_servings} ${notes.unit} in ${notes.food === "0" ? "Brakfast" : notes.food === "1" ? "Lunch" : notes.food === "2" ? "Dinner" : notes.food === "3" ? "Snack" : ""}` : notes.note === "1" ? `${notes.RAI} Units` : notes.note === "2" ? `${notes.LAI} Units` : notes.note === "3" ? `${notes.excercise === "0" ? "Low Intensity" : notes.excercise === "1" ? "Medium Intensity" : notes.excercise === "2" ? "High Intensity" : ""} ${notes.exc_time}` : notes.note === "4" ? `${notes.comments}` : notes.note === "5" ? this.getReading(notes.blood_glucose) : notes.note === "6" ? `${notes.medicine}` : notes.note === "7" ? `${notes.food_name} ${Math.round(notes.food_calories)}` : false;
            return {
                inverted: notesCount % 2 ? false : true,
                badgeColor: color,
                badgeIcon: icon,
                title: title,
                titleColor: color,
                body: noteBody,
                footerTitle: `${new Date(parseInt(notes.date_time === null ? 0 : notes.date_time, 10)).toString("MM/dd/yy HH:mm:ss").substring(0, 25)}`,
            };
        });

        return this.state.isPrintDualGraph ? (
            <Redirect
                to={{
                    pathname: "/dual-graph-report",
                    state: {
                        data: this.state.dualChartData,
                        options: this.state.dualGraphOption,
                        type: "chart",
                        patientName: patientName,
                        patientEmail: patientEmail,
                    },
                }}
            />
        ) : (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.cardContentLeft} style={{ display: "flex", alignItems: "center" }}>
                            <Button id="oneDay" disabled={this.state.isButtonDisabled} color="info" className={classes.marginRight} onClick={(e) => this.changeInterval("oneDay")}>
                                Last 24 Hours
                            </Button>
                            <Button id="sevenDays" disabled={this.state.isButtonDisabled} color="info" className={classes.marginRight} onClick={(e) => this.changeInterval("sevenDays")}>
                                Last 7 Days
                            </Button>
                            <Button id="thirtyDays" disabled={this.state.isButtonDisabled} color="info" className={classes.marginRight} onClick={(e) => this.changeInterval("thirtyDays")}>
                                Last 30 Days
                            </Button>
                            <DateRangePicker dateLimit={{ days: 90 }} maxDate={this.state.maxDate} onApply={this.state.isButtonDisabled ? false : this.handleEvent}>
                                <Button id="datePicker" disabled={this.state.isButtonDisabled} color="info">
                                    <FontAwesomeIcon icon={"calendar"} size="2x" />
                                </Button>
                            </DateRangePicker>
                            {
                                glucoseCSVReading.length > 0
                                    ?
                                    <GridItem xs={12} sm={12} md={12} lg={3}>
                                        <CSVLink data={[...csvHeaders, ...glucoseCSVReading]}
                                            headers={['Glucose Data', 'Generated on', getCurrentSystemDateTime(), 'Generated by', this.state.patientName]} filename={memberID + '-' + patientFirstName + "-" + getCurrentSystemDate() + ".csv"}
                                            enclosingCharacter={``}
                                            separator={','}>
                                            <Button color="info" onClick={() => this.setExportDate()}>
                                                Export Data
                                            </Button>
                                        </CSVLink>
                                    </GridItem>
                                    :
                                    <GridItem xs={12} sm={12} md={12} lg={3}>
                                        <Button color="info" disabled='true'>
                                            Export Data
                                        </Button>
                                    </GridItem>
                            }
                            {localStorage.getItem("companyLastUpdatedTime") > 0
                                &&
                                glucoseCSVReadingExportedTime.length > 0
                                ?
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CSVLink data={[...csvHeaders, ...glucoseCSVReadingExportedTime]}
                                        headers={['Glucose Data', 'Generated on', getCurrentSystemDateTime(), 'Generated by', this.state.patientName]} filename={memberID + '-' + patientFirstName + "-" + getCurrentSystemDate() + ".csv"}
                                        enclosingCharacter={``}
                                        separator={','}>
                                        <Button color="info" onClick={() => this.setExportDate()}>
                                            Export Data since last exported time {
                                                formatTimestampForExcepExport(localStorage.getItem("companyLastUpdatedTime"))
                                            }
                                        </Button>
                                    </CSVLink>
                                </GridItem>
                                :
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Button color="info" disabled='true'>
                                        Export Data since last exported time {
                                            localStorage.getItem("companyLastUpdatedTime") > 0 ?
                                                formatTimestampForExcepExport(localStorage.getItem("companyLastUpdatedTime"))
                                                : ''
                                        }
                                    </Button>
                                </GridItem>
                            }
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card style={{ background: "black" }}>
                            <CardHeader>
                                <h4 style={{ color: "white" }}>
                                    Glucose Reading <small style={{ textTransform: "uppercase", color: "white" }}>({patientName} {memberID})</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    color="primary"
                                    tabs={[
                                        {
                                            tabButton: "Graphical",
                                            tabContent: this.state.isLoadingGraph ? (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card plain>
                                                        <CardBody style={{ height: "100px" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                            ) : (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card plain style={{ background: "black" }}>
                                                        {this.state.chartData.length > 1 ? (
                                                            <CardBody>
                                                                <Chart
                                                                    width={"100%"}
                                                                    height={"400px"}
                                                                    chartType={this.state.is24HourGraph === true ? "LineChart" : "AreaChart"}
                                                                    data={this.state.chartData}
                                                                    options={this.state.graphOptions}
                                                                />
                                                            </CardBody>
                                                        ) : (
                                                            <CardBody style={{ height: "100px" }}>
                                                                <h4
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "white"
                                                                    }}
                                                                >
                                                                    There are no glucose readings available for this period.
                                                                </h4>
                                                            </CardBody>
                                                        )}
                                                    </Card>
                                                </GridItem>
                                            ),
                                        },
                                        {
                                            tabButton: "Tabular",
                                            tabContent:
                                                this.state.isLoadingTabular && glucoseReading.length > 0 ? (
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Card plain>
                                                            <CardBody style={{ height: "100px" }}>
                                                                <CircularProgress
                                                                    style={{
                                                                        position: "absolute",
                                                                        left: "50%",
                                                                        top: "50%",
                                                                    }}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </GridItem>
                                                ) : (
                                                    <div>
                                                        {glucoseReading.length > 0 ? (
                                                            <Card plain>
                                                                <CardBody>
                                                                    <PerfectScrollbar>
                                                                        <ReactTable
                                                                            data={glucoseReading}
                                                                            filterable
                                                                            columns={[
                                                                                {
                                                                                    width: 300,
                                                                                    Header: "ID",
                                                                                    accessor: "id",
                                                                                    filterable: false,
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    width: 90,
                                                                                    Header: "Reading",
                                                                                    accessor: "reading",
                                                                                    //here fix duplicate glucose_unit display with value
                                                                                    //Cell: (row) => <div style={{ textAlign: "right" }}>{row.value + "" + this.state.glucose_unit}</div>,
                                                                                    Cell: (row) => <div style={{ textAlign: "left" }}>{row.value + ""}</div>,
                                                                                    filterable: false,
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    width: 200,
                                                                                    Header: "Flow",
                                                                                    accessor: "reading_icon",
                                                                                    filterable: false,
                                                                                    style: { alignItems: 'left', self: 'left' },
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    width: 200,
                                                                                    Header: "Calibrated Reading",
                                                                                    accessor: "calibrated_value",
                                                                                    //Cell: (row) => <div style={{ textAlign: "right" }}>{row.value}</div>,
                                                                                    Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
                                                                                    filterable: false,
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    width: 90,
                                                                                    Header: "Flow",
                                                                                    accessor: "calibrated_icon",
                                                                                    filterable: false,
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                                {
                                                                                    Header: "Reading Time",
                                                                                    accessor: "reading_time",
                                                                                    filterable: false,
                                                                                    sortable: false,
                                                                                    style: { color: "white" }
                                                                                },
                                                                            ]}
                                                                            defaultPageSize={10}
                                                                            showPaginationTop
                                                                            showPaginationBottom={false}
                                                                            className="-highlight"
                                                                            getTheadThProps={() => {
                                                                                return {
                                                                                    style: {
                                                                                        color: "white", // Text color
                                                                                        backgroundColor: "#000" // Background color
                                                                                    }
                                                                                };
                                                                            }}
                                                                        />
                                                                    </PerfectScrollbar>
                                                                </CardBody>
                                                            </Card>
                                                        ) : (
                                                            <CardBody style={{ height: "100px" }}>
                                                                <h4
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "white"
                                                                    }}
                                                                >
                                                                    There are no glucose readings available for this period.
                                                                </h4>
                                                            </CardBody>
                                                        )}
                                                    </div>
                                                ),
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card style={{ background: "black" }}>
                            <CardHeader>
                                <h4 style={{ color: "white" }}>
                                    Notes <small style={{ textTransform: "uppercase", color: "white" }}>({patientName} {memberID})</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    color="primary"
                                    tabs={[
                                        {
                                            tabButton: "Graphical",
                                            tabContent: this.state.isLoadingDualGraph ? (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card plain>
                                                        <CardBody style={{ height: "100px" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                            ) : (
                                                <GridItem xs={12} sm={12} md={12} id="DualGraphData">
                                                    <Card plain style={{ background: "black" }}>
                                                        {this.state.dualChartData.length > 1 ? (
                                                            <CardBody>
                                                                <GridContainer justify="flex-end">
                                                                    <GridItem xs={12} sm={1} md={1} lg={1}>
                                                                        <Button style={{ borderRadius: "30px" }} color="info" className={classes.marginRight} onClick={() => this.printDualGraph()}>
                                                                            <FontAwesomeIcon icon={"print"} size="2x" />
                                                                        </Button>
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={2} md={2} lg={2}>
                                                                        <CustomDropdown
                                                                            hoverColor="info"
                                                                            buttonText={this.state.notesDropdownValue}
                                                                            buttonProps={{
                                                                                round: true,
                                                                                fullWidth: true,
                                                                                style: { marginBottom: "0" },
                                                                                color: "info",
                                                                            }}
                                                                            id="secondYaxis"
                                                                            dropdownList={[
                                                                                <p
                                                                                    style={{
                                                                                        display: "block",        // Makes the <p> behave like an <li>
                                                                                        color: "white",
                                                                                        width: "100%",
                                                                                        margin: 0,               // Removes any default margin
                                                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                        boxSizing: "border-box",
                                                                                    }}
                                                                                    onClick={(e) => this.handleNotesDropdownChange("Food Calories")}>Food Calories</p>,
                                                                                <p
                                                                                    style={{
                                                                                        display: "block",        // Makes the <p> behave like an <li>
                                                                                        color: "white",
                                                                                        width: "100%",
                                                                                        margin: 0,               // Removes any default margin
                                                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                        boxSizing: "border-box",
                                                                                    }}
                                                                                    onClick={(e) => this.handleNotesDropdownChange("Blood Glucose")}>Blood Glucose</p>,
                                                                                <p
                                                                                    style={{
                                                                                        display: "block",        // Makes the <p> behave like an <li>
                                                                                        color: "white",
                                                                                        width: "100%",
                                                                                        margin: 0,               // Removes any default margin
                                                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                        boxSizing: "border-box",
                                                                                    }}
                                                                                    onClick={(e) => this.handleNotesDropdownChange("Rapid-Acting Insulin")}>Rapid-Acting Insulin</p>,
                                                                                <p
                                                                                    style={{
                                                                                        display: "block",        // Makes the <p> behave like an <li>
                                                                                        color: "white",
                                                                                        width: "100%",
                                                                                        margin: 0,               // Removes any default margin
                                                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                        boxSizing: "border-box",
                                                                                    }}
                                                                                    onClick={(e) => this.handleNotesDropdownChange("Long-Acting Insulin")}>Long-Acting Insulin</p>,
                                                                                <p
                                                                                    style={{
                                                                                        display: "block",        // Makes the <p> behave like an <li>
                                                                                        color: "white",
                                                                                        width: "100%",
                                                                                        margin: 0,               // Removes any default margin
                                                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                                                        boxSizing: "border-box",
                                                                                    }}
                                                                                    onClick={(e) => this.handleNotesDropdownChange("Exercise")}>Exercise</p>]}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={1} md={1} lg={1} />
                                                                </GridContainer>
                                                                <Chart
                                                                    width={"100%"}
                                                                    height={"400px"}
                                                                    chartType="LineChart"
                                                                    data={this.state.dualChartData}
                                                                    options={this.state.dualGraphOption} />
                                                            </CardBody>
                                                        ) : (
                                                            <CardBody style={{ height: "100px" }}>
                                                                <h4
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "white"
                                                                    }}
                                                                >
                                                                    There are no notes & glucose readings available for this period.
                                                                </h4>
                                                            </CardBody>
                                                        )}
                                                    </Card>
                                                </GridItem>
                                            ),
                                        },
                                        {
                                            tabButton: "Timeline",
                                            tabContent: this.state.isLoadingTimeline ? (
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card plain>
                                                        <CardBody style={{ height: "100px" }}>
                                                            <CircularProgress
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                }}
                                                            />
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                            ) : (
                                                <GridItem xs={12}>
                                                    <Card plain>
                                                        {stories.length > 1 ? (
                                                            <CardBody style={{ height: "500px" }}>
                                                                <PerfectScrollbar>
                                                                    <Timeline stories={stories} />
                                                                </PerfectScrollbar>
                                                            </CardBody>
                                                        ) : (
                                                            <CardBody style={{ height: "100px" }}>
                                                                <h4
                                                                    style={{
                                                                        textAlign: "center",
                                                                        color: "white"
                                                                    }}
                                                                >
                                                                    There are no notes available for this period.
                                                                </h4>
                                                            </CardBody>
                                                        )}
                                                    </Card>
                                                </GridItem>
                                            ),
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(buttonsStyle)(Patient);
