import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import md5 from "md5";
import moment from "moment";
import { BASE_URL } from "views/Utiles/Constants";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

class CaregiverRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            tc: false,
            isLogin: false,
            notificationMessage: "",
            first_name: "",
            last_name: "",
            current_time: new Date(),
            email: "",
            password: "",
            confirm_password: "",
            dob: new Date().valueOf(),
            country: "",
            userType: "patient",
            maxDate: moment(),
            errors: {},
            loginError: false,
            isLoading: false,
        };
        this.onChange = this.onChange.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.onSubmitRegistrationAttempt = this.onSubmitRegistrationAttempt.bind(this);
        this.formatTimestamp = this.formatTimestamp.bind(this);
        this.formatDateTime = this.formatDateTime.bind(this);
    }
    componentDidMount() {
        document.title = "Caregiver Registration - Ambrosia Care";
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    handleEvent(event, picker) {
        this.setState({ dob: picker.startDate.valueOf() });
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    componentWillReceiveProps() {
        if (localStorage.token) {
            window.location.reload();
        }
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSubmitRegistrationAttempt(e) {
        e.preventDefault();

        const userData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            dob: this.formatTimestamp(new Date(this.state.dob)),
            country: this.state.country,
            email: this.state.email,
            password: md5(this.state.password),
            signup_dtm: this.formatDateTime(this.state.current_time),
            platform_name: "WEB",
        };

        this.setState({ isLoading: true });
        if (this.state.first_name === "" || this.state.last_name === "" || this.state.dob === "" || this.state.country === "" || this.state.email === "" || this.state.password === "" || this.state.confirm_password === "") {
            this.setState({ notificationMessage: "Please fill all the details." }, () => {
                this.showNotification("tc_danger");
                this.setState({ isLoading: false });
            });
        } else if (this.state.password !== this.state.confirm_password) {
            this.setState({ notificationMessage: "Password does not match." }, () => {
                this.showNotification("tc_danger");
                this.setState({ isLoading: false });
            });
        } else {
            $.ajax({
                method: "POST",
                url: BASE_URL + "/sign-up",
                data: userData,
                success: function (data) {
                    var DataJSON = JSON.parse(data);

                    if (DataJSON.success === false) {
                        this.setState({ isLoading: false });

                        if (DataJSON.msg.includes("Invalid email format.")) {
                            this.setState({ notificationMessage: "Invalid email format." }, () => {
                                this.showNotification("tc_danger");
                            });
                        } else if (DataJSON.msg.includes("Invalid data or already registered email.")) {
                            this.setState({ notificationMessage: "Invalid data or already registered email." }, () => {
                                this.showNotification("tc_danger");
                            });
                        } else if (DataJSON.msg.includes("Something went wrong or duplicate email.")) {
                            this.setState({ notificationMessage: "Something went wrong or duplicate email." }, () => {
                                this.showNotification("tc_danger");
                            });
                        }
                    } else {
                        this.setState({ notificationMessage: " Caregiver Added." }, () => {
                            this.showNotification("tc_info");
                            this.setState({ isLoading: false });
                            // this.props.history.push("/");
                        });
                        this.setState({ first_name: "", last_name: "", country: "", email: "", password: "", confirm_password: "" });
                    }
                }.bind(this),
                error: (error) => {
                    this.setState({ isLoading: false });
                    this.setState({ loginError: "Somthing went wrong while authentaction. Please try again." });
                    console.error(error);
                },
            });
        }
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    formatDateTime(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            second = "" + date.getSeconds(),
            millisecond = "" + date.getMilliseconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;
        if (millisecond.length < 2) millisecond = "00" + millisecond;
        return `${year}-${month}-${day} ${hours}:${minute}:${second}`;
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return `${year}-${month}-${day}`;
    }
    render() {
        const { classes, ...rest } = this.props;
        return this.state.isLogin ? (
            <Redirect to="/" />
        ) : this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                <AuthNavbar brandText="Ambrosia Care" {...rest} />
                <GridItem xs={12} sm={12} md={3}>
                    <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                    <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                </GridItem>
                <div className={classes.wrapper} ref="wrapper">
                    <div className={classes.fullPage}>
                        <GridContainer style={{ width: "100%" }} justify="center">
                            <GridItem xs={12} sm={9} md={3}>
                                <form id="registration-form" onSubmit={this.onSubmitRegistrationAttempt}>
                                    <Card login className={classes[this.state.cardAnimaton]} style={{ background: "black" }}>
                                        <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="success">
                                            <h4 className={classes.cardTitle}>Sign-up Caregiver Account</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        labelText="First Name"
                                                        id="first_name"
                                                        inputProps={{
                                                            name: "first_name",
                                                            value: this.state.first_name,
                                                            onChange: (e) => {
                                                                this.onChange(e);
                                                            },
                                                        }}
                                                        onChange={this.onChange}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        labelText="Last Name"
                                                        id="last_name"
                                                        inputProps={{
                                                            name: "last_name",
                                                            value: this.state.last_name,
                                                            onChange: (e) => {
                                                                this.onChange(e);
                                                            },
                                                        }}
                                                        onChange={this.onChange}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridItem style={{ display: "flex", alignItems: "center" }} xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        labelText="Birth"
                                                        id="dob"
                                                        inputProps={{
                                                            name: "dob",
                                                            value: `${this.formatTimestamp(new Date(this.state.dob))}`,
                                                            onChange: (e) => {
                                                                this.onChange(e);
                                                            },
                                                            disabled: true,
                                                        }}
                                                        formControlProps={{
                                                            onChange: this.handleInputChange,
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                    <DateRangePicker maxDate={this.state.maxDate} onApply={this.handleEvent} singleDatePicker={true} showDropdowns={true}>
                                                        <FontAwesomeIcon icon={"calendar"} size="lg" />
                                                    </DateRangePicker>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <CustomInput
                                                        labelText="Country"
                                                        id="country"
                                                        inputProps={{
                                                            name: "country",
                                                            value: this.state.country,
                                                            onChange: (e) => {
                                                                this.onChange(e);
                                                            },
                                                        }}
                                                        onChange={this.onChange}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>

                                            <CustomInput
                                                labelText="Email"
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                onChange={this.onChange}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    name: "email",
                                                    value: this.state.email,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    name: "password",
                                                    type: "password",
                                                    value: this.state.password,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Confirm Password"
                                                id="confirm_password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    name: "confirm_password",
                                                    type: "password",
                                                    value: this.state.confirm_password,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />
                                        </CardBody>
                                        <CardFooter className={classes.justifyContentCenter}>
                                            <Button type="submit" color="primary" simple size="lg" block>
                                                Register
                                            </Button>
                                        </CardFooter>
                                        <h5 style={{ display: "flex", alignItems: "center", marginLeft: "130px" }}>
                                            Already have an account?
                                            <Button
                                                color="primary"
                                                simple
                                                style={{ width: "60px" }}
                                                onClick={() => {
                                                    this.setState({ isLogin: true });
                                                }}
                                            >
                                                Sign-in
                                            </Button>
                                        </h5>
                                    </Card>
                                </form>
                            </GridItem>
                        </GridContainer>
                        <Footer white />
                    </div>
                </div>
            </div>
        );
    }
}

CaregiverRegistration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle, loginPageStyle)(CaregiverRegistration);
