import React from "react";
import $ from "jquery";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
// import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// import CardIcon from "components/Card/CardIcon.jsx";
// import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";

class ReportHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            clinic: "",
            country: "",
            logo: null,
            notificationMessage: "",
            caregiverHeaderData: {},
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleImageRemove = this.handleImageRemove.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.showNotification = this.showNotification.bind(this);
        // this.getCaregiverHeaderData = this.getCaregiverHeaderData.bind(this);
    }

    componentDidMount() {
        document.title = "Report Header - Ambrosia Care";
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: localStorage.getItem("user_id"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-caregiver-header",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.message.includes("No data found")) {
                        this.showNotification("tc");
                    }
                } else {
                    var name = DataJSON.data.caregiver_name.split(" ");
                    this.setState({ caregiverHeaderData: DataJSON.data, firstName: name[0], lastName: name[1], email: DataJSON.data.caregiver_email, clinic: DataJSON.data.caregiver_clinic, country: DataJSON.data.caregiver_country, logo: DataJSON.data.caregiver_logo });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    handleImageSelect(image) {
        this.setState({
            logo: image,
        });
    }
    handleImageRemove() {
        this.setState({
            logo: null,
        });
    }

    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }

    handleSubmitForm() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        const { firstName, lastName, email, country, clinic, logo } = this.state;
        const formData = new FormData();
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
            caregiver_id: localStorage.getItem("user_id"),
            caregiver_name: `${firstName} ${lastName}`,
            caregiver_email: email,
            caregiver_clinic: clinic,
            caregiver_country: country,
            caregiver_logo: logo,
        };
        Object.keys(data).forEach(function (key, index) {
            formData.append(key, data[key]);
        });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-update-caregiver-header",
            data: formData,
            processData: false,
            contentType: false,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ notificationMessage: "Something went wrong while saving details." }, () => {
                        this.showNotification("tc_danger");
                    });
                    // this.showNotification("tc");
                } else {
                    this.setState({ notificationMessage: "Details saved." }, () => {
                        this.showNotification("tc_info");
                    });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Card style={{ background: "black" }}>
                            <CardHeader color="info" icon>
                                {/* <CardIcon color="info">
                                    <PermIdentity />
                                </CardIcon> */}
                                <h4 className={classes.cardIconTitle} style={{ color: "white" }}>
                                    Header Details - <small>These details will be used in the data report generated through your account</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="First Name"
                                            id="firstName"
                                            inputProps={{
                                                value: `${this.state.firstName}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Last Name"
                                            id="lastName"
                                            inputProps={{
                                                value: `${this.state.lastName}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Email"
                                            id="email"
                                            inputProps={{
                                                value: `${this.state.email}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: true,
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Clinic"
                                            id="clinic"
                                            inputProps={{
                                                value: `${this.state.clinic}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: true,
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Country"
                                            id="country"
                                            inputProps={{
                                                value: `${this.state.country}`,
                                            }}
                                            formControlProps={{
                                                onChange: this.handleInputChange,
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <ImageUpload
                                            caregiverLogo={{
                                                value: `${this.state.logo}`,
                                            }}
                                            addButtonProps={{
                                                color: "info",
                                                round: true,
                                            }}
                                            changeButtonProps={{
                                                color: "info",
                                                round: true,
                                            }}
                                            removeButtonProps={{
                                                color: "danger",
                                                round: true,
                                            }}
                                            handleImageSelect={this.handleImageSelect}
                                            handleImageRemove={this.handleImageRemove}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <Button color="info" onClick={this.handleSubmitForm} className={classes.updateProfileButton}>
                                    Save
                                </Button>
                                <Clearfix />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(userProfileStyles)(ReportHeader);
