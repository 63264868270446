import React from "react";
// import { Redirect } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import PatientAppleHealtECG from "./PatientAppleHealtECG";

class patientECGDataSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            isECGDataLoading: false,
            isLoading: false,
            maxDate: moment(),
            isECGGraphData: false,
            patients: [],
            begin_date: new Date().valueOf() - 1 * 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            ecgChartData: [],
            starting_date: [],
            patient_id: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("user_id"),
            patient_email: localStorage.getItem("loginType") === "Caregiver" ? "" : localStorage.getItem("email"),
            patient_name: localStorage.getItem("loginType") === "Caregiver" ? "" : `${localStorage.getItem("first_name").toUpperCase()} ${localStorage.getItem("last_name").toUpperCase()}`,
        };
        this.showNotification = this.showNotification.bind(this);
        this.getECGData = this.getECGData.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handlePatientDropdownChange = this.handlePatientDropdownChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    componentDidMount() {
        document.title = "Patient ECG Report - Ambrosia Care";
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        if (localStorage.getItem("loginType") === "Caregiver") {
            const followers = localStorage.getItem("my-followers");
            if (followers) this.setState({ patients: JSON.parse(followers) });
        }
    }

    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
    }

    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name} ${e.last_name}`,
            patient_email: e.email,
        });
    }

    getECGData() {
        this.setState({ isECGDataLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-ecg-data",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("loginType") === "Caregiver" ? this.state.patient_id : localStorage.getItem("user_id"),
                begin_date: this.state.begin_date,
                end_date: this.state.end_date,
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ ecgChartData: [] });
                    }
                    this.showNotification("tc");
                } else if (DataJSON.success === true) {
                    var dataset = [];
                    const ecgChartData = DataJSON.data.map((data, index) => {
                        dataset[index] = data.reverse().map((response) => {
                            return [this.formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.voltageQuantity)];
                        });
                        dataset[index][0] = ["Time", "data"];
                        this.state.starting_date.push(dataset[index][1][0]);

                        return dataset[index];
                    });
                    this.setState({ ecgChartData: ecgChartData, isPatientSelect: true, isECGGraphData: true });
                }
                this.setState({ isECGDataLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            second = "" + date.getSeconds(),
            millisecond = "" + date.getMilliseconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;
        if (millisecond.length < 2) millisecond = "00" + millisecond;
        return `${day}-${month}-${year} ${hours}:${minute}:${second}:${millisecond}`;
    }

    handleBackButton() {
        this.setState({
            ecgChartData: [],
            isPatientSelect: false,
        });
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    render() {
        const { classes } = this.props;

        return this.state.isPatientSelect && this.state.isECGGraphData ? (
            <PatientAppleHealtECG handleBackButton={this.handleBackButton} starting_date={this.state.starting_date} ecgChartData={this.state.ecgChartData} patientName={this.state.patient_name} begin_date={this.state.begin_date} end_date={this.state.end_date} />
        ) : (
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <Snackbar place="tc" color="warning" message="Data are not available for selected date." open={this.state.tc} closeNotification={() => this.setState({ tc: false })} close />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Card style={{ background: "#000000" }}>
                        <CardHeader color="info" icon>
                            <h4 className={classes.cardIconTitle} style={{ color: "white" }}>Select Patient & Date</h4>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "white" }}>
                                <b>Patient Name:</b> {this.state.patient_name}
                            </h5>
                            <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "white" }}>
                                <b>Date Frame:</b>
                                {`${new Date(this.state.begin_date).toLocaleDateString()} To ${new Date(this.state.end_date).toLocaleDateString()}`}
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                    <CustomDropdown
                                        hoverColor="info"
                                        buttonText={this.state.patient_name === "" ? "Select Patient" : this.state.patient_name}
                                        buttonProps={{
                                            round: true,
                                            fullWidth: true,
                                            style: { marginBottom: "0", width: "300px" },
                                            color: "info",
                                        }}
                                        id="secondYaxis"
                                        dropdownList={this.state.patients.map((e, i) => (
                                            <p
                                                style={{
                                                    display: "block",        // Makes the <p> behave like an <li>
                                                    color: "white",
                                                    width: "100%",
                                                    margin: 0,               // Removes any default margin
                                                    padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                    boxSizing: "border-box",
                                                }}
                                                onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                                {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                            </p>
                                        ))}
                                    />
                                    <DateRangePicker dateLimit={{ days: 1 }} maxDate={this.state.maxDate} onApply={this.handleEvent}>
                                        <Button id="datePicker" style={{ marginRight: "1rem", marginLeft: "1rem" }} color="info">
                                            <FontAwesomeIcon icon={"calendar"} size="2x" />
                                        </Button>
                                    </DateRangePicker>
                                    <Button
                                        color="info"
                                        disabled={this.state.patient_name === ""}
                                        onClick={() => {
                                            this.getECGData();
                                        }}
                                    >
                                        Show ECG Graph
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

export default withStyles(validationFormsStyle)(patientECGDataSelection);
