import { Modal, Button } from "react-bootstrap";
import React, { Component } from "react";
import ReactTable from "react-table";

class ShowReportedUsers extends Component {
    render() {
        const columns = [
            {
                Header: "Reported Email",
                accessor: "email",
            },
            {
                Header: "Reason",
                accessor: "reason",
            },
        ];
        return (
            <div>
                <Modal {...this.props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <ReactTable data={this.props.data} columns={columns} defaultPageSize={5} showPaginationTop showPaginationBottom={false} className="-striped -highlight" />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default ShowReportedUsers;
