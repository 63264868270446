import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import ReactTable from "react-table";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import ShowReportedUsers from "../Modal/ShowReportedUsers";
import Switch from "@material-ui/core/Switch";
// import Button from "components/CustomButtons/Button.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { browserHistory, Router, Route } from 'react-router';
import { Router, Route, Redirect } from "react-router-dom";
//import CreateIcon from "@material-ui/icons/Create";
//import AdminLayout from "./layouts/Admin";
import AdminLayout from "../../layouts/Admin";
import { Group, Label } from "@material-ui/icons";
import caregiverList from "./caregiver-list";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Close from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { Editor } from "@tinymce/tinymce-react";
import { TextField } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});




class subscriptions extends React.Component {




    constructor(props) {
        super(props);

        this.state = {
            tc: false,
            diabuddyArry: [],
            diabuddyReportedArry: [],
            isLoading: false,
            isGetDataLoading: false,
            userNotificationMessage: "",
            app_version: "All",
            app_name: "LBC",
            deleteAlert: null,
            user_id: "",
            mAlert: null,
            modalShow: false,
            disable_Qty: "All",
            mId: "",
            SubId: "",
            Price: "$00",
            Qty: "",
            app_name: "LBC",
            // this for add dynamic views, on add click
            Qty1: "",
            Qty2: "",
            Qty3: "",
            Qty4: "",
            mQty: [],
            mFinal: [],
            Nightrider_Type: "None",
            yetNotAdded: true,
            rx_live: "/^[+-]?\d*(?:[.,]\d*)?$/"
        };

        this.handleAdd = this.handleAdd.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.showReportedUsersDetails = this.showReportedUsersDetails.bind(this);
        this.getallsubscriptions = this.getallsubscriptions.bind(this);
        this.setClassicModal = this.setClassicModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChange1 = this.handleInputChange1.bind(this);
        this.handleInputChange2 = this.handleInputChange2.bind(this);
        this.handleInputChange3 = this.handleInputChange3.bind(this);
        this.handleInputChange4 = this.handleInputChange4.bind(this);
        this.handleInputChangeId = this.handleInputChangeId.bind(this);
        this.handleInputChangeSubscriptionId = this.handleInputChangeSubscriptionId.bind(this);

        this.AddSubscription = this.AddSubscription.bind(this)

        this.onChange = this.onChange.bind(this);

    }



    modalClose = () => this.setState({ modalShow: false });
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }

    handleSubmitForm() {

        //alert("Nightrider_Type==>" + this.state.Nightrider_Type + "=Mid=>" + this.state.mId + "=SubId=>" + this.state.SubId + "=Qty=>" + this.state.Qty + "=Qty1=>" + this.state.Qty1 + "=Qty2=>" + this.state.Qty2 + "=Qty3=>" + this.state.Qty3)


        // all issue fix 
        if (this.state.SubId.length != 0) {

            if (this.state.Nightrider_Type != "None") {

                if (this.state.Qty != "") {

                    if (this.state.Qty1 != "") {



                        // this.state.Nightrider_Type = "None"
                        // this.state.Qty = 1;
                        // this.state.Qty1 = 0;
                        // this.state.SubId = 0;

                        this.AddSubscription();
                        this.setClassicModal(false)

                    } else {

                        alert("Enter Serial no")
                    }

                }
                else {

                    alert("Enter Qty")
                }

            } else {
                alert("Select Nightrider Type")
            }
        } else {
            alert("Enter Subscription Id")
        }









        // this.AddSubscription();
        // this.setClassicModal(false)
    }

    // Add Process

    AddSubscription() {

        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }

        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            subscription_id: this.state.SubId,
            nr_type: this.state.Nightrider_Type,
            nr_qty: this.state.Qty,
            nr_serial_no: this.state.Qty1,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-subscription",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ diabuddyArry: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    // this.setState({ diabuddyArry: DataJSON.data, deleteAlert: null });

                    this.state.Nightrider_Type = "None"
                    this.state.Qty = "";
                    this.state.Qty1 = "";
                    this.state.SubId = "";


                    alert("Subscription Sucessfully " + DataJSON.msg)
                    this.getallsubscriptions();
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    // Add process End

    hideAlertAdd() {
        //this.setState({ mAlert: null });
        this.setState({ deleteAlert: null });
    }

    componentDidMount() {
        document.title = "Users Dia Buddy - Ambrosia Care";
        this.getallsubscriptions();
    }

    getallsubscriptions() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }

        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-all-subscriptions",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ diabuddyArry: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ diabuddyArry: DataJSON.data, deleteAlert: null });
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    warningWithConfirmMessageUpdateEnabled(user_id, value) {
        this.setState({
            deleteAlert: <SweetAlert warning style={{ display: "block" }} title="Are you sure?" onConfirm={() => this.updateDiabuddyUserEnableStatus(user_id, value)} onCancel={() => this.hideAlertDelete()} confirmBtnText={value === "0" ? "Yes, Enable" : "Yes, Disable"} cancelBtnText="Cancel" showCancel />,

        });
    }


    AddMessageUpdate(user_id, value) {

        // alert("inProcess")
        this.setState({
            deleteAlert: <SweetAlert warning style={{ display: "block" }} title="Want to add Subscription details.." onConfirm={() => this.updateAddStatus(user_id, value)} onCancel={() => this.hideAlertDelete()} confirmBtnText={value === "0" ? "Yes" : "Yes"} cancelBtnText="Cancel" showCancel />,
        });

    }

    warningWithConfirmMessageUpdateBlock(user_id, value) {
        this.setState({
            deleteAlert: <SweetAlert warning style={{ display: "block" }} title="Are you sure?" onConfirm={() => this.updateDiabuddyUserBlockStatus(user_id, value)} onCancel={() => this.hideAlertDelete()} confirmBtnText={value === "0" ? "Yes, Block" : "Yes, Unblock"} cancelBtnText="Cancel" showCancel />,
        });
    }

    showEnabledButton(value, id) {
        return <Switch checked={value === "1" ? true : false} onChange={() => this.warningWithConfirmMessageUpdateEnabled(id, value)} color="primary" />;
        // <button className="btn btn-primary" onClick={() => this.warningWithConfirmMessageUpdateEnabled(id, value)}>
        //     {value === "0" ? "Enable" : "Disable"}
        // </button>
    }



    showAddButton(value, id) {
        //return <Switch checked={value === "1" ? true : false} onChange={() => this.warningWithConfirmMessageUpdateEnabled(id, value)} color="primary" />;
        return <button className="btn btn-primary" onClick={() => this.AddMessageUpdate(id, value)}>
            {value === "0" ? "AddB" : "Add"}
        </button>
    }

    showBlockedButton(value, id) {
        return <Switch checked={value === "1" ? true : false} onChange={() => this.warningWithConfirmMessageUpdateBlock(id, value)} color="primary" />;
    }

    showReportedUsersList(id, value) {
        return (
            <button className="btn btn-primary" onClick={() => this.showReportedUsersDetails(id)}>
                {value}
            </button>
        );
    }

    showReportedUsersDetails(id) {
        const data = {
            token: localStorage.getItem("token"),
            reported_id: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-reported-by-diabuddy-users",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ modalShow: false, diabuddyReportedArry: [] });
                } else {
                    this.setState({ diabuddyReportedArry: DataJSON.data, modalShow: true });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    updateDiabuddyUserEnableStatus(id, value) {
        const data = {
            token: localStorage.getItem("token"),
            id: id,
            value: value === "1" ? 0 : 1,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-enabled-for-diabuddy-user",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Updated..." onConfirm={() => this.getallsubscriptions()}>
                                User's Enabled status Updated.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }


    updateAddStatus(id, value) {
        this.setState({ deleteAlert: null });
    }

    updateDiabuddyUserBlockStatus(id, value) {
        const data = {
            token: localStorage.getItem("token"),
            reported_id: id,
            value: value === "1" ? 0 : 1,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/block-diabuddy-user-update",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Updated..." onConfirm={() => this.getallsubscriptions()}>
                                User's Block status Updated.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }


    handleAdd = () => {

        this.setState({
            isUpdate: false,
        });
        this.setClassicModal(true);
    }

    setClassicModal(classicModal) {
        //this.setState({ app_version: "All", checked: true, app_name: "LBC", Nightrider_Type: "Android" });
        this.setState({
            classicModal: classicModal,
            mQty: [],
            mFinal: []
        });
    }



    //ClickAdd(classicModal) {
    ClickAdd() {
        // alert("-->" + this.state.Qty)

        this.setState({
            mQty: [],
            mFinal: []
        });

        // if (this.state.yetNotAdded) {
        for (let m = 0; m < this.state.Qty; m++) {
            this.state.mQty.push("2")
        }
        /*  this.setState({
             yetNotAdded: true
         }); */
        this.setState({
            mQty: this.state.mQty,
            mFinal: []
        });
        // }


    }


    handleInputChangeId(e) {

        const { value } = e.target;
        this.setState({
            // [id]: value,
            mId: value,
            mFinal: []

        });

    }

    handleInputChangeSubscriptionId(me) {

        const { value } = me.target;
        this.setState({
            // [id]: value,
            SubId: value,
            mFinal: []
            //mFinal: []

        });
    }


    /* handleInputChangePrice(me) {
        const { value } = me.target;
        this.setState({
            // [id]: value,
            Price: value,
            mFinal: []
            //mFinal: []

        });
    } */


    handleInputChange(e) {
        const { value } = e.target;

        // alert("-->"+value.length)

        // rx_live: "/^[+-]?\d*(?:[.,]\d*)?$/"
        //this.state.rx_live = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        this.state.rx_live = value.match(/^[" "0-9]+$/);

        //fieldValidationErrors.email = emailValid ? '' : ' is invalid';

        if (this.state.rx_live) {
            // alert("Valid")
            this.setState({
                // [id]: value,
                Qty: value,
                mFinal: []

            });
        } else {
            if (value.length == 0) {
                this.setState({
                    // [id]: value,
                    Qty: "",
                    mFinal: []

                });
            } else {
                alert("Enter Invalid Number")
            }

        }

    }

    handleInputChange1(ee) {

        const { id, value } = ee.target;


        if (!/[0-9]/.test(value)) {
            ee.preventDefault();
        }

        this.setState({
            // [id]: value,
            Qty1: value,
            mFinal: []
        });
    }

    handleInputChange2(e) {
        const { id, value } = e.target;
        this.setState({
            // [id]: value,
            Qty2: value,
            mFinal: []
        });
    }

    handleInputChange3(e) {
        const { id, value } = e.target;
        this.setState({
            // [id]: value,
            Qty3: value,
            mFinal: []
        });
    }


    handleInputChange4(e) {
        const { id, value } = e.target;
        this.setState({
            // [id]: value,
            Qty4: value,
            mFinal: []
        });
    }


    onChange(e) {
        const { name, value } = e.target;


        // alert("--->"+value)
        if (value == "Water-Proof") {

            this.setState({ Price: "$150", [name]: value, mFinal: [] });
            // Price: value,
        } else {
            this.setState({ Price: "$120", [name]: value, mFinal: [] });
        }
    }

    renderSwitch(param) {

        // alert("--->" + this.state.yetNotAdded)
        // alert("-->" + param)
        switch (param) {
            case 1:

                return <li key={param}>{

                    this.state.mFinal.push(

                        <div>
                            <labelText
                                style={{ marginRight: 20 }}>
                                Serial No
                                :
                            </labelText>
                            <CustomInput
                                labelText="Enter Serial No"
                                id={param}
                                name="app_version"
                                inputProps={{
                                    value: `${this.state.Qty1}`,
                                    //disabled: this.state.app_version === "All" ? true : false,
                                }}
                                formControlProps={{
                                    onChange: this.handleInputChange1,
                                    fullWidth: false,
                                }}
                            />
                        </div>
                    )

                }</li>


            case 2:


                return <li key={param}>{

                    this.state.mFinal.push(

                        <div>
                            <labelText
                                style={{ marginRight: 20 }}>
                                Serial No
                                :
                            </labelText>

                            <CustomInput
                                labelText="Enter Serial No"
                                id={param}
                                name="app_version"
                                inputProps={{
                                    value: `${this.state.Qty2}`,
                                }}
                                formControlProps={{
                                    onChange: this.handleInputChange2,
                                    fullWidth: false,
                                }}
                            />
                        </div>

                    )

                }</li>


            case 3:

                return <li key={param}>{

                    this.state.mFinal.push(

                        <div>

                            <labelText
                                style={{ marginRight: 20 }}>
                                Serial No
                                :
                            </labelText>

                            <CustomInput
                                labelText="Entr Serial No"
                                id={param}
                                name="app_version"
                                inputProps={{
                                    value: `${this.state.Qty3}`,
                                    //disabled: this.state.app_version === "All" ? true : false,
                                }}
                                formControlProps={{
                                    onChange: this.handleInputChange3,
                                    fullWidth: false,
                                }}
                            />

                        </div>
                    )

                }</li>



            case 4:

                return <li key={param}>{

                    this.state.mFinal.push(

                        <div>

                            <labelText
                                style={{ marginRight: 20 }}>
                                Serial No
                                :
                            </labelText>

                            <CustomInput
                                labelText="Entr Serial No"
                                id={param}
                                name="app_version"
                                inputProps={{
                                    value: `${this.state.Qty4}`,
                                    //disabled: this.state.app_version === "All" ? true : false,
                                }}
                                formControlProps={{
                                    onChange: this.handleInputChange4,
                                    fullWidth: false,
                                }}
                            />

                        </div>
                    )

                }</li>

            default:



                return <div></div>;
        }

    }




    render() {

        const { classes } = this.props;
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };

        // New added

        var mhandle = 1;
        this.state.mQty.map((user, index) => {
            this.renderSwitch(mhandle)
            mhandle++;
        })



        //{ this.state.mQty.map((user, index) =>
        //final.push(<li key={user}>{user}</li>);

        /* for (let user of this.state.mQty) {
            final.push(

                <div>

                    <labelText
                        style={{ marginRight: 20 }}>
                        Night Rider Qty

                        :
                    </labelText>

                    <CustomInput
                        labelText="Entr Qty"
                        id="app_version"
                        name="app_version"
                        inputProps={{
                            value: `${this.state.Qty}`,
                            //disabled: this.state.app_version === "All" ? true : false,
                        }}
                        formControlProps={{
                            onChange: this.handleInputChange,
                            fullWidth: false,
                        }}

                    />

                </div>

            )
        } */


        // End
        var readingCount = 0;
        const tableData = this.state.diabuddyArry.map((data, i) => {
            const { id, subscription_id, nr_type, nr_qty, nr_serial_no, user_id, count } = data;

            readingCount++;
            return {
                id: id,
                subscription_id: subscription_id,
                nr_qty: nr_qty,
                nr_serial_no: nr_serial_no,
                nr_type: nr_type,
                // user_id: user_id,
                // showEnable: nr_qty,
                // showEnable: this.showEnabledButton(enabled, user_id),
                // is_blocked: is_blocked,
                // email: nr_serial_no,
                // isBlocked: nr_type,
                // showReported: this.showReportedUsersList(user_id, count),
                //showReported: this.showAddButton(user_id, count),
                // isBlocked: this.showBlockedButton(is_blocked, user_id),
                //
            };
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>

                {this.state.deleteAlert}
                {this.state.isGetDataLoading ? (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody style={{ height: "100px" }}>
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : (
                    <div>
                        <ShowReportedUsers show={this.state.modalShow} onHide={this.modalClose} title="Reported Diabuddy List" data={this.state.diabuddyReportedArry} />

                        {/* <Button id="infoIcon" color="info" onClick={this.handlePrevious}> */}


                        <GridContainer>

                            <div style={{ display: "flex", width: '100%', marginRight: 20, marginTop: 10 }}>
                                <Button id="infoIcon" color="info" style={{ marginLeft: "auto" }} onClick={this.handleAdd}>  {'Add'}
                                    {/* <FontAwesomeIcon icon={"chevron-circle-right"} size="2x" /> */}
                                </Button>
                            </div>


                            {tableData.length > 0 ? (
                                <Card>



                                    {/* start code */}

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={3}>
                                            {/* <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                                            <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close /> */}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Dialog
                                                classes={{
                                                    root: classes.center + " " + classes.modalRoot,
                                                    paper: classes.modal,
                                                }}
                                                open={this.state.classicModal}
                                                TransitionComponent={Transition}
                                                keepMounted
                                                onClose={() => this.setClassicModal(false)}
                                                aria-labelledby="classic-modal-slide-title"
                                                aria-describedby="classic-modal-slide-description"
                                                PaperProps={{
                                                    style: {
                                                        minHeight: "30vh",
                                                        minWidth: "30vw",
                                                    },
                                                }}
                                            >
                                                <DialogTitle style={{ display: "flex", alignItems: "center" }} id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                                                    <h4 style={{ textAlign: "center", width: "100%" }} className={classes.modalTitle}>
                                                        {this.state.isUpdate !== false ? "Update subscription details" : "Add Subscription details"}
                                                    </h4>
                                                    <Button justIcon className={classes.modalClose} style={{ position: "absolute", right: "20px" }} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                                        <Close className={classes.modalCloseButton} />
                                                    </Button>
                                                </DialogTitle>
                                                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                                    {/*   <GridContainer>
                                                        <GridItem xs={2} sm={2} md={2} lg={2} />

                                                        <div>

                                                            <labelText
                                                                        style={{ marginRight: 20, marginLeft: 20 }}>
                                                                ID :
                                                            </labelText>

                                                            <CustomInput
                                                                labelText="Enter Id"
                                                                id="app_version"
                                                                name="app_version"
                                                                inputProps={{
                                                                    value: `${this.state.mId}`,
                                                                    //disabled: this.state.app_version === "All" ? true : false,
                                                                }}
                                                                formControlProps={{
                                                                    onChange: this.handleInputChangeId,
                                                                    fullWidth: false,
                                                                }}
                                                            />
                                                        </div>

                                                    </GridContainer> */}

                                                    <GridContainer>

                                                        <div>
                                                            <labelText
                                                                style={{ marginRight: 20, width: 150, marginLeft: 30 }}>
                                                                Subscription Id :
                                                            </labelText>

                                                            <CustomInput
                                                                labelText="Enter Subscription Id"
                                                                id="app_version"
                                                                name="app_version"
                                                                inputProps={{
                                                                    value: `${this.state.SubId}`,
                                                                    //disabled: this.state.app_version === "All" ? true : false,
                                                                }}

                                                                formControlProps={{
                                                                    onChange: this.handleInputChangeSubscriptionId,
                                                                    fullWidth: false,
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.app_version === "All" ? true : false}
                                                                    onChange={this.getCheckboxValue}
                                                                    classes={{
                                                                        root: classes.root,
                                                                        checked: classes.checked,
                                                                    }}
                                                                />
                                                            }
                                                            label={"All"}
                                                        /> */}


                                                    </GridContainer>


                                                    <GridContainer>
                                                        {/*  <GridItem xs={12} sm={12} md={12} lg={12} /> */}
                                                        <h5 className={classes.cardIconTitle} style={{ width: 150, fontSize: "0.99em", color: "#000000", marginRight: "5px" }}>NightRider Type :
                                                        </h5>



                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={this.state.Nightrider_Type === "Non-WaterProof"}
                                                                    onChange={this.onChange}
                                                                    value="Non-WaterProof"
                                                                    name="Nightrider_Type"
                                                                    aria-label="None-Water-Proof"
                                                                    icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label,
                                                            }}
                                                            label="Non-Waterproof"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={this.state.Nightrider_Type === "Waterproof"}
                                                                    onChange={this.onChange}
                                                                    value="Waterproof"
                                                                    name="Nightrider_Type"
                                                                    aria-label="Waterproof"
                                                                    icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label,
                                                            }}
                                                            label="Waterproof"
                                                        />
                                                        {/* <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    checked={this.state.Nightrider_Type === "Both"}
                                                                    onChange={this.onChange}
                                                                    value="Both"
                                                                    name="Nightrider_Type"
                                                                    aria-label="Both"
                                                                    icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                                    checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                                    classes={{
                                                                        checked: classes.radio,
                                                                        root: classes.radioRoot,
                                                                    }}
                                                                />
                                                            }
                                                            classes={{
                                                                label: classes.label,
                                                            }}
                                                            label="Both"
                                                        /> */}
                                                    </GridContainer>


                                                    {/* <GridContainer>

                                                        <div >
                                                            <labelText
                                                                style={{ marginRight: 20, width: 150, marginLeft: 90 }}>
                                                                Price :
                                                            </labelText>

                                                            <CustomInput
                                                                labelText="Price Night Rider"
                                                                id="app_version"
                                                                name="app_version"
                                                                inputProps={{
                                                                    value: `${this.state.Price}`,
                                                                    //disabled: this.state.app_version === "All" ? true : false,
                                                                }}

                                                                formControlProps={{
                                                                    fullWidth: false,
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.app_version === "All" ? true : false}
                                                                    onChange={this.getCheckboxValue}
                                                                    classes={{
                                                                        root: classes.root,
                                                                        checked: classes.checked,
                                                                    }}
                                                                />
                                                            }
                                                            label={"All"}
                                                        /> */}


                                                    {/*     </GridContainer> */}


                                                    {/* <GridContainer>

                                                                <div >
                                                                    <labelText
                                                                        style={{ marginRight: 20, width: 150, marginLeft: 90 }}>
                                                                       Total Price :
                                                                    </labelText>

                                                                    <CustomInput
                                                                        labelText="Total Price Night Rider"
                                                                        id="app_version"
                                                                        name="app_version"
                                                                        inputProps={{
                                                                            value: `${this.state.Price}`,
                                                                            //disabled: this.state.app_version === "All" ? true : false,
                                                                        }}

                                                                        formControlProps={{
                                                                            fullWidth: false,
                                                                        }}
                                                                    />
                                                                </div>

                                                           

                                                            </GridContainer> */}





                                                    <GridContainer >

                                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            {/* <div style={divStyle}> */}
                                                            {/* <GridItem xs={12} sm={4} md={4} lg={4} > */}

                                                            <div>

                                                                <labelText
                                                                    style={{ marginRight: 20, width: 150, marginLeft: 20 }}>
                                                                    Night Rider Qty

                                                                    :
                                                                </labelText>


                                                                <CustomInput
                                                                    labelText="Enter Qty"
                                                                    id="edtQty"
                                                                    name="edtQty"
                                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                                    inputProps={{
                                                                        value: `${this.state.Qty}`,
                                                                        //  disabled: this.state.disable_Qty === "All" ? true : false,
                                                                        //disabled: this.state.app_version === "All" ? true : false,
                                                                    }}
                                                                    formControlProps={{
                                                                        onChange: this.handleInputChange,
                                                                        fullWidth: false,
                                                                    }}

                                                                />


                                                                {/* as discuss no need add button so.
                                                                 <Button id="infoIcon" color="info" style={{ marginLeft: "auto", marginLeft: 10 }} onClick={() => this.ClickAdd()} >  {'Add'}                                                                   
                                                                </Button>
 */}

                                                                <div>
                                                                    <ul>{this.state.mFinal}</ul>
                                                                </div>

                                                            </div>








                                                            {/* </GridItem> */}



                                                        </div>







                                                    </GridContainer>




                                                    <GridContainer >
                                                        <div>
                                                            <labelText
                                                                style={{ marginRight: 20, marginLeft: 50 }}>
                                                                Serial No
                                                                :
                                                            </labelText>
                                                            <CustomInput
                                                                labelText="Entr Serial No"
                                                                id={"param"}
                                                                name="app_version"
                                                                inputProps={{
                                                                    value: `${this.state.Qty1}`,
                                                                    //disabled: this.state.app_version === "All" ? true : false,
                                                                }}
                                                                formControlProps={{
                                                                    onChange: this.handleInputChange1,
                                                                    fullWidth: false,
                                                                }}
                                                            />
                                                        </div>
                                                    </GridContainer>




                                                    {/* <GridContainer>
                                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                                            Notification Message:
                                                        </h5>
                                                        <Editor
                                                            apiKey="0q7izru2yq2vohfc5b1e2cn6264n49nfqzugmsg6ihvagut1"
                                                            value={this.state.userNotificationMessage}
                                                            init={{
                                                                height: 250,
                                                                menubar: false,
                                                                plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
                                                                toolbar: "undo redo | formatselect | bold italic backcolor |   alignleft aligncenter alignright alignjustify |    bullist numlist outdent indent | removeformat | help",
                                                            }}
                                                            onEditorChange={this.handleEditorChange}
                                                        />
                                                    </GridContainer> */}
                                                </DialogContent>
                                                <DialogActions className={classes.modalFooter}>
                                                    {/* <Button onClick={() => this.setClassicModal(false)} justIcon round simple color="primary" disabled={this.state.app_version === "" || this.state.userNotificationMessage === ""} className="remove" onClick={() => (this.state.isUpdate === true ? this.updateUserNotification(this.state.id, this.state.userNotificationMessage, this.state.app_version, this.state.app_name, this.state.Nightrider_Type) : this.handleSubmitForm())}> */}
                                                    <Button onClick={() => this.handleSubmitForm()} color="danger" simple>
                                                        <CheckIcon />
                                                    </Button>
                                                    <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Clearfix />
                                        </GridItem>
                                    </GridContainer>


                                    {/* End code */}




                                    <CardBody>


                                        <PerfectScrollbar>



                                            <ReactTable
                                                data={tableData}
                                                filterable
                                                columns={[
                                                    {
                                                        width: 50,
                                                        Header: "ID",
                                                        accessor: "id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Subscription Id",
                                                        accessor: "subscription_id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    /* {
                                                        width: 120,
                                                        Header: "Enabled",
                                                        accessor: "showEnable",
                                                        filterable: false,
                                                    }, */
                                                    {
                                                        width: 155,
                                                        Header: "Nightrider Se_No",
                                                        accessor: "nr_serial_no",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 250,
                                                        Header: "Nightrider Type",
                                                        accessor: "nr_type",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Nightrider Qty",
                                                        accessor: "nr_qty",
                                                        Cell: (row) => <div style={{ color: "#262626", alignSelf: 'center' }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    /*  {
                                                         width: 150,
                                                         Header: "Reported Count",
                                                         accessor: "showReported",                                                                                                            
                                                         filterable: false,
                                                     }, */
                                                ]}
                                                defaultPageSize={50}
                                                showPaginationTop
                                                showPaginationBottom={false}
                                                className="-highlight"
                                            />
                                        </PerfectScrollbar>
                                    </CardBody>
                                </Card>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    There are no dia buddy users found.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(subscriptions);
