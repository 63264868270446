import React from "react";
import $ from "jquery";
import PerfectScrollbar from "react-perfect-scrollbar";
import SweetAlert from "react-bootstrap-sweetalert";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class groupCaregiverList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            deleteAlert: null,
            caregiverList: [],
            caregiver_email: "",
            notificationMessage: "",
            classicModal: false,
            caregiver_name: "",
            user_id: "",
            group_name: "",
            groups: [],
            id: "",
        };
        this.showNotification = this.showNotification.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.addPatientDialog = this.addPatientDialog.bind(this);
        this.setClassicModal = this.setClassicModal.bind(this);
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    componentDidMount() {
        document.title = "Manage Group Caregiver - Ambrosia Care";
        this.loadingCaregiverFollower();
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-group",
            data: {
                token: localStorage.getItem("token"),
                super_caregiver_id: localStorage.getItem("user_id"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ groups: DataJSON.data });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    loadingCaregiverFollower() {
        if (!localStorage.getItem("token") || localStorage.getItem("loginType") !== "Caregiver") {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-group-caregiver-list",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ caregiverList: [], deleteAlert: null });
                } else {
                    this.setState({ caregiverList: DataJSON.caregivers, deleteAlert: null });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    updateCaregiverRole(user_id, id) {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: user_id,
            group_id: id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-group-caregiver",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    if (DataJSON.msg.includes("Caregiver is already added to the group.")) {
                        this.setState({ notificationMessage: "Caregiver is already added to the group." }, () => {
                            this.showNotification("tc_danger");
                            this.loadingCaregiverFollower();
                            this.setClassicModal(false);
                            this.setState({ caregiver_email: "" });
                        });
                    }
                } else {
                    this.setState({ notificationMessage: "Caregiver is added to the group." }, () => {
                        this.showNotification("tc_info");
                        this.loadingCaregiverFollower();
                        this.setClassicModal(false);
                        this.setState({ caregiver_email: "" });
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleSubmitForm() {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_email: this.state.caregiver_email,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/check-caregiver-account",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    if (DataJSON.msg.includes("Caregiver not found.")) {
                        this.setState({ notificationMessage: " Caregiver with specifiied email address is not found." }, () => {
                            this.showNotification("tc_danger");
                            this.setClassicModal(false);
                            this.setState({ caregiver_email: "" });
                        });
                    }
                    if (DataJSON.msg.includes("Following caregiver is already the super caregiver.")) {
                        this.setState({ notificationMessage: "Following caregiver is already the super caregiver." }, () => {
                            this.showNotification("tc_danger");
                            this.setClassicModal(false);
                            this.setState({ caregiver_email: "" });
                        });
                    }
                    if (DataJSON.msg.includes("Caregiver already added.")) {
                        this.setState({ notificationMessage: " Caregiver already added." }, () => {
                            this.showNotification("tc_danger");
                            this.setClassicModal(false);
                            this.setState({ caregiver_email: "" });
                        });
                    }
                } else {
                    if (DataJSON.msg.includes("Invalid_Email_Format.")) {
                        this.setState({ notificationMessage: "Invalid Email Format." }, () => {
                            this.showNotification("tc_danger");
                            this.setClassicModal(false);
                            this.setState({ caregiver_email: "" });
                        });
                    } else {
                        this.setState({ caregiver_email: DataJSON.data.email, caregiver_name: DataJSON.data.first_name + " " + DataJSON.data.last_name, user_id: DataJSON.data.user_id });
                        this.setClassicModal(true);
                    }
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }
    deleteCaregiverRole(user_id, group_id) {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: user_id,
            group_id: group_id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-group-caregiver",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.loadingCaregiverFollower()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Caregiver is removed from the group.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleGroupDropdownChange({ ...e }) {
        this.setState({
            id: e.id,
            group_name: `${e.group_name.toUpperCase()} `,
        });
    }
    warningWithConfirmMessageDelete(user_id, group_id) {
        this.setState({
            deleteAlert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deleteCaregiverRole(user_id, group_id)} onCancel={() => this.hideAlertDelete()} confirmBtnText="Yes, Delete it!" cancelBtnText="Cancel" showCancel>
                    Following Caregiver will be removed.
                </SweetAlert>
            ),
        });
    }
    deleteCaregiver(user_id, group_id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessageDelete(user_id, group_id)}>
                <Delete />
            </Button>
        );
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    setClassicModal(classicModal) {
        this.setState({
            classicModal: classicModal,
        });
    }
    addPatientDialog() {
        this.setClassicModal(true);
    }
    render() {
        const { classes } = this.props;
        var readingCount = 0;
        const tableData = this.state.caregiverList.map((data, i) => {
            const { caregiver_id, first_name, last_name, group_name, email, group_id } = data;
            readingCount++;
            return [readingCount, `${first_name} ${last_name}`, group_name, email, this.deleteCaregiver(caregiver_id, group_id)];
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardBody style={{ background: "black" }}>
                                <CustomInput
                                    labelText="Email"
                                    id="caregiver_email"
                                    inputProps={{
                                        type: "search",
                                        value: `${this.state.caregiver_email}`,
                                    }}
                                    formControlProps={{
                                        onChange: this.handleInputChange,
                                        fullWidth: false,
                                    }}
                                />
                                <Button id="infoIcon" onClick={this.handleSubmitForm} style={{ marginLeft: "1rem", width: "10px" }} color="info">
                                    <FontAwesomeIcon icon={"search"} size="2x" />
                                </Button>

                                <Dialog
                                    classes={{
                                        root: classes.center + " " + classes.modalRoot,
                                        paper: classes.modal,
                                    }}
                                    open={this.state.classicModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.setClassicModal(false)}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description"
                                    PaperProps={{
                                        style: {
                                            minHeight: "25vh",
                                            minWidth: "25vw",
                                        },
                                    }}
                                >
                                    <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                                        <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                            <Close className={classes.modalClose} />
                                        </Button>
                                        <h4 className={classes.modalTitle}>Add Caregiver To Group</h4>
                                    </DialogTitle>
                                    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                        <p>Are You sure you want to add this caregiver to the group ?</p>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                            <b>Email: </b>
                                            {this.state.caregiver_email}
                                        </h5>
                                        <CustomDropdown
                                            hoverColor="info"
                                            buttonText={this.state.group_name === "" ? "Select Group" : this.state.group_name}
                                            buttonProps={{
                                                round: true,
                                                fullWidth: true,
                                                style: { marginBottom: "0", width: "300px" },
                                                color: "info",
                                            }}
                                            id="secondYaxis"
                                            dropdownList={this.state.groups.map((e, i) => (
                                                <p
                                                    style={{
                                                        display: "block",        // Makes the <p> behave like an <li>
                                                        color: "white",
                                                        width: "100%",
                                                        margin: 0,               // Removes any default margin
                                                        padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                        boxSizing: "border-box",
                                                    }}
                                                    onClick={() => this.handleGroupDropdownChange({ ...e })}>{`${e.group_name.toUpperCase()}  `}
                                                </p>
                                            ))}
                                        />
                                    </DialogContent>
                                    <DialogActions className={classes.modalFooter}>
                                        <Button justIcon round simple color="primary" disabled={this.state.group_name === ""} className="remove" onClick={() => this.updateCaregiverRole(this.state.user_id, this.state.id)}>
                                            <PersonAddIcon />
                                        </Button>
                                        <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </CardBody>
                        </Card>
                        {tableData.length > 0 ? (
                            <Card>
                                <CardBody style={{ background: "black" }}>
                                    <PerfectScrollbar>
                                        <Table tableHeaderColor="primary" tableHead={["ID", "Caregiver Name", "Group Name", "Email", "Action"]} tableData={tableData} />
                                    </PerfectScrollbar>
                                </CardBody>
                            </Card>
                        ) : (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Card>
                                        <CardBody style={{ height: "200px" }}>
                                            <h4
                                                style={{
                                                    marginTop: "80px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                There are no caregivers available in any group.
                                            </h4>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(groupCaregiverList);
