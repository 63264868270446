import React from "react";
import $ from "jquery";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap-daterangepicker/daterangepicker.css";
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Delete from "@material-ui/icons/Delete";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class CaregiverList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            isLoading: false,
            caregiverList: [],
            notificationMessage: "",
            deleteAlert: null,
            caregiver_email: "",
            classicModal: false,
            caregiver_name: "",
            user_id: "",
        };
        this.showNotification = this.showNotification.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    componentDidMount() {
        document.title = "Caregiver-List - Ambrosia Care";
        this.loadingCaregiverList();
    }
    loadingCaregiverList() {
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            data: data,
            url: BASE_URL + "/get-caregiver-list",
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/admin/login");
                    }
                    this.setState({ caregiverList: [], deleteAlert: null });
                } else {
                    this.setState({ caregiverList: DataJSON.caregivers, deleteAlert: null });
                }
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }
    deleteCaregiverRole(user_id) {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: user_id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-super-caregiver",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/admin/login");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.loadingCaregiverList()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Super Caregiver Deleted.
                            </SweetAlert>
                        ),
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    warningWithConfirmMessageDelete(user_id) {
        this.setState({
            deleteAlert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deleteCaregiverRole(user_id)} onCancel={() => this.hideAlertDelete()} confirmBtnText="Yes, Delete it!" cancelBtnText="Cancel" showCancel>
                    Following Caregiver will be deleted.
                </SweetAlert>
            ),
        });
    }
    deleteCaregiver(user_id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessageDelete(user_id)}>
                <Delete />
            </Button>
        );
    }
    handleSubmitForm() {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_email: this.state.caregiver_email,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/check-admin-account",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/admin/login");
                    }
                    if (DataJSON.msg.includes("Caregiver not found.")) {
                        this.setState({ notificationMessage: " Super Caregiver with specified email address is not found." }, () => {
                            this.showNotification("tc_danger");
                            this.setState({ caregiver_email: "" });
                        });
                    }
                    if (DataJSON.msg.includes("Caregiver already added.")) {
                        this.setState({ notificationMessage: "Caregiver already added as Super Caregiver." }, () => {
                            this.showNotification("tc_danger");
                            this.setState({ caregiver_email: "" });
                        });
                    }
                    if (DataJSON.msg.includes("Following caregiver is already added in the group.")) {
                        this.setState({ notificationMessage: "Following caregiver is already added in the group by super caregiver." }, () => {
                            this.showNotification("tc_danger");
                            this.setState({ caregiver_email: "" });
                        });
                    }
                    if (DataJSON.msg.includes("Caregiver is already the follower of the super caregiver")) {
                        this.setState({ notificationMessage: "Caregiver is already the follower of the super caregiver." }, () => {
                            this.showNotification("tc_danger");
                            this.setState({ caregiver_email: "" });
                        });
                    }
                } else {
                    if (DataJSON.msg.includes("Invalid_Email_Format.")) {
                        this.setState({ notificationMessage: "Invalid Email Address." }, () => {
                            this.showNotification("tc_danger");
                            this.setState({ caregiver_email: "" });
                        });
                    } else {
                        this.setState({ caregiver_email: DataJSON.data.email, caregiver_name: DataJSON.data.first_name + " " + DataJSON.data.last_name, user_id: DataJSON.data.user_id });
                        this.setClassicModal(true);
                    }
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    updateCaregiverRole(user_id) {
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: user_id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/update-caregiver-role",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/admin/login");
                    }
                } else {
                    this.setState({ notificationMessage: "Caregiver is added as the super caregiver." }, () => {
                        this.showNotification("tc_info");
                        this.loadingCaregiverList();
                        this.setClassicModal(false);
                        this.setState({ caregiver_email: "" });
                    });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    setClassicModal(classicModal) {
        // this.state.classicModal = classicModal;
        this.setState({
            classicModal: classicModal,
        });
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    render() {
        const { classes } = this.props;
        var readingCount = 0;
        const tableData = this.state.caregiverList.map((data, i) => {
            const { user_id, first_name, last_name, email } = data;
            readingCount++;
            return [readingCount, `${first_name} ${last_name}`, email, this.deleteCaregiver(user_id)];
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                        <Card>
                            <CardBody>
                                <CustomInput
                                    labelText="Email"
                                    id="caregiver_email"
                                    inputProps={{
                                        type: "search",
                                        value: `${this.state.caregiver_email}`,
                                    }}
                                    formControlProps={{
                                        onChange: this.handleInputChange,
                                        fullWidth: false,
                                    }}
                                />
                                <Button id="infoIcon" onClick={this.handleSubmitForm} style={{ marginLeft: "1rem", width: "10px" }} color="info">
                                    <FontAwesomeIcon icon={"search"} size="2x" />
                                </Button>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Dialog
                                    classes={{
                                        root: classes.center + " " + classes.modalRoot,
                                        paper: classes.modal,
                                    }}
                                    open={this.state.classicModal}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={() => this.setClassicModal(false)}
                                    aria-labelledby="classic-modal-slide-title"
                                    aria-describedby="classic-modal-slide-description"
                                >
                                    <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                                        <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                            <Close className={classes.modalClose} />
                                        </Button>
                                        <h4 className={classes.modalTitle}>Add Super Caregiver</h4>
                                    </DialogTitle>
                                    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                                        <p>Are You sure you want to add this caregiver as super caregiver ?</p>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                            <b>Email: </b>
                                            {this.state.caregiver_email}
                                        </h5>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#000000" }}>
                                            <b>Name: </b>
                                            {this.state.caregiver_name}
                                        </h5>
                                    </DialogContent>
                                    <DialogActions className={classes.modalFooter}>
                                        <Button justIcon round simple color="primary" className="remove" onClick={() => this.updateCaregiverRole(this.state.user_id)}>
                                            <PersonAddIcon />
                                        </Button>
                                        <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <PerfectScrollbar>
                                    <Table tableHeaderColor="primary" tableHead={["ID", "Caregiver Name", "Email", "Action"]} tableData={tableData} coloredColls={[3]} colorsColls={["primary"]} />
                                </PerfectScrollbar>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(CaregiverList);
