import React from "react";
import Report from "../bv-react-data-report/report";

export default class NotesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            type: "",
            patientName: "",
            patientEmail: "",
            flag: false
        };
    }

    componentDidMount() {
        document.title = "Notes Report - Ambrosia Care";
        this._isMounted = true;
        if (this.props.location.state) {
            const { data, type, patientName, patientEmail } = this.props.location.state;
            this.setState({ data, type, patientName, patientEmail, flag: true });
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        return this.state.flag ? (
            <Report data={this.state.data} type={this.state.type} patientName={this.state.patientName} patientEmail={this.state.patientEmail} />
        ) : null;
    }
}
