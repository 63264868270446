


class CommanProfile {
    // static currentVideoCount = 0;
    // static isEditAllowToCheck = true;
    static Global_GlucoseType = "";
    // static is_data_missing = false;

}
export default CommanProfile;

