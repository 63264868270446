import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { BASE_URL } from "views/Utiles/Constants";
import $ from "jquery";
import { CircularProgress } from "@mui/material";
import Switch from '@mui/material/Switch';
import Swal from "sweetalert2";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const columns = [
    { id: 'id', label: 'Id', minWidth: 100, align: 'center', },
    { id: 'name', label: 'Name', minWidth: 170, align: 'center', },
    { id: 'company', label: 'Company', minWidth: 170, align: 'center', },
    { id: 'email', label: 'Email', minWidth: 170, align: 'center', },
    { id: 'user_count', label: 'Total Users', minWidth: 100, align: 'center', },
    { id: 'createddate', label: 'Created Date time', minWidth: 170, align: 'center', },
    { id: 'modifieddate', label: 'Updated Date time', minWidth: 170, align: 'center', },
    { id: 'subscriptionType', label: 'Subscription', minWidth: 100, align: 'center', },
    { id: 'currentStatus', label: 'Status', minWidth: 100, align: 'center', },
];


function apiUsers() {
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "API Users - Ambrosia Care";
        APICalling();
    }, []);

    const APICalling = () => {
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-all-backend-users",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                setTableData(DataJSON.data);
                setLoading(false);
            },
            error: (error) => {
                console.error(error);
                setLoading(false);
            },
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const updateButtonStatus = (column) => {
        Swal.fire({
            title: 'Loading',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
            currentStatus: column.currentStatus === "1" ? 0 : 1,
            id: column.id,
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/enable-disable-backend-user",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON) {
                    APICalling();
                    Swal.close();
                    setLoading(true);
                }
            },
            error: (error) => {
                console.error(error);
                Swal.close();
            },
        });
    };

    const handleChangebutton = (column) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: column.currentStatus === "0" ? "Yes, Enable" : "Yes, Disable"
        }).then((result) => {
            if (result.isConfirmed) {
                updateButtonStatus(column);
            }
        })
    };

    return (
        loading ? <CircularProgress
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
            }}
        /> :
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, align: 'center', }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id !== 'currentStatus' ? row[column.id] :
                                                            <Switch {...label} checked={row["currentStatus"] === "1"} onChange={() => { handleChangebutton(row) }} />}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
    );
}

export default withStyles(validationFormsStyle)(apiUsers);



