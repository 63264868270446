import React, { Component } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class PatientHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <h2 style={{ textAlign: "center", color: "#00acc1" }}>Ambrosia Care</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <h5 style={{ fontSize: "0.99em" }}>
                            <b>Patient Name:</b> {this.props.patientName}
                        </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} />
                    {localStorage.getItem("loginType") === "Caregiver" ? (
                        <GridItem xs={12} sm={12} md={4}>
                            <h5 style={{ fontSize: "0.99em" }}>
                                <b>Caregiver Name:</b> {" " + localStorage.getItem("first_name") + " " + localStorage.getItem("last_name")}
                            </h5>
                        </GridItem>
                    ) : (
                        <GridItem xs={12} sm={12} md={4} />
                    )}
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <h5 style={{ fontSize: "0.99em" }}>
                            <b>Patient Email:</b> {this.props.patientEmail}
                        </h5>
                    </GridItem>
                </GridContainer>
                <hr />
            </div>
        );
    }
}

export default PatientHeader;
