import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { Typography, Grid, Box, Paper, Button, TableCell } from '@mui/material';
import { Table, TableBody, TableRow } from '@material-ui/core';
import Avatar from "react-avatar";
import Swal from 'sweetalert2';
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";

function ProfilePage() {

    const profilePicture = localStorage.getItem('profile_picture');
    const fName = localStorage.getItem('first_name');
    const lName = localStorage.getItem('last_name');
    const email = localStorage.getItem('email');
    const country = localStorage.getItem('country');
    const name = fName + " " + lName;
    let convertedName = name.replace(/\b\w/g, function (c) {
        return c.toUpperCase();
    });

    const sendLink = () => {
        Swal.fire({
            title: 'Loading',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })
        $.ajax({
            method: "POST",
            url: BASE_URL + "/forgot-password",
            data: {
                email: email,
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg !== undefined) {
                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                        }
                    }
                } else {
                    setTimeout(() => {
                        Swal.close();
                        Swal.fire({
                            title: 'Password Link Sent',
                            text: `${DataJSON.msg}`,
                            icon: 'success',
                        });
                    }, 1000);
                }
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={8} md={6}>
                <Paper elevation={3} sx={{ p: 4 }} style={{ background: "black" }}>
                    <div>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                {
                                    profilePicture.length > 5 ?
                                        <Avatar size='60' src={"data:image/jpeg;base64," + profilePicture} round={true} /> :
                                        <Avatar size='60' name={fName + " " + lName} round={true} />
                                }
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" style={{ color: "white" }}>
                                    {convertedName}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Box mt={4}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ border: 'none', color: "white" }}>
                                            <strong>Country</strong>
                                        </TableCell>
                                        <TableCell style={{ border: 'none', color: "white" }}>
                                            {country}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ border: 'none', color: "white" }}>
                                            <strong>Email</strong>
                                        </TableCell>
                                        <TableCell style={{ border: 'none', color: "white" }}>
                                            {email}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button variant='contained' style={{ background: '#4FACFE' }} onClick={() => { sendLink() }}>
                            Send Password Reset Link
                        </Button>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default withStyles(extendedTablesStyle)(ProfilePage);