import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./views/Styles/style.css";
// import "react-perfect-scrollbar/dist/css/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import AdminLayout from "./layouts/Admin";
import Login from "./views/Login/LoginPage";
import AdminLogin from "./views/Admin/login";
import CaregiverRegistration from "./views/Caregiver/CaregiverRegistration";

import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";

library.add(fas, far, fab);

const hist = createBrowserHistory();

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route exact path="/admin/login" component={AdminLogin} />
            <Route exact path="/admin/apiUsers" component={AdminLayout} />
            <Route exact path="/admin/caregiver-list" component={AdminLayout} />
            <Route exact path="/admin/user-notification" component={AdminLayout} />
            <Route exact path="/admin/reportedDiabuddyUsers" component={AdminLayout} />
            <Route exact path="/admin/diabuddyUsers" component={AdminLayout} />
            <Route exact path="/admin/subscriptions" component={AdminLayout} />
            <Route exact path="/admin/championSubscriptions" component={AdminLayout} />
            <Route exact path="/" component={Login} />
            <Route exact path="/caregiver-registration" component={CaregiverRegistration} />
            <Route exact path="/patientList" component={AdminLayout} />
            <Route exact path="/patientInfo" component={AdminLayout} />
            <Route exact path="/profileInfo" component={AdminLayout} />
            <Route exact path="/invitations" component={AdminLayout} />
            <Route exact path="/googleFit" component={AdminLayout} />
            <Route exact path="/fitbit-activities" component={AdminLayout} />
            <Route exact path="/fitbit-sleep-data" component={AdminLayout} />
            <Route exact path="/fitbit-graph" component={AdminLayout} />
            <Route exact path="/dual-graph-report" component={AdminLayout} />
            <Route exact path="/patient-date-selection" component={AdminLayout} />
            <Route exact path="/notes-report" component={AdminLayout} />
            <Route exact path="/report-header" component={AdminLayout} />
            <Route exact path="/data-report" component={AdminLayout} />
            <Route exact path="/healthData" component={AdminLayout} />
            <Route exact path="/appleHealthECG" component={AdminLayout} />
            <Route exact path="/patientHealthDataSelection" component={AdminLayout} />
            <Route exact path="/patientECGDataSelection" component={AdminLayout} />
            <Route exact path="/add-patient-notes" component={AdminLayout} />
            <Route exact path="/food-log" component={AdminLayout} />
            <Route exact path="/patient-food-log" component={AdminLayout} />
            {/* <Route exact path="/caregiver-follower" component={AdminLayout} /> */}
            <Route exact path="/groups" component={AdminLayout} />
            <Route exact path="/group-patient" component={AdminLayout} />
            <Route exact path="/group-caregiver" component={AdminLayout} />
            <Route exact path="*" component={Login} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
