// import Dashboard from "./views/Dashboard/Dashboard";
import PatientList from "./views/Patient/PatientList";
import ProfilePage from "./views/Pages/ProfilePage";
import Patient from "./views/Patient/Patient";
import NotesReport from "./views/Reports/notes-report";
import DualGraphReport from "./views/Reports/dual-graph-report";
import Invitations from "./views/Patient/Invitations";
import GoogleFit from "./views/GoogleFit/GoogleFitData";
import FitbitActivites from "./views/Fitbit/FitbitAuthentication";
import FitbitSleepData from "./views/Fitbit/FitbitSleepData";

import PatientAndDateSelection from "./views/Reports/PatientAndDateSelection";
import ReportHeader from "./views/Profile/ReportHeader";
import DailyReport from "./views/Reports/daily-report";
import HealthData from "./views/AppleHealth/HealthData";
import AppleHealthECG from "./views/AppleHealth/AppleHealthECG";
import patientHealthDataSelection from "./views/Patient/patientHealthDataSelection";
import patientECGDataSelection from "./views/Patient/patientECGDataSelection";
import AddPatientNotes from "./views/PatientNotes/add-patient-notes";
import PatientFoodLog from "./views/FoodLog/patient-food-log";
import FoodLog from "./views/FoodLog/food-log";

import CaregiverList from "./views/Admin/caregiver-list";
import AddNotification from "./views/Admin/add-notification";
import groups from "./views/GroupManagement/groups";
import groupPatient from "./views/GroupManagement/groupPatientList";
import groupCaregiver from "./views/GroupManagement/groupCaregiverList";
// @material-ui/icons
import { Group, PersonAdd, Public, Report, Image, Assessment, NoteAdd, DirectionsRun, Fastfood, BrokenImage, Favorite, GroupAdd, SupervisorAccount, Face, FaceTwoTone, AccountBalance, SupervisedUserCircle, AccountCircle } from "@material-ui/icons";

import reportedDiabuddyUsers from "./views/Admin/reported-diabuddy-users";
import diabuddyUsers from "./views/Admin/diabuddy-users";
import subscriptions from "./views/Admin/subscriptions";
import championSubscriptions from "./views/Admin/championSubscriptions";
import apiUsers from "./views/Admin/apiUsers";

const menu =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/patientList",
            name: "Patient List",
            icon: Group,
            component: PatientList,
            layout: "/admin",
        }
        : localStorage.getItem("loginType") === "Patient"
            ? {
                path: "/patientInfo",
                name: "Patient Info",
                icon: Group,
                component: Patient,
                layout: "/admin",
            }
            : { redirect: true };
const profilePage =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/profileInfo",
            name: "My Profile",
            icon: AccountCircle,
            component: ProfilePage,
        }
        : localStorage.getItem("loginType") === "Patient"
            ? {
                path: "/profileInfo",
                name: "My Profile",
                icon: AccountCircle,
                component: ProfilePage,
            }
            : { redirect: true };

const foodLog =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/patient-food-log",
            name: "Patient Food Log",
            icon: Fastfood,
            component: PatientFoodLog,
            layout: "/admin",
        }
        : localStorage.getItem("loginType") === "Patient" || localStorage.getItem("loginType") === "Caregiver"
            ? {
                path: "/food-log",
                name: "Patient Food Log",
                icon: Fastfood,
                component: FoodLog,
                layout: "/admin",
            }
            : { redirect: true };

const caregiverList =
    localStorage.getItem("loginType") === "Admin"
        ? {
            path: "/admin/caregiver-list",
            name: "Caregiver List",
            icon: Group,
            component: CaregiverList,
        }
        : { redirect: true };
const adminUserNotification =
    localStorage.getItem("loginType") === "Admin"
        ? {
            path: "/admin/user-notification",
            name: "User Notificaion",
            icon: Group,
            component: AddNotification,
        }
        : { redirect: true };

const apiUser = localStorage.getItem("loginType") === "Admin"
    ? {
        path: "/admin/apiUsers",
        name: "API Users",
        icon: SupervisedUserCircle,
        component: apiUsers,
    }
    : { redirect: true };
const diaBitics =
    localStorage.getItem("loginType") === "Admin"
        ? {
            path: "/admin/reportedDiabuddyUsers",
            name: "Reported DiaBuddy Users",
            icon: FaceTwoTone,
            component: reportedDiabuddyUsers,
        }
        : { redirect: true };

const diabuddyAllUsers =
    localStorage.getItem("loginType") === "Admin"
        ? {
            path: "/admin/diabuddyUsers",
            name: "DiaBuddy Users",
            icon: Face,
            component: diabuddyUsers,
        }
        : { redirect: true };
const mSubscriptions =
    localStorage.getItem("loginType") === "Admin"
        ? {
            path: "/admin/subscriptions",
            name: "Subscriptions",
            icon: AccountBalance,
            component: subscriptions,
        }
        : { redirect: true };

const mChampionSubscriptions = localStorage.getItem("loginType") === "Admin"
    ? {
        path: "/admin/championSubscriptions",
        name: "Champion Subscriptions",
        icon: AccountBalance,
        component: championSubscriptions,
    }
    : { redirect: true };


const patientInvitation =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/invitations",
            name: "Invitations",
            icon: PersonAdd,
            component: Invitations,
            layout: "/admin",
        }
        : { redirect: true };

const caregiverHeader =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/report-header",
            name: "Report Header",
            component: ReportHeader,
            layout: "/admin",
            icon: Report,
            // redirect: true,
        }
        : { redirect: true };
const patientHealthData =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/patientHealthDataSelection",
            name: "Patient Health Data",
            // mini: "NR",
            icon: DirectionsRun,
            component: patientHealthDataSelection,
            layout: "/admin",
        }
        : { redirect: true };

const gooleFitData =
    localStorage.getItem("loginType") === "Patient"
        ? {
            path: "/googleFit",
            name: "Google Fit Data",
            icon: Public,
            component: GoogleFit,
            layout: "/admin",
        }
        : { redirect: true };
const patientECGData =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/patientECGDataSelection",
            name: "Patient ECG Data",
            // mini: "NR",
            icon: BrokenImage,
            component: patientECGDataSelection,
            layout: "/admin",
        }
        : { redirect: true };
// const healthData =
//     localStorage.getItem("loginType") === "Patient"
//         ? {
//               path: "/healthData",
//               name: "Apple Health Data",
//               icon: Public,
//               component: HealthData,
//               layout: "/admin",
//           }
//         : { redirect: true };
const addPatientNotes =
    localStorage.getItem("loginType") === "Caregiver"
        ? {
            path: "/add-patient-notes",
            name: "Patient Notes",
            // mini: "NR",
            icon: NoteAdd,
            component: AddPatientNotes,
            layout: "/admin",
        }
        : { redirect: true };
// const caregiverFollower =
//     localStorage.getItem("loginType") === "Caregiver" && localStorage.getItem("is_parent") === "1"
//         ? {
//               path: "/caregiver-follower",
//               name: "Caregivers",
//               // mini: "NR",
//               icon: PersonAdd,
//               component: CaregiverFollower,
//               layout: "/admin",
//           }
//         : { redirect: true };
const fitbitData =
    localStorage.getItem("loginType") === "Patient"
        ? {
            collapse: true,
            name: "Fitbit Data",
            icon: Public,
            state: "fitbitCollapse",
            views: [
                {
                    path: "/fitbit-activities",
                    name: "Fitbit Activities",
                    mini: "FA",
                    component: FitbitActivites,
                    layout: "/admin",
                },
                {
                    path: "/fitbit-sleep-data",
                    name: "Sleep Data",
                    mini: "SD",
                    component: FitbitSleepData,
                    layout: "/admin",
                },
                //   {
                //       path: "/fitbit-graph",
                //       name: "Fitbit Graph",
                //       mini: "FG",
                //       component: FitbitGraph,
                //       layout: "/admin",
                //   },
            ],
        }
        : { redirect: true };

const appleHealth =
    localStorage.getItem("loginType") === "Patient"
        ? {
            collapse: true,
            name: "Apple Health",
            icon: Public,
            state: "appleHealthCollapse",
            views: [
                {
                    path: "/healthData",
                    name: "Apple Health Data",
                    icon: Favorite,
                    component: HealthData,
                    layout: "/admin",
                },
                {
                    path: "/appleHealthEcg",
                    name: "Apple Health ECG Data",
                    icon: BrokenImage,
                    component: AppleHealthECG,
                    layout: "/admin",
                },
            ],
        }
        : { redirect: true };
var dashRoutes = [
    // {
    //     path: "/dashboard",
    //     name: "Dashboard",
    //     icon: DashboardIcon,
    //     component: Dashboard,
    //     layout: "/admin"
    // },
    menu,
    patientInvitation,
    gooleFitData,
    appleHealth,
    fitbitData,
    caregiverHeader,
    addPatientNotes,
    // caregiverFollower,
    foodLog,
    caregiverList,
    adminUserNotification,
    // diaBitics,
    diabuddyAllUsers,
    mSubscriptions,
    mChampionSubscriptions,
    patientHealthData,
    patientECGData,
    apiUser,
    profilePage,
    localStorage.getItem("loginType") === "Caregiver" || localStorage.getItem("loginType") === "Patient"
        ? {
            collapse: true,
            name: "Reports",
            icon: Report,
            state: "reportCollapse",
            views: [
                {
                    path: "/patient-date-selection",
                    name: "Notes Report",
                    // mini: "NR",
                    icon: Image,
                    component: PatientAndDateSelection,
                    layout: "/admin",
                },
                {
                    path: "/data-report",
                    name: "Data Reports",
                    // mini: "DR",
                    icon: Assessment,
                    component: DailyReport,
                    layout: "/admin",
                },
            ],
        }
        : { redirect: true },
    localStorage.getItem("loginType") === "Caregiver" && localStorage.getItem("is_parent") === "1"
        ? {
            collapse: true,
            name: "Manage Group",
            icon: SupervisorAccount,
            state: "groupCollapse",
            views: [
                {
                    path: "/groups",
                    name: "Group",
                    // mini: "NR",
                    icon: GroupAdd,
                    component: groups,
                    layout: "/admin",
                },
                {
                    path: "/group-patient",
                    name: "Patients",
                    // mini: "NR",
                    icon: PersonAdd,
                    component: groupPatient,
                    layout: "/admin",
                },
                {
                    path: "/group-caregiver",
                    name: "Caregivers",
                    // mini: "DR",
                    icon: PersonAdd,
                    component: groupCaregiver,
                    layout: "/admin",
                },
            ],
        }
        : { redirect: true },
    {
        path: "/patientInfo",
        name: "Patient Info",
        component: Patient,
        layout: "/admin",
        redirect: true,
    },
    {
        path: "/dual-graph-report",
        name: "DualGraph Report",
        component: DualGraphReport,
        layout: "/admin",
        redirect: true,
    },
    {
        path: "/notes-report",
        name: "Notes Report",
        component: NotesReport,
        layout: "/admin",
        redirect: true,
    },
];
export default dashRoutes;
