import React from "react";
import $ from "jquery";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";
import SweetAlert from "react-bootstrap-sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Table from "components/Table/Table.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
class groupPatientList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            isLoading: false,
            notificationMessage: "",
            classicModal: false,
            patients: [],
            patientList: [],
            group_name: "",
            patient_name: "",
            groups: [],
            deleteAlert: null,
            id: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.addPatientDialog = this.addPatientDialog.bind(this);
        this.setClassicModal = this.setClassicModal.bind(this);
    }
    getFollowers() {
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-patient-list",
            data: {
                token: localStorage.getItem("token"),
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ patients: DataJSON.patients });
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    componentDidMount() {
        document.title = "Manage Group Patient - Ambrosia Care";
        this.loadingPatientList();
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
            this.getFollowers();
            $.ajax({
                method: "POST",
                url: BASE_URL + "/get-group",
                data: {
                    token: localStorage.getItem("token"),
                    platform_name: "WEB",
                },
                success: function (data) {
                    var DataJSON = JSON.parse(data);
                    if (DataJSON.success === false) {
                        if (DataJSON.msg.includes("Invalid token")) {
                            removeFromLocalStorage();
                            this.props.history.push("/");
                        }
                    } else {
                        this.setState({ groups: DataJSON.data });
                    }
                }.bind(this),
                error: (error) => {
                    console.error(error);
                },
            });
        }
    }
    loadingPatientList() {
        if (!localStorage.getItem("token") || localStorage.getItem("loginType") !== "Caregiver") {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            super_caregiver_id: localStorage.getItem("user_id"),
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-group-patient-list",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ patientList: [], deleteAlert: null });
                } else {
                    this.setState({ patientList: DataJSON.patients, deleteAlert: null });
                }

                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    deletePatientData(user_id) {
        const data = {
            token: localStorage.getItem("token"),
            patient_id: user_id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/delete-group-patient",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({
                        deleteAlert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "-100px" }}
                                title="Deleted!"
                                onConfirm={() => this.loadingPatientList()}
                                // onCancel={() => this.hideAlert()}
                                confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
                            >
                                Patient is removed from the group.
                            </SweetAlert>
                        ),
                    });
                    this.getFollowers();
                }
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    hideAlertDelete() {
        this.setState({ deleteAlert: null });
    }
    warningWithConfirmMessageDelete(user_id) {
        this.setState({
            deleteAlert: (
                <SweetAlert warning style={{ display: "block", marginTop: "-100px" }} title="Are you sure?" onConfirm={() => this.deletePatientData(user_id)} onCancel={() => this.hideAlertDelete()} confirmBtnText="Yes, Delete it!" cancelBtnText="Cancel" showCancel>
                    Following Patient will be removed from the group.
                </SweetAlert>
            ),
        });
    }
    deletePatient(user_id) {
        return (
            <Button justIcon round simple color="danger" className="remove" onClick={() => this.warningWithConfirmMessageDelete(user_id)}>
                <Delete />
            </Button>
        );
    }
    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()}`,
            patient_email: e.email,
        });
    }
    handleGroupDropdownChange({ ...e }) {
        this.setState({
            id: e.id,
            group_name: `${e.group_name.toUpperCase()} `,
        });
    }
    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }

    handleSubmitForm(patient_id, group_id) {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        const data = {
            token: localStorage.getItem("token"),
            group_id: group_id,
            patient_id: patient_id,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-group-patient",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                        this.setState({ notificationMessage: "Something went wrong while adding patient in group." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("Patient is already exist in other group.")) {
                        this.setState({ notificationMessage: "Patient is already exist in other group." }, () => {
                            this.showNotification("tc_danger");
                        });
                    } else if (DataJSON.msg.includes("FAILED")) {
                        this.setState({ notificationMessage: "Something went wrong while adding patient in group." }, () => {
                            this.showNotification("tc_danger");
                        });
                    }
                } else {
                    this.setState({ notificationMessage: "Patient is added to the group." }, () => {
                        this.showNotification("tc_info");
                        this.setClassicModal(false);
                        this.loadingPatientList();
                        this.getFollowers();
                    });
                }
                this.setState({ group_name: "", patient_name: "" });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    addPatientDialog() {
        this.setClassicModal(true);
    }
    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    setClassicModal(classicModal) {
        this.setState({
            classicModal: classicModal,
        });
    }
    render() {
        const { classes } = this.props;
        var readingCount = 0;
        const tableData = this.state.patientList.map((data, i) => {
            const { patient_id, first_name, last_name, group_name, email } = data;
            readingCount++;
            return [readingCount, `${first_name} ${last_name}`, group_name, email, this.deletePatient(patient_id)];
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                {this.state.deleteAlert}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info" icon />
                            <CardBody style={{ background: "black" }}>
                                <GridContainer>
                                    <GridItem style={{ display: "flex", alignItems: "center" }} xs={12} sm={12} md={12}>
                                        <Button style={{ marginLeft: "10px" }} color="info" onClick={this.addPatientDialog} className={classes.updateProfileButton}>
                                            Add Patient
                                        </Button>
                                    </GridItem>
                                </GridContainer>

                                <Clearfix />
                            </CardBody>
                        </Card>
                        {tableData.length > 0 ? (
                            <Card>
                                <CardBody style={{ background: "black" }}>
                                    <PerfectScrollbar>
                                        <Table tableHeaderColor="primary" tableHead={["ID", "Patient Name", "Group Name", "Email", "Action"]} tableData={tableData} />
                                    </PerfectScrollbar>
                                </CardBody>
                            </Card>
                        ) : (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Card>
                                        <CardBody style={{ height: "200px" }}>
                                            <h4
                                                style={{
                                                    marginTop: "80px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                There are no patients available in any group.
                                            </h4>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        )}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Dialog
                            classes={{
                                root: classes.center + " " + classes.modalRoot,
                                paper: classes.modal,
                            }}
                            open={this.state.classicModal}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => this.setClassicModal(false)}
                            aria-labelledby="classic-modal-slide-title"
                            aria-describedby="classic-modal-slide-description"
                            PaperProps={{
                                style: {
                                    minHeight: "25vh",
                                    minWidth: "25vw",
                                },
                            }}
                        >
                            <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader} style={{ background: "black" }}>
                                <Button justIcon className={classes.modalCloseButton} key="close" aria-label="Close" color="transparent" onClick={() => this.setClassicModal(false)}>
                                    <Close className={classes.modalClose} />
                                </Button>
                                <h4 className={classes.modalTitle} style={{ color: "white" }}>Add Patient To Group</h4>
                            </DialogTitle>
                            <DialogContent id="classic-modal-slide-description" className={classes.modalBody} style={{ background: "black" }}>
                                <p style={{ color: "white" }}>Are You sure you want to add patient to the group ?</p>
                                <CustomDropdown
                                    hoverColor="info"
                                    buttonText={this.state.patient_name === "" ? "Select Patient" : this.state.patient_name}
                                    buttonProps={{
                                        round: true,
                                        fullWidth: true,
                                        style: { marginBottom: "0", width: "300px" },
                                        color: "info",
                                    }}
                                    id="secondYaxis"
                                    dropdownList={this.state.patients.map((e, i) => (
                                        <p
                                            style={{
                                                display: "block",        // Makes the <p> behave like an <li>
                                                color: "white",
                                                width: "100%",
                                                margin: 0,               // Removes any default margin
                                                padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                boxSizing: "border-box",
                                            }}
                                            onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                            {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                        </p>
                                    ))}
                                />
                                <CustomDropdown
                                    hoverColor="info"
                                    buttonText={this.state.group_name === "" ? "Select Group" : this.state.group_name}
                                    buttonProps={{
                                        round: true,
                                        fullWidth: true,
                                        style: { marginBottom: "0", width: "300px" },
                                        color: "info",
                                    }}
                                    id="secondYaxis"
                                    dropdownList={this.state.groups.map((e, i) => (
                                        <p
                                            style={{
                                                display: "block",        // Makes the <p> behave like an <li>
                                                color: "white",
                                                width: "100%",
                                                margin: 0,               // Removes any default margin
                                                padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                boxSizing: "border-box",
                                            }}
                                            onClick={() => this.handleGroupDropdownChange({ ...e })}>{`${e.group_name.toUpperCase()}  `}</p>
                                    ))}
                                />
                            </DialogContent>
                            <DialogActions className={classes.modalFooter} style={{ background: "black" }}>
                                <Button justIcon round simple color="primary" disabled={this.state.group_name === "" || this.state.patient_name === ""} className="remove" onClick={() => this.handleSubmitForm(this.state.patient_id, this.state.id)}>
                                    <PersonAddIcon />
                                </Button>
                                <Button onClick={() => this.setClassicModal(false)} color="danger" simple>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(groupPatientList);
