import React, { Component } from "react";
import ReactTable from "react-table";
import $ from "jquery";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BASE_URL, FITBIT_BASE_URL, FITBIT_AUTHENTICATE_URL, FITBIT_CLIENT_ID, FITBIT_REDIRECT_URI, removeFromLocalStorage } from "views/Utiles/Constants";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";

class FitbitAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            executed: false,
            isLoading: false,
            syncButton: false,
            sleepHistoryData: [],
            sleepData: [],
        };
        this.authenticate = this.authenticate.bind(this);
        // this.syncData = this.syncData.bind(this);
        this.getFitbitSleepData = this.getFitbitSleepData.bind(this);
        this.getFitbitSleepHistory = this.getFitbitSleepHistory.bind(this);
    }

    componentDidMount() {
        document.title = "Sleep Data - Ambrosia Care";
        const urlParams = new URLSearchParams(this.props.location.search);
        const code = urlParams.get("code");
        if (code !== null) {
            $.ajax({
                method: "POST",
                url: FITBIT_BASE_URL + "/authorize",
                data: {
                    code: code,
                    patientId: localStorage.getItem("user_id"),
                    platform_name: "WEB",
                },
                success: function (data) {
                    // console.log(data);
                    var DataJSON = JSON.parse(data);
                    if (DataJSON.success === true) {
                        localStorage.setItem("fitbit_access_token", DataJSON.fitbit_access_token);
                        localStorage.setItem("fitbit_refresh_token", DataJSON.fitbit_refresh_token);
                    }
                    this.props.history.push("/fitbit-sleep-data");
                    this.getFitbitSleepData();
                    this.getFitbitSleepHistory();
                }.bind(this),
                error: (error) => {
                    console.error(error);
                },
            });
        }
        // For Geting Fitbit Data For Table
        if (localStorage.getItem("fitbit_access_token") !== "") {
            this.getFitbitSleepData();
            this.getFitbitSleepHistory();
        }
    }

    authenticate() {
        window.location.href = FITBIT_AUTHENTICATE_URL + "?response_type=code&client_id=" + FITBIT_CLIENT_ID + "&redirect_uri=" + FITBIT_REDIRECT_URI + "&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=604800";
    }

    getFitbitSleepHistory() {
        this.setState({ isLoading: true });
        this.setState({ syncButton: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-fitbit-sleep-data",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("user_id"),
                platform_name: "WEB",
                period: "last night",
                type: "table",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    // if (!DataJSON.data.length) {
                    //     this.setState({ sleepHistoryData: DataJSON.data });
                    // }
                    if ("msg" in DataJSON && DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ sleepHistoryData: DataJSON.data });
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    getFitbitSleepData() {
        this.setState({ isLoading: true });
        this.setState({ syncButton: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-fitbit-sleep-data",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("user_id"),
                platform_name: "WEB",
                period: "7 days",
                type: "table",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    // if (!DataJSON.data.length) {
                    //     this.setState({ sleepData: DataJSON.data });
                    // }
                    if ("msg" in DataJSON && DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                } else {
                    this.setState({ sleepData: DataJSON.data });
                }
                this.setState({ isLoading: false });
                this.setState({ syncButton: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    render() {
        const { classes } = this.props;
        var count = 0;
        var sleepCount = 0;
        const sleepHistoryData = this.state.sleepHistoryData.map((fitbitData, i) => {
            const { dateTime, level, seconds } = fitbitData;
            count++;
            return {
                id: count,
                dateTime: dateTime,
                level: level,
                seconds: seconds,
            };
        });

        const sleepData = this.state.sleepData.map((fitbitData, i) => {
            const { date, deep, light, rem, wake, asleep, awake, restless, totalMinutesAsleep, totalTimeInBed } = fitbitData;
            sleepCount++;
            return {
                id: sleepCount,
                date: date,
                deep: deep,
                light: light,
                rem: rem,
                wake: wake,
                asleep: asleep,
                awake: awake,
                restless: restless,
                totalMinutesAsleep: totalMinutesAsleep,
                totalTimeInBed: totalTimeInBed,
            };
        });

        return localStorage.getItem("fitbit_access_token") === "null" || localStorage.getItem("fitbit_access_token") === "" ? (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button color="info" className={classes.marginRight} onClick={this.authenticate}>
                        Authorize
                    </Button>
                </GridItem>
            </GridContainer>
        ) : (
            <div>
                {/* <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button color="info" className={classes.marginRight} disabled={this.state.syncButton} onClick={this.getFitbitSleepData}>
                            Last Night
                        </Button>
                        <Button color="info" className={classes.marginRight} disabled={this.state.syncButton} onClick={this.getFitbitSleepHistory}>
                            Last 7 Days
                        </Button>
                    </GridItem>
                </GridContainer> */}
                {this.state.isLoading ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card plain>
                                <CardBody style={{ height: "100px" }}>
                                    <CircularProgress
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Last Night Sleep History</h4>
                                </CardHeader>
                                <CardBody>
                                    <PerfectScrollbar>
                                        <ReactTable
                                            data={sleepHistoryData}
                                            filterable
                                            columns={[
                                                {
                                                    Header: "ID",
                                                    accessor: "id",
                                                },
                                                {
                                                    Header: "DateTime",
                                                    accessor: "dateTime",
                                                },
                                                {
                                                    Header: "Level",
                                                    accessor: "level",
                                                },
                                                {
                                                    Header: "Time Period (In Sec)",
                                                    accessor: "seconds",
                                                },
                                            ]}
                                            defaultPageSize={20}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            className="-highlight"
                                        />
                                    </PerfectScrollbar>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                )}
                {this.state.isLoading ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card plain>
                                <CardBody style={{ height: "100px" }}>
                                    <CircularProgress
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Last 7 Nights Sleep History</h4>
                                </CardHeader>
                                <CardBody>
                                    <PerfectScrollbar>
                                        <ReactTable
                                            data={sleepData}
                                            filterable
                                            columns={[
                                                {
                                                    Header: "ID",
                                                    accessor: "id",
                                                },
                                                {
                                                    Header: "Date",
                                                    accessor: "date",
                                                },
                                                {
                                                    Header: "Deep",
                                                    accessor: "deep",
                                                },
                                                {
                                                    Header: "Light",
                                                    accessor: "light",
                                                },
                                                {
                                                    Header: "REM",
                                                    accessor: "rem",
                                                },
                                                {
                                                    Header: "Wake",
                                                    accessor: "wake",
                                                },
                                                {
                                                    Header: "Asleep",
                                                    accessor: "asleep",
                                                },
                                                {
                                                    Header: "Awake",
                                                    accessor: "awake",
                                                },
                                                {
                                                    Header: "Restless",
                                                    accessor: "restless",
                                                },
                                                {
                                                    Header: "Total Asleep (Min)",
                                                    accessor: "totalMinutesAsleep",
                                                },
                                                {
                                                    Header: "Total In Bed (Min)",
                                                    accessor: "totalTimeInBed",
                                                },
                                            ]}
                                            defaultPageSize={7}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            className="-highlight"
                                        />
                                    </PerfectScrollbar>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                )}
            </div>
        );
    }
}

export default withStyles(extendedTablesStyle)(FitbitAuthentication);
