import React from "react";
import Report from "../bv-react-data-report/report";

export default class DualGraphReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            options: {},
            type: "",
            patientName: "",
            patientEmail: "",
            flag: false
        };
    }

    componentDidMount() {
        document.title = "Dual Graph Report - Ambrosia Care";
        if (this.props.location.state) {
            const { data, options, type, patientName, patientEmail } = this.props.location.state;
            this.setState({ data, options, type, patientName, patientEmail, flag: true });
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        return this.state.flag ? (
            <Report
                data={this.state.data}
                options={this.state.options}
                type={this.state.type}
                patientName={this.state.patientName}
                patientEmail={this.state.patientEmail}
            />
        ) : null;
    }
}
