import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import md5 from "md5";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";
import Swal from "sweetalert2";
import Button from "@mui/material/Button"

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            first_name: "",
            last_name: "",
            current_time: new Date(),
            email: "",
            password: "",
            dob: "",
            phone1: "",
            country: "",
            userType: "patient",
            errors: {},
            isRegistration: false,
            loginError: false,
            isLoading: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmitLoginAttempt = this.onSubmitLoginAttempt.bind(this);
    }
    componentDidMount() {
        document.title = "Login - Ambrosia Care";
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
        if (localStorage.getItem("token") !== null) {
            this.props.history.push(localStorage.getItem("loginType") === "Caregiver" ? "/patientList" : "/patientInfo");
        }
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    componentWillReceiveProps() {
        if (localStorage.token) {
            window.location.reload();
        }
    }

    onChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    openDialog(e) {
        Swal.fire({
            title: 'Forgot Password',
            input: 'email',
            inputPlaceholder: 'Please enter your email address',
            showCancelButton: true,
            confirmButtonText: 'Send Password Reset Link',
            cancelButtonText: 'Cancel',
            background: "black",
            confirmButtonColor: '#4FACFE',
            cancelButtonColor: '#4FACFE',
            preConfirm: (email) => {
                Swal.fire({
                    title: 'Loading',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                $.ajax({
                    method: "POST",
                    url: BASE_URL + "/forgot-password",
                    data: {
                        email: email,
                    },
                    success: function (data) {
                        var DataJSON = JSON.parse(data);
                        if (DataJSON.success === false) {
                            if (DataJSON.msg !== undefined) {
                                if (DataJSON.msg.includes("Invalid token")) {
                                    removeFromLocalStorage();
                                    this.props.history.push("/");
                                }
                            }
                        } else {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    Swal.close();
                                    Swal.fire({
                                        title: 'Password Link Sent',
                                        text: `${DataJSON.msg}`,
                                        icon: 'success',
                                    });
                                }, 1000);
                            });
                        }
                    },
                    error: (error) => {
                        console.error(error);
                    },
                });
            },
        });
    }

    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            second = "" + date.getSeconds(),
            millisecond = "" + date.getMilliseconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;
        if (millisecond.length < 2) millisecond = "00" + millisecond;
        return `${year}-${month}-${day} ${hours}:${minute}:${second}`;
    }
    onSubmitLoginAttempt(e) {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: md5(this.state.password),
            last_login: this.formatTimestamp(this.state.current_time),
            platform_name: "WEB",
        };
        this.setState({ isLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/sign-in",
            data: userData,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    this.setState({ isLoading: false });
                    this.setState({ errors: DataJSON.msg });
                    this.setState({ loginError: "Oops!. Please check the username and password." });
                } else {
                    if (this.state.userType === "patient" && DataJSON.user_type === "Patient") {
                        this.setState({ loginError: false });
                        localStorage.setItem("profile_picture", DataJSON.profile_picture);
                        localStorage.setItem("first_name", DataJSON.first_name);
                        localStorage.setItem("last_name", DataJSON.last_name);
                        localStorage.setItem("email", DataJSON.email);
                        localStorage.setItem("country", DataJSON.country);

                        localStorage.setItem("device_id", DataJSON.device_id);
                        localStorage.setItem("user_id", DataJSON.user_id);
                        localStorage.setItem("loginType", "Patient");
                        localStorage.setItem("token", DataJSON.web_token);
                        localStorage.setItem("glucose_unit", DataJSON.glucose_unit);
                        localStorage.setItem("fitbit_access_token", DataJSON.fitbit_access_token);
                        localStorage.setItem("fitbit_refresh_token", DataJSON.fitbit_refresh_token);
                        localStorage.setItem("google_fit_access_token", DataJSON.google_fit_access_token);
                        if (DataJSON.hasOwnProperty("settings_json")) {
                            let settings_json = JSON.parse(DataJSON.settings_json);
                            if (settings_json !== null) {
                                if (settings_json.hasOwnProperty('companyDetails')) {
                                    localStorage.setItem("memberID", settings_json.companyDetails.memberID);
                                    localStorage.setItem("companyLastUpdatedTime", settings_json.companyDetails.companyLastUpdatedTime);
                                } else {
                                    localStorage.setItem("memberID", '999');
                                    localStorage.setItem("companyLastUpdatedTime", 0);
                                }
                            }
                        }
                        window.location.reload();
                    } else if (this.state.userType === "caregiver" && DataJSON.user_type === "Caregiver") {
                        this.setState({ loginError: false });
                        localStorage.setItem("profile_picture", DataJSON.profile_picture);
                        localStorage.setItem("first_name", DataJSON.first_name);
                        localStorage.setItem("last_name", DataJSON.last_name);
                        localStorage.setItem("email", DataJSON.email);
                        localStorage.setItem("country", DataJSON.country);

                        localStorage.setItem("device_id", DataJSON.device_id);
                        localStorage.setItem("user_id", DataJSON.user_id);
                        localStorage.setItem("loginType", "Caregiver");
                        localStorage.setItem("token", DataJSON.web_token);
                        localStorage.setItem("glucose_unit", DataJSON.glucose_unit);
                        localStorage.setItem("fitbit_access_token", DataJSON.fitbit_access_token);
                        localStorage.setItem("fitbit_refresh_token", DataJSON.fitbit_refresh_token);
                        localStorage.setItem("google_fit_access_token", DataJSON.google_fit_access_token);
                        localStorage.setItem("is_parent", DataJSON.is_parent);
                        window.location.reload();
                    } else {
                        this.setState({ isLoading: false });
                        this.setState({ userType: "patient" });
                        this.setState({ loginError: "Please select the right user type." });
                    }
                }
            }.bind(this),
            error: (error) => {
                this.setState({ isLoading: false });
                this.setState({ loginError: "Somthing went wrong while authentaction. Please try again." });
                console.error(error);
            },
        });
    }

    render() {
        const { classes, ...rest } = this.props;
        return this.state.isRegistration ? (
            <Redirect to="/caregiver-registration" />
        ) : this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>
                <div className={classes.wrapper} ref="wrapper">
                    <div className={classes.fullPage}>
                        <GridContainer style={{ width: "100%" }} justify="center">
                            <GridItem xs={12} sm={9} md={3}>
                                <form id="login-form" onSubmit={this.onSubmitLoginAttempt}>
                                    <Card login className={classes[this.state.cardAnimaton]} style={{ background: "black" }}>
                                        <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="success">
                                            <h4 className={classes.cardTitle} style={{ fontWeight: '800', fontSize: '1.825em', lineHeight: '1.4em', }}>Ambrosia Care</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Email"
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                onChange={this.onChange}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Email className={classes.inputAdornmentIcon} />
                                                        </InputAdornment>
                                                    ),
                                                    name: "email",
                                                    value: this.state.email,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    name: "password",
                                                    type: "password",
                                                    value: this.state.password,
                                                    onChange: (e) => {
                                                        this.onChange(e);
                                                    },
                                                }}
                                                style={{ background: "black", color: "white" }}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={this.state.userType === "patient"}
                                                        onChange={this.onChange}
                                                        value="patient"
                                                        name="userType"
                                                        aria-label="Patient (LinkBluCon)"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot,
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                label="Patient (LinkBluCon)"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        checked={this.state.userType === "caregiver"}
                                                        onChange={this.onChange}
                                                        value="caregiver"
                                                        name="userType"
                                                        aria-label="Caregiver (FollowBluCon)"
                                                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                                                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot,
                                                        }}
                                                    />
                                                }
                                                classes={{
                                                    label: classes.label,
                                                }}
                                                label="Caregiver (FollowBluCon)"
                                            />
                                            {/* <h5 style={{ fontSize: "0.99em", color: "red" }}>
                                                <b>Note:</b> Once you login here, You will be logged out from the
                                                {this.state.userType === "patient" ? " LinkBluCon" : " FollowBluCon"} mobile app and you need to
                                                re-login in app.
                                            </h5> */}
                                        </CardBody>
                                        <div style={{ textAlign: 'center' }}>
                                            <Button variant="contained" type="submit" style={{ background: '#4FACFE' }} simple size="lg" block>
                                                Login
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem' }}>
                                            <Button type="submit" simple size="lg" block onClick={() => { this.setState({ isRegistration: true }) }}>
                                                Register Caregiver Account
                                            </Button>
                                            <Button simple size="lg" block onClick={() => { this.openDialog() }}>
                                                forgot password
                                            </Button>
                                        </div>
                                    </Card>
                                </form>
                            </GridItem>
                        </GridContainer>
                        <Footer white />
                    </div>
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle, loginPageStyle)(LoginPage);
