export function getSampleTypeData(DataJSON) {
    let data = [
        { type: "HKQuantityTypeIdentifierBodyMassIndex", name: "Body Mass Index", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBodyFatPercentage", name: "Body Fat Percentage", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierHeight", name: "Height", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBodyMass", name: "Body Mass", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierLeanBodyMass", name: "Lean Body Mass", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierWaistCircumference", name: "Waist Circumference", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierStepCount", name: "Steps", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDistanceWalkingRunning", name: "Distance Walking Running", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDistanceCycling", name: "Distance Cycling", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDistanceWheelchair", name: "Distance Wheelchair", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBasalEnergyBurned", name: "Basal Energy Burned", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierActiveEnergyBurned", name: "Active Energy Burned", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierFlightsClimbed", name: "Flights Climbed", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierNikeFuel", name: "Nike Fuel", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierAppleExerciseTime", name: "Exercise Time", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierPushCount", name: "Push Count", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDistanceSwimming", name: "Distance Swimming", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierSwimmingStrokeCount", name: "Swimming Stroke Count", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierVO2Max", name: "VO2 Max", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDistanceDownhillSnowSports", name: "Distance Downhill Snow Sports", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierAppleStandTime", name: "Stand Time", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierHeartRate", name: "Heart Rate", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBodyTemperature", name: "Body Temperature", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBasalBodyTemperature", name: "Basal Body Temperature", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBloodPressureSystolic", name: "Blood Pressure Systolic", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBloodPressureDiastolic", name: "Blood Pressure Diastolic", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierRespiratoryRate", name: "Respiratory Rate", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierRestingHeartRate", name: "Resting Heart Rate", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierWalkingHeartRateAverage", name: "Walking Heart Rate Average", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierHeartRateVariabilitySDNN", name: "Heart Rate Variability SDNN", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierOxygenSaturation", name: "Oxygen Saturation", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierPeripheralPerfusionIndex", name: "Peripheral Perfusion Index", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBloodGlucose", name: "Blood Glucose", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierNumberOfTimesFallen", name: "Number Of Times Fallen", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierElectrodermalActivity", name: "Electrodermal Activity", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierInhalerUsage", name: "Inhaler Usage", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierInsulinDelivery", name: "Insulin Delivery", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierBloodAlcoholContent", name: "Blood Alcohol Content", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierForcedVitalCapacity", name: "Forced Vital Capacity", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierForcedExpiratoryVolume1", name: "Forced Expiratory Volume1", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierPeakExpiratoryFlowRate", name: "Peak Expiratory FlowRate", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierEnvironmentalAudioExposure", name: "Environmental Audio Exposure", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierHeadphoneAudioExposure", name: "Headphone Audio Exposure", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFatTotal", name: "Dietary FatTotal", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFatPolyunsaturated", name: "Dietary Fat Polyunsaturated", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFatMonounsaturated", name: "Dietary Fat Monounsaturated", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFatSaturated", name: "Dietary Fat Saturated", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryCholesterol", name: "Dietary Cholesterol", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietarySodium", name: "Dietary Sodium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryCarbohydrates", name: "Dietary Carbohydrates", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFiber", name: "Dietary Fiber", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietarySugar", name: "Dietary Sugar", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryEnergyConsumed", name: "Dietary Energy Consumed", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryProtein", name: "Dietary Protein", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminA", name: "Dietary VitaminA", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminB6", name: "Dietary VitaminB6", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminB12", name: "Dietary VitaminB12", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminC", name: "Dietary VitaminC", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminD", name: "Dietary VitaminD", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminE", name: "Dietary VitaminE", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryVitaminK", name: "Dietary VitaminK", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryCalcium", name: "Dietary Calcium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryIron", name: "Dietary Iron", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryThiamin", name: "Dietary Thiamin", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryRiboflavin", name: "Dietary Riboflavin", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryNiacin", name: "Dietary Niacin", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryFolate", name: "Dietary Folate", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryBiotin", name: "Dietary Biotin", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryPantothenicAcid", name: "Dietary Pantothenic Acid", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryPhosphorus", name: "Dietary Phosphorus", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryIodine", name: "Dietary Iodine", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryMagnesium", name: "Dietary Magnesium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryZinc", name: "Dietary Zinc", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietarySelenium", name: "Dietary Selenium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryCopper", name: "Dietary Copper", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryManganese", name: "Dietary Manganese", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryChromium", name: "Dietary Chromium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryMolybdenum", name: "Dietary Molybdenum", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryChloride", name: "Dietary Chloride", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryPotassium", name: "Dietary Potassium", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryCaffeine", name: "Dietary Caffeine", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierDietaryWater", name: "Dietary Water", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierUVExposure", name: "UV Exposure", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierSleepAnalysis", name: "Sleep Analysis", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierAppleStandHour", name: "Stand Hour", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierCervicalMucusQuality", name: "Cervical MucusQuality", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierOvulationTestResult", name: "Ovulation Test Result", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierMenstrualFlow", name: "Menstrual Flow", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierIntermenstrualBleeding", name: "Intermenstrual Bleeding", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierSexualActivity", name: "Sexual Activity", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierMindfulSession", name: "Mindful Session", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierHighHeartRateEvent", name: "High Heart Rate Event", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierLowHeartRateEvent", name: "Low Heart Rate Event", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierIrregularHeartRhythmEvent", name: "Irregular Heart Rhythm Event", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierAudioExposureEvent", name: "Audio Exposure Event", tabularData: [], graphicalData: [] },
        { type: "HKCategoryTypeIdentifierToothbrushingEvent", name: "Toothbrushing Event", tabularData: [], graphicalData: [] },
        { type: "HKCharacteristicTypeIdentifierBiologicalSex", name: "Biological Sex", tabularData: [], graphicalData: [] },
        { type: "HKCharacteristicTypeIdentifierBloodType", name: "Blood Type", tabularData: [], graphicalData: [] },
        { type: "HKCharacteristicTypeIdentifierDateOfBirth", name: "Date Of Birth", tabularData: [], graphicalData: [] },
        { type: "HKCharacteristicTypeIdentifierFitzpatrickSkinType", name: "Fitzpatrick Skin Type", tabularData: [], graphicalData: [] },
        { type: "HKCharacteristicTypeIdentifierWheelchairUse", name: "Wheelchair Use", tabularData: [], graphicalData: [] },
        { type: "HKCorrelationTypeIdentifierBloodPressure", name: "Blood Pressure", tabularData: [], graphicalData: [] },
        { type: "HKCorrelationTypeIdentifierFood", name: "Food", tabularData: [], graphicalData: [] },
        { type: "HKDocumentTypeIdentifierCDA", name: "CDA", tabularData: [], graphicalData: [] },
        { type: "HKWorkoutTypeIdentifier", name: "Workout", tabularData: [], graphicalData: [] },
        { type: "HKWorkoutRouteTypeIdentifier", name: "Workout Route", tabularData: [], graphicalData: [] },
        { type: "HKDataTypeIdentifierHeartbeatSeries", name: "Heartbeat Series", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierNutrients", name: "Nutrients", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierWeight", name: "Weight", tabularData: [], graphicalData: [] },
        { type: "HKQuantityTypeIdentifierHydration", name: "Hydration", tabularData: [], graphicalData: [] },
    ];
    const bodyMassIndexData = [];
    const bodyFatPercentageData = [];
    const heightData = [];
    const bodyMassData = [];
    const leanBodyMassData = [];
    const waistCircumferenceData = [];
    const stepCountData = [];
    const distanceWalkingRunningData = [];
    const distanceCyclingData = [];
    const distanceWheelchairData = [];
    const basalEnergyBurnedData = [];
    const activeEnergyBurnedData = [];
    const flightsClimbedData = [];
    const nikeFuelData = [];
    const appleExerciseTimeData = [];
    const pushCountData = [];
    const distanceSwimmingData = [];
    const swimmingStrokeData = [];
    const VO2MaxData = [];
    const distanceDownhillSnowSportsData = [];
    const appleStandTimeData = [];
    const heartRateData = [];
    const bodyTemperatureData = [];
    const basalBodyTemperatureData = [];
    const bloodPressureSystolicData = [];
    const bloodPressureDiastolicData = [];
    const respiratoryRateData = [];
    const restingHeartRateData = [];
    const walkingHeartRateAverageData = [];
    const heartRateVariabilitySDNNData = [];
    const oxygenSaturationData = [];
    const peripheralPerfusionIndexData = [];
    const bloodGlucoseData = [];
    const numberOfTimesFallenData = [];
    const electrodermalActivityData = [];
    const inhalerUsageData = [];
    const insulinDeliveryData = [];
    const bloodAlcoholContentData = [];
    const forcedVitalCapacityData = [];
    const forcedExpiratoryVolume1Data = [];
    const peakExpiratoryFlowRateData = [];
    const environmentalAudioExposureData = [];
    const headphoneAudioExposureData = [];
    const dietaryFatTotalData = [];
    const dietaryFatPolyunsaturatedData = [];
    const dietaryFatMonounsaturatedData = [];
    const dietaryFatSaturatedData = [];
    const dietaryCholesterolData = [];
    const dietarySodiumData = [];
    const dietaryCarbohydratesData = [];
    const dietaryFiberData = [];
    const dietarySugarData = [];
    const dietaryEnergyConsumedData = [];
    const dietaryProteinData = [];
    const dietaryVitaminAData = [];
    const dietaryVitaminB6Data = [];
    const dietaryVitaminB12Data = [];
    const dietaryVitaminCData = [];
    const dietaryVitaminDData = [];
    const dietaryVitaminEData = [];
    const dietaryVitaminKData = [];
    const dietaryCalciumData = [];
    const dietaryIronData = [];
    const dietaryThiaminData = [];
    const dietaryRiboflavinData = [];
    const dietaryNiacinData = [];
    const dietaryFolateData = [];
    const dietaryBiotinData = [];
    const dietaryPantothenicAcidData = [];
    const dietaryPhosphorusData = [];
    const dietaryIodineData = [];
    const dietaryMagnesiumData = [];
    const dietaryZincData = [];
    const dietarySeleniumData = [];
    const dietaryCopperData = [];
    const dietaryManganeseData = [];
    const dietaryChromiumData = [];
    const dietaryMolybdenumData = [];
    const dietaryPotassiumeData = [];
    const dietaryCaffeineData = [];
    const dietaryWaterData = [];
    const UVExposureData = [];
    const sleepAnalysisData = [];
    const appleStandHourData = [];
    const cervicalMucusQualityData = [];
    const ovulationTestResultData = [];
    const menstrualFlowData = [];
    const intermenstrualBleedingData = [];
    const sexualActivityData = [];
    const mindfulSessionData = [];
    const highHeartRateEventData = [];
    const lowHeartRateEventData = [];
    const irregularHeartRhythmEventData = [];
    const audioExposureEventData = [];
    const toothbrushingEventData = [];
    const biologicalSexData = [];
    const bloodTypeData = [];
    const dateOfBirthData = [];
    const fitzpatrickSkinTypeData = [];
    const wheelchairUseData = [];
    const bloodPressureData = [];
    const foodData = [];
    const CDAData = [];
    const WorkoutTypeIdentifierData = [];
    const workoutRouteTypeIdentifierData = [];
    const dataTypeIdentifierHeartbeatSeriesData = [];
    const nutrientsData = [];
    const weightData = [];
    const hydrationData = [];

    const bodyMassIndexChartData = [];
    const bodyFatPercentageChartData = [];
    const heightChartData = [];
    const bodyMassChartData = [];
    const leanBodyMassChartData = [];
    const waistCircumferenceChartData = [];
    const stepCountChartData = [];
    const distanceWalkingRunningChartData = [];
    const distanceCyclingChartData = [];
    const distanceWheelchairChartData = [];
    const basalEnergyBurnedChartData = [];
    const activeEnergyBurnedChartData = [];
    const flightsClimbedChartData = [];
    const nikeFuelChartData = [];
    const appleExerciseTimeChartData = [];
    const pushCountChartData = [];
    const distanceSwimmingChartData = [];
    const swimmingStrokeChartData = [];
    const VO2MaxChartData = [];
    const distanceDownhillSnowSportsChartData = [];
    const appleStandTimeChartData = [];
    const heartRateChartData = [];
    const bodyTemperatureChartData = [];
    const basalBodyTemperatureChartData = [];
    const bloodPressureSystolicChartData = [];
    const bloodPressureDiastolicChartData = [];
    const respiratoryRateChartData = [];
    const restingHeartRateChartData = [];
    const walkingHeartRateAverageChartData = [];
    const heartRateVariabilitySDNNChartData = [];
    const oxygenSaturationChartData = [];
    const peripheralPerfusionIndexChartData = [];
    const bloodGlucoseChartData = [];
    const numberOfTimesFallenChartData = [];
    const electrodermalActivityChartData = [];
    const inhalerUsageChartData = [];
    const insulinDeliveryChartData = [];
    const bloodAlcoholContentChartData = [];
    const forcedVitalCapacityChartData = [];
    const forcedExpiratoryVolume1ChartData = [];
    const peakExpiratoryFlowRateChartData = [];
    const environmentalAudioExposureChartData = [];
    const headphoneAudioExposureChartData = [];
    const dietaryFatTotalChartData = [];
    const dietaryFatPolyunsaturatedChartData = [];
    const dietaryFatMonounsaturatedChartData = [];
    const dietaryFatSaturatedChartData = [];
    const dietaryCholesterolChartData = [];
    const dietarySodiumChartData = [];
    const dietaryCarbohydratesChartData = [];
    const dietaryFiberChartData = [];
    const dietarySugarChartData = [];
    const dietaryEnergyConsumedChartData = [];
    const dietaryProteinChartData = [];
    const dietaryVitaminAChartData = [];
    const dietaryVitaminB6ChartData = [];
    const dietaryVitaminB12ChartData = [];
    const dietaryVitaminCChartData = [];
    const dietaryVitaminDChartData = [];
    const dietaryVitaminEChartData = [];
    const dietaryVitaminKChartData = [];
    const dietaryCalciumChartData = [];
    const dietaryIronChartData = [];
    const dietaryThiaminChartData = [];
    const dietaryRiboflavinChartData = [];
    const dietaryNiacinChartData = [];
    const dietaryFolateChartData = [];
    const dietaryBiotinChartData = [];
    const dietaryPantothenicAcidChartData = [];
    const dietaryPhosphorusChartData = [];
    const dietaryIodineChartData = [];
    const dietaryMagnesiumChartData = [];
    const dietaryZincChartData = [];
    const dietarySeleniumChartData = [];
    const dietaryCopperChartData = [];
    const dietaryManganeseChartData = [];
    const dietaryChromiumChartData = [];
    const dietaryMolybdenumChartData = [];
    const dietaryPotassiumeChartData = [];
    const dietaryCaffeineChartData = [];
    const dietaryWaterChartData = [];
    const UVExposureChartData = [];
    const sleepAnalysisChartData = [];
    const appleStandHourChartData = [];
    const cervicalMucusQualityChartData = [];
    const ovulationTestResultChartData = [];
    const menstrualFlowChartData = [];
    const intermenstrualBleedingChartData = [];
    const sexualActivityChartData = [];
    const mindfulSessionChartData = [];
    const highHeartRateEventChartData = [];
    const lowHeartRateEventChartData = [];
    const irregularHeartRhythmEventChartData = [];
    const audioExposureEventChartData = [];
    const toothbrushingEventChartData = [];
    const biologicalSexChartData = [];
    const bloodTypeChartData = [];
    const dateOfBirthChartData = [];
    const fitzpatrickSkinTypeChartData = [];
    const wheelchairUseChartData = [];
    const bloodPressureChartData = [];
    const foodChartData = [];
    const CDAChartData = [];
    const WorkoutTypeIdentifierChartData = [];
    const workoutRouteTypeIdentifierChartData = [];
    const dataTypeIdentifierHeartbeatSeriesChartData = [];
    const nutrientsChartData = [];
    const weightChartData = [];
    const hydrationChartData = [];

    var bodyMassIndexCount = 0;
    var bodyFatPercentageCount = 0;
    var heightCount = 0;
    var bodyMassCount = 0;
    var leanBodyMassCount = 0;
    var waistCircumferenceCount = 0;
    var stepCount = 0;
    var distanceWalkingRunningCount = 0;
    var distanceCyclingCount = 0;
    var distanceWheelchairCount = 0;
    var basalEnergyBurnedCount = 0;
    var activeEnergyBurnedCount = 0;
    var flightsClimbedCount = 0;
    var nikeFuelCount = 0;
    var appleExerciseTimeCount = 0;
    var pushCount = 0;
    var distanceSwimmingCount = 0;
    var swimmingStrokeCount = 0;
    var VO2MaxCount = 0;
    var distanceDownhillSnowSportsCount = 0;
    var appleStandTimeCount = 0;
    var heartRateCount = 0;
    var bodyTemperatureCount = 0;
    var basalBodyTemperatureCount = 0;
    var bloodPressureSystolicCount = 0;
    var bloodPressureDiastolicCount = 0;
    var respiratoryRateCount = 0;
    var restingHeartRateCount = 0;
    var walkingHeartRateAverageCount = 0;
    var heartRateVariabilitySDNNCount = 0;
    var oxygenSaturationCount = 0;
    var peripheralPerfusionIndexCount = 0;
    var bloodGlucoseCount = 0;
    var numberOfTimesFallenCount = 0;
    var electrodermalActivityCount = 0;
    var inhalerUsageCount = 0;
    var insulinDeliveryCount = 0;
    var bloodAlcoholContentCount = 0;
    var forcedVitalCapacityCount = 0;
    var forcedExpiratoryVolume1Count = 0;
    var peakExpiratoryFlowRateCount = 0;
    var environmentalAudioExposureCount = 0;
    var headphoneAudioExposure = 0;
    var dietaryFatTotalCount = 0;
    var dietaryFatPolyunsaturatedCount = 0;
    var dietaryFatMonounsaturatedCount = 0;
    var dietaryFatSaturatedCount = 0;
    var dietaryCholesterolCount = 0;
    var dietarySodiumCount = 0;
    var dietaryCarbohydratesCount = 0;
    var dietaryFiberCount = 0;
    var dietarySugarCount = 0;
    var dietaryEnergyConsumedCount = 0;
    var dietaryProteinCount = 0;
    var dietaryVitaminACount = 0;
    var dietaryVitaminB6Count = 0;
    var dietaryVitaminB12Count = 0;
    var dietaryVitaminCCount = 0;
    var dietaryVitaminDCount = 0;
    var dietaryVitaminECount = 0;
    var dietaryVitaminKCount = 0;
    var dietaryCalciumCount = 0;
    var dietaryIronCount = 0;
    var dietaryThiaminCount = 0;
    var dietaryRiboflavinCount = 0;
    var dietaryNiacinCount = 0;
    var dietaryFolateCount = 0;
    var dietaryBiotinCount = 0;
    var dietaryPantothenicAcidCount = 0;
    var dietaryPhosphorusCount = 0;
    var dietaryIodineCount = 0;
    var dietaryMagnesiumCount = 0;
    var dietaryZincCount = 0;
    var dietarySeleniumCount = 0;
    var dietaryCopperCount = 0;
    var dietaryManganeseCount = 0;
    var dietaryChromiumCount = 0;
    var dietaryMolybdenumCount = 0;
    var dietaryPotassiumeCount = 0;
    var dietaryCaffeineCount = 0;
    var dietaryWaterCount = 0;
    var UVExposureCount = 0;
    var sleepAnalysisCount = 0;
    var appleStandHourCount = 0;
    var cervicalMucusQualityCount = 0;
    var ovulationTestResultCount = 0;
    var menstrualFlowCount = 0;
    var intermenstrualBleedingCount = 0;
    var sexualActivityCount = 0;
    var mindfulSessionCount = 0;
    var highHeartRateEventCount = 0;
    var lowHeartRateEventCount = 0;
    var irregularHeartRhythmEventCount = 0;
    var audioExposureEventCount = 0;
    var toothbrushingEventCount = 0;
    var biologicalSexCount = 0;
    var bloodTypeCount = 0;
    var dateOfBirthCount = 0;
    var fitzpatrickSkinTypeCount = 0;
    var wheelchairUseCount = 0;
    var bloodPressureCount = 0;
    var foodCount = 0;
    var CDACount = 0;
    var WorkoutTypeIdentifierCount = 0;
    var workoutRouteTypeIdentifierCount = 0;
    var dataTypeIdentifierHeartbeatSeriesCount = 0;
    var nutrientsCount = 0;
    var weightCount = 0;
    var hydrationCount = 0;

    bodyMassIndexChartData.push(["Time", "data"]);
    bodyFatPercentageChartData.push(["Time", "data"]);
    heightChartData.push(["Time", "data"]);
    bodyMassChartData.push(["Time", "data"]);
    leanBodyMassChartData.push(["Time", "data"]);
    waistCircumferenceChartData.push(["Time", "data"]);
    stepCountChartData.push(["Time", "data"]);
    distanceWalkingRunningChartData.push(["Time", "data"]);
    distanceCyclingChartData.push(["Time", "data"]);
    distanceWheelchairChartData.push(["Time", "data"]);
    basalEnergyBurnedChartData.push(["Time", "data"]);
    activeEnergyBurnedChartData.push(["Time", "data"]);
    flightsClimbedChartData.push(["Time", "data"]);
    nikeFuelChartData.push(["Time", "data"]);
    appleExerciseTimeChartData.push(["Time", "data"]);
    pushCountChartData.push(["Time", "data"]);
    distanceSwimmingChartData.push(["Time", "data"]);
    swimmingStrokeChartData.push(["Time", "data"]);
    VO2MaxChartData.push(["Time", "data"]);
    distanceDownhillSnowSportsChartData.push(["Time", "data"]);
    appleStandTimeChartData.push(["Time", "data"]);
    heartRateChartData.push(["Time", "data"]);
    bodyTemperatureChartData.push(["Time", "data"]);
    basalBodyTemperatureChartData.push(["Time", "data"]);
    bloodPressureSystolicChartData.push(["Time", "data"]);
    bloodPressureDiastolicChartData.push(["Time", "data"]);
    respiratoryRateChartData.push(["Time", "data"]);
    restingHeartRateChartData.push(["Time", "data"]);
    walkingHeartRateAverageChartData.push(["Time", "data"]);
    heartRateVariabilitySDNNChartData.push(["Time", "data"]);
    oxygenSaturationChartData.push(["Time", "data"]);
    peripheralPerfusionIndexChartData.push(["Time", "data"]);
    bloodGlucoseChartData.push(["Time", "data"]);
    numberOfTimesFallenChartData.push(["Time", "data"]);
    electrodermalActivityChartData.push(["Time", "data"]);
    inhalerUsageChartData.push(["Time", "data"]);
    insulinDeliveryChartData.push(["Time", "data"]);
    bloodAlcoholContentChartData.push(["Time", "data"]);
    forcedVitalCapacityChartData.push(["Time", "data"]);
    forcedExpiratoryVolume1ChartData.push(["Time", "data"]);
    peakExpiratoryFlowRateChartData.push(["Time", "data"]);
    environmentalAudioExposureChartData.push(["Time", "data"]);
    headphoneAudioExposureChartData.push(["Time", "data"]);
    dietaryFatTotalChartData.push(["Time", "data"]);
    dietaryFatPolyunsaturatedChartData.push(["Time", "data"]);
    dietaryFatMonounsaturatedChartData.push(["Time", "data"]);
    dietaryFatSaturatedChartData.push(["Time", "data"]);
    dietaryCholesterolChartData.push(["Time", "data"]);
    dietarySodiumChartData.push(["Time", "data"]);
    dietaryCarbohydratesChartData.push(["Time", "data"]);
    dietaryFiberChartData.push(["Time", "data"]);
    dietarySugarChartData.push(["Time", "data"]);
    dietaryEnergyConsumedChartData.push(["Time", "data"]);
    dietaryProteinChartData.push(["Time", "data"]);
    dietaryVitaminAChartData.push(["Time", "data"]);
    dietaryVitaminB6ChartData.push(["Time", "data"]);
    dietaryVitaminB12ChartData.push(["Time", "data"]);
    dietaryVitaminCChartData.push(["Time", "data"]);
    dietaryVitaminDChartData.push(["Time", "data"]);
    dietaryVitaminEChartData.push(["Time", "data"]);
    dietaryVitaminKChartData.push(["Time", "data"]);
    dietaryCalciumChartData.push(["Time", "data"]);
    dietaryIronChartData.push(["Time", "data"]);
    dietaryThiaminChartData.push(["Time", "data"]);
    dietaryRiboflavinChartData.push(["Time", "data"]);
    dietaryNiacinChartData.push(["Time", "data"]);
    dietaryFolateChartData.push(["Time", "data"]);
    dietaryBiotinChartData.push(["Time", "data"]);
    dietaryPantothenicAcidChartData.push(["Time", "data"]);
    dietaryPhosphorusChartData.push(["Time", "data"]);
    dietaryIodineChartData.push(["Time", "data"]);
    dietaryMagnesiumChartData.push(["Time", "data"]);
    dietaryZincChartData.push(["Time", "data"]);
    dietarySeleniumChartData.push(["Time", "data"]);
    dietaryCopperChartData.push(["Time", "data"]);
    dietaryManganeseChartData.push(["Time", "data"]);
    dietaryChromiumChartData.push(["Time", "data"]);
    dietaryMolybdenumChartData.push(["Time", "data"]);
    dietaryPotassiumeChartData.push(["Time", "data"]);
    dietaryCaffeineChartData.push(["Time", "data"]);
    dietaryWaterChartData.push(["Time", "data"]);
    UVExposureChartData.push(["Time", "data"]);
    sleepAnalysisChartData.push(["Time", "data"]);
    appleStandHourChartData.push(["Time", "data"]);
    cervicalMucusQualityChartData.push(["Time", "data"]);
    ovulationTestResultChartData.push(["Time", "data"]);
    menstrualFlowChartData.push(["Time", "data"]);
    intermenstrualBleedingChartData.push(["Time", "data"]);
    sexualActivityChartData.push(["Time", "data"]);
    mindfulSessionChartData.push(["Time", "data"]);
    highHeartRateEventChartData.push(["Time", "data"]);
    lowHeartRateEventChartData.push(["Time", "data"]);
    irregularHeartRhythmEventChartData.push(["Time", "data"]);
    audioExposureEventChartData.push(["Time", "data"]);
    toothbrushingEventChartData.push(["Time", "data"]);
    biologicalSexChartData.push(["Time", "data"]);
    bloodTypeChartData.push(["Time", "data"]);
    dateOfBirthChartData.push(["Time", "data"]);
    fitzpatrickSkinTypeChartData.push(["Time", "data"]);
    wheelchairUseChartData.push(["Time", "data"]);
    bloodPressureChartData.push(["Time", "data"]);
    foodChartData.push(["Time", "data"]);
    CDAChartData.push(["Time", "data"]);
    WorkoutTypeIdentifierChartData.push(["Time", "data"]);
    workoutRouteTypeIdentifierChartData.push(["Time", "data"]);
    dataTypeIdentifierHeartbeatSeriesChartData.push(["Time", "data"]);
    nutrientsChartData.push(["Time", "data"]);
    weightChartData.push(["Time", "data"]);
    hydrationChartData.push(["Time", "data"]);
    if (DataJSON.data && DataJSON.data.length > 0) {
        DataJSON.data.forEach((response) => {
            switch (response.sampleType) {
                case "HKQuantityTypeIdentifierBodyMassIndex":
                    bodyMassIndexCount++;
                    response.id = bodyMassIndexCount;
                    bodyMassIndexChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bodyMassIndexData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBodyFatPercentage":
                    bodyFatPercentageCount++;
                    response.id = bodyFatPercentageCount;
                    bodyFatPercentageChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bodyFatPercentageData.push(response);
                    break;
                case "HKQuantityTypeIdentifierHeight":
                    heightCount++;
                    response.id = heightCount;
                    heightChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    heightData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBodyMass":
                    bodyMassCount++;
                    response.id = bodyMassCount;
                    bodyMassChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bodyMassData.push(response);
                    break;
                case "HKQuantityTypeIdentifierLeanBodyMass":
                    leanBodyMassCount++;
                    response.id = leanBodyMassCount;
                    leanBodyMassChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    leanBodyMassData.push(response);
                    break;
                case "HKQuantityTypeIdentifierWaistCircumference":
                    waistCircumferenceCount++;
                    response.id = waistCircumferenceCount;
                    waistCircumferenceChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    waistCircumferenceData.push(response);
                    break;
                case "HKQuantityTypeIdentifierStepCount":
                    stepCount++;
                    response.id = stepCount;
                    stepCountChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    stepCountData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDistanceWalkingRunning":
                    distanceWalkingRunningCount++;
                    response.id = distanceWalkingRunningCount;
                    distanceWalkingRunningChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    distanceWalkingRunningData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDistanceCycling":
                    distanceCyclingCount++;
                    response.id = distanceCyclingCount;
                    distanceCyclingChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    distanceCyclingData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDistanceWheelchair":
                    distanceWheelchairCount++;
                    response.id = distanceWheelchairCount;
                    distanceWheelchairChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    distanceWheelchairData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBasalEnergyBurned":
                    basalEnergyBurnedCount++;
                    response.id = basalEnergyBurnedCount;
                    basalEnergyBurnedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    basalEnergyBurnedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierActiveEnergyBurned":
                    activeEnergyBurnedCount++;
                    response.id = activeEnergyBurnedCount;
                    activeEnergyBurnedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    activeEnergyBurnedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierFlightsClimbed":
                    flightsClimbedCount++;
                    response.id = flightsClimbedCount;
                    flightsClimbedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    flightsClimbedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierNikeFuel":
                    nikeFuelCount++;
                    response.id = nikeFuelCount;
                    nikeFuelChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    nikeFuelData.push(response);
                    break;
                case "HKQuantityTypeIdentifierAppleExerciseTime":
                    appleExerciseTimeCount++;
                    response.id = appleExerciseTimeCount;
                    appleExerciseTimeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    appleExerciseTimeData.push(response);
                    break;
                case "HKQuantityTypeIdentifierPushCount":
                    pushCount++;
                    response.id = pushCount;
                    pushCountChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    pushCountData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDistanceSwimming":
                    distanceSwimmingCount++;
                    response.id = distanceSwimmingCount;
                    distanceSwimmingChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    distanceSwimmingData.push(response);
                    break;
                case "HKQuantityTypeIdentifierSwimmingStrokeCount":
                    swimmingStrokeCount++;
                    response.id = swimmingStrokeCount;
                    swimmingStrokeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    swimmingStrokeData.push(response);
                    break;
                case "HKQuantityTypeIdentifierVO2Max":
                    VO2MaxCount++;
                    response.id = VO2MaxCount;
                    VO2MaxChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    VO2MaxData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDistanceDownhillSnowSports":
                    distanceDownhillSnowSportsCount++;
                    response.id = distanceDownhillSnowSportsCount;
                    distanceDownhillSnowSportsChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    distanceDownhillSnowSportsData.push(response);
                    break;
                case "HKQuantityTypeIdentifierAppleStandTime":
                    appleStandTimeCount++;
                    response.id = appleStandTimeCount;
                    appleStandTimeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    appleStandTimeData.push(response);
                    break;
                case "HKQuantityTypeIdentifierHeartRate":
                    heartRateCount++;
                    response.id = heartRateCount;
                    heartRateChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    heartRateData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBodyTemperature":
                    bodyTemperatureCount++;
                    response.id = bodyTemperatureCount;
                    bodyTemperatureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bodyTemperatureData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBasalBodyTemperature":
                    basalBodyTemperatureCount++;
                    response.id = basalBodyTemperatureCount;
                    basalBodyTemperatureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    basalBodyTemperatureData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBloodPressureSystolic":
                    bloodPressureSystolicCount++;
                    response.id = bloodPressureSystolicCount;
                    bloodPressureSystolicChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodPressureSystolicData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBloodPressureDiastolic":
                    bloodPressureDiastolicCount++;
                    response.id = bloodPressureDiastolicCount;
                    bloodPressureDiastolicChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodPressureDiastolicData.push(response);
                    break;
                case "HKQuantityTypeIdentifierRespiratoryRate":
                    respiratoryRateCount++;
                    response.id = respiratoryRateCount;
                    respiratoryRateChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    respiratoryRateData.push(response);
                    break;
                case "HKQuantityTypeIdentifierRestingHeartRate":
                    restingHeartRateCount++;
                    response.id = restingHeartRateCount;
                    restingHeartRateChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    restingHeartRateData.push(response);
                    break;
                case "HKQuantityTypeIdentifierWalkingHeartRateAverage":
                    walkingHeartRateAverageCount++;
                    response.id = walkingHeartRateAverageCount;
                    walkingHeartRateAverageChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    walkingHeartRateAverageData.push(response);
                    break;
                case "HKQuantityTypeIdentifierHeartRateVariabilitySDNN":
                    heartRateVariabilitySDNNCount++;
                    response.id = heartRateVariabilitySDNNCount;
                    heartRateVariabilitySDNNChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    heartRateVariabilitySDNNData.push(response);
                    break;
                case "HKQuantityTypeIdentifierOxygenSaturation":
                    oxygenSaturationCount++;
                    response.id = oxygenSaturationCount;
                    oxygenSaturationChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    oxygenSaturationData.push(response);
                    break;
                case "HKQuantityTypeIdentifierPeripheralPerfusionIndex":
                    peripheralPerfusionIndexCount++;
                    response.id = peripheralPerfusionIndexCount;
                    peripheralPerfusionIndexChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    peripheralPerfusionIndexData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBloodGlucose":
                    bloodGlucoseCount++;
                    response.id = bloodGlucoseCount;
                    bloodGlucoseChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodGlucoseData.push(response);
                    break;
                case "HKQuantityTypeIdentifierNumberOfTimesFallen":
                    numberOfTimesFallenCount++;
                    response.id = numberOfTimesFallenCount;
                    numberOfTimesFallenChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    numberOfTimesFallenData.push(response);
                    break;
                case "HKQuantityTypeIdentifierElectrodermalActivity":
                    electrodermalActivityCount++;
                    response.id = electrodermalActivityCount;
                    electrodermalActivityChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    electrodermalActivityData.push(response);
                    break;
                case "HKQuantityTypeIdentifierInhalerUsage":
                    inhalerUsageCount++;
                    response.id = inhalerUsageCount;
                    inhalerUsageChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    inhalerUsageData.push(response);
                    break;
                case "HKQuantityTypeIdentifierInsulinDelivery":
                    insulinDeliveryCount++;
                    response.id = insulinDeliveryCount;
                    insulinDeliveryChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    insulinDeliveryData.push(response);
                    break;
                case "HKQuantityTypeIdentifierBloodAlcoholContent":
                    bloodAlcoholContentCount++;
                    response.id = bloodAlcoholContentCount;
                    bloodAlcoholContentChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodAlcoholContentData.push(response);
                    break;
                case "HKQuantityTypeIdentifierForcedVitalCapacity":
                    forcedVitalCapacityCount++;
                    response.id = forcedVitalCapacityCount;
                    forcedVitalCapacityChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    forcedVitalCapacityData.push(response);
                    break;
                case "HKQuantityTypeIdentifierForcedExpiratoryVolume1":
                    forcedExpiratoryVolume1Count++;
                    response.id = forcedExpiratoryVolume1Count;
                    forcedExpiratoryVolume1ChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    forcedExpiratoryVolume1Data.push(response);
                    break;
                case "HKQuantityTypeIdentifierPeakExpiratoryFlowRate":
                    peakExpiratoryFlowRateCount++;
                    response.id = peakExpiratoryFlowRateCount;
                    peakExpiratoryFlowRateChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    peakExpiratoryFlowRateData.push(response);
                    break;
                case "HKQuantityTypeIdentifierEnvironmentalAudioExposure":
                    environmentalAudioExposureCount++;
                    response.id = environmentalAudioExposureCount;
                    environmentalAudioExposureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    environmentalAudioExposureData.push(response);
                    break;
                case "HKQuantityTypeIdentifierHeadphoneAudioExposure":
                    headphoneAudioExposure++;
                    response.id = headphoneAudioExposure;
                    headphoneAudioExposureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    headphoneAudioExposureData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFatTotal":
                    dietaryFatTotalCount++;
                    response.id = dietaryFatTotalCount;
                    dietaryFatTotalChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFatTotalData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFatPolyunsaturated":
                    dietaryFatPolyunsaturatedCount++;
                    response.id = dietaryFatPolyunsaturatedCount;
                    dietaryFatPolyunsaturatedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFatPolyunsaturatedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFatMonounsaturated":
                    dietaryFatMonounsaturatedCount++;
                    response.id = dietaryFatMonounsaturatedCount;
                    dietaryFatMonounsaturatedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFatMonounsaturatedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFatSaturated":
                    dietaryFatSaturatedCount++;
                    response.id = dietaryFatSaturatedCount;
                    dietaryFatSaturatedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFatSaturatedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryCholesterol":
                    dietaryCholesterolCount++;
                    response.id = dietaryCholesterolCount;
                    dietaryCholesterolChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCholesterolData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietarySodium":
                    dietarySodiumCount++;
                    response.id = dietarySodiumCount;
                    dietarySodiumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietarySodiumData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryCarbohydrates":
                    dietaryCarbohydratesCount++;
                    response.id = dietaryCarbohydratesCount;
                    dietaryCarbohydratesChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCarbohydratesData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFiber":
                    dietaryFiberCount++;
                    response.id = dietaryFiberCount;
                    dietaryFiberChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFiberData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietarySugar":
                    dietarySugarCount++;
                    response.id = dietarySugarCount;
                    dietarySugarChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietarySugarData.push(response);
                    break;

                case "HKQuantityTypeIdentifierDietaryEnergyConsumed":
                    dietaryEnergyConsumedCount++;
                    response.id = dietaryEnergyConsumedCount;
                    dietaryEnergyConsumedChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryEnergyConsumedData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryProtein":
                    dietaryProteinCount++;
                    response.id = dietaryProteinCount;
                    dietaryProteinChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryProteinData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminA":
                    dietaryVitaminACount++;
                    response.id = dietaryVitaminACount;
                    dietaryVitaminAChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminAData.push(response);
                    break;

                case "HKQuantityTypeIdentifierDietaryVitaminB6":
                    dietaryVitaminB6Count++;
                    response.id = dietaryVitaminB6Count;
                    dietaryVitaminB6ChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminB6Data.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminB12":
                    dietaryVitaminB12Count++;
                    response.id = dietaryVitaminB12Count;
                    dietaryVitaminB12ChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminB12Data.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminC":
                    dietaryVitaminCCount++;
                    response.id = dietaryVitaminCCount;
                    dietaryVitaminCChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminCData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminD":
                    dietaryVitaminDCount++;
                    response.id = dietaryVitaminDCount;
                    dietaryVitaminDChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminDData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminE":
                    dietaryVitaminECount++;
                    response.id = dietaryVitaminECount;
                    dietaryVitaminEChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminEData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminK":
                    dietaryVitaminKCount++;
                    response.id = dietaryVitaminKCount;
                    dietaryVitaminKChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryVitaminKData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryCalcium":
                    dietaryCalciumCount++;
                    response.id = dietaryCalciumCount;
                    dietaryCalciumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCalciumData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryIron":
                    dietaryIronCount++;
                    response.id = dietaryIronCount;
                    dietaryIronChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryIronData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryThiamin":
                    dietaryThiaminCount++;
                    response.id = dietaryThiaminCount;
                    dietaryThiaminChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryThiaminData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryRiboflavin":
                    dietaryRiboflavinCount++;
                    response.id = dietaryRiboflavinCount;
                    dietaryRiboflavinChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryRiboflavinData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryNiacin":
                    dietaryNiacinCount++;
                    response.id = dietaryNiacinCount;
                    dietaryNiacinChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryNiacinData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryFolate":
                    dietaryFolateCount++;
                    response.id = dietaryFolateCount;
                    dietaryFolateChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryFolateData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryBiotin":
                    dietaryBiotinCount++;
                    response.id = dietaryBiotinCount;
                    dietaryBiotinChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryBiotinData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryPantothenicAcid":
                    dietaryPantothenicAcidCount++;
                    response.id = dietaryPantothenicAcidCount;
                    dietaryPantothenicAcidChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryPantothenicAcidData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryPhosphorus":
                    dietaryPhosphorusCount++;
                    response.id = dietaryPhosphorusCount;
                    dietaryPhosphorusChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryPhosphorusData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryIodine":
                    dietaryIodineCount++;
                    response.id = dietaryIodineCount;
                    dietaryIodineChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryIodineData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryMagnesium":
                    dietaryMagnesiumCount++;
                    response.id = dietaryMagnesiumCount;
                    dietaryMagnesiumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryMagnesiumData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryZinc":
                    dietaryZincCount++;
                    response.id = dietaryZincCount;
                    dietaryZincChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryZincData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietarySelenium":
                    dietarySeleniumCount++;
                    response.id = dietarySeleniumCount;
                    dietarySeleniumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietarySeleniumData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryCopper":
                    dietaryCopperCount++;
                    response.id = dietaryCopperCount;
                    dietaryCopperChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCopperData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryManganese":
                    dietaryManganeseCount++;
                    response.id = dietaryManganeseCount;
                    dietaryManganeseChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryManganeseData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryChromium":
                    dietaryChromiumCount++;
                    response.id = dietaryChromiumCount;
                    dietaryChromiumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryChromiumData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryMolybdenum":
                    dietaryMolybdenumCount++;
                    response.id = dietaryMolybdenumCount;
                    dietaryMolybdenumChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryMolybdenumData.push(response);
                    break;

                case "HKQuantityTypeIdentifierDietaryChloride":
                    dietaryCholesterolCount++;
                    response.id = dietaryCholesterolCount;
                    dietaryCholesterolChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCholesterolData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryPotassium":
                    dietaryPotassiumeCount++;
                    response.id = dietaryPotassiumeCount;
                    dietaryPotassiumeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryPotassiumeData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryCaffeine":
                    dietaryCaffeineCount++;
                    response.id = dietaryCaffeineCount;
                    dietaryCaffeineChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryCaffeineData.push(response);
                    break;
                case "HKQuantityTypeIdentifierDietaryWater":
                    dietaryWaterCount++;
                    response.id = dietaryWaterCount;
                    dietaryWaterChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dietaryWaterData.push(response);
                    break;
                case "HKQuantityTypeIdentifierUVExposure":
                    UVExposureCount++;
                    response.id = UVExposureCount;
                    UVExposureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    UVExposureData.push(response);
                    break;
                case "HKCategoryTypeIdentifierSleepAnalysis":
                    sleepAnalysisCount++;
                    response.id = sleepAnalysisCount;
                    sleepAnalysisChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    sleepAnalysisData.push(response);
                    break;
                case "HKCategoryTypeIdentifierAppleStandHour":
                    appleStandHourCount++;
                    response.id = appleStandHourCount;
                    appleStandHourChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    appleStandHourData.push(response);
                    break;
                case "HKCategoryTypeIdentifierCervicalMucusQuality":
                    cervicalMucusQualityCount++;
                    response.id = cervicalMucusQualityCount;
                    cervicalMucusQualityChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    cervicalMucusQualityData.push(response);
                    break;
                case "HKCategoryTypeIdentifierOvulationTestResult":
                    ovulationTestResultCount++;
                    response.id = ovulationTestResultCount;
                    ovulationTestResultChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    ovulationTestResultData.push(response);
                    break;
                case "HKCategoryTypeIdentifierMenstrualFlow":
                    menstrualFlowCount++;
                    response.id = menstrualFlowCount;
                    menstrualFlowChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    menstrualFlowData.push(response);
                    break;
                case "HKCategoryTypeIdentifierIntermenstrualBleeding":
                    intermenstrualBleedingCount++;
                    response.id = intermenstrualBleedingCount;
                    intermenstrualBleedingChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    intermenstrualBleedingData.push(response);
                    break;
                case "HKCategoryTypeIdentifierSexualActivity":
                    sexualActivityCount++;
                    response.id = sexualActivityCount;
                    sexualActivityChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    sexualActivityData.push(response);
                    break;
                case "HKCategoryTypeIdentifierMindfulSession":
                    mindfulSessionCount++;
                    response.id = mindfulSessionCount;
                    mindfulSessionChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    mindfulSessionData.push(response);
                    break;
                case "HKCategoryTypeIdentifierHighHeartRateEvent":
                    highHeartRateEventCount++;
                    response.id = highHeartRateEventCount;
                    highHeartRateEventChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    highHeartRateEventData.push(response);
                    break;
                case "HKCategoryTypeIdentifierLowHeartRateEvent":
                    lowHeartRateEventCount++;
                    response.id = lowHeartRateEventCount;
                    lowHeartRateEventChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    lowHeartRateEventData.push(response);
                    break;
                case "HKCategoryTypeIdentifierIrregularHeartRhythmEvent":
                    irregularHeartRhythmEventCount++;
                    response.id = irregularHeartRhythmEventCount;
                    irregularHeartRhythmEventChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    irregularHeartRhythmEventData.push(response);
                    break;
                case "HKCategoryTypeIdentifierAudioExposureEvent":
                    audioExposureEventCount++;
                    response.id = audioExposureEventCount;
                    audioExposureEventChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    audioExposureEventData.push(response);
                    break;

                case "HKCategoryTypeIdentifierToothbrushingEvent":
                    toothbrushingEventCount++;
                    response.id = toothbrushingEventCount;
                    toothbrushingEventChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    toothbrushingEventData.push(response);
                    break;
                case "HKCharacteristicTypeIdentifierBiologicalSex":
                    biologicalSexCount++;
                    response.id = biologicalSexCount;
                    biologicalSexChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    biologicalSexData.push(response);
                    break;
                case "HKCharacteristicTypeIdentifierBloodType":
                    bloodTypeCount++;
                    response.id = bloodTypeCount;
                    bloodTypeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodTypeData.push(response);
                    break;
                case "HKCharacteristicTypeIdentifierDateOfBirth":
                    dateOfBirthCount++;
                    response.id = dateOfBirthCount;
                    dateOfBirthChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dateOfBirthData.push(response);
                    break;
                case "HKCharacteristicTypeIdentifierFitzpatrickSkinType":
                    fitzpatrickSkinTypeCount++;
                    response.id = fitzpatrickSkinTypeCount;
                    fitzpatrickSkinTypeChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    fitzpatrickSkinTypeData.push(response);
                    break;
                case "HKCharacteristicTypeIdentifierWheelchairUse":
                    wheelchairUseCount++;
                    response.id = wheelchairUseCount;
                    wheelchairUseChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    wheelchairUseData.push(response);
                    break;
                case "HKCorrelationTypeIdentifierBloodPressure":
                    bloodPressureCount++;
                    response.id = bloodPressureCount;
                    bloodPressureChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    bloodPressureData.push(response);
                    break;
                case "HKCorrelationTypeIdentifierFood":
                    foodCount++;
                    response.id = foodCount;
                    foodChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    foodData.push(response);
                    break;
                case "HKDocumentTypeIdentifierCDA":
                    CDACount++;
                    response.id = CDACount;
                    CDAChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    CDAData.push(response);
                    break;
                case "HKWorkoutTypeIdentifier":
                    WorkoutTypeIdentifierCount++;
                    response.id = WorkoutTypeIdentifierCount;
                    WorkoutTypeIdentifierChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    WorkoutTypeIdentifierData.push(response);
                    break;
                case "HKWorkoutRouteTypeIdentifier":
                    workoutRouteTypeIdentifierCount++;
                    response.id = workoutRouteTypeIdentifierCount;
                    workoutRouteTypeIdentifierChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    workoutRouteTypeIdentifierData.push(response);
                    break;
                case "HKDataTypeIdentifierHeartbeatSeries":
                    dataTypeIdentifierHeartbeatSeriesCount++;
                    response.id = dataTypeIdentifierHeartbeatSeriesCount;
                    dataTypeIdentifierHeartbeatSeriesChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    dataTypeIdentifierHeartbeatSeriesData.push(response);
                    break;
                case "HKQuantityTypeIdentifierNutrients":
                    nutrientsCount++;
                    response.id = nutrientsCount;
                    nutrientsChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    nutrientsData.push(response);
                    break;
                case "HKQuantityTypeIdentifierWeight":
                    weightCount++;
                    response.id = weightCount;
                    weightChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    weightData.push(response);
                    break;
                case "HKQuantityTypeIdentifierHydration":
                    hydrationCount++;
                    response.id = hydrationCount;
                    hydrationChartData.push([formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.info.split(" ")[0])]);
                    response.timestamp = formatTimestamp(new Date(parseInt(response.timestamp)));
                    hydrationData.push(response);
                    break;
                default:
                    response.sampleType = "sample type is not defined.";
            }
        });
        data.forEach((element) => {
            switch (element.type) {
                case "HKQuantityTypeIdentifierBodyMassIndex":
                    element.tabularData = bodyMassIndexData;
                    element.graphicalData = bodyMassIndexChartData;
                    break;
                case "HKQuantityTypeIdentifierBodyFatPercentage":
                    element.tabularData = bodyFatPercentageData;
                    element.graphicalData = bodyFatPercentageChartData;
                    break;
                case "HKQuantityTypeIdentifierHeight":
                    element.tabularData = heightData;
                    element.graphicalData = heartRateChartData;
                    break;
                case "HKQuantityTypeIdentifierBodyMass":
                    element.tabularData = bodyMassData;
                    element.graphicalData = bodyMassChartData;
                    break;
                case "HKQuantityTypeIdentifierLeanBodyMass":
                    element.tabularData = leanBodyMassData;
                    element.graphicalData = leanBodyMassChartData;
                    break;
                case "HKQuantityTypeIdentifierWaistCircumference":
                    element.tabularData = waistCircumferenceData;
                    element.graphicalData = waistCircumferenceChartData;
                    break;
                case "HKQuantityTypeIdentifierStepCount":
                    element.tabularData = stepCountData;
                    element.graphicalData = stepCountChartData;
                    break;
                case "HKQuantityTypeIdentifierDistanceWalkingRunning":
                    element.tabularData = distanceWalkingRunningData;
                    element.graphicalData = distanceWalkingRunningChartData;
                    break;
                case "HKQuantityTypeIdentifierDistanceCycling":
                    element.tabularData = distanceCyclingData;
                    element.graphicalData = distanceCyclingChartData;
                    break;
                case "HKQuantityTypeIdentifierDistanceWheelchair":
                    element.tabularData = distanceWheelchairData;
                    element.graphicalData = distanceWheelchairChartData;
                    break;
                case "HKQuantityTypeIdentifierBasalEnergyBurned":
                    element.tabularData = basalEnergyBurnedData;
                    element.graphicalData = basalEnergyBurnedChartData;
                    break;
                case "HKQuantityTypeIdentifierActiveEnergyBurned":
                    element.tabularData = activeEnergyBurnedData;
                    element.graphicalData = activeEnergyBurnedChartData;
                    break;
                case "HKQuantityTypeIdentifierFlightsClimbed":
                    element.tabularData = flightsClimbedData;
                    element.graphicalData = flightsClimbedChartData;
                    break;
                case "HKQuantityTypeIdentifierNikeFuel":
                    element.tabularData = nikeFuelData;
                    element.graphicalData = nikeFuelChartData;
                    break;
                case "HKQuantityTypeIdentifierAppleExerciseTime":
                    element.tabularData = appleExerciseTimeData;
                    element.graphicalData = appleExerciseTimeChartData;
                    break;
                case "HKQuantityTypeIdentifierPushCount":
                    element.tabularData = pushCountData;
                    element.graphicalData = pushCountChartData;
                    break;
                case "HKQuantityTypeIdentifierDistanceSwimming":
                    element.tabularData = distanceSwimmingData;
                    element.graphicalData = distanceSwimmingChartData;
                    break;
                case "HKQuantityTypeIdentifierSwimmingStrokeCount":
                    element.tabularData = swimmingStrokeData;
                    element.graphicalData = swimmingStrokeChartData;
                    break;
                case "HKQuantityTypeIdentifierVO2Max":
                    element.tabularData = VO2MaxData;
                    element.graphicalData = VO2MaxChartData;
                    break;
                case "HKQuantityTypeIdentifierDistanceDownhillSnowSports":
                    element.tabularData = distanceDownhillSnowSportsData;
                    element.graphicalData = distanceDownhillSnowSportsChartData;
                    break;
                case "HKQuantityTypeIdentifierAppleStandTime":
                    element.tabularData = appleStandTimeData;
                    element.graphicalData = appleStandTimeChartData;
                    break;
                case "HKQuantityTypeIdentifierHeartRate":
                    element.tabularData = heartRateData;
                    element.graphicalData = heartRateChartData;
                    break;
                case "HKQuantityTypeIdentifierBodyTemperature":
                    element.tabularData = bodyTemperatureData;
                    element.graphicalData = bodyTemperatureChartData;
                    break;
                case "HKQuantityTypeIdentifierBasalBodyTemperature":
                    element.tabularData = basalBodyTemperatureData;
                    element.graphicalData = basalBodyTemperatureChartData;
                    break;
                case "HKQuantityTypeIdentifierBloodPressureSystolic":
                    element.tabularData = bloodPressureSystolicData;
                    element.graphicalData = bloodPressureSystolicChartData;
                    break;
                case "HKQuantityTypeIdentifierBloodPressureDiastolic":
                    element.tabularData = bloodPressureDiastolicData;
                    element.graphicalData = bloodPressureDiastolicChartData;
                    break;
                case "HKQuantityTypeIdentifierRespiratoryRate":
                    element.tabularData = respiratoryRateData;
                    element.graphicalData = respiratoryRateChartData;
                    break;
                case "HKQuantityTypeIdentifierRestingHeartRate":
                    element.tabularData = restingHeartRateData;
                    element.graphicalData = restingHeartRateChartData;
                    break;
                case "HKQuantityTypeIdentifierWalkingHeartRateAverage":
                    element.tabularData = walkingHeartRateAverageData;
                    element.graphicalData = walkingHeartRateAverageChartData;
                    break;
                case "HKQuantityTypeIdentifierHeartRateVariabilitySDNN":
                    element.tabularData = heartRateVariabilitySDNNData;
                    element.graphicalData = heartRateVariabilitySDNNChartData;
                    break;
                case "HKQuantityTypeIdentifierOxygenSaturation":
                    element.tabularData = oxygenSaturationData;
                    element.graphicalData = oxygenSaturationChartData;
                    break;
                case "HKQuantityTypeIdentifierPeripheralPerfusionIndex":
                    element.tabularData = peripheralPerfusionIndexData;
                    element.graphicalData = peripheralPerfusionIndexChartData;
                    break;
                case "HKQuantityTypeIdentifierBloodGlucose":
                    element.tabularData = bloodGlucoseData;
                    element.graphicalData = bloodGlucoseChartData;
                    break;
                case "HKQuantityTypeIdentifierNumberOfTimesFallen":
                    element.tabularData = numberOfTimesFallenData;
                    element.graphicalData = numberOfTimesFallenChartData;
                    break;
                case "HKQuantityTypeIdentifierElectrodermalActivity":
                    element.tabularData = electrodermalActivityData;
                    element.graphicalData = electrodermalActivityChartData;
                    break;
                case "HKQuantityTypeIdentifierInhalerUsage":
                    element.tabularData = inhalerUsageData;
                    element.graphicalData = inhalerUsageChartData;
                    break;
                case "HKQuantityTypeIdentifierInsulinDelivery":
                    element.tabularData = insulinDeliveryData;
                    element.graphicalData = insulinDeliveryChartData;
                    break;
                case "HKQuantityTypeIdentifierBloodAlcoholContent":
                    element.tabularData = bloodAlcoholContentData;
                    element.graphicalData = bloodAlcoholContentChartData;
                    break;
                case "HKQuantityTypeIdentifierForcedVitalCapacity":
                    element.tabularData = forcedVitalCapacityData;
                    element.graphicalData = forcedVitalCapacityChartData;
                    break;
                case "HKQuantityTypeIdentifierForcedExpiratoryVolume1":
                    element.tabularData = forcedExpiratoryVolume1Data;
                    element.graphicalData = forcedExpiratoryVolume1ChartData;
                    break;
                case "HKQuantityTypeIdentifierPeakExpiratoryFlowRate":
                    element.tabularData = peakExpiratoryFlowRateData;
                    element.graphicalData = peakExpiratoryFlowRateChartData;
                    break;
                case "HKQuantityTypeIdentifierEnvironmentalAudioExposure":
                    element.tabularData = environmentalAudioExposureData;
                    element.graphicalData = environmentalAudioExposureChartData;
                    break;
                case "HKQuantityTypeIdentifierHeadphoneAudioExposure":
                    element.tabularData = headphoneAudioExposureData;
                    element.graphicalData = headphoneAudioExposureChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFatTotal":
                    element.tabularData = dietaryFatTotalData;
                    element.graphicalData = dietaryFatTotalChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFatPolyunsaturated":
                    element.tabularData = dietaryFatPolyunsaturatedData;
                    element.graphicalData = dietaryFatPolyunsaturatedChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFatMonounsaturated":
                    element.tabularData = dietaryFatMonounsaturatedData;
                    element.graphicalData = dietaryFatMonounsaturatedChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFatSaturated":
                    element.tabularData = dietaryFatSaturatedData;
                    element.graphicalData = dietaryFatSaturatedChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryCholesterol":
                    element.tabularData = dietaryCholesterolData;
                    element.graphicalData = dietaryCholesterolChartData;
                    break;
                case "HKQuantityTypeIdentifierDietarySodium":
                    element.tabularData = dietarySodiumData;
                    element.graphicalData = dietarySodiumChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryCarbohydrates":
                    element.tabularData = dietaryCarbohydratesData;
                    element.graphicalData = dietaryCarbohydratesChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFiber":
                    element.tabularData = dietaryFiberData;
                    element.graphicalData = dietaryFiberChartData;
                    break;
                case "HKQuantityTypeIdentifierDietarySugar":
                    element.tabularData = dietarySugarData;
                    element.graphicalData = dietarySugarChartData;
                    break;

                case "HKQuantityTypeIdentifierDietaryEnergyConsumed":
                    element.tabularData = dietaryEnergyConsumedData;
                    element.graphicalData = dietaryEnergyConsumedChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryProtein":
                    element.tabularData = dietaryProteinData;
                    element.graphicalData = dietaryProteinChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminA":
                    element.tabularData = dietaryVitaminAData;
                    element.graphicalData = dietaryVitaminAChartData;
                    break;

                case "HKQuantityTypeIdentifierDietaryVitaminB6":
                    element.tabularData = dietaryVitaminB6Data;
                    element.graphicalData = dietaryVitaminB6ChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminB12":
                    element.tabularData = dietaryVitaminB12Data;
                    element.graphicalData = dietaryVitaminB12ChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminC":
                    element.tabularData = dietaryVitaminCData;
                    element.graphicalData = dietaryVitaminCChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminD":
                    element.tabularData = dietaryVitaminDData;
                    element.graphicalData = dietaryVitaminDChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminE":
                    element.tabularData = dietaryVitaminEData;
                    element.graphicalData = dietaryVitaminEChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryVitaminK":
                    element.tabularData = dietaryVitaminKData;
                    element.graphicalData = dietaryVitaminKChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryCalcium":
                    element.tabularData = dietaryCalciumData;
                    element.graphicalData = dietaryCalciumChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryIron":
                    element.tabularData = dietaryIronData;
                    element.graphicalData = dietaryIronChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryThiamin":
                    element.tabularData = dietaryThiaminData;
                    element.graphicalData = dietaryThiaminChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryRiboflavin":
                    element.tabularData = dietaryRiboflavinData;
                    element.graphicalData = dietaryRiboflavinChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryNiacin":
                    element.tabularData = dietaryNiacinData;
                    element.graphicalData = dietaryNiacinChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryFolate":
                    element.tabularData = dietaryFolateData;
                    element.graphicalData = dietaryFolateChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryBiotin":
                    element.tabularData = dietaryBiotinData;
                    element.graphicalData = dietaryBiotinChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryPantothenicAcid":
                    element.tabularData = dietaryPantothenicAcidData;
                    element.graphicalData = dietaryPantothenicAcidChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryPhosphorus":
                    element.tabularData = dietaryPhosphorusData;
                    element.graphicalData = dietaryPhosphorusChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryIodine":
                    element.tabularData = dietaryIodineData;
                    element.graphicalData = dietaryIodineChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryMagnesium":
                    element.tabularData = dietaryMagnesiumData;
                    element.graphicalData = dietaryMagnesiumChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryZinc":
                    element.tabularData = dietaryZincData;
                    element.graphicalData = dietaryZincChartData;
                    break;
                case "HKQuantityTypeIdentifierDietarySelenium":
                    element.tabularData = dietarySeleniumData;
                    element.graphicalData = dietarySeleniumChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryCopper":
                    element.tabularData = dietaryCopperData;
                    element.graphicalData = dietaryCopperChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryManganese":
                    element.tabularData = dietaryManganeseData;
                    element.graphicalData = dietaryManganeseChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryChromium":
                    element.tabularData = dietaryChromiumData;
                    element.graphicalData = dietaryChromiumChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryMolybdenum":
                    element.tabularData = dietaryMolybdenumData;
                    element.graphicalData = dietaryMolybdenumChartData;
                    break;

                case "HKQuantityTypeIdentifierDietaryChloride":
                    element.tabularData = dietaryCholesterolData;
                    element.graphicalData = dietaryCholesterolChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryPotassium":
                    element.tabularData = dietaryPotassiumeData;
                    element.graphicalData = dietaryPotassiumeChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryCaffeine":
                    element.tabularData = dietaryCaffeineData;
                    element.graphicalData = dietaryCaffeineChartData;
                    break;
                case "HKQuantityTypeIdentifierDietaryWater":
                    element.tabularData = dietaryWaterData;
                    element.graphicalData = dietaryWaterChartData;
                    break;
                case "HKQuantityTypeIdentifierUVExposure":
                    element.tabularData = UVExposureData;
                    element.graphicalData = UVExposureChartData;
                    break;
                case "HKCategoryTypeIdentifierSleepAnalysis":
                    element.tabularData = sleepAnalysisData;
                    element.graphicalData = sleepAnalysisChartData;
                    break;
                case "HKCategoryTypeIdentifierAppleStandHour":
                    element.tabularData = appleStandHourData;
                    element.graphicalData = appleStandHourChartData;
                    break;
                case "HKCategoryTypeIdentifierCervicalMucusQuality":
                    element.tabularData = cervicalMucusQualityData;
                    element.graphicalData = cervicalMucusQualityChartData;
                    break;
                case "HKCategoryTypeIdentifierOvulationTestResult":
                    element.tabularData = ovulationTestResultData;
                    element.graphicalData = ovulationTestResultChartData;
                    break;
                case "HKCategoryTypeIdentifierMenstrualFlow":
                    element.tabularData = menstrualFlowData;
                    element.graphicalData = menstrualFlowChartData;
                    break;
                case "HKCategoryTypeIdentifierIntermenstrualBleeding":
                    element.tabularData = intermenstrualBleedingData;
                    element.graphicalData = intermenstrualBleedingChartData;
                    break;
                case "HKCategoryTypeIdentifierSexualActivity":
                    element.tabularData = sexualActivityData;
                    element.graphicalData = sexualActivityChartData;
                    break;
                case "HKCategoryTypeIdentifierMindfulSession":
                    element.tabularData = mindfulSessionData;
                    element.graphicalData = mindfulSessionChartData;
                    break;
                case "HKCategoryTypeIdentifierHighHeartRateEvent":
                    element.tabularData = highHeartRateEventData;
                    element.graphicalData = highHeartRateEventChartData;
                    break;
                case "HKCategoryTypeIdentifierLowHeartRateEvent":
                    element.tabularData = lowHeartRateEventData;
                    element.graphicalData = lowHeartRateEventChartData;
                    break;
                case "HKCategoryTypeIdentifierIrregularHeartRhythmEvent":
                    element.tabularData = irregularHeartRhythmEventData;
                    element.graphicalData = irregularHeartRhythmEventChartData;
                    break;
                case "HKCategoryTypeIdentifierAudioExposureEvent":
                    element.tabularData = audioExposureEventData;
                    element.graphicalData = audioExposureEventChartData;
                    break;

                case "HKCategoryTypeIdentifierToothbrushingEvent":
                    element.tabularData = toothbrushingEventData;
                    element.graphicalData = toothbrushingEventChartData;
                    break;
                case "HKCharacteristicTypeIdentifierBiologicalSex":
                    element.tabularData = biologicalSexData;
                    element.graphicalData = biologicalSexChartData;
                    break;
                case "HKCharacteristicTypeIdentifierBloodType":
                    element.tabularData = bloodTypeData;
                    element.graphicalData = bloodTypeChartData;
                    break;
                case "HKCharacteristicTypeIdentifierDateOfBirth":
                    element.tabularData = dateOfBirthData;
                    element.graphicalData = dateOfBirthChartData;
                    break;
                case "HKCharacteristicTypeIdentifierFitzpatrickSkinType":
                    element.tabularData = fitzpatrickSkinTypeData;
                    element.graphicalData = fitzpatrickSkinTypeChartData;
                    break;
                case "HKCharacteristicTypeIdentifierWheelchairUse":
                    element.tabularData = wheelchairUseData;
                    element.graphicalData = wheelchairUseChartData;
                    break;
                case "HKCorrelationTypeIdentifierBloodPressure":
                    element.tabularData = bloodPressureData;
                    element.graphicalData = bloodPressureChartData;
                    break;
                case "HKCorrelationTypeIdentifierFood":
                    element.tabularData = foodData;
                    element.graphicalData = foodChartData;
                    break;
                case "HKDocumentTypeIdentifierCDA":
                    element.tabularData = CDAData;
                    element.graphicalData = CDAChartData;
                    break;
                case "HKWorkoutTypeIdentifier":
                    element.tabularData = WorkoutTypeIdentifierData;
                    element.graphicalData = WorkoutTypeIdentifierChartData;
                    break;
                case "HKWorkoutRouteTypeIdentifier":
                    element.tabularData = workoutRouteTypeIdentifierData;
                    element.graphicalData = workoutRouteTypeIdentifierChartData;
                    break;
                case "HKDataTypeIdentifierHeartbeatSeries":
                    element.tabularData = dataTypeIdentifierHeartbeatSeriesData;
                    element.graphicalData = dataTypeIdentifierHeartbeatSeriesChartData;
                    break;
                case "HKQuantityTypeIdentifierNutrients":
                    element.tabularData = nutrientsData;
                    element.graphicalData = nutrientsChartData;
                    break;
                case "HKQuantityTypeIdentifierWeight":
                    element.tabularData = weightData;
                    element.graphicalData = weightChartData;
                    break;
                case "HKQuantityTypeIdentifierHydration":
                    element.tabularData = hydrationData;
                    element.graphicalData = hydrationChartData;
                    break;
                default:
                    element.sampleType = "sample type is not defined.";
            }
        });
    } else {
        data = [];
    }
    return data;
}
function formatTimestamp(date) {
    var month = "" + (date.getMonth() + 1),
        day = "" + date.getDate(),
        year = date.getFullYear(),
        hours = "" + date.getHours(),
        minute = "" + date.getMinutes();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (minute.length < 2) minute = "0" + minute;
    return `${day}-${month}-${year} ${hours}:${minute}`;
}
export function getGlucoseValue(glucoseVal, glucose_unit) {

    let UpdatedglucoseVal;

    switch (glucose_unit) {
        case "mmol/L":
            UpdatedglucoseVal = (parseInt(glucoseVal) / 18.0182).toFixed(1);
            break;
        case "mg/dL":
            UpdatedglucoseVal = glucoseVal;
            break;
        default:
            UpdatedglucoseVal = glucoseVal;
            break;
    }


    if (UpdatedglucoseVal.toString() === "NaN") {
        return "NA"
    }

    return UpdatedglucoseVal;

    // Old working condition H1
    // return glucose_unit === "mmol/L" ? (parseInt(glucoseVal) / 18.0182).toFixed(1) : parseInt(glucoseVal) % 1 === 0 ? parseInt(glucoseVal) : (parseInt(glucoseVal) / 1).toFixed(0);

    // new updated condition A1
    //return glucose_unit === "mmol/L" ? UpdatedglucoseVal : UpdatedglucoseVal ? parseInt(glucoseVal) : UpdatedglucoseVal;
    //return glucose_unit === "mmol/L" ? UpdatedglucoseVal : UpdatedglucoseVal ? UpdatedglucoseVal : UpdatedglucoseVal;
}

export function getGlucoseValuestr(glucoseVal, glucose_unit) {

    if (parseInt(glucoseVal) < 0) {
        return "NA"
    }

    let UpdatedglucoseVal;

    switch (glucose_unit) {
        case "mmol/L":
            UpdatedglucoseVal = (parseInt(glucoseVal) / 18.0182).toFixed(1);
            break;
        case "mg/dL":
            UpdatedglucoseVal = glucoseVal;
            break;
        default:
            UpdatedglucoseVal = glucoseVal;
            break;
    }
    if (UpdatedglucoseVal.toString() === "NaN") {
        return "NA"
    }

    // console.log("U----->" + UpdatedglucoseVal + " " + glucose_unit)
    return UpdatedglucoseVal + " " + glucose_unit;

}

export function getTickValue(glucose_unit) {
    return glucose_unit === "mmol/L" ? [0, 5.3, 10.6, 15.9, 21.3] : [0, 100, 200, 300, 400];
}

export function getTickSize(glucose_unit) {
    return glucose_unit === "mmol/L" ? 0.5 : 5;
}
export function getFormattedDate(date) {
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let day = date
        .getDate()
        .toString()
        .padStart(2, "0");
    return `${month} ${day}, ${year}`;
}
export function getFormattedDateMMDD(date) {
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date
        .getDate()
        .toString()
        .padStart(2, "0");
    return `${month}/${day}`;
}

export function formatTimestampWithDay(date) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month = months[date.getMonth()],
        day = "" + date.getDate(),
        year = date.getFullYear(),
        hours = "" + date.getHours(),
        minute = "" + date.getMinutes(),
        seconds = "" + date.getSeconds();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minute.length < 2) minute = "0" + minute;
    if (seconds.length < 2) seconds = "0" + seconds;
    return `${month} ${day} ${year} ${hours}:${minute}:${seconds}`;
}
export function formatTimestampWithHM(date) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    var month = months[date.getMonth()],
        day = "" + date.getDate(),
        year = date.getFullYear(),
        hours = "" + date.getHours(),
        minute = "" + date.getMinutes();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minute.length < 2) minute = "0" + minute;
    return `${month} ${day} ${year} ${hours}:${minute}`;
}
export function formatTimestampForExcepExport(milliseconds) {
    milliseconds = parseInt(milliseconds);
    const date = new Date(milliseconds); // Create a new Date object using the milliseconds
    const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to get the correct month number
    const day = date.getDate(); // Get the day of the month (1-31)
    const year = date.getFullYear(); // Get the year (e.g. 2021)
    const hours = date.getHours(); // Get the hours (0-23)
    const minutes = date.getMinutes(); // Get the minutes (0-59)
    // const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine whether it's AM or PM

    const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year.toString()} ${hours.toString().padStart(2, '0') }:${minutes.toString().padStart(2, '0')}`; // Format the date time string in MM-dd-yyyy HH:mm aa format

    // console.log(formattedDate); // Output: 05-03-2021 12:00 AM
    return formattedDate;
}

export function getCurrentSystemDateTime() {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Get the month (0-11) and add 1 to get the correct month number
    const day = currentDate.getDate(); // Get the day of the month (1-31)
    const year = currentDate.getFullYear(); // Get the year (e.g. 2021)
    const hours = currentDate.getHours(); // Get the hours (0-23)
    const minutes = currentDate.getMinutes(); // Get the minutes (0-59)
    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine whether it's AM or PM

    const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year.toString()} ${((hours % 12) || 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`; // Format the date time string in MM-dd-yyyy HH:mm aa format
    return formattedDate;
}

export function getCurrentSystemDate() {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1; // Get the month (0-11) and add 1 to get the correct month number
    const day = currentDate.getDate(); // Get the day of the month (1-31)
    const year = currentDate.getFullYear(); // Get the year (e.g. 2021)

    const formattedDate = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year.toString()}`; // Format the date time string in MM-dd-yyyy HH:mm aa format
    return formattedDate;
}

export function compareValue(value1, value2) {
    value1 = parseInt(value1 === null ? 0 : value1, 10)
    value2 = parseInt(value2 === null ? 0 : value2, 10)
    const date1 = new Date(value1);
    const month1 = date1.getMonth() + 1; // Get the month (0-11) and add 1 to get the correct month number
    const day1 = date1.getDate(); // Get the day of the month (1-31)
    const year1 = date1.getFullYear(); // Get the year (e.g. 2021)
    const hours1 = date1.getHours(); // Get the hours (0-23)
    const minutes1 = date1.getMinutes(); // Get the minutes (0-59)
    const date2 = new Date(value2);
    const month2 = date2.getMonth() + 1; // Get the month (0-11) and add 1 to get the correct month number
    const day2 = date2.getDate(); // Get the day of the month (1-31)
    const year2 = date2.getFullYear(); // Get the year (e.g. 2021)
    const hours2 = date2.getHours(); // Get the hours (0-23)
    const minutes2 = date2.getMinutes(); // Get the minutes (0-59)

    const formattedDate1 = `${year1.toString()}${month1.toString().padStart(2, '0')}${day1.toString().padStart(2, '0')}${((hours1 % 12) || 12).toString().padStart(2, '0')}${minutes1.toString().padStart(2, '0')}`; // Format the date time string in yyyyMMddHHmm format
    const formattedDate2 = `${year2.toString()}${month2.toString().padStart(2, '0')}${day2.toString().padStart(2, '0')}${((hours2 % 12) || 12).toString().padStart(2, '0')}${minutes2.toString().padStart(2, '0')}`; // Format the date time string in yyyyMMddHHmm format
    let result = (formattedDate1 === formattedDate2);
    return result;
}

export function decodeData(readingData) {
    var readings = [];
    readingData.map((data, i) => {
        if (data.reading != "NA") {
            if (readings.length <= 0 || !compareValue(data.reading_time, readings[readings.length - 1].reading_time)) {
                readings.push(data);
            }
        }
    });
    return readings;
}

export function getDataAfterLastExported(readingData, lastExportedTime) {
    var readings = [];
    readingData.map((data, i) => {
        if (data.reading_time > lastExportedTime) {
            readings.push(data);
        }
    });
    return readings;
}

export function getCurrentTimeInMillies() {
    const currentDate = new Date();
    let ms = currentDate.valueOf();
    return ms;
}

export function getMemberId(settings_json) {
    if (settings_json != undefined){
        try {
            settings_json = JSON.parse(settings_json);
        } catch (e) {
        }
        if (settings_json.hasOwnProperty('companyDetails')) {
            if (settings_json.companyDetails.hasOwnProperty('memberID')) {
                return settings_json.companyDetails.memberID;
            } else {
                return "";
            }
        } else {
            return "";
        }
   } else{
        return "";
   }
}

