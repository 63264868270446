import React from "react";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";

import FoodLog from "./food-log";

class AddPatientNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            patients: [],
            isLoading: false,
            isPatientSelect: false,
            patient_id: "",
            patient_email: "",
            patient_name: "",
        };
        this.handleEvent = this.handleEvent.bind(this);
        this.handlePatientDropdownChange = this.handlePatientDropdownChange.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    componentDidMount() {
        document.title = "Patient Food Log - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
            const followers = localStorage.getItem("my-followers");
            if (followers) this.setState({ patients: JSON.parse(followers) });
        }
    }

    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
    }

    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()}`,
            patient_email: e.email,
        });
        this.setState({
            isPatientSelect: e.patient_id,
        });
    }
    handleBackButton() {
        this.setState({
            isPatientSelect: false,
            patient_name: "",
        });
    }

    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        return `${day}-${month}-${year} ${hours}:${minute}`;
    }
    render() {
        const { classes } = this.props;
        const divStyle = {
            display: "flex",
            alignItems: "center",
            background: "black",
        };
        return this.state.isPatientSelect ? (
            // <Redirect
            //     to={{
            //         pathname: "/food-log",
            //         state: {
            //             patient_id: this.state.isPatientSelect,
            //         },
            //     }}
            // />
            <FoodLog handleBackButton={this.handleBackButton} patientName={this.state.patient_name} patient_id={this.state.isPatientSelect} />
        ) : (
            <div>
                <GridContainer>
                    {/* <GridItem xs={12} sm={12} md={3} /> */}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info" icon />
                            <CardBody style={{ background: "black" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <div style={divStyle}>
                                            <CustomDropdown
                                                hoverColor="info"
                                                buttonText={this.state.patient_name === "" ? "Select Patient" : this.state.patient_name}
                                                buttonProps={{
                                                    round: true,
                                                    fullWidth: true,
                                                    style: { marginBottom: "0", width: "300px" },
                                                    color: "info",

                                                }}
                                                id="secondYaxis"
                                                dropdownList={this.state.patients.map((e, i) => (
                                                    <p
                                                        style={{
                                                            display: "block",        // Makes the <p> behave like an <li>
                                                            color: "white",
                                                            width: "100%",
                                                            margin: 0,               // Removes any default margin
                                                            padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                            boxSizing: "border-box",
                                                        }}
                                                        onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                                        {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                                    </p>
                                                ))}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", marginRight: "10px", color: "white" }}>
                                            <b>Patient Name:</b> {this.state.patient_name}
                                        </h5>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(AddPatientNotes);
