import React from "react";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import "bootstrap-daterangepicker/daterangepicker.css";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomTextField from "components/CustomInput/CustomTextField.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";

import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
// @material-ui/icons
import CircularProgress from "@material-ui/core/CircularProgress";

class AddPatientNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tc: false,
            patients: [],
            notesData: [],
            isLoading: false,
            isNotesDataLoading: false,
            maxDate: moment(),
            notes: "",
            notificationMessage: "",
            patient_id: "",
            patient_email: "",
            patient_name: "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.handlePatientDropdownChange = this.handlePatientDropdownChange.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.getPatientNotes = this.getPatientNotes.bind(this);
    }

    componentDidMount() {
        document.title = "Patient Notes - Ambrosia Care";
        if (localStorage.getItem("loginType") === "Caregiver") {
            if (!localStorage.getItem("token")) {
                this.props.history.push("/");
            }
            const followers = localStorage.getItem("my-followers");
            if (followers) this.setState({ patients: JSON.parse(followers) });
        }
    }

    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
    }

    handlePatientDropdownChange({ ...e }) {
        this.setState({
            patient_id: e.patient_id,
            patient_name: `${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()}`,
            patient_email: e.email,
        });
        this.getPatientNotes(e.patient_id);
    }

    showNotification(place) {
        if (!this.state[place]) {
            var x = [];
            x[place] = true;
            this.setState(x);
            setTimeout(
                function () {
                    x[place] = false;
                    this.setState(x);
                }.bind(this),
                3000
            );
        }
    }
    getPatientNotes(patient_id) {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isNotesDataLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            patient_id: patient_id,
            caregiver_id: localStorage.getItem("user_id"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-patient-notes",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ notesData: [] });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ notesData: DataJSON.data });
                }
                this.setState({ isNotesDataLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }
    handleSubmitForm() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            caregiver_id: localStorage.getItem("user_id"),
            caregiver_name: localStorage.getItem("first_name") + " " + localStorage.getItem("last_name"),
            patient_id: this.state.patient_id,
            time: new Date().valueOf(),
            notes_detail: this.state.notes,
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/add-patient-notes",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    }
                    this.setState({ notificationMessage: "Something went wrong while adding notes." }, () => {
                        this.showNotification("tc_danger");
                    });
                    // this.showNotification("tc");
                } else {
                    this.setState({ notificationMessage: "Notes added." }, () => {
                        this.showNotification("tc_info");
                        this.getPatientNotes(this.state.patient_id);
                    });
                }
                this.setState({ isLoading: false, notes: "" });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    handleInputChange(e) {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        });
    }
    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        return `${day}-${month}-${year} ${hours}:${minute}`;
    }
    render() {
        const { classes } = this.props;
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <Snackbar place="tc" color="danger" message={this.state.notificationMessage} open={this.state.tc_danger} closeNotification={() => this.setState({ tc_danger: false })} close />
                        <Snackbar place="tc" color="info" message={this.state.notificationMessage} open={this.state.tc_info} closeNotification={() => this.setState({ tc_info: false })} close />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <CardHeader color="info" icon />
                            <CardBody style={{ background: "black" }}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <div style={divStyle}>
                                            <CustomDropdown
                                                hoverColor="info"
                                                buttonText={this.state.patient_name === "" ? "Select Patient" : this.state.patient_name}
                                                buttonProps={{
                                                    round: true,
                                                    fullWidth: true,
                                                    style: { marginBottom: "0", width: "300px" },
                                                    color: "info",
                                                }}
                                                id="secondYaxis"
                                                dropdownList={this.state.patients.map((e, i) => (
                                                    <p
                                                        style={{
                                                            display: "block",        // Makes the <p> behave like an <li>
                                                            color: "white",
                                                            width: "100%",
                                                            margin: 0,               // Removes any default margin
                                                            padding: "10px",    // Matches typical <li> padding (adjust as needed)
                                                            boxSizing: "border-box",
                                                        }}
                                                        onClick={() => this.handlePatientDropdownChange({ ...e })}>
                                                        {`${e.first_name.toUpperCase()} ${e.last_name.toUpperCase()} (${e.email})`}
                                                    </p>
                                                ))}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", marginRight: "10px", color: "white" }}>
                                            <b>Patient Name:</b> {this.state.patient_name}
                                        </h5>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    {this.state.patient_id !== "" ? (
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomTextField
                                                labelText="Note"
                                                id="notes"
                                                inputProps={{
                                                    value: `${this.state.notes}`,
                                                }}
                                                formControlProps={{
                                                    onChange: this.handleInputChange,
                                                    fullWidth: true,
                                                }}
                                            />
                                        </GridItem>
                                    ) : null}
                                </GridContainer>
                                {this.state.patient_name !== "" ? (
                                    <Button color="info" disabled={this.state.notes === ""} onClick={this.handleSubmitForm} className={classes.updateProfileButton}>
                                        Save
                                    </Button>
                                ) : null}
                                <Clearfix />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <div>
                    <GridContainer>
                        {/* <GridItem xs={12} sm={12} md={3} /> */}
                        {this.state.patient_id !== "" ? (
                            <GridItem>
                                <h4 className={classes.cardIconTitle} style={{ color: "#fff" }}>
                                    Patient Notes
                                </h4>
                            </GridItem>
                        ) : null}
                        {this.state.patient_id !== "" ? (
                            this.state.isNotesDataLoading ? (
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card plain>
                                        <CardBody style={{ height: "100px" }}>
                                            <CircularProgress
                                                style={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            ) : this.state.notesData.length > 0 ? (
                                this.state.notesData.map((data, count) => {
                                    const tableData = [];
                                    tableData.push(data);
                                    return (
                                        <GridContainer key={count}>
                                            {/* <GridItem xs={12} sm={12} md={3} /> */}
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Card>
                                                    <CardBody style={{ background: "black" }}>
                                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#fff" }}>
                                                            {this.formatTimestamp(new Date(parseInt(data.time)))}
                                                        </h5>
                                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#fff" }}>
                                                            <b>Note: </b>
                                                            {data.notes_detail}
                                                        </h5>
                                                        <h5 className={classes.cardIconTitle} style={{ fontSize: "0.99em", color: "#fff" }}>
                                                            <b>Note Added by: </b>
                                                            {data.added_by}
                                                        </h5>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        </GridContainer>
                                    );
                                })
                            ) : (
                                <GridContainer>
                                    {/* <GridItem xs={12} sm={12} md={3} /> */}
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px", background: "black" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                        color: "white"
                                                    }}
                                                >
                                                    There are no notes data available.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )
                        ) : null}
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(AddPatientNotes);
