import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import validationFormsStyle from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

class championSubscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            championsSubscriptionArray: [],
            isLoading: false,
            isGetDataLoading: false,
            deleteAlert: null,
        };
        this.getallChampionsSubscriptions = this.getallChampionsSubscriptions.bind(this);
    }

    componentDidMount() {
        document.title = "Champions subscriptions- Ambrosia Care";
        this.getallChampionsSubscriptions();
    }

    getallChampionsSubscriptions() {
        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }

        this.setState({ isLoading: true });
        const data = {
            token: localStorage.getItem("token"),
            platform_name: "WEB",
        };
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-all-Champions-subscription",
            data: data,
            success: function (data) {
                var DataJSON = JSON.parse(data);

                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ championsSubscriptionArray: [], deleteAlert: null });
                    }
                } else if (DataJSON.success === true) {
                    this.setState({ championsSubscriptionArray: DataJSON.data, deleteAlert: null });
                }
                this.setState({ isGetDataLoading: false });
                this.setState({ isLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    render() {

        const tableData = this.state.championsSubscriptionArray.map((data, i) => {
            const { id, subscription_id, email, total_subscription, user_id } = data;
            return {
                id: id,
                subscription_id: subscription_id,
                email: email,
                total_subscription: total_subscription,
                user_id: user_id,
            };
        });

        return this.state.isLoading ? (
            <CircularProgress
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                }}
            />
        ) : (
            <div>

                {this.state.deleteAlert}
                {this.state.isGetDataLoading ? (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card plain>
                            <CardBody style={{ height: "100px" }}>
                                <CircularProgress
                                    style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                ) : (
                    <div>
                        <GridContainer>
                            {tableData.length > 0 ? (
                                <Card>
                                    <CardBody>
                                        <PerfectScrollbar>
                                            <ReactTable
                                                data={tableData}
                                                filterable
                                                columns={[
                                                    {
                                                        width: 50,
                                                        Header: "ID",
                                                        accessor: "id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 150,
                                                        Header: "Subscription Id",
                                                        accessor: "subscription_id",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 155,
                                                        Header: "Email Id",
                                                        accessor: "email",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    {
                                                        width: 250,
                                                        Header: "Total Subscription",
                                                        accessor: "total_subscription",
                                                        Cell: (row) => <div style={{ color: "#262626" }}>{row.value}</div>,
                                                        filterable: false,
                                                    },
                                                    // {
                                                    //     width: 150,
                                                    //     Header: "Action",
                                                    //     accessor: "nr_qty",
                                                    //     Cell: (row) => <div style={{ color: "#262626", alignSelf: 'center' }}>{row.value}</div>,
                                                    //     filterable: false,
                                                    // },
                                                ]}
                                                defaultPageSize={50}
                                                showPaginationTop
                                                showPaginationBottom={false}
                                                className="-highlight"
                                            />
                                        </PerfectScrollbar>
                                    </CardBody>
                                </Card>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardBody style={{ height: "200px" }}>
                                                <h4
                                                    style={{
                                                        marginTop: "80px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    There are no dia buddy users found.
                                                </h4>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            )}
                        </GridContainer>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(validationFormsStyle)(championSubscriptions);
