import React, { Component } from "react";
import $ from "jquery";
import moment from "moment";
import { BASE_URL, removeFromLocalStorage } from "views/Utiles/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Chart from "react-google-charts";
import "bootstrap-daterangepicker/daterangepicker.css";
// @material-ui/core components
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

class AppleHealthECG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isECGDataLoading: false,
            maxDate: moment(),
            begin_date: new Date().valueOf() - 1 * 24 * 60 * 60 * 1000,
            end_date: new Date().valueOf(),
            ecgChartData: [],
            starting_date: [],
        };
        this.handleEvent = this.handleEvent.bind(this);
    }
    handleEvent(event, picker) {
        this.setState({ begin_date: picker.startDate.valueOf(), end_date: picker.endDate.valueOf() });
        this.getECGData();
    }
    componentDidMount() {
        document.title = "ECG Data - Ambrosia Care";

        if (!localStorage.getItem("token")) {
            this.props.history.push("/");
        }
        this.getECGData();
    }

    getECGData() {
        this.setState({ isECGDataLoading: true });
        $.ajax({
            method: "POST",
            url: BASE_URL + "/get-ecg-data",
            data: {
                token: localStorage.getItem("token"),
                patient_id: localStorage.getItem("user_id"),
                begin_date: this.state.begin_date,
                end_date: this.state.end_date,
                platform_name: "WEB",
            },
            success: function (data) {
                var DataJSON = JSON.parse(data);
                if (DataJSON.success === false) {
                    if (DataJSON.msg.includes("Invalid token")) {
                        removeFromLocalStorage();
                        this.props.history.push("/");
                    } else {
                        this.setState({ ecgChartData: [] });
                    }
                } else if (DataJSON.success === true) {
                    var dataset = [];
                    const ecgChartData = DataJSON.data.map((data, index) => {
                        dataset[index] = data.reverse().map((response) => {
                            return [this.formatTimestamp(new Date(parseInt(response.timestamp))), parseFloat(response.voltageQuantity)];
                        });
                        dataset[index][0] = ["Time", "data"];
                        this.state.starting_date.push(dataset[index][1][0]);
                        return dataset[index];
                    });
                    this.setState({ ecgChartData: ecgChartData });
                }
                this.setState({ isECGDataLoading: false });
            }.bind(this),
            error: (error) => {
                console.error(error);
            },
        });
    }

    formatTimestamp(date) {
        var month = "" + (date.getMonth() + 1),
            day = "" + date.getDate(),
            year = date.getFullYear(),
            hours = "" + date.getHours(),
            minute = "" + date.getMinutes(),
            second = "" + date.getSeconds(),
            millisecond = "" + date.getMilliseconds();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        if (minute.length < 2) minute = "0" + minute;
        if (second.length < 2) second = "0" + second;
        if (millisecond.length < 2) millisecond = "00" + millisecond;
        return `${day}-${month}-${year} ${hours}:${minute}:${second}:${millisecond}`;
    }

    render() {
        const divStyle = {
            display: "flex",
            alignItems: "center",
        };
        return (
            <div>
                {this.state.isECGDataLoading ? (
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Card plain>
                                <CardBody style={{ height: "100px" }}>
                                    <CircularProgress
                                        style={{
                                            position: "absolute",
                                            left: "50%",
                                            top: "50%",
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                ) : (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Card style={{ background: "black" }}>
                                <CardBody>
                                    <div style={divStyle}>
                                        <DateRangePicker dateLimit={{ days: 1 }} maxDate={this.state.maxDate} onApply={this.handleEvent}>
                                            <Button id="datePicker" style={{ marginRight: "1rem" }} color="info">
                                                <FontAwesomeIcon icon={"calendar"} size="2x" />
                                            </Button>
                                        </DateRangePicker>
                                        <h5 style={{ fontSize: "0.99em", color: "white", marginLeft: "10px" }}>
                                            <b>Date :</b>
                                            {`  ${new Date(this.state.end_date).toLocaleDateString()}`}
                                        </h5>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                        {this.state.ecgChartData.length > 0 ? (
                            this.state.ecgChartData.map((data, count) => {
                                return (
                                    <GridItem key={count} xs={12} sm={12} md={12} lg={12}>
                                        <Card style={{ background: "black" }}>
                                            <CardBody>
                                                <h4 style={{ fontSize: "0.99em", color: "white" }}>
                                                    <b>Date : </b>
                                                    {this.state.starting_date[count]}
                                                </h4>
                                                <Chart
                                                    width={"100%"}
                                                    height={"400px"}
                                                    chartType={"LineChart"}
                                                    series={{ 0: { axis: "Time" }, 1: { axis: "Data" } }}
                                                    options={{
                                                        hAxis: { textPosition: "none" },
                                                        vAxis: { textPosition: "none" },
                                                        legend: "none",
                                                        chartArea: { top: 5, width: "80%", height: "80%" },
                                                        colors: ["#ff4d4d"],
                                                    }}
                                                    data={data}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                );
                            })
                        ) : (
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody style={{ height: "200px", background: "black" }}>
                                        <h4
                                            style={{
                                                marginTop: "80px",
                                                textAlign: "center",
                                                color: "white"
                                            }}
                                        >
                                            No data is currently available for this period.
                                        </h4>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        )}
                    </GridContainer>
                )}
            </div>
        );
    }
}

export default AppleHealthECG;
